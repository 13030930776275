import { Text } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import WellbeingSlider from '../WellbeingSlider'
import { Container, Label } from './index.styles'
import { BurnoutSliderProps } from './index.types'

export default function BurnoutSlider({
  value,
  minValue,
  maxValue,
  setValue,
  isDisable,
  style,
}: BurnoutSliderProps) {
  const theme = useTheme()
  const { t } = useTranslation('assessment')

  return (
    <Container>
      {theme.device === 'large' && <Label>{t('never')}</Label>}
      <WellbeingSlider
        style={style}
        type='burnout'
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        setValue={setValue}
        isDisable={isDisable}
      />
      {theme.device === 'large' && <Label>{t('everyday')}</Label>}
      {theme.device !== 'large' && (
        <View
          style={{
            flex: 1,
            width: '100%',
            marginTop: 16,
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}>
          <Text>{t('never')}</Text>
          <Text>{t('everyday')}</Text>
        </View>
      )}
    </Container>
  )
}
