import { IS_BROWSER } from '@ui/utils'
import { Animated } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled, { css } from 'styled-components/native'

const web = css`
  box-shadow: 0 -2px 13px rgb(51, 51, 51);
  transition: all 0.15s ease-in-out;
`

export const Header = styled.View.attrs({
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,
  elevation: 5,
})`
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => (theme.device === 'large' ? '80px' : '52px')};
  width: 100%;
  padding-horizontal: ${({ theme }) =>
    theme.device === 'large' ? '52px' : '16px'} ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 999;
  ${IS_BROWSER && web};
`

export const Logo = styled.Image`
  height: 48px;
  width: 95px;
`

export const ActionContainer = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const ActionItem = styled.View<{ last?: boolean }>`
  padding-horizontal: ${({ theme }) =>
    theme.device === 'large' ? '16px' : '8px'};
  ${({ last }) =>
    !last &&
    css`
    border-right-width: 1px;
    border-right-styled: solid;
    border-right-color: ${({ theme }) => theme.colors.separatorGrey}
  `}
`
