import styled from 'styled-components/native'

import { Animated } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

export const Gradient = styled(LinearGradient)`
  width: 100%;
  height: 15px;
  border-radius: 40px;
`

export const AnimatedView = styled(Animated.View)`
  position: absolute;
  z-index: 2;
  top: -8;
  margin-left: -10;
`

export const Container = styled.View`
  min-height: 15px;
`
