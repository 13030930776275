import React, { useRef, useState, useEffect } from 'react'
import {
  OTPInputContainer,
  SplitOTPBoxesContainer,
  TextInputHidden,
  SplitBoxes,
  SplitBoxText,
  SplitBoxesFocused,
} from './index.styles'
import { OnDevice } from '@ui/OnDevice'
import { useTheme } from '@ui/utils'

const OTPInput = ({ code, setCode, maximumLength, setIsPinReady }) => {
  const boxArray = new Array(maximumLength).fill(0)
  const inputRef = useRef()
  const theme = useTheme()

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false)

  const handleOnPress = () => {
    setIsInputBoxFocused(true)
    inputRef.current.focus()
  }

  const handleOnBlur = () => {
    setIsInputBoxFocused(false)
  }

  useEffect(() => {
    // update pin ready status
    setIsPinReady(code.length === maximumLength)
    // clean up function
    return () => {
      setIsPinReady(false)
    }
  }, [code])

  const boxDigit = (_, index) => {
    const emptyInput = ''
    const digit = code[index] || emptyInput

    const isCurrentValue = index === code.length
    const isLastValue = index === maximumLength - 1
    const isCodeComplete = code.length === maximumLength

    const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete)

    const StyledSplitBoxes =
      isInputBoxFocused && isValueFocused ? SplitBoxesFocused : SplitBoxes
    return (
      <StyledSplitBoxes hasValue={digit !== emptyInput} key={index}>
        <SplitBoxText>{digit}</SplitBoxText>
      </StyledSplitBoxes>
    )
  }

  return (
    <OTPInputContainer>
      <SplitOTPBoxesContainer onPress={handleOnPress}>
        <SplitBoxes hasValue={true} defaultValue>
          <SplitBoxText>{'M'}</SplitBoxText>
        </SplitBoxes>
        <SplitBoxes hasValue={true} defaultValue>
          <SplitBoxText>{'W'}</SplitBoxText>
        </SplitBoxes>
        {boxArray.map(boxDigit)}
      </SplitOTPBoxesContainer>
      <TextInputHidden
        value={code}
        onChangeText={(code) => setCode(code)}
        autoCapitalize='characters'
        maxLength={maximumLength}
        ref={inputRef}
        onBlur={handleOnBlur}
      />
    </OTPInputContainer>
  )
}

export default OTPInput
