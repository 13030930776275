import { MODAL_ASSESSMENT } from '@core/constants'
import { fetchModal } from '@core/lib/modal'
import { AssessmentsNavigation } from '@core/lib/types'
import { useAssessment } from '@core/services/assessments'
import { useUserInfo } from '@core/services/viewer'
import { WELLBEING_ASSESSMENT_KEY } from '@core/types/assessment'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Assessment } from '@ui/Moodwork-UI/organisms/Test/Assessment'
import { AssessmentSkeleton } from '@ui/Moodwork-UI/organisms/Test/Assessment.loading'
import * as React from 'react'
import { useEffect } from 'react'

const Assessments = () => {
  const params = useRoute<AssessmentsNavigation>().params
  const { data, isFetching, isError } = useAssessment(
    params?.key ?? WELLBEING_ASSESSMENT_KEY
  )
  const navigation = useNavigation()
  const { data: userInfoData, isSuccess } = useUserInfo()

  useEffect(() => {
    if (isSuccess) {
      if (
        (params?.key ?? WELLBEING_ASSESSMENT_KEY) === WELLBEING_ASSESSMENT_KEY
      ) {
        fetchModal(userInfoData?.email, MODAL_ASSESSMENT).then((response) => {
          if (response != 'false') {
            navigation.navigate(MODAL_ASSESSMENT)
          }
        })
      }
    }
  }, [params?.key, userInfoData?.email])

  // proc error if the key params is wrong
  useEffect(() => {
    if (isError) {
      throw new Error('')
    }
  }, [isError])

  return (
    <>
      {isFetching ? (
        <AssessmentSkeleton
          assessmentKey={params?.key ?? WELLBEING_ASSESSMENT_KEY}
        />
      ) : (
        <Assessment data={data}></Assessment>
      )}
    </>
  )
}

export default Assessments
