import { UserTest } from '@core/types/history'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery, UseQueryResult } from 'react-query'
import { useHttp } from './http'

export function useResults(
  id?: string,
  withDiaries = false
): UseQueryResult<UserTest, AxiosError> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getResults = async () => {
    if (!id) {
      return
    }
    return http
      .get(`/api/v1/user_tests/${id}?recommended_diaries=${withDiaries}`)
      .then((r) => r.data)
  }
  return useQuery([`fetch::test-results`, id, i18n.language], getResults, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 0,
    staleTime: 1000 * 60 * 60,
  })
}

export function useRecommendations(id: string) {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getRecommendations = async () =>
    (await http.get(`/api/v3/user_tests/${id}/recommendation_detailed`))?.data
      ?.recommended_diaries
  return useQuery(
    `fetch:${i18n.language}:test-results-recommendations`,
    getRecommendations,
    {
      retry: 1,
      staleTime: 0,
    }
  )
}

export function useResultsHistory() {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getResultsHistory = async () =>
    (await http.get(`/api/v3/user_tests`))?.data
  return useQuery(
    [`fetch::test-results-history`, i18n.language],
    getResultsHistory,
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      retry: 0,
      staleTime: 0,
    }
  )
}
