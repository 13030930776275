import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import customEvent from 'highcharts-custom-events'
import moment from 'moment'
import { Pressable, useWindowDimensions } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { useTranslation } from 'react-i18next'
import Color from 'color'
import { Button, Title, Text, SubTitle, FontIcon } from '@ui/Moodwork-UI'
import { useTheme } from '@ui/utils'
import {
  AssesmentContainer,
  Container,
  GraphContainer,
  LeftContainer,
  PageContent,
  RightContainer,
} from './index.styles'
import { trackEvent } from '@core/lib/analytics'
import { useNavigationLink } from '@core/lib/navigation'
import { HOST, SCREEN_ASSESSMENT } from '@core/constants'
import { reverse } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import 'moment/min/locales'

export function interpolateColor(value: number) {
  const colors = ['#FF6086', '#FFD644', '#51CB9F']
  let color1, color2
  if (value < 50) {
    color1 = new Color(colors[0])
    color2 = new Color(colors[1])
  } else {
    color1 = new Color(colors[1])
    color2 = new Color(colors[2])
  }

  value = value -= 25

  let red = Math.round(
    color1.red() + (value / 100) * (color2.red() - color1.red())
  )
  let green = Math.round(
    color1.green() + (value / 100) * (color2.green() - color1.green())
  ) // 214 96 118
  let blue = Math.round(
    color1.blue() + (value / 100) * (color2.blue() - color1.blue())
  )

  let finalColor =
    '#' + red.toString(16) + green.toString(16) + blue.toString(16)

  return finalColor
}
export default function WellbeingGraph({
  data,
  selected,
  setSelected,
  isFetching,
}: {
  data: any[]
  selected?: string
  setSelected: (s: string) => void
  isFetching: boolean
}) {
  const chartRef = useRef()
  const navigation = useNavigation()
  const { i18n, t } = useTranslation('result')
  const [max, setMax] = useState(5)
  const [currentPage, setCurrentPage] = useState(0)
  const [width, setWidth] = useState()
  const dimension = useWindowDimensions()
  const theme = useTheme()
  const device = theme.device
  moment.locale(i18n.language)
  customEvent(Highcharts)

  const [graphOption, setGraphOption] = useState<Highcharts.Options>({
    credits: {
      enabled: false,
    },
    chart: {
      marginLeft: 0,
    },
    title: {
      text: '',
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: 'white',
          lineWidth: 2,
          radius: 12,
        },
        color: 'black',
        cursor: 'pointer',
      },
    },
    xAxis: {
      labels: {
        formatter: function () {
          let isSelected =
            moment(
              data.filter((d) => d.uuid === selected)[0]?.finish_at
            ).format('DD MMM YYYY, HH[h]mm') == this.value
          return `<p style="font-family: 'Asap'; font-weight: 400; font-size: 14px; padding: 16px; border-radius: 5px;${
            isSelected
              ? 'box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); color: #1E32F5; font-weight: 700'
              : ''
          }">${this.value}</p>`
        },
        // @ts-ignore
        events: {
          click: function () {
            let res = data.filter(
              (d) =>
                moment(d.finish_at).format('DD MMM YYYY, HH[h]mm') ===
                this.value.split('>')[1].split('<')[0]
            )
            if (res.length) {
              setSelected(res[0].uuid)
            }
          },
        },
        useHTML: true,
      },
      reversed: true,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      labels: {
        formatter: () => {},
      },
      title: '',
      min: 0,
      max: 100,
      alternateGridColor: '#FAFAFA',
      tickInterval: 20,
      gridLineWidth: 0,
    },
    tooltip: {
      formatter: function () {
        return this.key // TODO: dynamic
      },
    },
  })

  const onLeftPress = useCallback(() => {
    let extremeMin = (currentPage + 1) * max
    let extremeMax = (currentPage + 1) * max + max - 1
    setCurrentPage(currentPage + 1)
    chartRef.current.chart.axes[0].setExtremes(extremeMin, extremeMax)
  }, [currentPage, chartRef, max])

  const onRightPress = useCallback(() => {
    let extremeMin = (currentPage - 1) * (max - 1)
    let extremeMax = (currentPage - 1) * (max - 1) + max - 1
    setCurrentPage(currentPage - 1)
    chartRef.current.chart.axes[0].setExtremes(extremeMin, extremeMax)
  }, [currentPage, chartRef, max])

  const sendAnalytics = (itemId, eventName) => {
    const item = data.filter((d) => d.uuid === itemId)
    if (!item) {
      return
    }
    trackEvent(eventName, {
      test_uuid: item[0].uuid,
      test_date: item[0].finish_at,
      test_score: item[0].score,
      wellbeing_level: item[0].risk_level_name,
    })
  }

  useEffect(() => {
    if (!data || isFetching) {
      return
    }
    const seriesData = data.map((d) => {
      const color = interpolateColor(d.score)
      return {
        name: d.risk_level_name,
        y: d.score,
        marker: { lineColor: color },
        events: {
          click: function () {
            sendAnalytics(d.uuid, 'clicked_tracker_graph_datapoint')
            setSelected(d.uuid)
          },
        },
      }
    })
    const categoriesData = data.map((d) =>
      moment(d.finish_at).format('DD MMM YYYY, HH[h]mm')
    )
    setGraphOption({
      credits: {
        enabled: false,
      },
      chart: {
        marginLeft: 0,
        marginTop: 0,
        height: 450,
        width: device === 'large' ? null : width,
      },
      title: {
        text: '',
      },
      plotOptions: {
        series: {
          marker: {
            fillColor: 'white',
            lineWidth: 2,
            radius: 12,
          },
          point: {
            events: {
              click: function () {
                let res = data.filter(
                  (d) =>
                    moment(d.finish_at).format('DD MMM YYYY, HH[h]mm') ===
                    this.category
                )
                if (res.length) {
                  sendAnalytics(res[0].uuid, 'clicked_tracker_graph_datapoint')
                  setSelected(res[0].uuid)
                }
              },
            },
          },
          color: 'black',
        },
      },
      xAxis: {
        showEmpty: false,
        lineWidth: 0,
        categories: reverse(categoriesData),

        max:
          categoriesData.length > max - 1 ? max - 1 : categoriesData.length - 1,
        min: 0,
        maxPadding: 0.5,
        minPadding: 0.5,
        labels: {
          style: {
            width: device === 'small' ? '100%' : null,
          },
          formatter: function () {
            if (typeof this.value === 'number') {
              return ''
            }
            let isSelected =
              moment(
                data.filter((d) => d.uuid === selected)[0]?.finish_at
              ).format('DD MMM YYYY, HH[h]mm') == this.value
            return `<p style="font-size: 14px; cursor: pointer; padding: ${
              device === 'large' ? 16 : 12
            }px; border-radius: 5px;${
              isSelected
                ? "box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); color: #1E32F5; font-family: 'Asap_700Bold'; font-weight: 700"
                : "font-family: 'Asap_400Regular'; font-weight: 400;"
            }">${this.value}</p>`
          },
          // @ts-ignore
          events: {
            click: function () {
              let res = data.filter(
                (d) =>
                  moment(d.finish_at).format('DD MMM YYYY, HH[h]mm') ===
                  this.value.split('>')[1].split('<')[0]
              )
              if (res.length) {
                sendAnalytics(res[0].uuid, 'clicked_tracker_graph_date')
                setSelected(res[0].uuid)
              }
            },
          },
          useHTML: true,
        },
        reversed: true,
        scrollbar: {
          enabled: true,
        },
      },
      yAxis: {
        labels: {
          formatter: () => {},
        },
        title: '',
        min: 0,
        max: 103,
        alternateGridColor: '#FAFAFA',
        tickInterval: 21,
        gridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return `<p style="font-family: 'Asap'; background-color: white; font-weight: 400; font-size: ${
            device === 'large' ? 14 : 12
          }px; padding: 16px 8px; border-radius: 5px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);">${
            this.key
          }</p>`
        },
        style: {
          zIndex: 100,
        },
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0,
        shadow: false,
        useHTML: true,
      },
      series: [{ showInLegend: false, data: reverse(seriesData) }],
    })
  }, [data, selected, device, width, isFetching])

  useEffect(() => {
    if (data) {
      setSelected(data[data.length - 1].uuid)
    }
  }, [data])

  useEffect(() => {
    setWidth(dimension.width - (device === 'small' ? 42 : 118))
  }, [dimension, chartRef, device])

  useEffect(() => {
    setMax(device === 'large' ? 5 : 3)
  }, [device])

  return (
    <PageContent>
      {device === 'large' && <Title>{t('tracking.title')}</Title>}
      <Container>
        <LeftContainer>
          <LinearGradient
            style={{ width: 16, height: 355, borderRadius: 40 }}
            colors={['#FF6086', '#FFD644', '#51CB9F']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }}
            locations={[0, 0.5, 1]}
          />
          <GraphContainer>
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={graphOption}
            />
            {currentPage * max + max < data?.length && (
              <Pressable onPress={onLeftPress}>
                <FontIcon
                  style={{ position: 'absolute', left: -16, bottom: 42 }}
                  name='arrow-left'
                />
              </Pressable>
            )}
            {currentPage !== 0 && (
              <Pressable onPress={onRightPress}>
                <FontIcon
                  style={{
                    position: 'absolute',
                    right: device === 'small' ? 16 : 0,
                    bottom: 42,
                  }}
                  name='arrow-right'
                />
              </Pressable>
            )}
          </GraphContainer>
        </LeftContainer>
        {device !== 'large' && <Title>{t('tracking.title')}</Title>}
        <RightContainer>
          <AssesmentContainer>
            <SubTitle size='L' marginSize='0'>
              {t('tracking.remake-title')}
            </SubTitle>
            <Text marginSize='16px 0'>{t('tracking.remake-text')}</Text>
            <Button
              label={t('tracking.make-assessment')}
              trackLabel='clicked_tracker_take_assessment_test'
              onPress={() =>
                navigation.push(SCREEN_ASSESSMENT, {
                  key: 'wellbeing_assessment',
                })
              }
            />
          </AssesmentContainer>
        </RightContainer>
      </Container>
    </PageContent>
  )
}
