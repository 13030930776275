import { trackEvent } from '@core/lib/analytics'
import { useSyncReady } from '@core/lib/ready'
import { useRecommendedResources } from '@services/dashboard'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import RecommendedResources from '../RecommendedResources'

export default function RecommendedResourcesForYou({
  onVisible,
}: {
  onVisible: (visible: boolean) => void
}) {
  const { data } = useRecommendedResources()
  const isReady = useSyncReady('dashboard', 'for_you', !!data)
  const { t } = useTranslation('dashboard')

  useEffect(() => {
    if (data != null && data.length > 0) {
      onVisible(true)
    }
  }, [data?.length])

  useEffect(() => {
    if (isReady && data) {
      trackEvent('viewed_db_reading_recommendation', {
        nb_recommended_items: data.length,
      })
    }
  }, [isReady])

  if (isReady) {
    return (
      <RecommendedResources
        title={t('dashboard.recommendation.for_you')}
        data={data}
        trackLabel='clicked_db_based_on_reading_item'
      />
    )
  }
  return null
}
