import { FontIcon } from '../../atoms/images/FontIcon'
import React, { useMemo } from 'react'
import { PrefixedContentProps } from './interface'
import {
  Container,
  PrefixContainer,
  PrefixLine,
  ImageIcon,
} from './PrefixedContent.styles'
import { useTheme } from '@ui/utils'
import { ViewProps } from 'react-native'
import riskIcon from '../../../../assets/images/history/mal_etre.png'
import protectionIcon from '../../../../assets/images/history/bien_etre.png'
import questionIcon from '../../../../assets/images/resource/question.png'

export const PrefixedContent = ({
  prefix = 'none',
  color,
  children,
  alignPosition = 'baseline',
  style,
  big,
}: PrefixedContentProps & ViewProps) => {
  const theme = useTheme()
  const renderPrefixElement = useMemo(() => {
    switch (prefix) {
      case 'line':
        return <PrefixLine color={color}></PrefixLine>
      case 'star':
        return (
          <PrefixContainer positionContent={alignPosition}>
            <FontIcon
              size={theme.device === 'large' ? 32 : 20}
              name='star-circle'
              color={theme.colors[color || 'secondary']}
            />
          </PrefixContainer>
        )
      case 'risk':
        return (
          <PrefixContainer positionContent={alignPosition}>
            <ImageIcon big={big} source={riskIcon} resizeMode='contain' />
          </PrefixContainer>
        )
      case 'protection':
        return (
          <PrefixContainer positionContent={alignPosition}>
            <ImageIcon big={big} source={protectionIcon} resizeMode='contain' />
          </PrefixContainer>
        )
      case 'question':
        return (
          <PrefixContainer position={alignPosition}>
            <ImageIcon big={big} source={questionIcon} resizeMode='contain' />
          </PrefixContainer>
        )
      default:
        return <></>
    }
  }, [theme.device, theme.colors, color, prefix])

  return (
    <Container style={style}>
      {renderPrefixElement}
      {children}
    </Container>
  )
}
