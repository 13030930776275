import {
  ActionTitle,
  Line,
  SectionTitle,
  SmallParagraph,
} from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'

export const Title = styled(ActionTitle)`
  flex: 1;
  margin-bottom: ${({ theme }) => `${theme.spacings.XL}px`};
`

export const WebinarTitle = styled(SectionTitle)`
 font-size: ${({ theme }) => theme.textSizes.M};
`

export const WebinarInfos = styled(SmallParagraph)`
`

export const Separator = styled(Line)`
  margin-vertical: ${({ theme }) => `${theme.spacings.L}px`};
`
