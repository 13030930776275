import styled from 'styled-components/native'
import { ContainerProps } from './ContentTag.types'
import Color from 'color'

export const Container = styled.View<ContainerProps>`
  padding: 8px 16px;
  align-self: flex-start;
  background-color: ${({ color, theme }) =>
    Color(theme.colors[color]).darken(0.2).desaturate(0.4).hex()};
  border-radius: 500px;
`

export const Content = styled.Text`
  color: white;
  font-family: Inter_700Bold;
  font-size: 12px;
`
