import styled from 'styled-components/native'

export const Container = styled.Pressable`
    margin-vertical: 8px;
    margin-left: 8px;
    flex-direction: row;
    flex: 1;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.darkerGrey};
    padding: 5px 14px;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    max-height: 32px;
`
