import { FlexCenter, FlexRow } from '../../templates/Flex'
import styled, { css } from 'styled-components/native'
import { Line } from '@ui/Moodwork-UI/atoms'
import { Position } from './interface'

export const PrefixContainer = styled(FlexCenter)<{
  positionContent?: Position
}>`
  align-self: flex-start;
  ${({ positionContent }) => {
    switch (positionContent) {
      case 'baseline':
        return 'margin-top: 12px;'
      case 'center':
        return 'margin-top: 0px;'
      default:
        return 'margin-top: 12px;'
    }
  }};
  margin-right: ${({ theme }) => (theme.device !== 'small' ? '8px' : '4px')};
  ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return 'min-width: 22px;'
    }
  }};
`

export const Container = styled(FlexRow)`
  align-items: center;
  ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return 'padding-right: 32px;'
      case 'medium':
        return 'padding-right: 26px;'
      case 'large':
        return 'padding-right: 50px;'
      default:
        return 'padding-right: 12px;'
    }
  }};
`

export const PrefixLine = styled(Line)`
  margin-right: ${({ theme }) => (theme.device !== 'small' ? '8px' : '4px')};
  ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return 'width: 12px;'
      case 'medium':
        return 'width: 26px;'
      case 'large':
        return 'width: 40px;'
      default:
        return 'width: 12px;'
    }
  }};
`

export const ImageIcon = styled.Image<{ big?: boolean }>`
  height: ${({ theme, big }) =>
    theme.device === 'large' ? '32px' : big ? '39px' : '20px'}
  width: ${({ theme, big }) =>
    theme.device === 'large' ? '32px' : big ? '39px' : '20px'}
`
