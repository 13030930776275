import { useQuery, UseQueryResult } from 'react-query'
import { useHttp } from './http'
import { useTranslation } from 'react-i18next'
import { Webinar } from '@types/webinar'
import { AxiosError } from 'axios'

export function useWebinars(
  limit?: number
): UseQueryResult<Webinar[], AxiosError> {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getWebinars = async () =>
    (
      await http.get(
        `/api/v3/webinars${limit && limit > 0 ? `?limit=${limit}` : ''}`
      )
    )?.data
  return useQuery(['webinars', i18n.language], getWebinars, {
    retry: 1,
    refetchOnWindowFocus: false,
  })
}

export function useWebinar(id: string): UseQueryResult<Webinar, AxiosError> {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getWebinar = async () =>
    (await http.get(`/api/v3/webinars/${id}`))?.data
  return useQuery(['webinar', id, i18n.language], getWebinar, {
    retry: 1,
    refetchOnWindowFocus: false,
  })
}