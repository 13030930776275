import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AppointmentReminderProps } from './AppointmentReminder.types'
import {
  Container,
  ContainerRight,
  ContainerWrapped,
  Date,
  Picture,
} from './AppointmentReminder.styles'
import { DateTime } from 'luxon'
import { Text } from '@moodwork-ui'

export default function AppointmentReminder({
  date,
  name,
  pictureUrl,
}: AppointmentReminderProps) {
  const { t, i18n } = useTranslation('dates')
  const fulldate = useMemo(() => {
    const datetime = DateTime.fromISO(date)
    return [
      datetime.toLocaleString({
        locale: i18n.language,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      t('at'),
      datetime.toLocaleString({
        locale: i18n.language,
        hour: '2-digit',
        minute: '2-digit',
      }),
    ].join(' ')
  }, [date, i18n])

  return (
    <Container>
      <Date size='XS' weight='medium'>
        {fulldate}
      </Date>
      <ContainerWrapped>
        <Picture source={{ uri: pictureUrl }} />
        <ContainerRight>
          <Text size='XS' weight='medium' color='grey'>
            {t('dates:with')}
          </Text>
          <Text size='XS' weight='medium'>
            {name}
          </Text>
        </ContainerRight>
      </ContainerWrapped>
    </Container>
  )
}
