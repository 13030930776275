import { Text } from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'
import { Line } from '@ui/Moodwork-UI/atoms'

export const Container = styled.View`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: auto;
  padding: ${({ theme }) =>
    theme.device === 'small' ? '16px' : '32px 24px 24px 24px'};
`

export const Separator = styled.View`
  height: 32px;
  width: 32px;
`
