import { FlexRow, Flex } from '@ui/Moodwork-UI/templates/Flex'
import styled, { css } from 'styled-components/native'
import { Sizes } from './interface'

export const Container = styled(FlexRow)`
  max-width: 100%;
  align-items: flex-start;
`
export const ContentContainer = styled.View`
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
`

export const ImageContainer = styled(Flex)`
  margin-right: ${({ theme }) => theme.spacings.L + 'px'};
  margin-vertical: ${({ theme }) => theme.spacings.L + 'px'};
`

export const Image = styled.Image<{ sizes: Sizes; rounded: boolean }>`
  ${({ rounded }) => rounded && css`border-radius: 50px;`};
  height: ${({ theme, sizes }) => {
    switch (theme.device) {
      case 'small':
        return `${sizes.small.height}px`
      case 'medium':
        return `${sizes.medium.height}px`
      case 'large':
        return `${sizes.large.height}px`
      default:
        return `${sizes.small.height}px`
    }
  }};
  width: ${({ theme, sizes }) => {
    switch (theme.device) {
      case 'small':
        return `${sizes.small.width}px`
      case 'medium':
        return `${sizes.medium.width}px`
      case 'large':
        return `${sizes.large.width}px`
      default:
        return `${sizes.small.width}px`
    }
  }};
`
