import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigationLink } from '@core/lib/navigation'
import { HOST, SCREEN_SIGNUP_EMAIL } from '@core/constants'
import imgStep1 from '../../assets/images/sign/img_step1.png'
import imgStep2 from '../../assets/images/sign/img_step2.png'
import imgStep3 from '../../assets/images/sign/img_step3.png'

import Tutorials from '@core/screens/Tutorials'
import {
  ButtonContainer,
  Next,
  NextContainer,
  Previous,
  PreviousContainer,
} from '@ui/AppointmentTutorial/AppointmentTutorial.style'
import { Icon } from '@ui/Moodwork-UI/molecules/pressables/Icon'
import { OnDevice } from '@ui/OnDevice'
import BullList from '@ui/BullList'
import { Logo } from '@ui/Logo'
import { HomePressable, Container } from './SignUpIntroSlides.style'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useTheme } from '@ui/utils'
import { trackEvent } from '@core/lib/analytics'
import { SignUpIntroSlidesProps } from './SignUpIntroSlides.type'

export default function SignUpIntroSlides({
  setVisible,
}: SignUpIntroSlidesProps) {
  const { t, i18n } = useTranslation(['appointments', 'sign'])
  const [displayNext, setDisplayNext] = useState(false)
  const [displayPrevious, setDisplayPrevious] = useState(false)
  const [displayStart, setDisplayStart] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const route = useRoute()
  const navigation = useNavigation()
  const linkTo = useNavigationLink()
  const backToShowcase = useCallback(() => {
    trackEvent('clicked_intro_logo', {
      popin_step: currentStep + 1,
    }).then(() => linkTo(`${HOST}`))
  }, [currentStep])

  useEffect(() => {
    if (route?.params?.locale && i18n.language !== route?.params?.locale) {
      i18n.changeLanguage(route?.params?.locale)
    }
  }, [i18n, route])

  const handleChooseStep = useCallback(
    (step) => setCurrentStep(step),
    [currentStep]
  )
  const steps = [
    {
      screen: (
        <>
          <Tutorials
            setCurrentStep={handleChooseStep}
            image={imgStep1}
            imageBackground={false}
            title={t('sign:up.understand.title')}
            description={t('sign:up.understand.description')}
          />
        </>
      ),
      enableNext: true,
      enablePrevious: false,
      enableStart: false,
    },
    {
      screen: (
        <>
          <Tutorials
            setCurrentStep={handleChooseStep}
            image={imgStep2}
            imageBackground={false}
            title={t('sign:up.act.title')}
            description={t('sign:up.act.description')}
          />
        </>
      ),
      enableNext: true,
      enablePrevious: true,
      enableStart: false,
    },
    {
      screen: (
        <>
          <Tutorials
            setCurrentStep={handleChooseStep}
            image={imgStep3}
            imageBackground={false}
            title={t('sign:up.talk.title')}
            description={t('sign:up.talk.description')}
          />
        </>
      ),
      enableNext: false,
      enablePrevious: true,
      enableStart: true,
    },
  ]

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1)
  }, [currentStep])
  const handlePreviousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }, [currentStep])

  const handleEnd = useCallback(() => {
    navigation.push(SCREEN_SIGNUP_EMAIL, {
      locale: route?.params?.locale || 'fr',
      code: route?.params?.code || undefined,
    })
  }, [route])

  useEffect(() => {
    const step = steps[currentStep]
    if (step) {
      setDisplayNext(step['enableNext'])
      setDisplayPrevious(step['enablePrevious'])
      setDisplayStart(step['enableStart'])
    }
  }, [currentStep])

  const device = useTheme().device

  return (
    <Container>
      <HomePressable onPress={backToShowcase} device={device}>
        <Logo big={device === 'large'} />
      </HomePressable>
      {currentStep >= 0 && steps[currentStep]['screen']}
      <ButtonContainer>
        <PreviousContainer
          displayElement={displayPrevious}
          onPress={handlePreviousStep}>
          <Icon
            disabled={!displayPrevious}
            icon='arrow-left'
            onPress={handlePreviousStep}
            trackLabel='clicked_intro_previous'
            trackProperties={{ popin_step: currentStep + 1 }}
          />
          <OnDevice size={['large', 'medium']}>
            <Previous>{t('tuto.buttons.back')}</Previous>
          </OnDevice>
        </PreviousContainer>
        <BullList
          analyticsEventName={'clicked_intro_bullet'}
          setCurrentStep={setCurrentStep}
          activeStep={currentStep}
          numberOfSteps={steps.length}
        />
        {displayNext && (
          <NextContainer onPress={handleNextStep}>
            <OnDevice size={['large', 'medium']}>
              <Next>{t('tuto.buttons.next')}</Next>
            </OnDevice>
            <Icon
              icon='arrow-right'
              onPress={handleNextStep}
              trackLabel='clicked_intro_next'
              trackProperties={{ popin_step: currentStep + 1 }}
            />
          </NextContainer>
        )}
        {displayStart && (
          <NextContainer onPress={handleEnd}>
            <OnDevice size={['large', 'medium']}>
              <Next>{t('tuto.buttons.next')}</Next>
            </OnDevice>
            <Icon
              icon='arrow-right'
              onPress={handleEnd}
              trackLabel='clicked_intro_next'
              trackProperties={{ popin_step: currentStep + 1 }}
            />
          </NextContainer>
        )}
      </ButtonContainer>
    </Container>
  )
}
