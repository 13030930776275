import * as React from 'react'
import styled from 'styled-components/native'
import { Conference } from '../../ui/Conference'
import { Spacer } from '../../ui/Spacer'
import { TitleSurTitle } from '../../ui/TitleSurTitle'

const Container = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingTop: 90,
    alignItems: 'center',
  },
})`
  flex: 1;
`

/**
 * TODO: Flatlist
 */
export default function Conferences() {
  return (
    <Container>
      <TitleSurTitle>Participer à une conférence en ligne</TitleSurTitle>
      <Spacer size={32} />
      <Conference />
      <Conference />
      <Conference />
      <Conference />
      <Conference />
      <Conference />
    </Container>
  )
}
