import React, { useCallback, useMemo } from 'react'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import ResourceCardProps, {
  ResourceCardLayoutProps,
} from './ResourceCard.types'
import {
  CardWrapper,
  Container,
  Image,
  Tag,
  Title,
  ImageGradient,
  SoundContainer,
  ImageContainer,
  TopContainer,
  FooterButtonContainer,
  FooterButtonText,
  SuccessContainer,
  CardWrapperContainer,
  TitleContainer,
} from './ResourceCard.style'
import { FontIcon, SmallParagraph } from '@moodwork-ui'
import { useTheme } from '@utils'
import { useToggle } from '@core/lib/hooks'
import { useTranslation } from 'react-i18next'
import { Text } from '@moodwork-ui'
import { DateTime } from 'luxon'
import { capitalize } from 'lodash'
import { FooterInformations } from '../../Resource/FooterInformations'
import ProgressComponent from './ProgressComponent'
import { GestureResponderEvent } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { MODAL_SHARE_RESOURCE } from '@core/constants'

export const getResourcesCardHeight = (
  data: { title: string; collection_count?: number }[],
  initialHeight: number = 272
) => {
  const { collections, others } = data.reduce(
    (lengths, resource) => {
      const key =
        resource?.collection_count != null && resource?.collection_count > 0
          ? 'collections'
          : 'others'
      return {
        ...lengths,
        [key]: Math.max(lengths[key], resource?.title?.length || 0),
      }
    },
    {
      collections: 0,
      others: 0,
    }
  )

  const maxHeight =
    initialHeight +
    Math.max(
      Math.max(0, Math.ceil(collections / 23) - 2) * 33,
      Math.max(0, Math.ceil(others / 23) - 3) * 33
    )

  return `${maxHeight}px`
}

export default function ResourceCard({
  uuid,
  type,
  title,
  date,
  tag,
  tagKey,
  duration,
  image,
  sound = false,
  onCardPress,
  onFavorite,
  trackLabel,
  padding = '32px 32px 0 0',
  bottom,
  removeImageOverlay,
  count,
  sizeForInline = ['small', 'medium'],
  trackProperties,
  customImageHeight,
  state,
  favorite,
  displayCollectionProgress,
  first = false,
  last = false,
}: ResourceCardProps & ResourceCardLayoutProps & TrackableEventProps) {
  const [hover, onHoverIn, onHoverOut] = useToggle(false)
  const navigation = useNavigation()
  const theme = useTheme()
  const _onCardPress = useCallback((event: GestureResponderEvent) => {
    trackEvent(trackLabel, trackProperties).then(() => onCardPress(event))
  }, [])

  const onSharePress = useCallback(() => {
    trackEvent('clicked_resource_share', {
      uuid: uuid,
      title: title,
      tag: tagKey,
    }).then(() => {
      navigation.navigate(MODAL_SHARE_RESOURCE, {
        resourceType: type,
        resourceUuid: uuid,
        resourceStickerKey: tagKey,
        resourceTitle: title,
      })
    })
  }, [type, uuid])

  const onFavoritePress = useCallback(
    (addFavorite: boolean) => {
      trackEvent(
        addFavorite
          ? 'clicked_resource_add_to_favorites'
          : 'clicked_resource_remove_from_favorites',
        {
          uuid: uuid,
          title: title,
          tag: tagKey,
          is_favorited: favorite,
        }
      )
      onFavorite && onFavorite({ type, resourceUuid: uuid, addFavorite })
    },
    [type, uuid]
  )

  const { t, i18n } = useTranslation(['resources', 'dashboard'])
  const listMode = sizeForInline.includes(theme.device)

  const fulldate = useMemo(() => {
    const datetime = DateTime.fromISO(date)
    let resourceCardDate = [
      datetime.toLocaleString({
        locale: i18n.language,
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      }),
    ]
    if (tagKey != 'replay') {
      resourceCardDate = [
        ...resourceCardDate,
        t('at', { ns: 'dates' }),
        datetime.toLocaleString({
          locale: i18n.language,
          hour: '2-digit',
          minute: '2-digit',
        }),
      ]
    }
    return resourceCardDate.join(' ')
  }, [date, i18n])

  const progression = `${state?.progress || 0}%`
  const read = state?.status === 'done' || state?.progress === 100

  return (
    <Container
      listMode={listMode}
      paddingValue={listMode ? '0' : padding}
      avancement={listMode && displayCollectionProgress}>
      {listMode && displayCollectionProgress && (
        <ProgressComponent first={first} last={last} read={read} />
      )}
      <CardWrapperContainer displayCollectionProgress listMode={listMode}>
        <CardWrapper
          hover={hover}
          listMode={listMode}
          onPress={_onCardPress}
          onMouseEnter={onHoverIn}
          // @ts-ignore
          onMouseLeave={onHoverOut}>
          <TopContainer listMode={listMode}>
            <ImageContainer
              bottomElement={!!bottom}
              bottomComponent={!!bottom}
              listMode={listMode}
              customImageHeight={customImageHeight}>
              <Image
                source={{ uri: image }}
                listMode={listMode}
                hover={hover}
              />
              {!removeImageOverlay && (
                <ImageGradient
                  start={{ x: 0, y: 0 }}
                  locations={[0, 1]}
                  colors={['#000000B2', '#0000004B']}
                />
              )}
            </ImageContainer>
            {listMode ? (
              <TitleContainer>
                <Title hover={hover} listMode={listMode}>
                  {title}
                </Title>
                {date && (
                  <Text
                    marginSize={`4px ${theme.spacings.M}px`}
                    color={'secondary'}>
                    {capitalize(fulldate)}
                  </Text>
                )}
                <FooterInformations
                  hover={hover}
                  listMode={listMode}
                  title={title}
                  date={date}
                  theme={theme}
                  fulldate={fulldate}
                  duration={duration}
                  count={count}
                  bottomElement={bottom}
                  state={state}
                  read={read}
                  progression={progression}
                  favorite={favorite}
                  onFavorite={onFavoritePress}
                  onShare={onSharePress}
                />
              </TitleContainer>
            ) : (
              <>
                <Title hover={hover} listMode={listMode}>
                  {title}
                </Title>
                {date && (
                  <Text
                    marginSize={`4px ${theme.spacings.M}px`}
                    color={'secondary'}>
                    {capitalize(fulldate)}
                  </Text>
                )}
              </>
            )}
          </TopContainer>
          {sound && (
            <SoundContainer listMode={listMode}>
              <FontIcon name='sound' size={24} color={theme.colors.white} />
            </SoundContainer>
          )}
          {tag && <Tag weight='bold'>{tag.toUpperCase()}</Tag>}
          {(bottom && bottom.kind === 'success' && (
            <SuccessContainer>
              <SmallParagraph color='lightGreen'>
                {t('dashboard.webinar.has_subscribe', { ns: 'dashboard' })}
              </SmallParagraph>
              <FontIcon name='check-circle' color={theme.colors.lightGreen} />
            </SuccessContainer>
          )) ||
            (bottom && (
              <FooterButtonContainer>
                <FooterButtonText
                  hover={hover}
                  marginSize='4px'
                  alignPosition={'center'}>
                  {bottom.title}
                </FooterButtonText>
              </FooterButtonContainer>
            ))}
          {!listMode && !bottom && (
            <FooterInformations
              hover={hover}
              listMode={listMode}
              title={title}
              date={date}
              theme={theme}
              fulldate={fulldate}
              duration={duration}
              count={count}
              bottomElement={bottom}
              favorite={favorite}
              read={read}
              state={state}
              progression={progression}
              onFavorite={onFavoritePress}
              onShare={onSharePress}
            />
          )}
        </CardWrapper>
      </CardWrapperContainer>
    </Container>
  )
}
