import { DateTime } from 'luxon'
import { storeItem, getStoredItem } from './storage'

export function hashCode(str: string) {
  return (str || '')
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    )
}

export async function fetchModal(secureKey: string, name: string) {
  if (!secureKey || !name) {
    return 'false'
  }

  return await getStoredItem(`${name}${hashCode(secureKey)}`)
}

export function storeModal(
  secureKey: string,
  value: string | null,
  name: string
) {
  storeItem(`${name}${hashCode(secureKey)}`, value)
}

export async function isExpiredStoredDate(key: string, expirationDays: number) {
  let value = await getStoredItem(key)
  if (value) {
    return (
      DateTime.fromISO(value).plus({ days: expirationDays }) < DateTime.now()
    )
  }
  return true
}

export async function storeDate(key: string) {
  await storeItem(key, DateTime.now().toISO())
}
