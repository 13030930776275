import { FontIcon, ActionTitle, Paragraph } from '@ui/Moodwork-UI/atoms'
import { DashboardCardContainer } from '@ui/Moodwork-UI/templates/Dashboard/index.style'
import styled, { css } from 'styled-components/native'

export const Container = styled(DashboardCardContainer)`
  flex-direction: row;
`

export const ImageContainer = styled.View`
  flex: 1;
  aspect-ratio: 1.265;
  height: 100%;
  margin-right: 32px;
`

export const DecorateImage = styled.Image`
  width: 100%;
  height: 100%;
`

export const MainText = styled(ActionTitle)`
  margin-bottom: 8px;
`

export const FollowText = styled(Paragraph)`
  margin-vertical: 0;
  margin-right: 8px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const RightContainer = styled.View`
  flex: 3;
  flex-direction: column;
`

export const TextContent = styled.View`
  flex-direction: column;
  width: 100%;
`

export const TitleContainer = styled.View`
flex-direction: row;
align-items: center;
`

export const Icon = styled(FontIcon)`
  margin-right: 16px;
`

export const ButtonContainer = styled.View`
  flex-direction: ${({ theme }) =>
    theme.device === 'small' ? 'column' : 'row'};
  align-items: ${({ theme }) =>
    theme.device === 'small' ? 'undefined' : 'center'}; 
  margin-top: 16px;
  ${({ theme }) =>
    theme.device != 'small' &&
    css`
    justify-content: flex-end;
  `}
`

export const FollowContainer = styled.Pressable`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${({ theme }) => (theme.device === 'small' ? '16px' : '0px')};
`
export const FollowIcon = styled(FontIcon)`
`
