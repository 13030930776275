import React from 'react'
import { useDevice } from '@ui/utils'
import { HTMLView, SubTitle } from '../../atoms'
import { Button } from '../../molecules'
import { Container, ButtonContainer } from './InformationCardWithButton.style'
import { InformationCardWithButtonProps } from './interface'

export const InformationCardWithButton = ({
  title,
  description,
  onPress,
  buttonLabel,
  disclaimer,
  trackLabel = 'clicked_result_information_card',
}: InformationCardWithButtonProps) => {
  const device = useDevice()
  return (
    <Container device={device}>
      <SubTitle weight='bold'>{title}</SubTitle>
      <HTMLView source={description} big />
      <ButtonContainer device={device}>
        <Button
          compact
          trackLabel={trackLabel}
          label={buttonLabel}
          onPress={onPress}
        />
      </ButtonContainer>
      {disclaimer && (
        <HTMLView
          source={disclaimer}
          customHtmlStyles={{
            p: { fontSize: '14px', marginTop: 16 },
            a: { fontSize: '14px' },
          }}
        />
      )}
    </Container>
  )
}
