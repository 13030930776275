import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { ItemContainerProps, WrapperProps } from './NavigationDropdown.types'
import { Text } from '@moodwork-ui'

/**
 * TODO:
 *  - At this stage, we ARE in a browser
 */
const web =
  IS_BROWSER &&
  css`
    box-shadow: 5px 5px 5px rgba(100, 100, 100, 0.1);
    transition: all 0.2s ease-in-out;
  `

export const Container = styled.View.attrs({
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowRadius: 2.22,
  shadowOpacity: 0.22,
  elevation: 5,
})`
  border-radius: 4px;
  background-color: white;
  position: absolute;
  top: 100%;
  right: ${({ isRight }: WrapperProps) => (isRight ? 0 : 'auto')};
  transform: translateY(50px);
  opacity: 0;
  ${({ open }: WrapperProps) =>
    open &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}
  border: 1px solid #f0f0f0;
  ${IS_BROWSER && web};
`

export const ItemContainer = styled.Pressable<ItemContainerProps>`
  min-width: 310px;
  border-bottom-width: 1px;
  padding: ${({ big }: WrapperProps) => (big ? 32 : 20)}px;
  border-bottom-color: #e1e1e1;
  ${({ hover }) =>
    hover &&
    css`
      background-color: ${themeColor('secondary')};
    `}
`

// TODO: change with more explicitName but not 'right'
export const NavigationDropdownContainer = styled.Pressable<WrapperProps>`
  margin: ${({ isRight }: WrapperProps) => (isRight ? '0px' : '0 18px')};
  justify-content: center;
  ${IS_BROWSER && css`cursor: pointer;`};
`

export const ItemTitle = styled(Text)<ItemContainerProps>`
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.black};
  ${
    IS_BROWSER &&
    css`
    transition: all 0.15s ease-in-out;
  `
  };
  ${({ hover }) =>
    hover &&
    css`
      color: white;
    `}
`

export const ItemDescription = styled(Text)<ItemContainerProps>`
  ${
    IS_BROWSER &&
    css`
    transition: all 0.15s ease-in-out;
  `
  };
  ${({ hover }) =>
    hover &&
    css`
      color: white;
    `}
`
