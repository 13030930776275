import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../utils/device'

const web =
  IS_BROWSER &&
  css`
    cursor: auto;
    box-shadow: 3px 3px 3px rgba(100, 100, 100, 0.1);
    border: 1px solid #f0f0f0;
  `

export const Wrapper = styled.Pressable.attrs({
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,

  elevation: 3,
})`
  ${IS_BROWSER && 'cursor: auto'};
  background: white;
  margin-bottom: 17px;
`

export const Container = styled.View<{ device: DeviceSizeType }>`
  padding: 16px;
  flex-direction: row;
  background: white;
  min-height: 0px;
  ${web};
  ${({ device }) =>
    device === 'small' &&
    IS_BROWSER &&
    css`
      cursor: pointer !important;
    `}
`

export const TopBar = styled.View`
  flex-direction: row;
  align-items: center;
`

export const NameContainer = styled.View`
  flex-direction: column;
  flex: 1;
`

export const CalendarContainer = styled.View`
  height: 100%;
`

export const InfoColumn = styled.View<{ device: DeviceSizeType }>`
  /* width: 100%; */
  flex: 1;
  margin-left: 8px;
  ${IS_BROWSER && 'cursor: auto'};
  ${({ device }) =>
    device === 'small' &&
    IS_BROWSER &&
    css`
      cursor: pointer !important;
    `}
`

export const CalendarColumn = styled.View<{ hidden: boolean }>`
  /* flex: 1; */
  padding-left: 16px;
  flex: 1.5;
  background: white;
  height: 100%;
  ${IS_BROWSER && 'cursor: auto'};
  ${({ hidden }) =>
    hidden &&
    css`
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    `}
`

export const PlayVideoWrapper = styled.View`
  align-items: flex-start;
`

export const LinkWrapper = styled.View`
  margin-top: 32px;
  align-items: flex-end;
`

export const SkeletonContainer = styled(Container)`
  min-height: 425px;
  max-height: 425px;
  display: flex;
  flex-direction: row;
`

export const PsySkeleton = styled.View`
  flex: 1;
  flex-direction: column;
`
export const CalendarSkeleton = styled.View<{ height: number }>`
  flex: 1.45;
  min-height: 425px;
  flex-direction: column;
  overflow: hidden;
`

export const AvatarSkeleton = styled.View`
  display: flex;
  flex-direction: row;
`

export const PositionSkeleton = styled.View`
  flex: 1;
  width: 110px;
  max-height: 7px;
  border-radius: 10px;
  margin-left: 16px;
  margin-top: 29px;
  background-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`
export const PsyInfoSkeleton = styled.View`
  flex-direction: column;
`

export const MediumRowSkeleton = styled.View`
  width: 135px;
  height: 7px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`

export const SmallRowSkeleton = styled.View`
  width: 99px;
  height: 7px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`

export const LongRowSkeleton = styled.View`
  width: 311px;
  height: 7px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`

export const PsyDescriptionSkeleton = styled.View`
  flex-direction: column;
  overflow: hidden;
`

export const CalendarTitleSkeleton = styled.View`
  alignself: center;
  width: 292px;
  height: 7px;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 77px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`

export const RowSkeleton = styled.View`
  flex: 1;
  flex-direction: row;
  height: 56px;
  overflow: hidden;
`

export const EmptySlotSkeleton = styled.View`
  width: 81px;
  height: 39px;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 10px;
  background-color: transparent;
`

export const SlotSkeleton = styled.View`
  width: 81px;
  height: 39px;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`

export const FlagImage = styled.Image`
  margin-left: 8px;
  width: 20px;
  height: 13px;
`
