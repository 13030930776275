import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { Text } from '@moodwork-ui'

const web =
  IS_BROWSER &&
  css`
    cursor: pointer;
  `

export const Container = styled.Pressable<{ selected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.secondaryLighter};
  width: 84px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.small}
  margin-bottom: 16px;
  ${web};
  ${({ selected }) =>
    selected &&
    IS_BROWSER &&
    css`
      border-color: ${({ theme }) => theme.colors.primary};
      border-width: 2px;
    `}
`
