import { useMutation, useQuery, UseQueryResult } from 'react-query'
import * as _ from 'lodash'
import { useHttp } from './http'
import {
  ContentsMap,
  DiaryContent,
  DiaryGroup,
  ResourceMark,
} from '../types/diary'
import { AxiosError } from 'axios'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

function transformData({
  contents,
  groups,
}: {
  groups: DiaryGroup[]
  contents: DiaryContent[]
}) {
  return { groups, contents: _.groupBy(contents, 'uuid') as ContentsMap }
}

export function useDiaries() {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getDiaries = async () =>
    (await http.get(`/api/v1/diary_groups?preview=true`))?.data
  const { data = null } = useQuery(['diaries', i18n.language], getDiaries, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 0,
  })
  return data ? transformData(data) : null
}