import { LinearGradient } from 'expo-linear-gradient'
import styled from 'styled-components/native'

export const Container = styled.View`
  height: 100%;
  width: 10%;
  justify-content: center;
  align-items: center;
`

export const Gradient = styled(LinearGradient)<{ read: boolean }>`
  width: 5px;
  height: 100%;
  background-color: ${({ read, theme }) =>
    read ? theme.colors.lightGreen : theme.colors.separatorGrey};
  align-items: center;
  justify-content: center;
  overflow: visible;
`
export const Circle = styled.View`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.separatorGrey};
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`
