import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => `${theme.spacings.M}px`};
  margin-bottom: ${({ theme }) => `${theme.spacings.S}px`};
`
