// Wrapper for Intercom SDK to avoid errors on web
export const IntercomWrapper = {
  addEventListener: () => {
    throw new Error('The function addEventListener is not yet implemeted')
  },
  displayArticle: (articleId: string) =>
    new Promise(() => {
      window.Intercom('displayArticle', articleId)
    }),
  displayCarousel: () =>
    new Promise(() => {
      throw new Error('The function displayCarousel is not yet implemeted')
    }),
  displayHelpCenter: () =>
    new Promise(() => {
      throw new Error('The function displayHelpCenter is not yet implemeted')
    }),
  displayHelpCenterCollections: () =>
    new Promise(() => {
      throw new Error(
        'The function displayHelpCenterCollections is not yet implemeted'
      )
    }),
  displayMessageComposer: () =>
    new Promise(() => {
      window.Intercom('showMessages')
    }),
  displayMessenger: () =>
    new Promise(() => {
      window.Intercom('show')
    }),
  fetchHelpCenterCollection: () =>
    new Promise(() => {
      throw new Error(
        'The function fetchHelpCenterCollection is not yet implemeted'
      )
    }),
  fetchHelpCenterCollections: () =>
    new Promise(() => {
      throw new Error(
        'The function fetchHelpCenterCollections is not yet implemeted'
      )
    }),
  getUnreadConversationCount: () =>
    new Promise(() => {
      throw new Error(
        'The function getUnreadConversationCount is not yet implemeted'
      )
    }),
  handlePushMessage: () =>
    new Promise(() => {
      throw new Error('The function handlePushMessage is not yet implemeted')
    }),
  hideIntercom: () =>
    new Promise(() => {
      window.Intercom('hide')
    }),
  logEvent: (eventName: string, metaData?) =>
    new Promise(() => {
      window.Intercom('trackEvent', eventName, metaData)
    }),
  logout: () =>
    new Promise(() => {
      window.Intercom('shutdown')
    }),
  registerIdentifiedUser: (params) =>
    new Promise(() => {
      window.intercomSettings = { ...window.intercomSettings, ...params }
      window.Intercom('update')
    }),
  registerUnidentifiedUser: () =>
    new Promise(() => {
      throw new Error(
        'The function registerUnidentifiedUser is not yet implemeted'
      )
    }),
  searchHelpCenter: () =>
    new Promise(() => {
      throw new Error('The function searchHelpCenter is not yet implemeted')
    }),
  sendTokenToIntercom: () =>
    new Promise(() => {
      throw new Error('The function sendTokenToIntercom is not yet implemeted')
    }),
  setBottomPadding: (bottomPadding: number) =>
    new Promise(() => {
      window.intercomSettings = {
        ...window.intercomSettings,
        vertical_padding: bottomPadding,
      }
    }),
  setInAppMessageVisibility: () =>
    new Promise(() => {
      throw new Error(
        'The function setInAppMessageVisibility is not yet implemeted'
      )
    }),
  setLauncherVisibility: (visibility) =>
    new Promise(() => {
      window.intercomSettings = {
        ...window.intercomSettings,
        hide_default_launcher: visibility === Visibility.GONE,
      }
    }),
  setLogLevel: () =>
    new Promise(() => {
      throw new Error('The function setLogLevel is not yet implemeted')
    }),
  setUserHash: (hash: string) =>
    new Promise(() => {
      window.intercomSettings = { ...window.intercomSettings, user_hash: hash }
      window.Intercom('update')
    }),
  updateUser: (params) =>
    new Promise(() => {
      window.intercomSettings = { ...window.intercomSettings, ...params }
      window.Intercom('update')
    }),
}
