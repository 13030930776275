import React from 'react'
import styled from 'styled-components/native'

interface ContainerWithBorderProps {
  padding?: string
  borderWidth?: string
  borderColor?: string
  borderRadius?: string
}

export const ContainerWithBorder = styled.View<ContainerWithBorderProps>`
  flex: 1;
  padding: ${({ theme, padding }) =>
    padding ? `${theme.spacings[padding]}px` : `${theme.spacings.L}px}`};
  border-width: ${({ theme, borderWidth }) =>
    borderWidth ? theme.borderWidth[borderWidth] : theme.borderWidth.XS};
  border-style: solid;
  border-color: ${({ theme, borderColor }) =>
    borderColor ? borderColor : theme.colors.secondary};
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius ? theme.borderRadius[borderRadius] : theme.borderRadius.small};
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 382px; */
`
