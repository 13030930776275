import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@utils'
import { DashboardCardContainer } from '@ui/Moodwork-UI/templates/Dashboard/index.style'

export default function WellbeingSummaryLoading({
  highlight,
}: {
  highlight: boolean
}) {
  const theme = useTheme()
  return (
    <DashboardCardContainer highlight={highlight}>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='100%'
        height='260px'>
        <Rect x='0' y='0' rx='100' ry='100' width='100' height='100' />
        <Rect x='130' y='0' rx='3' ry='3' width='90%' height='20' />
        <Rect x='130' y='40' rx='3' ry='3' width='60%' height='25' />
        <Rect x='40%' y='210' rx='20' ry='20' width='60%' height='40' />
      </ContentLoader>
    </DashboardCardContainer>
  )
}
