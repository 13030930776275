import styled from 'styled-components/native'
import { ContentProps, TitleProps } from './Paragraph.types'

export const Container = styled.View`
  flex: 1;
  align-items: flex-start;
  margin-top: -4px;
`

export const Title = styled.Text<TitleProps>`
  font-family: Asap_700Bold;
  font-size: 18px;
  margin-bottom: 4px;
  color: ${(props) => props.color};
`

export const Content = styled.Text<ContentProps>`
  color: ${(props) => props.color};
  font-family: Inter_500Medium;
  font-size: 15px;
`

export const ButtonContainer = styled.View`
  margin-left: -16px;
`

export const Details = styled.Text`
  color: #b0b0b0;
  font-family: Inter_500Medium;
  font-size: 12px;
`
