import { DateTime } from 'luxon'
import { useMemo } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryResult,
} from 'react-query'
import { DaysMap, deserializeSlots, flatSlotsPages } from '../lib/helpers'
import { useHttp } from './http'
import { AxiosError, AxiosResponse } from 'axios'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Psychologist } from '@ui/utils'

export const SLOTS_DAYS = 20

/**
 * TODO: Move into types or not as it's specific to this file ..?
 */
interface Cursor {
  start: DateTime
  finish: DateTime
}

function numberToCursor(num: number = 0) {
  const start = DateTime.now()
    .startOf('day')
    .plus({ days: num * SLOTS_DAYS })
  const finish = start.plus({ days: SLOTS_DAYS + 1 })
  return { start, finish } as Cursor
}

/**
 * Retrieve the psychologists list in order
 * to get an appointment
 */
export function usePsychologists(): UseQueryResult<Psychologist[], AxiosError> {
  const http = useHttp(useNavigation())
  const { i18n } = useTranslation()
  const getPsychologists = () =>
    http.get('/api/v1/psychologists').then((result) => result?.data)
  return useQuery(['psychologists', i18n.language], getPsychologists, {
    retry: 1,
    staleTime: 1000 * 60 * 60,
  })
}

/**
 * Given a psychologist ID, retrieve a paginated list
 * of available slots
 *
 * TODO overwrite fetchNextPage,
 * with an additional parameter maybe : "pageSize",
 * calculated from the component. This will allow the hook to know if a
 * fetch is necesary.
 */
export const SlotsQueryKeyName = 'infinite:slots'

export function useInfiniteSlots(
  psychlogistId: string,
  days: DaysMap,
  starting?: DateTime
) {
  const http = useHttp(useNavigation())

  const result = useInfiniteQuery(
    [SlotsQueryKeyName, psychlogistId],
    /**
     * Fetch the next page,
     * and set the next parameters for subsequent
     * fetch
     * If no param has been found,
     * it means that it was the last page
     */
    async ({ pageParam = 0 }) => {
      const { start, finish } = numberToCursor(pageParam)
      const url = `/api/v1/psychologists/${psychlogistId}/slots?start_at=${start.toISO()}&finish_at=${finish.toISO()}`
      const data = await http.get(url).then(deserializeSlots(days))
      return { data, pageParam: data ? pageParam + 1 : pageParam }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pageParam
      },
    }
  )

  const data = useMemo(() => {
    if (!result.data?.pages) return new Map() as DaysMap
    return flatSlotsPages(result.data.pages, starting)
  }, [result, starting])

  return [result, data] as const
}

type PostEventType = {
  eventId: number
  eventType: string
  reason: string
  phoneNumber: string
}

// TODO: correct typing
export const useSendAppointment = (): [
  (event: PostEventType) => void,
  AxiosResponse<any> | undefined,
  unknown,
  boolean,
  boolean,
  boolean
] => {
  type Idata = null | number
  const http = useHttp(useNavigation())
  const postNewEvents = ({
    eventId,
    eventType,
    reason,
    phoneNumber,
  }: PostEventType) =>
    http.post(
      `/api/v1/events`,
      {
        event_slot_id: eventId,
        reason: reason,
        kind: eventType,
        phone: phoneNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  const { data, error, isError, isLoading, isSuccess, mutate } = useMutation(
    'post:events',
    postNewEvents,
    { retry: 1 }
  )

  return [mutate, data, error, isError, isLoading, isSuccess]
}

export function useAppointments() {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getAppointments = async () =>
    (await http.get(`/api/v1/events?coming=true`))?.data
  return useQuery(['appointments', i18n.language], getAppointments, {
    retry: 1,
  })
}

export const useCancelAppointments = () => {
  const http = useHttp()
  const cancelAppointment = (eventId: number) =>
    http.put(`/api/v3/events/${eventId}/cancel`)

  return useMutation('put:cancelAppointment', cancelAppointment, { retry: 1 })
}

export const useAppointmentsComment = () => {
  const http = useHttp()
  const sendComment = ({
    eventId,
    comment,
  }: {
    eventId: number
    comment: string
  }) =>
    http.put(
      `/api/v1/events/${eventId}`,
      {
        comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  return useMutation('put:appointmentComment', sendComment, { retry: 1 })
}
