import * as React from 'react'
import { Container } from './TimeSlot.style'
import { TimeSlotProps } from './TimeSlot.types'
import { useToggle } from '@core/lib/hooks'
import { Text } from '@moodwork-ui'

export default function TimeSlot({ value, onPress }: TimeSlotProps) {
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const selected = !!hovered

  return (
    <Container
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      selected={selected}
      onPress={onPress}>
      <Text weight='bold' color='secondary'>
        {value}
      </Text>
    </Container>
  )
}
