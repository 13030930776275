import { useSyncReady } from '@core/lib/ready'
import { useDashboard } from '@core/services/dashboard'
import Highlight from '@ui/Moodwork-UI/molecules/Dashboard/Highlight'
import React from 'react'
import { View } from 'react-native'

export default function HighlightList() {
  const { data, isLoading } = useDashboard()
  const isReady = useSyncReady('dashboard', 'highlights', !!data)

  return (
    isReady &&
    (data?.resource_banners || []).map(
      (d, i, array) =>
        d?.resources?.length > 0 && (
          <View
            key={'highlight-' + i}
            style={{
              marginBottom: i !== array.length - 1 ? 64 : 0,
            }}>
            <Highlight data={d} />
          </View>
        )
    )
  )
}
