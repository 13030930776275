import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { Button } from '../../ui'

export default function Welcome() {
  const navigation = useNavigation()

  return (
    <>
      <Button onPress={() => navigation.push('Signup')}>
        Create an account
      </Button>
      <Button outline onPress={() => navigation.push('SignIn')}>
        I already have an account
      </Button>
    </>
  )
}
