import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useTheme } from '@ui/utils'
import { useToggle } from '@core/lib/hooks'
import { Animated, LayoutChangeEvent, Pressable, View } from 'react-native'
import { FontIcon } from '@ui/Moodwork-UI'
import {
  Container,
  ContentContainer,
  Title,
  TitleContainer,
  PressableContainer,
} from './index.styles'

export default function Expandable({
  title,
  children,
}: {
  title: string | undefined
  children: JSX.Element | null
}) {
  const [isOpen, _, __, setInverse] = useToggle(false)
  const [collapse] = useState(new Animated.Value(0))
  const [rotate] = useState(new Animated.Value(0))
  const [maxHeight, setMaxHeight] = useState(0)
  const theme = useTheme()
  const rotateValues = rotate.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  })

  const closeAnimation = useMemo(() => {
    return Animated.parallel([
      Animated.timing(collapse, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.timing(rotate, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }),
    ])
  }, [collapse, rotate])

  const openAnimation = useMemo(() => {
    return Animated.parallel([
      Animated.timing(collapse, {
        toValue: maxHeight,
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.timing(rotate, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }),
    ])
  }, [collapse, rotate, maxHeight])

  useEffect(() => {
    isOpen ? openAnimation.start() : closeAnimation.start()
  }, [isOpen, openAnimation, closeAnimation, maxHeight])

  const onLayout = React.useCallback(
    (event: LayoutChangeEvent) => {
      const measuredHeight = event.nativeEvent.layout.height

      if (maxHeight !== measuredHeight) {
        setMaxHeight(measuredHeight)
      }
    },
    [maxHeight]
  )

  return (
    <Container isOpen={isOpen}>
      <TitleContainer>
        <Title size='S'>{title || 'SOURCES'}</Title>
        <PressableContainer>
          <Pressable onPress={setInverse}>
            <Animated.View style={{ transform: [{ rotate: rotateValues }] }}>
              <FontIcon
                name={'chevron-up'}
                size={10}
                color={theme.colors.grey}
              />
            </Animated.View>
          </Pressable>
        </PressableContainer>
      </TitleContainer>
      <ContentContainer style={{ height: collapse }}>
        <View
          style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
          onLayout={onLayout}>
          {children}
        </View>
      </ContentContainer>
    </Container>
  )
}
