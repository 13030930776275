import React from 'react'
import { Pressable, Text, View, Image } from 'react-native'
import { HOST } from '@core/constants'
import styles from './styles'
import logoMoodwork from '../../assets/logo.png'

/* This component doesn't use i18n or UIProvider (styled-components) to ensure standalone usability on AppCore*/

const Button = ({ text, onPress }: { text: string; onPress: () => void }) => {
  return (
    <Pressable style={styles.button} onPress={onPress}>
      <Text>{text}</Text>
    </Pressable>
  )
}

export default function StandaloneTechnicalError() {
  const onReload = () => {
    location?.reload()
  }
  const onGoHome = () => {
    if (window) {
      window.location.href = `${HOST}/`
    }
  }
  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={logoMoodwork} />
      <Text style={[styles.text, { fontSize: 20 }]}>
        Une erreur est survenue, nous travaillons déjà à sa correction
      </Text>
      <Text style={styles.text}>
        Nos équipes mettent tout en oeuvre pour rétablir le service.
      </Text>
      <View style={styles.buttonContainer}>
        <Button text='Recharger la page' onPress={onReload} />
        <Button text="Revenir à l'accueil" onPress={onGoHome} />
      </View>
    </View>
  )
}

/*
font-size: 16px;
  line-height: 24px;
  color: black;
  */
