import * as React from 'react'
import {
  EmptyItemWrapper,
  EmptyPressable,
  EmptyText,
  IconContainer,
  InactiveMessage,
  styles,
} from './AppointmentCalendar.style'
import { EmptyProps } from './AppointmentCalendar.types'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import theme from '../UIProvider/defaultTheme'
import { useMemo } from 'react'

/**
 * When no slot are being displayed because it's too early
 * When clicking this element, the view should scroll
 * to the next slot in the calendar
 */
export function EmptyItem({
  content,
  onPress,
  clickable,
  inactive_status,
  inactive_message,
}: EmptyProps) {
  const isInactive = useMemo(() => {
    return inactive_status && inactive_message != undefined
  }, [inactive_message, inactive_status])

  return (
    <EmptyItemWrapper inactive={isInactive}>
      {isInactive ? (
        <InactiveMessage source={inactive_message} customHtmlStyles={styles} />
      ) : (
        <EmptyPressable onPress={onPress} clickable={clickable}>
          <EmptyText clickable={clickable}>{content}</EmptyText>
          {clickable && (
            <IconContainer>
              <Icon
                name='arrow-right'
                color={theme.colors.secondary}
                size={22}
              />
            </IconContainer>
          )}
        </EmptyPressable>
      )}
    </EmptyItemWrapper>
  )
}
