import React, { useCallback } from 'react'
import { Text, FontIcon } from '../../../atoms'
import { Container, IconWrapper } from './LinkWithIconStyles'
import { LinkWithIconProps } from './interface'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import { useTheme } from '@utils'

export const LinkWithIcon: React.FC<
  LinkWithIconProps & TrackableEventProps
> = ({
  onHoverIn,
  onHoverOut,
  iconName,
  IconPosition,
  iconSize,
  textSize,
  spacing = 'none',
  iconColor = 'link',
  textColor = 'link',
  onPress,
  label,
  trackLabel,
  textWeight,
  textUnderline = false,
  justifyCenter = true,
  IconMargin,
  paddingSize,
  trackProperties,
  style,
}) => {
  const onTrackedPress = useCallback(() => {
    trackEvent(trackLabel, trackProperties).then(() => onPress())
  }, [trackLabel, onPress])

  const theme = useTheme()

  return (
    <Container
      onPress={onTrackedPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      justifyCenter={justifyCenter}
      paddingSize={paddingSize}
      style={style}>
      {IconPosition === 'left' && (
        <IconWrapper spacing={spacing} iconMargin={IconMargin}>
          <FontIcon
            name={iconName || ''}
            size={iconSize}
            color={theme.colors[iconColor]}
          />
        </IconWrapper>
      )}
      <Text
        style={{ flex: 1 }}
        color={textColor}
        size={textSize}
        weight={textWeight}
        underline={textUnderline}>
        {label}
      </Text>
      {IconPosition === 'right' && (
        <IconWrapper spacing={spacing} iconMargin={IconMargin}>
          <FontIcon
            name={iconName || ''}
            size={iconSize}
            color={theme.colors[iconColor]}
          />
        </IconWrapper>
      )}
    </Container>
  )
}
