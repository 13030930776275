import { FontIcon } from '@ui/Moodwork-UI/atoms'
import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CommentInput, Container, Content, Title } from './index.styles'
import { FlexWrap } from '@ui/Moodwork-UI/templates'
import { AlertContext } from '@core/components/Alerthandler'

import {
  IconContainer,
  ModalClose,
} from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal.style'
import { AppointmentCommentProps } from './interface'
import { useDevice, useTheme } from '@ui/utils'
import { Button } from '../pressables'
import { useAppointmentsComment } from '@core/services/appointments'

export default function AppointmentComment({
  setVisible,
  eventId,
}: AppointmentCommentProps) {
  const { t } = useTranslation(['appointments', 'errors'])
  const theme = useTheme()
  const [comment, setComment] = useState('')
  const { mutate, isError, isSuccess } = useAppointmentsComment()
  const { setMessage, setType, setOffsetY } = useContext(AlertContext)
  const device = useDevice()

  const handleClose = useCallback(() => {
    mutate({
      eventId: eventId,
      comment: '',
    })
    setVisible(false)
  }, [eventId])

  const handleSubmit = useCallback(() => {
    mutate({
      eventId: eventId,
      comment: comment,
    })
  }, [eventId, comment])

  useEffect(() => {
    if (isSuccess) {
      setVisible(false)
      setType('success')
      setOffsetY(device === 'large' ? 80 : 130)
      setMessage(t('psychologists.comment.success'))
    }
  }, [isSuccess, device])

  useEffect(() => {
    if (isError) {
      setType('warning')
      setOffsetY(device === 'large' ? 80 : 130)
      setMessage(t('errors:technical.title'))
    }
  }, [isError])

  return (
    <Container>
      <ModalClose onPress={handleClose}>
        <IconContainer>
          <FontIcon name='cancel' color={theme.colors.black} size={16} />
        </IconContainer>
      </ModalClose>
      <Content>
        <Title>{t('psychologists.comment.title')}</Title>
        <FlexWrap>
          <CommentInput
            onChange={setComment}
            type={'text'}
            value={comment}
            inputStyle={{ backgroundColor: theme.colors.secondaryBackground }}
          />
          <Button
            compact
            label={t('psychologists.comment.submit')}
            onPress={handleSubmit}
          />
        </FlexWrap>
      </Content>
    </Container>
  )
}
