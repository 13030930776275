import React, { useCallback, useEffect, useState } from 'react'
import { storeModal } from '../../../../core/lib/modal'
import { useUserInfo } from '@services/viewer'
import { FlexCenter } from '@ui/Moodwork-UI/templates/Flex'
import { Paragraph } from '@ui/Moodwork-UI'
import { useTranslation } from 'react-i18next'
import { Container, Image } from './AssessmentModal.styles'
import { HTMLView, Title } from '@ui/Moodwork-UI/atoms'
import slider from '../../../../assets/slider.gif'
import { Button } from '../../molecules/pressables'
import { MODAL_ASSESSMENT } from '@core/constants'
import { useNavigation } from '@react-navigation/native'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { useTheme } from '@ui/utils'
import { View } from 'react-native'

export default function AssessmentModal() {
  const { data } = useUserInfo()
  const navigation = useNavigation()
  const { t } = useTranslation(['assessment'])
  const theme = useTheme()

  const handleClose = useCallback(() => {
    storeModal(data?.email, 'false', MODAL_ASSESSMENT)
    navigation.goBack()
  }, [navigation, data?.email])

  return (
    <NavigationModal onClose={handleClose}>
      <Container>
        <View>
          <Title>{t('modal.title')}</Title>
          <Paragraph>{t('modal.introduction')}</Paragraph>
        </View>
        <HTMLView source={t('modal.description')} />
        <Image resizeMode='contain' source={slider} />
        <Button
          label={t('modal.start')}
          onPress={() => {
            handleClose()
          }}
        />
      </Container>
    </NavigationModal>
  )
}
