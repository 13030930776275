import * as React from 'react'
import { Button } from '../Button'
import { Spacer } from '../Spacer'
import {
  Title,
  Content,
  Container,
  ButtonContainer,
  Details,
} from './Paragraph.style'
import { ParagraphProps } from './Paragraph.types'

export default function Paragraph({
  title,
  content,
  ctaContent,
  details,
  onPress,
  color = 'black',
}: ParagraphProps) {
  return (
    <Container>
      <Title color={color}>{title}</Title>
      <Content color={color}>{content}</Content>
      {details && (
        <>
          <Spacer size={8} />
          <Details>{details}</Details>
        </>
      )}
      {ctaContent && (
        <ButtonContainer>
          <Spacer size={16} />
          <Button onPress={() => {}}>{ctaContent}</Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
