import * as React from 'react'
import {
  Box,
  Row,
  RightColumn,
  Link,
  Bull,
  Logo,
  Language,
  SelectedLanguage,
  Pressable,
  RightRow,
  IosImage,
  AndroidImage,
  StoreButton,
  IosImageContainer,
  AndroidImageContainer,
} from './Footer.style'
import {
  ANDROID_STORE,
  APPLE_STORE,
  HOST,
  SCREEN_CONTACT,
} from '../../constants'
import { IS_BROWSER, useDevice } from '../../../ui'
import { Linking } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text } from '@moodwork-ui'
import moodwork from '../../../assets/footer/logo_footer.png'
import { useCompanyInfo, useSetUser } from '../../services/users'
import { IntercomWrapper as Intercom } from '../../Wrapper/IntercomWrapper'
import android from '../../../assets/android.png'
import ios from '../../../assets/ios.png'
import androidEn from '../../../assets/android-en.png'
import iosEn from '../../../assets/ios-en.png'
import { useCallback } from 'react'
import { useNavigationLink } from '@core/lib/navigation'
import { trackEvent } from '@core/lib/analytics'
import { useNavigation } from '@react-navigation/native'

export default function Footer() {
  const device = useDevice()
  const navigation = useNavigation()

  const { i18n, t } = useTranslation('footer')

  const openTargetBlank = React.useCallback((url) => Linking.openURL(url), [])

  const [newLanguage, setNewLanguage] = React.useState(i18n.language)
  const [user, setUser] = useSetUser()
  const switchLanguage = (language: string) => {
    setUser({ user: { selected_language: language } })
    setNewLanguage(language)
  }

  const { data } = useCompanyInfo()
  const linkTo = useNavigationLink()

  // TODO : if backend returns the user check selected_language and not the code response
  React.useEffect(() => {
    if (user === 204) {
      i18n.changeLanguage(newLanguage)

      // change for both platforms (not the same variable name)
      // @ts-ignore
      Intercom.updateUser({
        languageOverride: newLanguage,
        language_override: newLanguage,
      })
    }
  }, [user, newLanguage])

  const onPressIOS = useCallback(() => {
    trackEvent('clicked_footer_app_store').then(() => linkTo(APPLE_STORE, true))
  }, [])

  const onPressAndroid = useCallback(() => {
    trackEvent('clicked_footer_google_play').then(() =>
      linkTo(ANDROID_STORE, true)
    )
  }, [])

  return (
    <Box device={device}>
      <Row>
        {device == 'large' && (
          <Pressable
            device={device}
            onPress={() =>
              trackEvent('clicked_footer_moodwork_logo').then(() =>
                openTargetBlank(HOST)
              )
            }>
            <Logo source={moodwork}></Logo>
          </Pressable>
        )}
        {IS_BROWSER && data?.mobile_app_access && (
          <>
            <IosImageContainer>
              <StoreButton onPress={onPressIOS}>
                <IosImage
                  resizeMode={'contain'}
                  source={i18n.language === 'fr' ? ios : iosEn}
                />
              </StoreButton>
            </IosImageContainer>
            <AndroidImageContainer>
              <StoreButton onPress={onPressAndroid}>
                <AndroidImage
                  resizeMode={'contain'}
                  source={i18n.language === 'fr' ? android : androidEn}
                />
              </StoreButton>
            </AndroidImageContainer>
          </>
        )}
        <RightColumn>
          <RightRow device={device}>
            {data?.available_locales?.length > 1 &&
              data?.available_locales.map((language, i) =>
                i18n.language === language.language.toLowerCase() ? (
                  <SelectedLanguage
                    device={device}
                    weight='bold'
                    size='M'
                    underline
                    color='secondary'
                    key={i}>
                    {language.language.toUpperCase()}
                  </SelectedLanguage>
                ) : (
                  <Language
                    device={device}
                    key={i}
                    onPress={() =>
                      trackEvent('clicked_footer_language', {
                        target_language: language.language,
                      }).then(() => switchLanguage(language.language))
                    }>
                    <Text device={device} size='S'>
                      {language.language.toUpperCase()}
                    </Text>
                  </Language>
                )
              )}
            {(device === 'medium' || device == 'large') && <Bull>&bull;</Bull>}
            <Pressable
              device={device}
              onPress={() =>
                trackEvent('clicked_footer_copyright').then(() =>
                  openTargetBlank(HOST)
                )
              }>
              <Link size='S'>&copy;{new Date().getFullYear()} Moodwalk</Link>
            </Pressable>
            {(device === 'medium' || device == 'large') && <Bull>&bull;</Bull>}
            <Pressable
              device={device}
              onPress={() =>
                trackEvent('clicked_footer_legal_information').then(() =>
                  openTargetBlank(`${HOST}/pages/terms`)
                )
              }>
              <Link size='S'>{t('footer.terms')}</Link>
            </Pressable>
            {(device === 'medium' || device == 'large') && <Bull>&bull;</Bull>}
            <Pressable
              device={device}
              onPress={() =>
                trackEvent('clicked_footer_contact').then(() =>
                  navigation.push(SCREEN_CONTACT)
                )
              }>
              <Link size='S'>{t('footer.contact')}</Link>
            </Pressable>
          </RightRow>
        </RightColumn>
      </Row>
    </Box>
  )
}
