import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { useHttp } from './http'
import { AssessmentTypes } from '@core/lib/types'
import { Assessment } from '@core/types/assessment'

export function useAssessment(
  key: AssessmentTypes
): UseQueryResult<Assessment, AxiosError> {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getCheckup = async () =>
    (await http.get(`/api/v3/checkups/${key}`))?.data
  return useQuery(['collection', key, i18n.language], getCheckup, {
    retry: 1,
  })
}

export function useSendAnswer(uuid: string) {
  const http = useHttp()

  const sendanswer = (
    params: {
      score: number
      uuid: string
    }[]
  ) =>
    http.post(`/api/v1/user_tests/${uuid}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 30000,
    })

  return useMutation('userTest:sendAnswer', sendanswer, { retry: 1 })
}
