import styled, { css } from 'styled-components/native'

export const Wrapper = styled.Pressable<{ outlined: boolean }>`
  display: flex;
  height: ${({ theme }) => theme.heights.Button};
  width: ${({ theme }) => theme.widths.RoundedButton};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.primary};
      border-width: 2px;
    `}
`

export const HoverContainer = styled.View<{ hover: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  height: ${({ theme }) => theme.heights.HoveredRoundedButton};
  width: ${({ theme }) => theme.widths.HoveredRoundedButton};
  background-color: 'transparent'
    ${({ hover }) =>
      hover &&
      css`
        border-color: ${({ theme }) => theme.colors.primary};
        border-width: 2px;
      `};
`
