import React, { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { ErrorCard } from '../../organisms'
import { Wrapper } from './ErrorPage.styles'
import { ErrorPageProps } from './interface'
import { useTranslation } from 'react-i18next'
import {
  HOST,
  SCREEN_CONTACT,
  SCREEN_HOME,
  SCREEN_SIGNIN,
} from '@core/constants'
import { useSignOut } from '@core/lib/navigation'
import { IS_NATIVE } from '@ui/utils'

export const ErrorPage = ({
  type,
  disabledNavigation = false,
  onNetworkButtonPressed,
  errorMessage,
}: ErrorPageProps) => {
  const navigation = !disabledNavigation ? useNavigation() : undefined
  const signOut = useSignOut()
  const onSupportLink = useCallback(
    () => navigation && navigation.push(SCREEN_CONTACT),
    []
  )

  const onGoHome = () => navigation?.push(SCREEN_HOME)

  const { t } = useTranslation('errors')
  const renderErrorCard = () => {
    switch (type) {
      case 'FUNCTIONAL_PROBLEM':
        return (
          <ErrorCard
            type='withButton'
            errorTitle={t('technical.title')}
            errorText={errorMessage || t('technical.text')}
            buttonLabel={t('goHome')}
            onPressButton={onGoHome}
          />
        )
      case 'TECHNICAL_ERROR':
        return (
          <ErrorCard
            type='withLink'
            errorTitle={t('technical.title')}
            errorText={errorMessage || t('technical.text')}
            linkLabel={t('contactSupport')}
            onPressLink={onSupportLink}
          />
        )
      case 'NETWORK_ERROR_FULL_SCREEN':
        return (
          <ErrorCard
            type='withLinkAndButton'
            errorTitle={t('network.title')}
            errorText={errorMessage || t('network.text')}
            buttonLabel={t('goHome')}
            linkLabel={t('contactSupport')}
            onPressButton={onGoHome}
            onPressLink={onSupportLink}
          />
        )
      case 'NETWORK_ERROR':
        if (onNetworkButtonPressed) {
          return (
            <ErrorCard
              type='withButton'
              errorTitle={t('network.title')}
              errorText={errorMessage || t('network.text')}
              buttonLabel={t('reload')}
              onPressButton={onNetworkButtonPressed}
            />
          )
        }
      case 'NOT_LOGGED_IN_ERROR':
        return (
          <ErrorCard
            type='withButton'
            errorTitle={t('notLoggedIn.title')}
            errorText={t('notLoggedIn.text')}
            buttonLabel={t('goHome')}
            onPressButton={
              !IS_NATIVE
                ? signOut
                : () => {
                    signOut(navigation?.push(SCREEN_SIGNIN))
                  }
            }
          />
        )
      case 'CARE_ACCESS_ERROR':
        return (
          <ErrorCard
            type='withButton'
            errorTitle={t('careAccess.title')}
            errorText={t('careAccess.text')}
            buttonLabel={t('goHome')}
            onPressButton={onGoHome}
          />
        )
      default:
        return
    }
  }

  return <Wrapper>{renderErrorCard()}</Wrapper>
}
