import React, { useEffect } from 'react'
import { ErrorPage, Resource as ResourceComponent } from '@moodwork-ui'
import { useRoute } from '@react-navigation/core'
import { useNavigation } from '@react-navigation/native'
import { ResourcesPageType } from '../lib/types'
import { useResource } from '../services/resources'
import { ErrorType } from '@utils'

const ResourceScreen = () => {
  const { id, collection } = useRoute<ResourcesPageType>().params
  const navigation = useNavigation()
  const { data, isLoading, isError } = useResource(id)
  useEffect(() => {
    if (data?.title?.length > 0) {
      navigation.setOptions({ title: data.title })
    }
  }, [data?.title])
  return isError ? (
    <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  ) : (
    <ResourceComponent
      resource={data}
      isLoading={isLoading}
      collection={collection}
    />
  )
}

export default ResourceScreen
