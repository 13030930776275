import React, { useEffect } from 'react'
import { SubTitle } from '@ui/Moodwork-UI/atoms'
import { RegistrationQuestionProps } from './interface'
import SelectPicker from '@ui/Moodwork-UI/atoms/SelectPicker/SelectPicker'
import { FlexColumn } from '@ui/Moodwork-UI/templates'

export const RegistrationQuestion = ({
  registrationQuestion,
  currentQuestionValue,
  nextQuestion,
}: RegistrationQuestionProps) => {
  useEffect(() => {
    if (
      registrationQuestion.registration_responses &&
      registrationQuestion?.registration_responses.length === 1
    ) {
      nextQuestion({
        rqUuid: registrationQuestion.uuid,
        rqPriority: registrationQuestion.priority,
        rrUuid: registrationQuestion.registration_responses[0].uuid,
      })
    }
  }, [])

  const handleChange = (value: string) => {
    nextQuestion({
      rqUuid: registrationQuestion.uuid,
      rqPriority: registrationQuestion.priority,
      rrUuid: value,
    })
  }

  return (
    <>
      {registrationQuestion.registration_responses &&
        registrationQuestion?.registration_responses.length > 1 && (
          <FlexColumn>
            <SubTitle alignPosition='center' marginSize={'0 0 8px 0'}>
              {registrationQuestion.content}
            </SubTitle>
            <SelectPicker
              emptyable
              value={currentQuestionValue}
              // @ts-ignore
              onChange={handleChange}
              options={registrationQuestion.registration_responses.map(
                ({ content: name, uuid: value }) => ({ name, value })
              )}
            />
          </FlexColumn>
        )}
    </>
  )
}
