import {
  HtmlProps,
  HtmlTextProps,
} from '@ui/Moodwork-UI/atoms/HTMLView/HTMLView.types'
import theme from '../UIProvider/defaultTheme'

const textStyle: HtmlTextProps = {
  fontFamily: theme.fonts.regular,
  fontSize: 14,
  lineHeight: 21,
  marginBottom: 8,
}

export const htmlStyles: HtmlProps = {
  p: textStyle,
  li: {
    ...textStyle,
    marginBottom: 0,
    marginLeft: 5,
  },
  ul: {
    ...textStyle,
    paddingLeft: 0,
    marginTop: 0,
    marginLeft: 20,
  },
  span: textStyle,
}
