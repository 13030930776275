import styled, { css } from 'styled-components/native'
import { SubTitle, Link } from '@ui/Moodwork-UI'
import { TextInput } from '@ui/TextInput'
import { Button } from '@moodwork-ui'

export const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
`

export const Title = styled(SubTitle)`
  margin: 40px 0px
`

export const SignTextInput = styled(TextInput)`
  margin-bottom: 15px;
  ${({ theme }) =>
    theme.device === 'small'
      ? css`
    width: 90%;`
      : css`
    width: 60%;`}
`

export const LogInButton = styled(Button)`
  margin-bottom: 55px;
`

export const SignLink = styled(Link)`
  margin-bottom: 15px;
`
