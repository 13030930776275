import styled, { css } from 'styled-components/native'
import { Text } from '@moodwork-ui'
import { DeviceSizeType, IS_BROWSER } from '../utils/device'

export const Image = styled.Image`
  flex: 1;
`

export const Content = styled.View<{ device: DeviceSizeType }>`
  flex: 1;
  flex-direction: row;
  ${IS_BROWSER && 'flex-basis: auto'};
  margin-horizontal: ${(props) => (props.device === 'small' ? '0px' : '80px')};
  padding-horizontal: 16px;
`

export const InfoContent = styled.View`
  flex: 2;
`

export const ImageContent = styled.View`
  flex: 1;
  margin: -10% 48px 0 80px;
`

export const Button = styled.Pressable`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 500px;
  padding: 16px 32px;
`

export const ButtonContainer = styled.View<{ device: string }>`
  margin-top: ${(props) => (props.device === 'small' ? 'auto' : 'unset')};
  margin-bottom: 32px;
  ${({ device }) =>
    device !== 'small' &&
    css`
      align-items: flex-start;
    `}
`

export const Label = styled(Text)`
  line-height: 26px;
`
export const PhoneLabel = styled(Label)`
  margin-bottom: 16px;
`
export const ReasonLabel = styled(PhoneLabel)`
  margin-top: 16px;
`

export const SubjectTextInput = styled.TextInput`
  margin: 8px 0 16px 0;
  background-color: #f4f5fd;
  padding: 8px;
  border-radius: 4px;
  color: #303030;
`

export const ActivityContainer = styled.View`
  width: 25%;
  aspect-ratio: 1;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const ActivityText = styled(Text)``

export const LoaderContainer = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: 'rgba(0,0,0,.2)';
  z-index: 10;
`
