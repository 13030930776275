import { createReducer, createStore } from '../lib/store'
import { SupportedLocale, Thunk } from '../lib/types'
import { getDeviceLocale } from '../lib/locale'
import { DEFAULT_LANGUAGE } from '../constants'

/**
 * The locale store will contain
 * the required informations to choose the language:
 * .- The device language
 *  - the user selected language
 *  - the current company supported languages
 */
interface LocaleState {
  appLanguage: SupportedLocale
}

/**
 * We fetch the default values from the cookies
 * and create our store with theme
 */
const initialState: LocaleState = {
  // TODO : set proper value
  appLanguage: getDeviceLocale() || DEFAULT_LANGUAGE,
}

const { actions, reducer } = createReducer({
  setAppLanguage: (
    state: LocaleState,
    { language }: { language: SupportedLocale }
  ) => {
    state.appLanguage = language
  },
})

const [useState, LocaleProvider] = createStore(reducer, initialState)
export { LocaleProvider, useState }

/**
 * Thunks
 */

/**
 * TODO: Choose the language with the companySupportedLanguage and
 * user Selected language in mind
 */
export function changeAppLanguage(value: SupportedLocale): Thunk<LocaleState> {
  return async (dispatch) => {
    dispatch(actions.setAppLanguage({ language: value }))
  }
}
