import styled, { css } from 'styled-components/native'
import { IS_BROWSER, IS_NATIVE } from '@utils'
import { Text } from '@moodwork-ui'
import { LinearGradient } from 'expo-linear-gradient'
import { View } from 'react-native'

const webHover = (listMode: boolean) =>
  IS_BROWSER &&
  css`
    cursor: pointer;
    box-shadow: 3px 3px 3px rgba(100, 100, 100, 0.1);
    border: 1px solid #f0f0f0;
    border-radius: 15px;
    background-color: white;
    ${({ theme }) =>
      !listMode && theme.device === 'large' && css`transform: scale(1.1)`};
  `

export const Container = styled.View<{
  paddingValue: string
  avancement: boolean
  listMode: boolean
}>`
  padding: ${({ paddingValue }) => paddingValue};
  width: ${({ avancement, listMode }) =>
    avancement && listMode ? '90%' : '100%'};
  height: 100%;
  flex-direction: row;
`

export const CardWrapper = styled.Pressable<{
  hover: boolean
  listMode: boolean
  paddingValue: string
}>`
  flex-direction: ${({ listMode }) => (listMode ? 'column' : 'column')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  ${({ hover, listMode }) => hover && webHover(listMode)}
`

export const ImageContainer = styled.View<{
  listMode: boolean
  bottom: boolean
  customImageHeight?: string
  bottomComponent: boolean
}>`
    width: ${({ listMode }) => (listMode ? '25%' : '100%')};
    height: ${({ theme, listMode, bottom, customImageHeight }) =>
      customImageHeight && theme.device === 'large'
        ? customImageHeight
        : listMode
        ? '100%'
        : '33%'}; 
    min-height: ${({ theme, listMode, bottom, customImageHeight }) =>
      customImageHeight && theme.device === 'large'
        ? customImageHeight
        : listMode
        ? '100%'
        : '33%'};  
  `

export const Image = styled.Image<{
  hover: boolean
}>`
  width: 100%;
  height: 100%; 
`

export const ImageGradient = styled(LinearGradient)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const Tag = styled(Text)`
  background: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.textSizes.XXS};
  left: ${({ theme }) => theme.spacings.XS}px;
  top: ${({ theme }) => theme.spacings.XS}px;
  position: absolute;
  padding: ${({ theme }) => theme.spacings.XS}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`

export const Title = styled(Text)<{ hover: boolean; listMode: boolean }>`
  padding: ${({ theme, hover, listMode }) =>
    (listMode ? theme.spacings.S : theme.spacings.M) - (hover ? 1 : 0)}px;
  padding-bottom: 0;
  font-size: ${(
    { theme } // to verify
  ) => (theme.device !== 'large' ? theme.textSizes.XS : theme.textSizes.M)};
`

export const SoundContainer = styled.View<{ listMode: boolean }>`
  position: absolute;
  ${({ listMode, theme }) =>
    !listMode &&
    css`
      right: ${theme.spacings.M}px;
      top: ${theme.spacings.M}px;
    `}
    ${({ listMode, theme }) =>
      listMode &&
      css`
    bottom: ${theme.spacings.M}px;
    left: ${theme.spacings.M}px;
    `}
`

export const TopContainer = styled.View<{ listMode: boolean }>`
  flex: 1;
  flex-direction: ${({ listMode }) => (listMode ? 'row' : 'column')};
`

export const FooterButtonContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: flex-end;
`

export const FooterButtonText = styled(Text)<{ hover: boolean }>`
  padding: 8px;
  ${({ hover }) =>
    hover &&
    css`
    border: solid 2px white;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
  `}
`

export const SuccessContainer = styled(View)`
  align-items: center;
`

export const CardWrapperContainer = styled.View<{
  listMode: boolean
  displayCollectionProgress: boolean
}>`
  padding-vertical: ${({ listMode }) => (listMode ? 16 : 0)};
  width: 100%;
  margin-left: ${(displayCollectionProgress) =>
    displayCollectionProgress === true && IS_NATIVE ? '5%' : 0};
  overflow: ${({ listMode }) => (listMode ? 'hidden' : 'visible')};
`

export const TitleContainer = styled.View`
  flex: 1;
  height: 100%;
  justify-content: space-between;
`
