import {
  Container,
  ContentContainer,
  ImageContainer,
  Image,
} from './ImageContent.styles'
import React from 'react'
import { ImageContentProps } from './interface'

export const ImageContent = ({
  imageUrl,
  imagePosition = 'left',
  children,
  rounded = false,
  imageWidth = {
    small: {
      width: 70,
      height: 70,
    },
    medium: {
      width: 70,
      height: 70,
    },
    large: {
      width: 80,
      height: 80,
    },
  },
}: ImageContentProps) => {
  return (
    <Container>
      {imagePosition === 'left' && (
        <ImageContainer>
          <Image
            sizes={imageWidth}
            source={{ uri: imageUrl }}
            resizeMode='contain'
            rounded={rounded}
          />
        </ImageContainer>
      )}
      <ContentContainer>{children}</ContentContainer>
      {imagePosition === 'right' && (
        <ImageContainer>
          <Image
            sizes={imageWidth}
            source={{ uri: imageUrl }}
            resizeMode='contain'
            rounded={rounded}
          />
        </ImageContainer>
      )}
    </Container>
  )
}
