import * as React from 'react'
import { useTheme } from '@ui/utils'
import { useTranslation } from 'react-i18next'
import { Text, FontIcon, SubTitle } from '@ui/Moodwork-UI'
import {
  Container,
  Content,
  ContentNavigation,
  SupportTab,
  ChildContent,
} from './index.styles'
import { useNavigation, useRoute } from '@react-navigation/native'
import { SCREEN_CONTACT, SCREEN_FAQ } from '@core/constants'
import { ViewProps } from 'react-native'
import { useCallback } from 'react'

export default function SupportTemplate({ children }: ViewProps) {
  const route = useRoute()
  const navigation = useNavigation()
  const { t } = useTranslation('contact')
  const theme = useTheme()

  const handlePress = useCallback(() => {
    navigation.push(route.name === SCREEN_CONTACT ? SCREEN_FAQ : SCREEN_CONTACT)
  }, [route])

  return (
    <Container>
      <Content>
        <ContentNavigation>
          <SupportTab
            active={route.name === SCREEN_CONTACT}
            onPress={handlePress}>
            {theme.device === 'small' ? (
              <FontIcon
                name='white-email'
                size={16}
                color={
                  route.name === SCREEN_CONTACT
                    ? theme.colors.white
                    : theme.colors.secondary
                }
              />
            ) : (
              <>
                <Text
                  color={route.name === SCREEN_CONTACT ? 'white' : 'secondary'}>
                  {t('tabs.contact.title')}
                </Text>
                <Text
                  color={route.name === SCREEN_CONTACT ? 'white' : 'secondary'}>
                  {t('tabs.contact.description')}
                </Text>
              </>
            )}
          </SupportTab>
          <SupportTab active={route.name === SCREEN_FAQ} onPress={handlePress}>
            {theme.device === 'small' ? (
              <FontIcon
                name='question-circle'
                size={24}
                color={
                  route.name === SCREEN_FAQ
                    ? theme.colors.white
                    : theme.colors.secondary
                }
              />
            ) : (
              <>
                <Text color={route.name === SCREEN_FAQ ? 'white' : 'secondary'}>
                  {t('tabs.q-and-a.title')}
                </Text>
                <Text color={route.name === SCREEN_FAQ ? 'white' : 'secondary'}>
                  {t('tabs.q-and-a.description')}
                </Text>
              </>
            )}
          </SupportTab>
        </ContentNavigation>
        <ChildContent>{children}</ChildContent>
      </Content>
    </Container>
  )
}
