import React, { useContext, useEffect } from 'react'
import { Container, Content } from './index.styles'
import { BrandedContainer } from '@ui/Moodwork-UI'
import { IS_NATIVE } from '@ui/utils'
import AwaitConfirmation from '@ui/Moodwork-UI/organisms/SignUp/AwaitConfirmation'
import { useConfirmEmail } from '@core/services/viewer'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import Confirmation from '@ui/Moodwork-UI/organisms/SignUp/Confirmation'
import { first } from 'lodash'
import { AlertContext } from '@core/components/Alerthandler'
import { SCREEN_HOME, SCREEN_REGISTRATION } from '@core/constants'

export default function EmailConfirmation() {
  const route = useRoute()
  const params = route.params

  const { data, isLoading, error } = useConfirmEmail(params?.confirmation_token)
  const { setMessage, setType } = useContext(AlertContext)
  const navigation = useNavigation()

  useEffect(() => {
    if (error && params?.confirmation_token) {
      setType('warning')
      const errorKey = first(error?.response?.data.errors)
      const errorMessage = first(error?.response?.data.details)
      if (errorKey === 'invalid') {
        setMessage(errorMessage)
      } else if (errorKey === 'already_confirmed') {
        setTimeout(() => {
          navigation.dispatch(StackActions.replace(SCREEN_HOME))
        }, 2000)
      }
    } else if (!error && params?.confirmation_token) {
      setTimeout(() => {
        navigation.dispatch(StackActions.replace(SCREEN_REGISTRATION))
      }, 10000)
    }
  }, [error])

  return (
    <Container>
      <BrandedContainer fullscreen={IS_NATIVE}>
        <Content>
          {params?.confirmation_token && data && !isLoading ? (
            <Confirmation emailType={data.data.email_type} />
          ) : (
            <AwaitConfirmation />
          )}
        </Content>
      </BrandedContainer>
    </Container>
  )
}
