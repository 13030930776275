import React, { useEffect, useState } from 'react'
import {
  DescriptionContainer,
  FactorsContainer,
  FactorsRiskIcon,
  MainFactorItemList,
  PannelFactorItem,
  PannelSelected,
  PannelSelectionContainer,
  PannelSelectionItemContainer,
} from './HistoryFactors.styles'
import { HistoryFactorsPannelProps } from './interface'
import { Paragraph, Text } from '@ui/Moodwork-UI/atoms/Text'
import { HTMLView } from '@ui/Moodwork-UI/atoms'
import { GradientScoreIndicator, PrefixedContent } from '@ui/Moodwork-UI'
import { useTheme } from '@ui/utils'
import riskIcon from '../../../../assets/images/history/mal_etre.png'
import protectionIcon from '../../../../assets/images/history/bien_etre.png'
import { useTranslation } from 'react-i18next'
import { PannelSelection } from './PannelSelection'
import { UserTestCategory } from '@core/types/history'

export const HistoryFactorsPannel = ({
  data,
  onPress,
  sortFactors,
}: HistoryFactorsPannelProps) => {
  const [selectedCategory, setSelectedCategory] = useState(0)
  const { t } = useTranslation(['result'])
  const theme = useTheme()

  const _onPress = (item: UserTestCategory, index: number) => {
    onPress(item, index)
    setSelectedCategory(index)
  }

  return (
    <PannelSelectionContainer>
      <PannelSelectionItemContainer>
        {data.map((e, idx) => (
          <PannelSelection
            key={`select-${e.uuid}`}
            onPress={_onPress}
            selected={idx === selectedCategory}
            element={e}
            index={idx}>
            <>
              <Text weight={'bold'} marginSize={`0 0 8px 0`}>
                {e.name}
              </Text>
              <GradientScoreIndicator score={e.score} />
            </>
          </PannelSelection>
        ))}
      </PannelSelectionItemContainer>
      <PannelSelected>
        <DescriptionContainer>
          <HTMLView source={data[selectedCategory]?.description} />
        </DescriptionContainer>
        <FactorsContainer>
          <PannelFactorItem>
            <FactorsRiskIcon source={riskIcon} resizeMode='contain' />
            <Text weight={'bold'} marginSize={`0 0 8px 0`}>
              {t('risk-title')}
            </Text>
            <MainFactorItemList>
              {data[selectedCategory]?.factors.filter((e) => e.kind === 'risk')
                .length > 0 ? (
                sortFactors(
                  data[selectedCategory]?.factors.filter(
                    (e) => e.kind === 'risk'
                  )
                ).map((e, idx) => (
                  <Paragraph key={e.uuid}>{`${idx + 1}. ${
                    e.content[e.kind]
                  }`}</Paragraph>
                ))
              ) : (
                <Paragraph>{t('empty-factors')}</Paragraph>
              )}
            </MainFactorItemList>
          </PannelFactorItem>
          <PannelFactorItem>
            <FactorsRiskIcon source={protectionIcon} resizeMode='contain' />
            <Text weight={'bold'} marginSize={`0 0 8px 0`}>
              {t('protection-title')}
            </Text>
            <MainFactorItemList>
              {data[selectedCategory]?.factors.filter(
                (e) => e.kind === 'protection'
              ).length > 0 ? (
                sortFactors(
                  data[selectedCategory]?.factors.filter(
                    (e) => e.kind === 'protection'
                  ),
                  false,
                  true
                ).map((e, idx) => (
                  <Paragraph key={e.uuid}>{`${idx + 1}. ${
                    e.content[e.kind]
                  }`}</Paragraph>
                ))
              ) : (
                <Paragraph>{t('empty-factors')}</Paragraph>
              )}
            </MainFactorItemList>
          </PannelFactorItem>
        </FactorsContainer>
      </PannelSelected>
    </PannelSelectionContainer>
  )
}
