import { Text } from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'

export const FlexContainer = styled.View`
  flex-direction: row
`
export const IconsContainer = styled.View`
  flex: 1;
`

export const StarContainer = styled.Pressable<{
  pressable: boolean
}>`
  cursor: ${({ pressable }) => (pressable ? 'pointer' : 'default')}  
`

export const Circle = styled.View<{hover: boolean}>`
  background-color: ${({theme, hover}) => hover ? theme.colors.secondary : theme.colors.primary};
  width:  ${({theme}) => theme.device === 'small' ? 35 : 40}px;
  height: ${({theme}) => theme.device === 'small' ? 35 : 40}px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-right: 4px;
  `

  export const CircleText = styled(Text)<{hover: boolean}>`
  color: ${({theme, hover}) => hover ? theme.colors.white : theme.colors.black};
  `
