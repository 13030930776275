import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '@ui/utils'

export const Container = styled.Pressable<{ device: DeviceSizeType }>`
  justify-content: flex-start;
  margin-bottom: 40px;
  ${({ device }) =>
    device === 'small' || device == 'medium'
      ? css`
          flex: 1;
          width: 100%;
          max-width: 100%;
          flex-basis: auto;
        `
      : css`
          width: 320px;
          margin-right: 80px;
        `}
`

export const TextWrapper = styled.View`
  ${IS_BROWSER && css`min-height: 55px`};
  justify-content: center;
  min-width: 90%;
  max-width: 90%;
`

export const LinkAndTextContainer = styled.View`
  
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.spacings.S}px`};
`
