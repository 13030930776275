import { deleteItems } from '@core/lib/storage'
import { FontIcon } from '@ui/Moodwork-UI/atoms'
import {
  ModalClose,
  IconContainer,
} from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal.style'
import { useTheme } from '@ui/utils'
import React, { useEffect, useState } from 'react'
import Animated, {
  useSharedValue,
  withTiming,
  useAnimatedStyle,
} from 'react-native-reanimated'

export const HeadContent: React.FC<{
  visible: boolean
  onClose: () => void
}> = ({ onClose, visible, children }) => {
  const theme = useTheme()
  const collapse = useSharedValue(0)

  const config = {
    duration: 800,
  }

  const style = useAnimatedStyle(() => {
    return {
      height: withTiming(collapse.value, config),
    }
  })

  useEffect(() => {
    collapse.value = visible ? 186 : 0
  }, [visible])

  return (
    <Animated.View
      style={[
        {
          backgroundColor: theme.colors.greyBlue,
          alignItems: 'center',
          overflow: 'hidden',
        },
        style,
      ]}>
      {children}
      <ModalClose
        device={theme.device}
        onPress={() => {
          onClose()
        }}>
        <IconContainer>
          <FontIcon name='cancel' color={theme.colors.black} size={16} />
        </IconContainer>
      </ModalClose>
    </Animated.View>
  )
}
