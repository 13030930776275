import { IS_BROWSER, themeColor } from '@ui/utils'
import styled, { css } from 'styled-components/native'

export const Content = styled.View<{ titleInline: boolean }>`
max-width: 100%;
margin-right: -52px;
width: 100%;
flex-direction: ${({ titleInline }) => (titleInline ? 'row' : 'column')};
`

export const ListContainer =  styled.View<{ titleInline: boolean }>`
${({theme, titleInline}) => theme.device != "small" && titleInline ? css`flex: 6` : css`margin-top: 16px;`}; 
`

export const Separator = styled.View`
  height: 16px;
  width: 32px;
`

export const ArrowLeftContainer = styled.Pressable<{
  arrowBackgroundColor: string
  displayArrowShadow: boolean
  titleInline: boolean
}>`
  position: absolute;
  left: 0;
  bottom:  ${({titleInline}) => titleInline ? 16 : 0}px; 
  top:  ${({titleInline}) => titleInline ? 0 : -63}px;
  width: 52px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background-color: ${({ arrowBackgroundColor }) => arrowBackgroundColor};
  ${({ displayArrowShadow }) =>
    displayArrowShadow &&
    IS_BROWSER &&
    css`box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.15)`};
`

export const ArrowContainer = styled.Pressable<{
  arrowBackgroundColor: string
  displayArrowShadow: boolean
  titleInline: boolean
}>`
  width: 52px;
  z-index: 10;
  margin-bottom: ${({titleInline}) => titleInline ? 16 : 0}px;
  align-items: center;
  justify-content: center;
  background-color: ${({ arrowBackgroundColor }) => arrowBackgroundColor};
  ${({ displayArrowShadow }) =>
    displayArrowShadow &&
    IS_BROWSER &&
    css`box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.15)`};
`