import React from 'react'
import AppointmentInfo from '../../ui/AppointmentInfo'
import { useNavigation, useRoute } from '@react-navigation/native'
import { PsychologistAppointmentInfoRoute } from '../lib/types'
import { PageView } from '../../ui/PageView'
import { useUserInfo } from '@core/services/viewer'
import { ErrorPage } from '@ui/Moodwork-UI'
import { ErrorType, IS_NATIVE } from '@ui/utils'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'

export default function PsychologistAppointmentInfo() {
  const { eventSlotId, eventType, eventDate, psyName, psyAvatar } =
    useRoute<PsychologistAppointmentInfoRoute>().params
  const userInfo = useUserInfo()?.data
  const navigation = useNavigation()

  if (!userInfo?.care_access) {
    return <ErrorPage type={ErrorType.CARE_ACCESS_ERROR} />
  } else {
    return (
      <PageView>
        {IS_NATIVE && <BackButton navigation={navigation} />}
        <AppointmentInfo
          eventId={eventSlotId}
          eventType={eventType}
          eventDate={eventDate}
          psyName={psyName}
          psyAvatar={psyAvatar}
        />
      </PageView>
    )
  }
}
