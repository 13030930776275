import * as React from 'react'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import { useWindowDimensions } from 'react-native'
import {
  Container,
  IconContainer,
  ChatIcon,
  TextContainer,
  Title,
  Content,
} from './ChatBanner.style'
import { ChatBannerProps } from './ChatBanner.types'
import chatIcon from '../../assets/chatIcon.png'

export default function ChatBanner({ title, content }: ChatBannerProps) {
  const { width } = useWindowDimensions()

  // TODO: replace image with icoMoon icon
  return (
    <Container width={width}>
      <ChatIcon size={36} source={chatIcon} />
      <TextContainer>
        <Title weight='bold' size='L' color='white'>
          {title}
        </Title>
        <Content color='white'>{content}</Content>
      </TextContainer>
      <IconContainer>
        <Icon name='arrow-right' color='white' size={22} />
      </IconContainer>
    </Container>
  )
}
