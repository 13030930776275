import React from 'react'
import { ActivityIndicator, Pressable, PressableProps } from 'react-native'
import { useDevice } from '../../../../utils/device'
import { BigParagraph } from '../../../atoms'
import { Container, Wrapper } from './Button.style'
import ButtonProps from './Button.types'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import { useToggle } from '@core/lib/hooks'

/**
 * TODO: remove the typescript ignores
 */
export const Button = ({
  onPress,
  disabled,
  label,
  trackLabel,
  outline,
  loading,
  color,
  noHover,
  compact,
  textColor,
  trackProperties,
  style,
}: ButtonProps & TrackableEventProps & Pick<PressableProps, 'style'>) => {
  const device = useDevice()
  const [hover, onHoverIn, onHoverOut] = useToggle(false)

  const onTrackedPress = () => {
    trackEvent(trackLabel, trackProperties).then(() => {
      onPress()
    })
  }

  return (
    <Pressable
      testID='Button'
      disabled={disabled}
      onPress={onTrackedPress}
      // @ts-ignore
      onMouseEnter={!noHover && onHoverIn}
      // @ts-ignore
      onMouseLeave={!noHover && onHoverOut}
      focusable>
      <Wrapper
        style={style}
        hover={!disabled && hover}
        device={device}
        color={color}>
        <Container
          compact={compact}
          disabled={disabled}
          outline={outline}
          color={color}>
          {loading ? (
            <ActivityIndicator color='white' />
          ) : (
            <BigParagraph
              alignPosition='center'
              color={textColor}
              weight='medium'>
              {label}
            </BigParagraph>
          )}
        </Container>
      </Wrapper>
    </Pressable>
  )
}
