import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../../../../utils/device'
import { View } from 'moti'

export const FlexContainer = styled.View<{ device: DeviceSizeType }>`
  max-width: 385px;
  ${(props) =>
    props.device === 'small' || props.device === 'medium'
      ? css`
          ${IS_BROWSER && 'flex-grow: auto'};
          flex-basis: auto;
          margin: 0 0 40px 0;
        `
      : css`
          flex: 1;
          margin: 0 32px 0 0;
        `}
`

export const Image = styled.Image`
  height: 64px;
  width: 64px;
  margin: 0 24px 0 16px;
`

const web = css`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
`

export const Wrapper = styled(View)`
  background-color: white;
  shadow-color: ${({ theme }) => theme.colors.black};
  shadow-offset: 0 1px;
  shadowOpacity: 0.22;
  shadowRadius: 2.22px;
  elevation: 5;
  ${IS_BROWSER && web};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`

export const CardContainer = styled.Pressable<{ selected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  flex-direction: row;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary};
      border-width: 2px;
    `}
`
