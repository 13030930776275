import React, { useCallback, useState } from 'react'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import { useTheme } from '../../../../utils'
import {
  Container,
  InputContainer,
  IconContainer,
  InputSearchText,
} from './SearchBar.style'
import SearchBarProps from './SearchBar.types'
import { FontIcon } from '@moodwork-ui'
import { useToggle } from '@core/lib/hooks'
import { useRoute } from '@react-navigation/native'

export default function SearchBar({
  onSearch,
  trackLabel,
  trackProperties,
  backgroundColor = 'secondaryBackground',
  borderRadius = '6px',
  inputPlaceHolder,
  iconSize = 20,
}: SearchBarProps & TrackableEventProps) {
  const theme = useTheme()
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const params = useRoute()?.params
  const [searchValue, setSearchValue] = useState(params?.q || '')

  const onCallSearch = useCallback(() => {
    if (searchValue.length > 0) {
      trackEvent('confirmed_search_bar_query', {
        search_query: searchValue,
      }).then()
      onSearch && onSearch(searchValue)
    }
  }, [onSearch, searchValue])

  return (
    <Container // @ts-ignore
      onMouseEnter={onHoverIn}
      // @ts-ignore
      onMouseLeave={onHoverOut}
      hovered={hovered}
      borderRadiusSize={borderRadius}
      backgroundColor={backgroundColor}>
      <IconContainer onPress={onCallSearch}>
        <FontIcon name='search' color={theme.colors.black} size={iconSize} />
      </IconContainer>
      <InputContainer>
        <InputSearchText
          placeholder={inputPlaceHolder || ''}
          value={searchValue}
          onChangeText={setSearchValue}
          onSubmitEditing={onCallSearch}
        />
      </InputContainer>
    </Container>
  )
}
