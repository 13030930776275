import styled, { css } from 'styled-components/native'
import { DeviceSizeType } from '@ui/utils'
import { Text } from '@moodwork-ui'

export const BullRow = styled.View`
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const BullContainer = styled.View<{ device: DeviceSizeType }>`
  width: 100%;
  flex: 1;
`

export const Bull = styled(Text)<{ active: boolean }>`
  font-size: 35px;
  margin-right: 3px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.secondary
      : props.theme.colors.secondaryLight};
`
