import React from 'react'
import styled, { css } from 'styled-components/native'
import { PsychologistRefererProps } from './interface'
import { BigParagraph, LinkWithIcon, HTMLView } from '@moodwork-ui'
import { Linking } from 'react-native'
import { useTheme } from '@utils'

const RefererContainer = styled.View<{
  index: number
  refererCount: number
  numColumn: number
  width: number
}>`
  width: ${({ width }) => width}px;
  margin-bottom: 50px;
  ${({ index, refererCount, numColumn }) =>
    numColumn > 0 &&
    (index + 1) % numColumn != 0 &&
    index != refererCount - 1 &&
    css`
      padding-right: 32px;
      margin-right: 32px;
      border-right-width: 1px;
      border-right-color: black;
    `};
`

const RefererRole = styled.View`
  margin-bottom: ${({ theme }) => `${theme.spacings.L}px`};
`

const RefererEmail = styled.View<{ numColumn: number; width: number }>`
  margin-top: ${({ theme }) => `${theme.spacings.S}px`};
  ${({ numColumn, width }) =>
    numColumn > 1 &&
    css`
      max-width: ${width}px;
      line-break: anywhere;
    `};
`

export const PsychologistRefererCard = ({
  name,
  phone,
  email,
  role,
  index,
  refererCount,
  numColumn,
  itemWidth,
  description,
  trackLabel = 'clicked_psychologist_phone',
}: PsychologistRefererProps) => {
  const theme = useTheme()
  return (
    <RefererContainer
      index={index}
      refererCount={refererCount}
      numColumn={numColumn}
      width={itemWidth}>
      <BigParagraph weight='bold'>{name}</BigParagraph>
      <RefererRole>
        <BigParagraph>{role}</BigParagraph>
        <HTMLView source={description} />
      </RefererRole>
      {!!phone && (
        <LinkWithIcon
          justifyCenter={false}
          label={phone}
          onPress={() => Linking.openURL(`tel:${phone}`)}
          trackLabel={trackLabel}
          textColor='secondary'
          iconColor='secondary'
          iconName={'phone-circle'}
          iconSize={36}
          IconPosition={'left'}
          IconMargin='0px 14px 0px 0px'
          paddingSize='0px'
          textSize={theme.device !== 'large' ? 'S' : 'M'}
        />
      )}
      {!!email && (
        <RefererEmail width={itemWidth} numColumn={numColumn}>
          <LinkWithIcon
            justifyCenter={false}
            label={email}
            onPress={() => Linking.openURL(`mailto:${email}`)}
            trackLabel={'clicked_wellbeing_results_representative'}
            iconColor='secondary'
            iconName={'email'}
            iconSize={36}
            IconPosition={'left'}
            IconMargin='0px 14px 0px 0px'
            paddingSize='0px'
            textSize={theme.device !== 'large' ? 'S' : 'M'}
          />
        </RefererEmail>
      )}
    </RefererContainer>
  )
}
