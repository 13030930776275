import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { NavigationContainer } from './NavigationIcon.types'
import { FontIcon } from '@ui/Moodwork-UI'

const web =
  IS_BROWSER &&
  css`
    transition: all 0.15s ease-in-out;
  `

export const Container = styled.View<NavigationContainer>`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 500px;
  align-items: center;
  justify-content: center;
  ${web};
  ${({ big, theme }) =>
    big &&
    theme.device === 'large' &&
    css`
      width: 53px;
      height: 53px;
    `}
`

export const Icon = styled(FontIcon)`
  width: 100%;
  height: 100%;
`
