import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  Container,
  Title,
  SignTextInput,
  LogInButton,
  SignLink,
} from './SignIn.styles'
import { BrandedContainer } from '@ui/Moodwork-UI'
import { useTranslation } from 'react-i18next'
import { HOST, LOGGED_IN_ENV, RECAPTCHA_SITE_KEY } from '@core/constants'
import { useSignIn, useUserInfo } from '@services/viewer'
import { useSSOVerification } from '@core/services/viewer'
import { useNavigationLink } from '@core/lib/navigation'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { storeItem, getStoredItem } from '@core/lib/storage'
import { IS_BROWSER, IS_NATIVE } from '@ui/utils'
import { AlertContext } from '@core/components/Alerthandler'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@core/constants'
import { setAnalyticUser } from '@core/lib/analytics'
import * as WebBrowser from 'expo-web-browser'
import { InteractionManager } from 'react-native'

export const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [webviewUrl, setWebviewUrl] = useState()
  const { setMessage, setType } = useContext(AlertContext)
  const params = useRoute().params

  const { t, i18n } = useTranslation('sign')
  const route = useRoute()
  const ssoCheck = useSSOVerification()
  const {
    data: userData,
    refetch: refetchUserData,
    remove,
    isRefetching,
  } = useUserInfo()
  const signIn = useSignIn(params?.locale)
  const linkTo = useNavigationLink()
  const navigation = useNavigation()

  useEffect(() => {
    if (IS_NATIVE) {
      getStoredItem(ACCESS_TOKEN_KEY).then((accessToken) => {
        getStoredItem(REFRESH_TOKEN_KEY).then((refreshToken) => {
          accessToken &&
            refreshToken &&
            InteractionManager.runAfterInteractions(() => {
              navigation.dispatch(StackActions.replace('Home'))
            })
        })
      })
    }
  }, [])

  if (
    IS_BROWSER &&
    getStoredItem(ACCESS_TOKEN_KEY) &&
    getStoredItem(REFRESH_TOKEN_KEY)
  ) {
    navigation.dispatch(StackActions.replace('Home'))
  }

  const checkEmailForSSO = useCallback(() => {
    if (/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      ssoCheck.mutate(email)
    }
  }, [email])

  const handlePressLogIn = useCallback(() => {
    if (IS_BROWSER) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
          .then((token) => {
            signIn.mutate({
              username: email,
              password: password,
              captchaToken: token,
            })
          })
      })
    } else {
      signIn.mutate({
        username: email,
        password: password,
      })
    }
  }, [email, password, signIn])

  // sso check email
  useEffect(() => {
    if (ssoCheck.isSuccess && ssoCheck.data?.data?.url) {
      if (IS_NATIVE) {
        setWebviewUrl(HOST + ssoCheck.data?.data?.mobile_url)
      } else {
        linkTo(ssoCheck.data?.data?.url)
      }
    }
  }, [ssoCheck])

  useEffect(() => {
    if (isRefetching) {
      remove()
      return
    }
    if (userData) {
      setAnalyticUser(userData).then(() => {
        if (navigation.getState().routes.length > 1) {
          navigation.dispatch(
            StackActions.replace(
              navigation.getState().routes[
                navigation.getState().routes.length - 2
              ].name,
              navigation.getState().routes[
                navigation.getState().routes.length - 2
              ].params
            )
          )
          return
        }
        navigation.dispatch(StackActions.replace('Home'))
      })
    }
  }, [userData])

  useEffect(() => {
    if (signIn.isSuccess) {
      storeItem(LOGGED_IN_ENV, '1')
      refetchUserData()
    }
    if (signIn.isError) {
      signIn.reset()
      setType('warning')
      setMessage(signIn?.error?.response?.data?.error_description)
    }
  }, [signIn])

  // handle locale
  useEffect(() => {
    if (route?.params?.locale && i18n.language !== route?.params?.locale) {
      i18n.changeLanguage(route?.params?.locale)
    }
  }, [i18n, route])

  useEffect(() => {
    if (webviewUrl) {
      WebBrowser.openAuthSessionAsync(webviewUrl)
    }
  }, [webviewUrl])

  return (
    <Container>
      <BrandedContainer>
        <>
          <Title>{t('in.title')}</Title>
          <SignTextInput
            type='email'
            autoCapitalize='none'
            onBlur={checkEmailForSSO}
            placeholder={t('in.email.placeholder')}
            onChange={setEmail}
          />
          <SignTextInput
            type='password'
            autoCapitalize='none'
            placeholder={t('in.password.placeholder')}
            onChange={setPassword}
          />
          <LogInButton
            compact
            onPress={handlePressLogIn}
            label={t('in.log-in')}
            trackLabel='clicked_signin_login'
          />
          <SignLink
            text={t('in.sign-up')}
            url={`/${i18n.language}/intro`}
            trackLabel='clicked_signin_go_to_signup'
          />
          <SignLink
            text={t('in.forgot-password')}
            url={`${HOST}/${i18n.language}/reset_password`}
            trackLabel='clicked_signin_reset_password'
          />
          {/* TODO: Remove next line */}
          {/* <Text>HOST: {HOST}</Text> */}
        </>
      </BrandedContainer>
    </Container>
  )
}
