import ResourceCardLoading from '@ui/Moodwork-UI/molecules/pressables/ResourceCard/ResourceCard.loading'
import { PageView } from '@ui/PageView'
import { useTheme } from '@ui/utils'
import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { Container } from './index.styles'

export default function SearchLoading() {
  const theme = useTheme()
  const imageSize = theme.device === 'small' ? '44' : '70'
  return (
    <Container>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='200px'
        height='16'
        style={{ marginBottom: 24 }}>
        <Rect x='0' y='0' rx='3' ry='3' width='100%' height='14' />
      </ContentLoader>
      <ResourceCardLoading
        items={[
          { title: 'skeleton-1' },
          { title: 'skeleton-2' },
          { title: 'skeleton-3' },
          { title: 'skeleton-4' },
          { title: 'skeleton-5' },
          { title: 'skeleton-6' },
        ]}
      />
    </Container>
  )
}
