import styled, { css } from 'styled-components/native'
import { themeColor } from '../utils/styled'
import { Label as Text } from '../Text'

export const Input = styled.TextInput<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  letter-spacing: 1px;
  padding: 6px 12px;
  height: 44px;
  color: #303030;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid ${themeColor('error')};
      color: ${themeColor('error')};
    `}
  ${({ editable }) =>
    !editable &&
    css`
      opacity: 0.8;
    `}
`

export const Label = styled(Text)<{ error?: boolean }>`
  ${({ error }) =>
    error &&
    css`
      color: ${themeColor('error')};
    `}
`
