import * as React from 'react'
import { AppCore } from './core'

import { LogBox, Platform } from 'react-native'
import {
  ENV,
  HOST,
  SENTRY_DSN,
  INTERCOM_APP_ID,
  RECAPTCHA_SITE_KEY,
} from './core/constants'
import { IS_NATIVE, IS_BROWSER, useDevice } from './ui/utils/device'
import * as Sentry from 'sentry-expo'
import messaging from '@react-native-firebase/messaging'
import dynamicLinks from '@react-native-firebase/dynamic-links'
import { PermissionsAndroid } from 'react-native'
import { enableFreeze } from 'react-native-screens'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import { useNavigationLink } from '@core/lib/navigation'

enableFreeze(true)

/**
 * Ignore warning caused by reat-query timers
 */
if (IS_NATIVE) {
  LogBox.ignoreLogs(['timer'])
}

if (IS_BROWSER) {
  window['intercomSettings'] = {
    app_id: INTERCOM_APP_ID,
  }
  window['recaptchaSettings'] = {
    RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY,
  }
}

if (ENV !== 'production') {
  console.info(`Starting app using ${HOST}`)
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  enableNative: true,
  enableNativeCrashHandling: true,
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
})

async function requestIosUserPermission() {
  const authStatus = await messaging().requestPermission()
  const enabled =
    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
    authStatus === messaging.AuthorizationStatus.PROVISIONAL

  if (enabled) {
    console.log('Authorization status:', authStatus)
  }
}

export default function App() {
  if (IS_NATIVE) {
    if (Platform.OS === 'ios') {
      requestIosUserPermission()
    } else if (Platform.OS === 'android') {
      PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS
      )
    }

    messaging().registerDeviceForRemoteMessages()
  }

  const linkTo = useNavigationLink()

  const handleDynamicLink = (link) => {
    // Handle dynamic link inside your own application
    linkTo(link)
  }

  React.useEffect(() => {
    if (IS_NATIVE) {
      const unsubscribe = dynamicLinks().onLink(handleDynamicLink)
      // When the component is unmounted, remove the listener
      return () => unsubscribe()
    }
  }, [])

  return (
    <SafeAreaProvider>
      <SafeAreaView style={{ flex: 1 }}>
        <AppCore />
      </SafeAreaView>
    </SafeAreaProvider>
  )
}
