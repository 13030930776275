import React, { useEffect, useState } from 'react'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import ProfilingSlides from '@ui/Moodwork-UI/organisms/ProfilingSlides'
import { useUserInfo } from '@core/services/viewer'
import { useProfileQuestion } from '@core/services/profile'
import { StackActions, useNavigation } from '@react-navigation/native'
import { SCREEN_HOME, SCREEN_PROFILING } from '@core/constants'
import { InteractionManager } from 'react-native'

export default function Profiling() {
  const { data, isError, isSuccess, isFetched, isFetching } =
    useProfileQuestion()
  const { data: userInfos, isFetched: userInfoFetched } = useUserInfo()
  const navigation = useNavigation()

  useEffect(() => {
    if (
      isSuccess &&
      isFetched &&
      userInfos &&
      userInfos.questions_to_answer_type !== 'profile'
    ) {
      InteractionManager.runAfterInteractions(() => {
        navigation.dispatch(StackActions.replace(SCREEN_HOME))
      })
    }
  }, [isSuccess, isFetched, userInfoFetched])

  useEffect(() => {
    if (isError) {
      navigation.dispatch(StackActions.replace(SCREEN_HOME))
    }
  }, [isError])

  return !isFetching && isFetched && data.length > 0 ? (
    <NavigationModal
      backgroundColor='secondary'
      isClosable={false}
      hasBackgroundOpacity={false}>
      <ProfilingSlides
        questions={data}
        isRefresh={userInfos?.refresh_profile_questions ?? false}
      />
    </NavigationModal>
  ) : (
    <></>
  )
}
