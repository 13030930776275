import { Text } from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'

export const Container = styled.View`
  margin-left: ${({ theme }) => (theme.device === 'large' ? 32 : 0)}px;
  flex: 1;
  flex-basis: undefined;
  flex-direction: ${({ theme }) =>
    theme.device === 'large' ? 'row' : 'column'};
  align-items: center;
`

export const Label = styled(Text)`
  margin: 0 8px 0 8px;
  padding: 8px 16px;
  background-color: #EBF0F9;
  border-radius: 999px;
`
