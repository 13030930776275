import * as React from 'react'
import produce, { Draft } from 'immer'
import { useContext } from 'react'
import { useReducer, createContext } from 'react'
import {
  ActionCreator,
  ActionType,
  ContextValue,
  Dispatch,
  ProviderType,
  Thunk,
} from './types'

class StoreError extends Error {
  constructor(message: string) {
    super(`[StoreError]: ${message}`)
  }
}

/**
 * I borrowed the Thunk middleware idea from redux. The concept is
 * that instead of dispatching Actions, we can dispatch function or async functions
 * that will resolve in actions
 *
 * for example:
 *
 * function asyncAction(payload) {
 *   return async dispatch => {
 *     await sleep(1000)
 *     dispatch({ type: 'OK', payload })
 *   }
 * }
 *
 * dispatch(asyncAction({ payload: 'ok' }))
 *
 * TODO:
 *  - Maybe we can create a more generic way of handling middlewares
 */
function thunk<T>(dispatch: Dispatch<T>, getState: () => T) {
  const _dispatch: Dispatch<T> = (action: ActionType | Thunk<T>) => {
    if (typeof action === 'function') action(_dispatch, getState)
    else dispatch(action)
  }
  return _dispatch
}

/**
 * We will use this function to provide the app with
 * a context Provider as well as the reducer configured
 *
 * Usage:
 *
 * cont initialState = {}
 * const reducer = state => state
 * const [context, Provider] = createStore(reducer, initialState)
 */
export function createStore<T>(
  reducer: (s: T, a: ActionType) => T,
  initialState: T
): [() => ContextValue<T>, ProviderType] {
  const context = createContext({
    state: initialState,
    dispatch: (() => {}) as Dispatch<T>,
  })
  const { Provider } = context

  /**
   * The provider will allow any children element
   * to access the current state, as well as the dispatch function
   *
   * TODO:
   *  - should we make a DispatchProvider and StateProvider ?
   */
  function StoreProvider({ children }: { children: JSX.Element }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    /**
     * TODO: Fix type error 🚨
     */
    return (
      <Provider value={{ state, dispatch: thunk(dispatch, () => state) }}>
        {children}
      </Provider>
    )
  }

  function useState() {
    return useContext(context)
  }

  return [useState, StoreProvider]
}

export function createAction<T>(type: string) {
  return (payload?: T) => ({
    type,
    payload,
  })
}

/**
 * Inspired from redux slices.
 * Creates a reducer, and returned the resulting
 * actions.
 * This function will "generate" the switches from the
 * reducerMap
 *
 * The actions will be described using Immer.
 *
 * Usage:
 *
 * createReducer({
 *   setName: (state, payload) => { state.name = payload }
 * })
 *
 * TODO: type action payloads
 *
 */
export function createReducer<
  T,
  K extends { [k: string]: (s: Draft<T>, a?: any) => void }
>(reducerMap: K) {
  const entries = Object.entries(reducerMap)
  const actions = entries.reduce<Partial<{ [k in keyof K]: ActionCreator }>>(
    (p, [name]) => ({ ...p, [name]: createAction(name) }),
    {}
  )

  function reducer(state: T, action: ActionType): T {
    const match = entries.find(([name]) => name === action.type)
    if (!match) {
      throw new StoreError(`Invalid action name: '${action.type}'`)
    }
    return produce(state, (draft) => match[1](draft, action.payload))
  }

  return { actions: actions as { [k in keyof K]: ActionCreator }, reducer }
}

/**
 * Given a bunch of Store providers,
 * return a single provider that will render all of them
 * at once
 */
export function combineProviders([P, ...rest]: ProviderType[]): ProviderType {
  return function ({ children }) {
    if (!rest.length) {
      return <P>{children}</P>
    }
    const Rest = combineProviders(rest)
    return (
      <P>
        <Rest>{children}</Rest>
      </P>
    )
  }
}
