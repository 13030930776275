import styled, { css } from 'styled-components/native'
import { PageContent } from '../Page'
import { Title, SectionTitle } from '@moodwork-ui'

export const Container = styled(PageContent)`
  max-width: 1080px;
  margin-horizontal: 16px;
  margin-top: 32px;
  align-self: center;
`

export const TitleCollection = styled(Title)`
margin-bottom: 16px;`

export const Description = styled(SectionTitle)`
margin-bottom: 32px;`