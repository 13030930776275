import * as React from 'react'
import { AntDesign as Icon } from '@expo/vector-icons'
import { Text } from '@moodwork-ui'
import { Container, IconContainer } from './ErrorBlock.style'
import { ErrorBlockProps } from './ErrorBlock.types'
import { Showable } from '../Showable'

export default function ErrorBlock({ content }: ErrorBlockProps) {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    setShow(true)
  }, [])

  return (
    <Showable show={show} approximateHeight={64}>
      <Container>
        <IconContainer>
          <Icon name='warning' color='white' />
        </IconContainer>
        <Text size='XS' color='white'>
          {content}
        </Text>
      </Container>
    </Showable>
  )
}
