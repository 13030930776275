import styled from 'styled-components/native'
import { Colors } from '../../../UIProvider/UIProvider.types'

export const Line = styled.View<{
  color?: keyof Colors
  width?: number
  height?: number
}>`
  height: ${({ height }) => (height ? `${height}px` : '3px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.primary};
`
