import { ActionTitle, BigParagraph } from '@ui/Moodwork-UI/atoms'
import styled, { css } from 'styled-components/native'

export const Title = styled(ActionTitle)`
  flex: 1;
  margin-bottom: 8px;
`

export const RowContainer = styled.View`
  flex-direction: row;
  display: flex;
  min-height: 75px;
`

export const DecorateImage = styled.Image`
  width: 109px;
  height: 75px;
`

export const Description = styled(BigParagraph)`
  flex: 1;
  padding-left: ${({ theme }) =>
    `${theme.device === 'small' ? '0' : theme.spacings.XL}px`};
`

export const ButtonContainer = styled.View`
  margin-top: ${({ theme }) =>
    `${theme.device === 'medium' ? '0' : theme.spacings.L}px`};
  flex-direction: ${({ theme }) =>
    theme.device === 'small' ? 'column' : 'row'};
  ${({ theme }) =>
    theme.device != 'small' &&
    css`
     align-self: flex-end;
  `}
`
