import { useTranslation } from 'react-i18next'
import { useHttp } from './http'
import { UseQueryResult, useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { ContactCategory, ContactPayload } from '@core/types/contact'
import { AlertContext } from '@core/components/Alerthandler'

export function useContactCategories(): UseQueryResult<
  ContactCategory[],
  AxiosError
> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getContactCategories = () =>
    http.get(`/api/v3/contact_categories`).then((result) => result?.data)
  return useQuery(['contact-categories', i18n.language], getContactCategories, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  })
}

export function useContactForm() {
  const http = useHttp()
  const sendSupportRequest = ({
    subject,
    message,
    contact_category_id,
  }: ContactPayload) => {
    return http.post(
      '/api/v3/contact',
      {
        subject: subject,
        message: message,
        contact_category_id: contact_category_id,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
  }
  return useMutation(sendSupportRequest, { retry: 1 })
}
