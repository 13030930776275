import styled from 'styled-components/native'
import { DeviceSizeType } from '../utils/device'

export const ImageContainer = styled.View`
  flex: 1;
  align-items: flex-start;
  margin-top: -7%;
  margin-left: 8px;
`

export const BigImage = styled.Image`
  width: 100%;
  aspectRatio: 1.15;
`

export const Container = styled.View<{ device: DeviceSizeType }>`
  display: flex;
  height: 100%;
  margin-horizontal: ${(props) => (props.device === 'small' ? '0px' : '80px')};
  padding-horizontal: 16px;
  margin-bottom: 32px;
  padding-top: ${(props) => (props.device === 'small' ? '32px' : '0px')}
`

export const ContentContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const AppointmentCardContainer = styled.View<{ device: DeviceSizeType }>`
  display: flex;
  flex: ${(props) =>
    props.device === 'small' || props.device === 'medium' ? 1 : 2};
  flex-direction: ${(props) =>
    props.device === 'small' || props.device === 'medium' ? 'column' : 'row'};
`
