import { pull } from 'lodash'

export function capitalize(s: string) {
  const exceptions = ['de', 'du', 'le', 'des', 'la', 'un', 'une']
  return s
    .split(' ')
    .map((n) =>
      exceptions.includes(n)
        ? n
        : n.charAt(0).toUpperCase() + n.slice(1).toLowerCase()
    )
    .join(' ')
}

export function chunk(array: any[], chunkSize: number) {
  if (chunkSize <= 0) throw new Error('Invalid chunk size')
  const result = []
  for (let i = 0, len = array.length; i < len; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

export function inc(a: number) {
  return a + 1
}

export function dec(a: number) {
  return a - 1
}

export const toggleKeyList = (element: string) => (list: string[]) => {
  let currentList = list.slice(0)
  if (list.includes(element)) {
    let a = pull(currentList, element)
    return a
  } else {
    return currentList.concat(element)
  }
}
