import { Text } from '@moodwork-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDevice } from '@ui/utils'
import loader from '../../assets/loader.gif'
import { Container, Image } from './ResultLoadingScreen.style'

export default function ResultLoadingScreen() {
  const { t } = useTranslation('result')
  const device = useDevice()
  return (
    <Container>
      <Image device={device} resizeMode='contain' source={loader} />
      <Text size={device === 'small' ? 'M' : 'L'} alignPosition='center'>
        {t('loader-label')}
      </Text>
    </Container>
  )
}
