import styled from 'styled-components/native'
import { Text } from '@moodwork-ui'
import { AlertHandlerTypes } from './index.types'
import { IS_BROWSER } from '@ui/utils'

export const Alert = styled.View`
  padding: 16px;
  width: 100%;
  position: absolute;
  z-index: 999;
  
`

export const AlertContainer = styled.View<{ type: AlertHandlerTypes }>`
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'warning':
        return theme.colors.mistyRose
      case 'success':
        return theme.colors.paleGreen
      default:
        return theme.colors.lightSteelBlue
    }
  }};
  width: 100%;
  min-height: 30px;
  padding: ${() => (IS_BROWSER ? '0.75rem 1.25rem' : '5% 10%')};
  border-radius: ${() => (IS_BROWSER ? '0.25rem' : '8px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const AlertText = styled(Text)<{ type: AlertHandlerTypes }>`
  color: ${({ theme, type }) => {
    switch (type) {
      case 'warning':
        return theme.colors.crimson
      case 'success':
        return theme.colors.forestGreen
      default:
        return theme.colors.secondary
    }
  }}
`
