import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { NavigationTextProps } from './NavigationLink.types'
import { Text } from '@moodwork-ui'

export const NavigationLinkContainer = styled.Pressable`
  margin: 0 17.5px
  justify-content: center
  flex: 1 1 auto
  flex-direction: row
  align-items: center
  ${
    IS_BROWSER &&
    css`
      cursor: pointer;
    `
  };
`

export const NavigationText = styled(Text)<NavigationTextProps>`
  ${({ selected }) =>
    selected &&
    css`
      color: ${themeColor('secondary')};
    `}
`
NavigationText.displayName = 'NavigationText'
