import React, { useEffect } from 'react'
import { InformationCardWithButton } from '../../organisms'
import { GradientScoreIndicator } from '../../molecules'
import { FontIcon, HTMLView, SubTitle, Title } from '../../atoms'
// @ts-ignore
import appointmentPicture from '../../../../assets/images/img_accompagnement.png'
// @ts-ignore
import burnout from '../../../../assets/images/burnout.png'
import { OnDevice } from '../../../OnDevice'
import { BurnoutProps } from './interface'
import {
  TitleWrapper,
  AppointmentImage,
  AppointmentImageContainer,
  HeaderWrapper,
  MedicalWarning,
  BurnoutChatImage,
} from './Burnout.styles'
import {
  WorkPlanImage,
  WorkPlanImageWrapper,
  RecommendationWrapper,
  ImageAndInformationCardWrapper,
  CategoriesWrapper,
  MainSeparator,
  MainTitleWrapper,
  IconWrapper,
} from '../WellBeing/WellBeing.styles'
import { SCREEN_PSYCHOLOGISTS } from '@core/constants'
import { useTranslation } from 'react-i18next'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from '@ui/utils'
import { PageView } from '@ui/PageView'
import { trackEvent } from '@core/lib/analytics'
import { Viewer } from '@core/services'
import { useNavigationLink } from '@core/lib/navigation'

export const Burnout = ({ testId, result }: BurnoutProps) => {
  const { t, i18n } = useTranslation('result')
  const navigation = useNavigation()
  const theme = useTheme()
  const userInfo = Viewer.useUserInfo().data
  const linkTo = useNavigationLink()

  useEffect(() => {
    trackEvent('viewed_burnout_results_screen', {
      user_test_id: testId,
      score: result.final_score,
    })
  }, [])

  return (
    <PageView>
      <MainTitleWrapper>
        <IconWrapper>
          <FontIcon
            name={'check-circle'}
            size={theme.sizes.XL}
            color={theme.colors.secondary}
          />
        </IconWrapper>
        <Title>{t('main-title-burnout')}</Title>
      </MainTitleWrapper>
      <OnDevice size={['small']}>
        <MainSeparator />
      </OnDevice>
      <CategoriesWrapper>
        <HeaderWrapper>
          <TitleWrapper>
            <SubTitle>{result.title}</SubTitle>
          </TitleWrapper>
          <GradientScoreIndicator
            style={{ marginTop: '16px', flex: 1 }}
            score={10 - result.final_score / 10}
          />
        </HeaderWrapper>
        <HTMLView source={result.description} />
        <MedicalWarning color='grey'>
          {t('main-medical-warning')}
        </MedicalWarning>
      </CategoriesWrapper>
      {(userInfo?.care_access ||
        (!userInfo?.care_access && userInfo?.hotline)) && (
        <RecommendationWrapper
          colors={['#F4F5FD', '#FAFAFE', '#FFFFFF']}
          start={{ x: 0, y: 0.5 }}
          locations={[0, 0.5, 1]}>
          <Title alignPosition='center' marginSize={'0px'}>
            {t('recommanded-actions')}
          </Title>
          {!userInfo?.care_access && userInfo?.hotline ? (
            <ImageAndInformationCardWrapper>
              <OnDevice size={['large', 'medium']}>
                <WorkPlanImageWrapper>
                  <BurnoutChatImage source={burnout} resizeMode='contain' />
                </WorkPlanImageWrapper>
              </OnDevice>
              <InformationCardWithButton
                title={t('hotline-title')}
                buttonLabel={t('hotline-button')}
                description={t('hotline-description')}
                onPress={() => linkTo(userInfo?.hotline?.link)}
                trackLabel={'clicked_burnout_results_hotline'}
                disclaimer={
                  i18n.language === 'fr' ? t('hotline-disclaimer') : undefined
                }
              />
            </ImageAndInformationCardWrapper>
          ) : (
            <>
              <ImageAndInformationCardWrapper>
                <OnDevice size={['large', 'medium']}>
                  <WorkPlanImageWrapper>
                    <BurnoutChatImage source={burnout} resizeMode='contain' />
                  </WorkPlanImageWrapper>
                </OnDevice>
                <InformationCardWithButton
                  title={t('burnout-to-chat-title')}
                  buttonLabel={t('chat-action')}
                  description={t('burnout-to-chat-text')}
                  onPress={() => IntercomWrapper.displayMessageComposer()}
                  trackLabel={'clicked_burnout_results_chat_now'}
                />
              </ImageAndInformationCardWrapper>
              <ImageAndInformationCardWrapper>
                <InformationCardWithButton
                  title={t('burnout-appointment-title')}
                  buttonLabel={t('appointment-action')}
                  description={t('burnout-appointment-text')}
                  onPress={() => navigation.push(SCREEN_PSYCHOLOGISTS)}
                  trackLabel={'clicked_burnout_results_appointment'}
                />
                <OnDevice size={['large', 'medium']}>
                  <AppointmentImageContainer>
                    <AppointmentImage
                      source={appointmentPicture}
                      resizeMode='contain'
                    />
                  </AppointmentImageContainer>
                </OnDevice>
              </ImageAndInformationCardWrapper>
            </>
          )}
        </RecommendationWrapper>
      )}
    </PageView>
  )
}
