import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    marginTop: 16,
    flexDirection: 'row'
  },
  button: {
    backgroundColor: "#F4CB50", 
    borderRadius: 500,
    minHeight: 52,
    justifyContent: 'center',
    paddingVertical: 16,
    paddingHorizontal: 45,
    margin: 8
  },
  text: {
    fontSize: 18, 
    lineHeight: 24, 
    color: 'black'
  },
  logo: {
    width: 147,
    height: 75,
    marginBottom: 16
  }
 
})