import styled from 'styled-components/native'
import { themeColor } from '../utils/styled'

export const Text = styled.Text`
  font-family: Asap_400Regular;
  font-size: 16px;
  line-height: 24px;
  color: black;
`

export const CenteredText = styled(Text)`
  text-align: center;
`

export const Title = styled(Text)`
  font-family: Asap_700Bold;
  font-size: 24px;
  line-height: 34px;
`

export const CenteredTitle = styled(Title)`
  font-size: 20px;
  text-align: center;
`

export const Label = styled(Text)`
  font-size: 14px;
`

export const Link = styled(Text)`
  text-decoration: underline;
`

export const BlueLink = styled(Text)`
  text-decoration: underline;
  color: ${themeColor('secondary')};
  text-decoration-color: ${themeColor('secondary')};
  cursor: pointer;
`

export const CenteredLink = styled(Link)`
  text-align: center;
`

export const Legal = styled(Text)`
  font-size: 12px;
  line-height: 16.8px;
`

export const ErrorText = styled(Text)`
  color: red;
`
