import React from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { useTheme } from './styled'

export type DeviceSizeType = 'small' | 'medium' | 'large'
export type PlatformType = 'web' | 'native'

export const IS_BROWSER = Platform.OS === 'web'
export const IS_NATIVE = !IS_BROWSER

/* TODO : Legacy to delete. Call useTheme().device instead*/
export function useDevice() {
  return useTheme().device
}

export function useDeviceSizeType() {
  const { width } = useWindowDimensions()
  return React.useMemo(
    () =>
      (width < 768
        ? 'small'
        : width < 992
        ? 'medium'
        : 'large') as DeviceSizeType,
    [width]
  ) as DeviceSizeType
}
