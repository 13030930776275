import React, { useCallback, useState } from 'react'
import AppointmentTutorial from '@ui/AppointmentTutorial/AppointmentTutorial'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { useNavigation } from '@react-navigation/native'
import { useUserInfo } from '@core/services/viewer'
import { storeModal } from '@core/lib/modal'
import { MODAL_APPOINTMENT_TUTORIAL } from '@core/constants'
import { trackEvent } from '@core/lib/analytics'

export default function AppointmentTutorialModal() {
  const navigation = useNavigation()
  const { data: userInfoData } = useUserInfo()
  const [currentStep, setCurrentStep] = useState(0)

  const handleClose = useCallback(() => {
    storeModal(userInfoData?.email, 'false', MODAL_APPOINTMENT_TUTORIAL)
    navigation.goBack()
  }, [navigation, userInfoData?.email])

  const handleTrackClose = useCallback(() => {
    trackEvent('clicked_appointment_tuto_close', {
      popin_step: currentStep + 1,
    }).then(() => handleClose())
  }, [currentStep])

  return (
    <NavigationModal onClose={handleTrackClose}>
      <AppointmentTutorial
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onClose={handleClose}
      />
    </NavigationModal>
  )
}
