import * as React from 'react'
import { Content, Container } from './ContentTag.style'
import { ContentTagProps } from './ContentTag.types'

export default function ContentTag({ content, color }: ContentTagProps) {
  return (
    <Container color={color}>
      <Content>{content}</Content>
    </Container>
  )
}
