import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as React from 'react'
import { Pressable } from 'react-native'
import { FontIcon } from '../Moodwork-UI/atoms'
import { BackgroundContainer, Container } from './IconButton.style'
import { FAQButtonProps, IconButtonProps } from './IconButton.types'

export default function IconButton({
  onPress,
  color,
  containerSize = 64,
  name,
  size,
}: IconButtonProps) {
  return (
    <Pressable testID='IconButton' onPress={onPress}>
      <Container size={containerSize}>
        <FontIcon name={name || ''} size={size} color={color} />
      </Container>
    </Pressable>
  )
}

export function FAQButton({ url, big }: FAQButtonProps) {
  return (
    <Pressable onPress={() => console.log({ url })}>
      <BackgroundContainer big={big}>
        <MaterialCommunityIcons
          style={{ color: 'white' }}
          size={18}
          name='information'
        />
      </BackgroundContainer>
    </Pressable>
  )
}
