import styled from 'styled-components/native'

export const Container = styled.View<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-horizontal: ${({ theme }) => `${theme.spacings.S}px}`};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  height: 20px;
  width: auto;
  margin-vertical: ${({ theme }) => `${theme.spacings.L}px`};
`
