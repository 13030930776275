import React from 'react'
import {
  Container,
  NextContainer,
  PreviousContainer,
  Previous,
  Next,
  ButtonContainer,
  EndButtonContainer,
} from './AppointmentTutorial.style'
import { AppointmentTutorialProps } from './AppointmentTutorial.types'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@ui/Moodwork-UI/molecules/pressables/Icon'
import { OnDevice } from '@ui/OnDevice'
import confidentiality from '../../assets/images/tutorial/confidentiality.png'
import individualSupport from '../../assets/images/tutorial/individual-support.png'
import whenYouWant from '../../assets/images/tutorial/whenever-you-want.png'
import Tutorials from '@screens/Tutorials'
import BullList from '@ui/BullList'

/** TODO: refactor to make it more generic:
 * steps as props
 * handle next/previous/close internally
 * add swiper
 */
export default function AppointmentTutorial({
  onClose,
  currentStep,
  setCurrentStep,
}: AppointmentTutorialProps) {
  const { t } = useTranslation(['appointments'])
  const [displayNext, setDisplayNext] = useState(false)
  const [displayPrevious, setDisplayPrevious] = useState(false)
  const [displayStart, setDisplayStart] = useState(false)

  const handleChooseStep = useCallback(
    (step) => setCurrentStep(step),
    [currentStep]
  )
  const steps = [
    {
      screen: (
        <Tutorials
          setCurrentStep={handleChooseStep}
          image={individualSupport}
          title={t('tuto.individual-support.title')}
          description={t('tuto.individual-support.description')}
        />
      ),
      enableNext: true,
      enablePrevious: false,
      enableStart: false,
    },
    {
      screen: (
        <Tutorials
          setCurrentStep={handleChooseStep}
          image={whenYouWant}
          title={t('tuto.whenever-you-want.title')}
          description={t('tuto.whenever-you-want.description')}
        />
      ),
      enableNext: true,
      enablePrevious: true,
      enableStart: false,
    },
    {
      screen: (
        <Tutorials
          setCurrentStep={handleChooseStep}
          image={confidentiality}
          title={t('tuto.confidentiality.title')}
          description={t('tuto.confidentiality.description')}
        />
      ),
      enableNext: false,
      enablePrevious: true,
      enableStart: true,
    },
  ]

  const handleNextStep = useCallback(
    () => setCurrentStep(currentStep + 1),
    [currentStep]
  )

  const handlePreviousStep = useCallback(() => {
    if (currentStep > 0) setCurrentStep(currentStep - 1)
  }, [currentStep])

  const closeModal = useCallback(() => {
    onClose()
  }, [])

  useEffect(() => {
    const step = steps[currentStep]
    if (step) {
      setDisplayNext(step['enableNext'])
      setDisplayPrevious(step['enablePrevious'])
      setDisplayStart(step['enableStart'])
    }
  }, [currentStep])

  return (
    <>
      <Container>
        {currentStep >= 0 && steps[currentStep]['screen']}
        <ButtonContainer>
          <PreviousContainer
            displayElement={displayPrevious}
            onPress={handlePreviousStep}>
            <Icon
              disabled={!displayPrevious}
              icon='arrow-left'
              onPress={handlePreviousStep}
              trackLabel='clicked_appointment_tuto_prev'
              trackProperties={{ popin_step: currentStep + 1 }}
            />
            <OnDevice size={['large', 'medium']}>
              <Previous>{t('tuto.buttons.back')}</Previous>
            </OnDevice>
          </PreviousContainer>
          <BullList
            setCurrentStep={setCurrentStep}
            activeStep={currentStep}
            numberOfSteps={steps.length}
            analyticsEventName='clicked_appointment_tuto_bullet'
          />
          {displayNext && (
            <NextContainer onPress={handleNextStep}>
              <OnDevice size={['large', 'medium']}>
                <Next>{t('tuto.buttons.next')}</Next>
              </OnDevice>
              <Icon
                icon='arrow-right'
                onPress={handleNextStep}
                trackLabel='clicked_appointment_tuto_next'
                trackProperties={{ popin_step: currentStep + 1 }}
              />
            </NextContainer>
          )}
          {displayStart && (
            <EndButtonContainer
              onPress={closeModal}
              label={t('tuto.buttons.start')}
              trackLabel='clicked_appointment_tuto_end'
              compact
            />
          )}
        </ButtonContainer>
      </Container>
    </>
  )
}
