import React from 'react'
import { Text, FontIcon } from '../../atoms'
import { LinkWithIcon, Button } from '../../molecules'
import { ErrorCardProps } from './interface'
import { Container, TextContainer, ButtonWrapper } from './ErrorCard.styles'
import { useDevice } from '@utils'

export const ErrorCard = ({
  onPressLink,
  onPressButton,
  type,
  errorTitle,
  errorText,
  linkLabel,
  buttonLabel,
}: ErrorCardProps) => {
  return (
    <Container>
      <FontIcon name='error' size={60} />

      <TextContainer device={useDevice()}>
        {
          <Text size='M' weight='bold' alignPosition='center'>
            {errorTitle}
          </Text>
        }
        <Text size='M' alignPosition='center'>
          {errorText}
        </Text>
      </TextContainer>
      {type === 'withLink' && onPressLink && linkLabel ? (
        <LinkWithIcon onPress={onPressLink} label={linkLabel} />
      ) : type === 'withLinkAndButton' &&
        onPressLink &&
        onPressButton &&
        linkLabel &&
        buttonLabel ? (
        <>
          <LinkWithIcon onPress={onPressLink} label={linkLabel} />
          <ButtonWrapper>
            <Button onPress={onPressButton} label={buttonLabel} />
          </ButtonWrapper>
        </>
      ) : (
        onPressButton &&
        buttonLabel && <Button onPress={onPressButton} label={buttonLabel} />
      )}
    </Container>
  )
}
