import * as React from 'react'
import { PsychologistMediumProps } from './PsychologistMedium.types'
import { Container, Items } from './PsychologistMedium.style'
import { useTranslation } from 'react-i18next'
import { CallConfTag } from '@moodwork-ui'

export default function PsychologistMedium({
  event_kind,
}: PsychologistMediumProps) {
  const { t } = useTranslation(['appointments'])
  return (
    <Container>
      <Items>
        {event_kind.length > 0 &&
          event_kind.map((key) => (
            <CallConfTag
              key={key}
              type={key}
              label={t(`psychologists.appointmentType.${key}`)}
            />
          ))}
      </Items>
    </Container>
  )
}
