import * as React from 'react'
import { Navigation } from '../../ui'

export interface HeaderProps {
  isTop: boolean
}

export default function Header({ isTop }: HeaderProps) {
  return <Navigation expand={isTop} />
}
