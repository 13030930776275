import * as React from 'react'
import { OnDevice } from '../OnDevice'
import {
  Container,
  ImageContainer,
  BigImage,
  AppointmentCardContainer,
  ContentContainer,
} from './AppointmentType.style'
import phone from '../../assets/phone.png'
import video from '../../assets/videoconference.png'
import psycho from '../../assets/appointmentType.png'
import { AppointmentTypeProps } from './AppointmentType.types'
import { useTranslation } from 'react-i18next'
import { IS_NATIVE, useDevice } from '../utils'
import { ButtonWithIcon } from '../Moodwork-UI/molecules/pressables/ButtonWithIcon'
import { Title } from '@moodwork-ui'
import AppointmentReminder from '@ui/AppointmentReminder'
import { PageView } from '../PageView'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'
import { useNavigation } from '@react-navigation/native'

export default function AppointmentType({
  onPhonePress,
  onVisioPress,
  eventDate,
  psyName,
  psyAvatar,
}: AppointmentTypeProps) {
  const { t } = useTranslation(['appointments', 'dates'])
  const device = useDevice()
  const navigation = useNavigation()

  return (
    <PageView>
      <Container device={device}>
        {IS_NATIVE && <BackButton navigation={navigation} />}
        <AppointmentReminder
          date={eventDate}
          name={psyName}
          pictureUrl={psyAvatar}
        />
        <Title size='XXL' weight='bold'>
          {t('appointments:psychologists.appointmentType.title')}
        </Title>
        <ContentContainer>
          <AppointmentCardContainer device={device}>
            <ButtonWithIcon
              device={device}
              label={t('appointments:psychologists.appointmentType.byPhone')}
              onPress={onPhonePress}
              image={phone}
              trackLabel='clicked_appointment_by_phone'
            />
            <ButtonWithIcon
              device={device}
              label={t('appointments:psychologists.appointmentType.byVisio')}
              onPress={onVisioPress}
              image={video}
              trackLabel='clicked_appointment_by_visio'
            />
          </AppointmentCardContainer>
          <OnDevice size={['medium', 'large']}>
            <ImageContainer>
              <BigImage resizeMode='contain' source={psycho} />
            </ImageContainer>
          </OnDevice>
        </ContentContainer>
      </Container>
    </PageView>
  )
}
