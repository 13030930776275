import { IS_NATIVE } from '@ui/utils'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Linking } from 'react-native'
import VersionCheck from 'react-native-version-check-expo'
import remoteConfig from '@react-native-firebase/remote-config'

export default function UpdateProvider({
  children,
}: {
  children: JSX.Element
}) {
  const [needUpdate, setNeedUpdate] = React.useState(true)

  const { t } = useTranslation(['errors'])
  useEffect(() => {
    if (IS_NATIVE) {
      remoteConfig()
        .setDefaults({ needUpdate: {} })
        .then(() => {
          remoteConfig().fetchAndActivate()
          VersionCheck.needUpdate({
            provider: () =>
              JSON.parse(remoteConfig().getValue('needUpdate').asString())
                .priorVersion,
          })
            .then((res) => {
              if (res.isNeeded) {
                VersionCheck.getStoreUrl({ appID: 'id1446714282' })
                  .then((storeUrl) => {
                    const forceUpdate = JSON.parse(
                      remoteConfig().getValue('needUpdate').asString()
                    ).force
                    let alertButtons = [
                      {
                        text: t('update.action'),
                        onPress: () => {
                          Linking.openURL(
                            res.storeUrl && res.storeUrl !== ''
                              ? res.store
                              : storeUrl
                          )
                          setNeedUpdate(false)
                        },
                        style: 'default',
                      },
                    ]
                    if (!forceUpdate) {
                      alertButtons.push({
                        text: t('update.dismiss'),
                        onPress: () => {
                          setNeedUpdate(false)
                        },
                        style: 'default',
                      })
                    }
                    Alert.alert(
                      t(forceUpdate ? 'update.title' : 'update.optional-title'),
                      t(
                        forceUpdate
                          ? 'update.message'
                          : 'update.optional-message'
                      ),
                      alertButtons
                    )
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              } else {
                setNeedUpdate(false)
              }
            })
            .catch((error) => {
              console.log(error)
              setNeedUpdate(false)
            })
        })
    } else {
      setNeedUpdate(false)
    }
  }, [])

  return <>{!needUpdate && children}</>
}
