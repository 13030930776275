import styled, { css } from 'styled-components/native'

export const Container = styled.Pressable<{
  justifyCenter: boolean
  paddingSize?: string | null
}>`
  display: flex;
  flex-direction: row;
  ${({ justifyCenter }) =>
    justifyCenter === true &&
    css`
      justify-content: center;
    `};
  align-items: center;
  padding: ${({ theme, padding }) => padding || `${theme.spacings.XS}px`};
`
export const IconWrapper = styled.View<{
  spacing: string
  iconMargin: string | null
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme, spacing }) =>
    spacing ? `${theme.spacings[spacing]}px` : `${theme.spacings.none}px`};
  ${({ iconMargin }) =>
    iconMargin &&
    css`
      margin: ${iconMargin};
    `};
  width: 40px;
`
