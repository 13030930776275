import styled from 'styled-components/native'

export const WellbeingTrackMark = styled.View<{
  index: number
  value: number
  valueLength: number
}>`
  height: ${({ index }) => 23 + Math.abs(index - 5) * 6}px;
  width: 20px;
  background-color: ${({ index, value, valueLength }) => {
    if (!value) {
      return '#EBF0F9'
    }
    if (value < 0) {
      if (valueLength / 2 + value > index) {
        return '#EBF0F9'
      }
      return index > valueLength / 2 ? '#EBF0F9' : 'transparent'
    }
    if (valueLength / 2 + value < index) {
      return '#EBF0F9'
    }
    return index < valueLength / 2 ? '#EBF0F9' : 'transparent'
  }};
  border-radius: 10px;
`

export const BurnoutTrackMark = styled.View<{
  index: number
  value: number
}>`
  height: ${({ index }) => 23 + index * 6}px;
  width: 20px;
  background-color: ${({ index, value }) => {
    if (!value) {
      return '#EBF0F9'
    }
    if (index < value) {
      return 'transparent'
    }
    return '#EBF0F9'
  }};
  border-radius: 10px;
`
