
import { ResourceContentCSS, ResourceMarginBottomContentCSS } from '@ui/Moodwork-UI/organisms/Resource/ResourceBlock/ResourceCommon.styles'
import { Animated, View } from 'react-native'
import styled, { css } from 'styled-components/native'
import { Text } from '../Text'

export const Container = styled(Animated.View)<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  ${ResourceMarginBottomContentCSS}
`

export const ContentContainer = styled(Animated.View)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const TitleContainer = styled(View)`
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.separatorGrey};
  padding-top: 13px;
  flex-direction: row;
  display: flex;
  ${ResourceContentCSS};
`

export const Title = styled(Text)`
  align-self: flex-start;
  width: 80%
`
export const PressableContainer = styled(View)`
  width: 20%;
  align-items: flex-end;
`
