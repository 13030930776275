import { PrefixedContent, SectionTitle } from '@ui/Moodwork-UI'
import VideoComponent from '@ui/Moodwork-UI/atoms/VideoComponent'
import { FlexCenter, FlexColumn } from '@ui/Moodwork-UI/templates/Flex'
import styled from 'styled-components/native'
import {
  ResourceContentCSS,
  ResourceMarginBottomContentCSS,
} from './ResourceCommon.styles'

export const Heading = styled.View`
  margin-bottom: 8px;
  margin-left: ${({ theme }) => theme.spacings.XS};
`

export const ImageContainer = styled(FlexCenter)`
  margin-vertical: ${({ theme }) =>
    theme.device === 'small' ? '24px' : '32px'}
  width: 100%;
  height: ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return '270px;'
      case 'medium':
        return '310px'
      case 'large':
        return '415px'
      default:
        return '270px'
    }
  }};
`

export const VideoContainer = styled.View`
  width: 100%;
  padding-left: ${({ theme }) =>
    theme.device !== 'large'
      ? `${theme.spacings.none}px;`
      : `${theme.spacings.XXXL}px;`}};
  margin-vertical: ${({ theme }) =>
    theme.device === 'small'
      ? `${theme.spacings.XL}px;`
      : `${theme.spacings.XXL}px;`};
`

export const Video = styled(VideoComponent)`
  min-width: 100%;
  max-height: 100%;
`

export const Image = styled.Image`
  height: 100%;
  width: 100%;
`

export const PrefixedContainer = styled(PrefixedContent)`
  margin-bottom: ${({ theme }) => (theme.device === 'small' ? '24px' : '40px')};
`

export const ContentContainer = styled(FlexColumn)`
  width: 100%;
`

export const HtmlContainer = styled(FlexColumn)<{
  background?: string
  bottomMargin?: boolean
}>`
  ${ResourceContentCSS};
  ${({ bottomMargin }) => bottomMargin && ResourceMarginBottomContentCSS}
  ${({ theme, background }) => {
    switch (background) {
      case 'transparent':
        return `background-color: ${theme.colors.white};`
      case 'dark':
        return `background-color: ${theme.colors.secondary};
        padding-horizontal: ${theme.device === 'small' ? '16px' : '24px'};
        padding-vertical: 16px;`
      case 'light':
        return `background-color: ${theme.colors.secondaryBackground};
        padding-horizontal: ${theme.device === 'small' ? '16px' : '24px'};
        padding-vertical: 16px;`
      default:
        return `background-color: ${theme.colors.white};`
    }
  }};
`

export const InTitle = styled(SectionTitle)`
`
