import * as React from 'react'
import { TimeSlot } from '../TimeSlot'
import { TimeSlotDayProps } from './TimeSlotDay.types'
import { Container, DateTimeText, Header } from './TimeSlotDay.style'

export default function TimeSlotDay({
  day,
  date,
  slots,
  onPressOne,
  limited,
  chunkHours,
}: TimeSlotDayProps) {
  // TODO : replace with an helper returned from parent that handle all language full string date format
  return (
    <Container>
      <Header>
        <DateTimeText
          alignPosition='center'
          weight='bold'
          marginSize='0px 0px 2px 0px'>
          {day}
        </DateTimeText>
        <DateTimeText alignPosition='center'>{date}</DateTimeText>
      </Header>
      {(slots || [])
        .sort((a, b) => a.date.ts - b.date.ts)
        .map((slot, index) => (
          <TimeSlot
            key={index}
            value={`${slot.date.hour}:${slot.date.minute}`.replace(
              /:0$/,
              ':00'
            )}
            onPress={() => onPressOne({ item: slot, index })}
          />
        ))
        .slice(0, limited ? chunkHours : (slots || []).length)}
    </Container>
  )
}
