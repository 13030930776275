import styled from 'styled-components/native'
import { PAGE_HORIZONTAL_MARGINS } from '@core/constants'

export const Container = styled.View`
  align-items: flex-start;
  flex-direction: row;
  margin-left: ${PAGE_HORIZONTAL_MARGINS}px;
  margin-right: ${PAGE_HORIZONTAL_MARGINS}px;
  margin-bottom: 32px;
`

export const ImagePlaceholder = styled.View`
  background-color: #e0e0e0;
  width: 54px;
  height: 54px;
  margin-right: 16px;
`

export const Image = styled.Image`
  width: 54px;
  height: 54px;
  margin-right: 16px;
`
