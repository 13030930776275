import React from 'react'
import { useRoute } from '@react-navigation/core'
import { Burnout, ErrorPage, WellBeing } from '../../ui/Moodwork-UI'
import { TestResultNavigation } from '../lib/types'
import { useResults } from '../services/results'
import ResultLoadingScreen from '@ui/ResultLoadingScreen'
import { ErrorType } from '@ui/utils'

const TestResult = () => {
  const { id } = useRoute<TestResultNavigation>().params

  const { data, isLoading, isError } = useResults(id, false)

  return isLoading ? (
    <ResultLoadingScreen />
  ) : isError ? (
    <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  ) : data?.key === 'burnout_test' ? (
    <Burnout testId={id} result={data?.result} />
  ) : (
    <WellBeing testId={id} result={data.result} />
  )
}

export default TestResult
