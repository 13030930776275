import { View } from 'react-native'
import * as React from 'react'
import { FeaturedContent } from '../FeaturedContent'
import { Spacer } from '../Spacer'
import { TitleSurTitle } from '../TitleSurTitle'
import { Container, List } from './FeaturedContentList.style'
import { FeaturedContentListProps } from './FeaturedContentList.types'

export default function FeaturedContentList({
  contents,
}: FeaturedContentListProps) {
  return (
    <Container>
      <TitleSurTitle>Featured Content</TitleSurTitle>
      <Spacer size={16} />
      <View style={{ flexDirection: 'row' }}>
        <List>
          {contents.map((content, index) => (
            <FeaturedContent {...content} key={index} />
          ))}
        </List>
      </View>
    </Container>
  )
}
