import { FontIcon, Text } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import React from 'react'
import { TouchableOpacity, ViewStyle } from 'react-native'
import information from '../../../../assets/information.png'
import { Arrow, Container, Image, QuestionText } from './index.styles'
import { AssessementQuestionProps } from './index.types'
import Popover, { PopoverPlacement } from 'react-native-popover-view'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@core/lib/analytics'

export default function AssessmentQuestion({
  text,
  isRight,
  tooltipInfo,
  isDisabled = false,
  style,
}: AssessementQuestionProps & { style?: ViewStyle }) {
  const theme = useTheme()
  const { t } = useTranslation(['assessment'])
  return (
    <Container style={style} isRight={isRight} tooltip={!!tooltipInfo}>
      {tooltipInfo && (
        <Popover
          popoverStyle={{
            width: 280,
            padding: 16,
          }}
          placement={isRight ? PopoverPlacement.LEFT : PopoverPlacement.RIGHT}
          onOpenComplete={() =>
            trackEvent('clicked_wellbeing_assessment_tooltip', {
              test_item_label: text,
            })
          }
          from={
            <TouchableOpacity disabled={isDisabled}>
              <Image source={information} isRight={isRight} />
            </TouchableOpacity>
          }>
          <Text>
            <FontIcon name='lightbulb' /> {t('question-tooltip-title')}
          </Text>
          <Text>{tooltipInfo}</Text>
        </Popover>
      )}
      <QuestionText>{text}</QuestionText>
      {theme.device === 'large' && <Arrow isRight={isRight} />}
    </Container>
  )
}
