import React from 'react'
import { ButtonContainer, Container } from './index.styles'
import { Text } from '@ui/Moodwork-UI/atoms'
import { useTranslation } from 'react-i18next'
import { useRoute } from '@react-navigation/native'
import { Button } from '../../pressables/Button'
import { SearchResusltProps } from './index.types'
import { ResourceGrid } from '@ui/Moodwork-UI/organisms'
import { SearchNavigation } from '@core/lib/types'
import { SCREEN_LIBRARY, SCREEN_SEARCH } from '@core/constants'

export default function SearchResults({
  results,
  totalCount,
  loadMore,
  isLoading,
  displayMore,
  eventComponentName,
  activeFilters,
}: SearchResusltProps) {
  const { t } = useTranslation('search')
  const route = useRoute<SearchNavigation>()
  const params = route.params

  let eventProperties = {
    active_filters: activeFilters,
  }

  if (route.name === SCREEN_SEARCH) {
    eventProperties = { ...eventProperties, search_query: params?.q }
  }

  return (
    <Container>
      <Text>{t('content', { count: totalCount })}</Text>
      <ResourceGrid
        itemWidth='270px'
        listHeight='148px'
        resources={results || []}
        trackLabel={`clicked_${eventComponentName}_item`}
        collectionLayout={{
          customImageHeight: '50%',
          removeImageOverlay: true,
        }}
        trackParentProps={eventProperties}
      />
      {displayMore && (
        <ButtonContainer>
          <Button
            loading={isLoading}
            trackLabel={`clicked_${eventComponentName}_show_more`}
            trackProperties={{
              search_query: params?.q,
              nb_search_results: totalCount,
            }}
            label={t('showMore')}
            onPress={loadMore}
          />
        </ButtonContainer>
      )}
    </Container>
  )
}
