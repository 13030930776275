import styled, { css } from 'styled-components/native'
import { Text } from '@moodwork-ui'
import { DeviceSizeType } from '@utils'

export const Container = styled.View<{ device: DeviceSizeType }>`
  display: flex;
  flex-direction: ${(props) => (props.device === 'large' ? 'row' : 'column')};
  width: 100%;
  height: 100%;
  margin-bottom: -64px;
`

export const ImageContainer = styled.View<{
  device: DeviceSizeType
  hasBackground: boolean
}>`
  flex: 1;
  align-items: center;
  height: auto;
  background-color: ${({ theme, hasBackground }) =>
    hasBackground ? theme.colors.secondaryBackground : theme.colors.white};
  flex-basis: 45%;
  margin: -10px;
  padding-left: ${(props) => (props.device === 'large' ? '35px' : '0px')};
  ${({ device }) =>
    device === 'medium' &&
    css`
    padding-top: 87px;
  `}
  ${({ device }) =>
    device === 'small' &&
    css`
    padding: 53px;
    padding-bottom: 0px;
  `}
`

export const Description = styled.ScrollView<{ device: DeviceSizeType }>`
  flex: 1;
  flex-basis: 55%;
  padding-left: 49px; 
  padding-right: 49px;
  padding-bottom: ${(props) => (props.device === 'large' ? '0px;' : '49px')};
  margin-top: ${(props) => (props.device === 'small' ? '40px;' : '0px')};
  ${({ device }) =>
    device === 'large' &&
    css`
    overflow-y: auto;
    margin-bottom: 50px;
    padding-top: 50px;
 `}
  ${({ device }) =>
    device === 'medium' &&
    css`
    padding-right: 49px;
    padding-bottom: 49px;
    margin-top: 0px;
  `}
  ${({ device }) =>
    device === 'small' &&
    css`
    padding: 16px;
    padding-top: 30px;
    margin-top: 0px;
  `}
`

export const Image = styled.Image<{ device: DeviceSizeType }>`
  height: 100%;
  width: 100%;
  ${({ device }) =>
    device === 'large' &&
    css`
    right: -23px;
  `}
  ${({ device }) =>
    device === 'medium' &&
    css`
    bottom: -10px;
  `}
`
