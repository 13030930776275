import { SCREEN_SEARCH } from '@core/constants'
import { trackEvent } from '@core/lib/analytics'
import { SearchNavigation } from '@core/lib/types'
import { useRoute } from '@react-navigation/native'
import { Text } from '@ui/Moodwork-UI/atoms'
import React, { useCallback } from 'react'
import { Button } from './index.styles'
import { FilterButtonProps } from './index.types'

export default function FilterButton({
  title,
  element,
  onThemePress,
  isActive,
  numberOfSearch,
  eventName,
  propertyName,
}: FilterButtonProps) {
  const route = useRoute<SearchNavigation>()
  const params = route.params
  const onPress = useCallback(() => {
    const finalEventName = (isActive ? 'turned_off_' : 'turned_on_') + eventName
    let eventProperties = {
      search_query: params?.q,
    }
    eventProperties = { ...eventProperties, [propertyName]: title }
    if (!isActive && route.name === SCREEN_SEARCH) {
      eventProperties = {
        ...eventProperties,
        nb_search_results: numberOfSearch,
      }
    }

    trackEvent(finalEventName, eventProperties).then(() =>
      onThemePress(element)
    )
  }, [isActive])

  return (
    <Button isActive={isActive} onPress={onPress}>
      <Text
        color={isActive ? 'white' : 'black'}
        alignPosition='center'
        size='XS'>
        {title}
      </Text>
    </Button>
  )
}
