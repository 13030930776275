import styled, { css } from 'styled-components/native'
import { Text } from '@moodwork-ui'
import { IS_BROWSER } from '@utils'

export const Container = styled.View`
  padding-horizontal: ${({ theme }) =>
    theme.device !== 'small'
      ? `${theme.spacings.PAGE}px`
      : `${theme.spacings.L}px`};
  padding-vertical: ${({ theme }) => `32px`};
  width: 100%;
  height: 100%;
`

export const ContactTitle = styled(Text)`
  margin-bottom:  ${({ theme }) => `${theme.spacings.L}px`};
`

export const IntroContainer = styled.View`
  margin-bottom:  ${({ theme }) => `${theme.spacings.XXL}px`};
`

export const FinalContainer = styled.View`
  margin-top:  ${({ theme }) => `${theme.spacings.L}px`};
`

export const ContactContainer = styled.View`
  flex-grow: 0
`

export const RefererSeparator = styled.View`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black};
  margin-bottom: 24px;
`
