import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { AVPlaybackStatus, Video, VideoProps, VideoState } from 'expo-av'
import React, { useCallback, useRef, useState } from 'react'
import { LayoutChangeEvent, NativeEventEmitter, View } from 'react-native'
import { trackEvent } from '@core/lib/analytics'

export default function VideoComponent({
  trackableParams,
  ...props
}: { trackableParams: {} } & VideoProps) {
  const videoRef = useRef()

  const [isPlaying, setIsPlaying] = useState(false)
  const [maxHeight, setMaxHeight] = useState()

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (videoRef.current) {
          videoRef.current.getStatusAsync().then((status) => {
            let totalVideoDuration = Math.round(status.durationMillis / 1000)
            let currentDurationPercentage = Math.floor(
              100 * (status.positionMillis / status.durationMillis)
            )
            let currentDuration = Math.round(status.positionMillis / 1000)
            videoRef.current.stopAsync()
            trackEvent('leave_page_video_state', {
              ...trackableParams,
              total_video_duration_sec: totalVideoDuration,
              current_duration_percentage: currentDurationPercentage,
              current_duration_sec: currentDuration,
            })
          })
        }
      }
    }, [videoRef])
  )

  const onStatusChange = (status: AVPlaybackStatus) => {
    if (
      (status.isPlaying && status.isPlaying !== isPlaying) ||
      (!status.isPlaying && status.isPlaying !== isPlaying)
    ) {
      let totalVideoDuration = Math.round(status.durationMillis / 1000)
      let currentDurationPercentage = Math.floor(
        100 * (status.positionMillis / status.durationMillis)
      )
      let currentDuration = Math.round(status.positionMillis / 1000)
      if (status.isPlaying) {
        trackEvent('clicked_resource_video_play', {
          ...trackableParams,
          total_video_duration_sec: totalVideoDuration,
          current_duration_percentage: currentDurationPercentage,
          current_duration_sec: currentDuration,
        }).then()
        setIsPlaying(true)
      } else {
        trackEvent('clicked_resource_video_pause', {
          ...trackableParams,
          total_video_duration_sec: totalVideoDuration,
          current_duration_percentage: currentDurationPercentage,
          current_duration_sec: currentDuration,
        }).then()
        setIsPlaying(false)
      }
    }
  }

  // simulate aspect-ratio 16/9 du to non support by safari < 15.0
  const onLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setMaxHeight(nativeEvent.layout.width / 1.78)
  }, [])

  return (
    <View onLayout={onLayout} style={{ maxHeight: maxHeight }}>
      <Video
        {...props}
        videoStyle={{
          position: 'relative',
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
        }}
        onPlaybackStatusUpdate={onStatusChange}
        ref={videoRef}
      />
    </View>
  )
}
