import React, { useCallback, useEffect, useState } from 'react'
import { useUserInfo } from '@services/viewer'
import { IntercomWrapper } from '../../../../../core/Wrapper/IntercomWrapper'
import { WellbeingStatus } from './index.types'
import { useNavigation } from '@react-navigation/native'
import { useNavigationLink } from '../../../../../core/lib/navigation'
import { HOST } from '../../../../../core/constants'
import WellbeingSummary from '@ui/Moodwork-UI/molecules/Dashboard/WellbeingSummary'
import WellbeingSummaryLoading from '@ui/Moodwork-UI/molecules/Dashboard/WellbeingSummary/WellbeingSummary.loading'
import { useSyncReady } from '@core/lib/ready'
import { trackEvent } from '@core/lib/analytics'
import moment from 'moment-timezone'
import { Hotline } from '@core/types/viewer'

export const getWellbeingProps = (
  status: WellbeingStatus,
  careAccess: boolean,
  hotline: Hotline
) => {
  const showImage = status === 'take_wellbeing' || status === 'retake_wellbeing'
  const title =
    status !== 'take_wellbeing'
      ? 'dashboard.wellbeing.title.last'
      : 'dashboard.wellbeing.title.take_wellbeing'
  const text =
    status === 'take_wellbeing' ||
    status === 'retake_wellbeing' ||
    status === 'take_burnout'
      ? `dashboard.wellbeing.text.${status}`
      : !careAccess && !hotline
      ? null
      : !careAccess && hotline
      ? status !== 'manage_mild_risk' && status !== 'manage_low_risk'
        ? `dashboard.wellbeing.text.call_hotline`
        : null
      : status !== 'manage_mild_risk' && status != 'manage_low_risk'
      ? `dashboard.wellbeing.text.${status}`
      : null
  // main button
  let mainAction
  if (status === 'take_wellbeing' || status === 'retake_wellbeing') {
    mainAction = {
      type: 'url',
      text: `dashboard.wellbeing.action.${status}`,
      trackableLabel: 'clicked_db_take_assessment',
      trackableProperties: { is_first_assessment: status === 'take_wellbeing' },
      url: `/checkups/wellbeing_assessment`,
    }
  } else if (
    (status === 'take_assistance' || status === 'manage_high_risk') &&
    careAccess
  ) {
    mainAction = {
      type: 'chat',
      trackableLabel: 'clicked_db_chat_now',
      text: `dashboard.wellbeing.action.${status}`,
    }
  } else if (status === 'take_burnout') {
    mainAction = {
      type: 'url',
      text: `dashboard.wellbeing.action.${status}`,
      trackableLabel: 'clicked_db_take_burnout_test',
      url: `/checkups/further_assessment`,
    }
  } else if (
    !careAccess &&
    hotline &&
    hotline?.link &&
    (status === 'take_assistance' || status === 'manage_high_risk')
  ) {
    mainAction = {
      type: 'url',
      text: `dashboard.wellbeing.action.take_assistance`,
      trackableLabel: 'clicked_db_hotline',
      url: hotline.link,
    }
  }

  // follow
  let followAction
  if (status !== 'take_wellbeing') {
    followAction = {
      type: 'url',
      text: 'dashboard.wellbeing.action.follow',
      trackableLabel: 'clicked_db_track_your_progress',
      url: `/user/wellbeing_tracker`,
    }
  }

  return {
    showImage,
    title,
    text,
    mainAction,
    followAction,
  }
}

export default function UserWellbeing() {
  const { data: userInfo } = useUserInfo()
  const [alreadySent, setAlreadySent] = useState(false)
  const navigation = useNavigation()
  const linkTo = useNavigationLink()
  const onPress = useCallback(
    (action) => {
      switch (action.type) {
        case 'url':
          if (action.trackableLabel) {
            trackEvent(action.trackableLabel, action.trackableProperties).then(
              () => {
                linkTo(action.url)
              }
            )
          } else {
            linkTo(action.url)
          }
          break
        case 'chat':
          IntercomWrapper.displayMessageComposer()
          break
      }
    },
    [navigation]
  )

  const isReady = useSyncReady('dashboard', 'userwellbeing', !!userInfo)

  useEffect(() => {
    if (isReady && !alreadySent) {
      setAlreadySent(true)
      trackEvent('viewed_db_assessment_status', {
        test_date: userInfo?.test_report?.date,
        test_is_old: moment(userInfo?.test_report?.date).isBefore(
          moment().subtract(3, 'months')
        ),
        assessment_status: userInfo?.test_report?.status || 'take_wellbeing',
        tracker_is_visible: userInfo?.test_report?.status !== 'take_wellbeing',
      })
    }
  }, [userInfo, isReady])

  if (!isReady) {
    return <WellbeingSummaryLoading highlight={false} />
  }

  const props = getWellbeingProps(
    userInfo?.test_report?.status || 'take_wellbeing',
    userInfo?.care_access,
    userInfo?.hotline
  )
  return (
    <WellbeingSummary
      {...props}
      onPress={onPress}
      date={userInfo?.test_report?.date}
    />
  )
}
