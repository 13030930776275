import React from 'react'
import { FontIcon, Text, Title } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Button } from '@ui/Moodwork-UI/molecules'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_REGISTRATION } from '@core/constants'

export default function Confirmation({ emailType }: { emailType: string }) {
  const { t } = useTranslation('sign')
  const theme = useTheme()
  const navigation = useNavigation()

  return (
    <View style={{ marginHorizontal: 16, flex: 1, alignItems: 'center' }}>
      <View style={{ marginTop: 32, flexDirection: 'row' }}>
        <FontIcon
          name='check-circle'
          size={19}
          color={theme.colors.lightGreen}
          style={{ marginRight: 8 }}
        />
        <Title marginSize='0'>{t('up.email.confirm-title')}</Title>
      </View>
      {emailType === 'personal_email' && (
        <Text marginSize='24px 0' alignPosition={'center'}>
          {t(`up.email.confirm-subtitle`)}
        </Text>
      )}
      <Button
        onPress={() => {
          navigation.push(SCREEN_REGISTRATION)
        }}
        label={t('up.email.button')}
      />
    </View>
  )
}
