import { IS_BROWSER } from '@ui/utils/device'
import styled, { css } from 'styled-components/native'

export const RoundedImageStyle = styled.Image<{ size: string }>`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  ${IS_BROWSER && css`transition: all 0.3s ease;`};
`
