import styled from 'styled-components/native'
import { DeviceSizeType } from '@utils'

export const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextContainer = styled.View<{ device: DeviceSizeType }>`
  margin-top: ${({ theme }) => `${theme.spacings.L}px`};
  margin-bottom: ${({ theme }) => `${theme.spacings.L}px`};
  margin-horizontal: ${(props) => (props.device === 'small' ? '0px' : '80px')};
  padding-horizontal: 16px;
`

export const ButtonWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`
