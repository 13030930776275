import { HTMLView, SubTitle } from '@ui/Moodwork-UI/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Image } from './index.style'
import { Button } from '../../pressables'
import { WelcomeModalContentProps } from './index.types'

export function WelcomeModalContent({
  logoUrl,
  userName,
  companyText,
  handleClose,
}: WelcomeModalContentProps) {
  const { t } = useTranslation(['dashboard'])

  return (
    <Container>
      <Image resizeMode={'contain'} source={{ uri: logoUrl }} />
      <SubTitle>
        {t('dashboard.welcomeModal.greeting', { name: userName })}
      </SubTitle>
      {
        // <Image resizeMode={'contain'} source={employee} /> TODO : Activate with new image and scroll view
      }
      <HTMLView source={companyText || ''} />
      <Button
        label={t('dashboard.welcomeModal.discover')}
        onPress={() => {
          handleClose()
        }}
      />
    </Container>
  )
}
