import styled, { css } from 'styled-components/native'
import { Text } from '@moodwork-ui'
import { IS_BROWSER } from '../utils/device'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 10px 20px;
  ${IS_BROWSER ? css`margin-bottom: 40px;` : css`margin-bottom: 16px;`}
  width: auto;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 0.5px solid #c4c4c480;
`

export const Date = styled(Text)`
  margin-right: 10px;
`

export const ContainerWrapped = styled.View`
  display: flex;
  flex-direction: row;
  margin-vertical: 5;
`

export const Picture = styled.Image`
  height: 42px;
  width: 42px;
  margin-right: 10px;
`

export const ContainerRight = styled.View`
  display: flex;
  flex-direction: column;
`
