import {
  ACCESS_TOKEN_KEY,
  HOST,
  RECAPTCHA_SITE_KEY,
  REFRESH_TOKEN_KEY,
} from '@core/constants'
import { useCheckCompanyCode, useSignUp } from '@core/services/viewer'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'
import { BrandedContainer } from '@ui/Moodwork-UI'
import CompanyCode from '@ui/Moodwork-UI/organisms/SignUp/CompanyCode'
import Form from '@ui/Moodwork-UI/organisms/SignUp/Form'
import RGPD from '@ui/Moodwork-UI/organisms/SignUp/RGPD'
import { IS_BROWSER, IS_NATIVE } from '@ui/utils'
import { first } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Container, Content } from './index.styles'
import { getStoredItem } from '@core/lib/storage'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import * as WebBrowser from 'expo-web-browser'
import { InteractionManager } from 'react-native'

export default function SignUp() {
  const [page, setPage] = useState(0)
  const { i18n } = useTranslation()
  const [companyCode, setCompanyCode] = useState()
  const [webviewUrl, setWebviewUrl] = useState()
  const navigation = useNavigation()
  IntercomWrapper.logout()
  const route = useRoute()
  const params = route.params

  const { mutate, data, error } = useCheckCompanyCode(
    params?.locale ?? i18n.language
  )
  const {
    mutate: signUpMutate,
    data: signUpData,
    error: signUpError,
  } = useSignUp(params?.locale ?? i18n.language)

  useEffect(() => {
    if (IS_NATIVE) {
      getStoredItem(ACCESS_TOKEN_KEY).then((accessToken) => {
        getStoredItem(REFRESH_TOKEN_KEY).then((refreshToken) => {
          accessToken &&
            refreshToken &&
            InteractionManager.runAfterInteractions(() =>
              navigation.dispatch(StackActions.replace('Home'))
            )
        })
      })
    }
  }, [])
  useEffect(() => {
    if (
      IS_BROWSER &&
      getStoredItem(ACCESS_TOKEN_KEY) &&
      getStoredItem(REFRESH_TOKEN_KEY)
    ) {
      InteractionManager.runAfterInteractions(() =>
        navigation.dispatch(StackActions.replace('Home'))
      )
    }
  }, [])

  useEffect(() => {
    if (params?.locale) {
      i18n.changeLanguage(params.locale)
    }
    if (params?.code) {
      setCompanyCode(params?.code)
      mutate(params?.code)
    }
  }, [])

  useEffect(() => {
    if (webviewUrl) {
      WebBrowser.openAuthSessionAsync(webviewUrl)
    }
  }, [webviewUrl])

  const onValidate = () => {
    setPage(page + 1)
  }

  const onValidateSSO = () => {
    if (IS_BROWSER) {
      window.location.href = HOST + data?.data.sso_informations[0].sso_login_url
    } else {
      setWebviewUrl(HOST + data?.data?.sso_informations[0].sso_mobile_login_url)
    }
  }

  const signUp = (
    firstName: string,
    lastName: string,
    email: string,
    personnalEmail: string,
    password: string,
    subToNewsletter: boolean
  ) => {
    if (IS_BROWSER) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
          .then((token) => {
            signUpMutate({
              firstName: firstName,
              email: email,
              lastName: lastName,
              password: password,
              personnalEmail: personnalEmail,
              companyCode: companyCode,
              subToNewsletter: subToNewsletter,
              captchaToken: token,
            })
          })
      })
    } else {
      signUpMutate({
        firstName: firstName,
        lastName: lastName,
        email: email,
        personnalEmail: personnalEmail,
        password: password,
        companyCode: companyCode,
        subToNewsletter: subToNewsletter,
      })
    }
  }

  const screens = [
    <CompanyCode
      onValidate={onValidate}
      mutate={mutate}
      setCompanyCode={setCompanyCode}
      data={data}
      error={error}
    />,
    <RGPD
      onValidate={onValidate}
      onValidateSSO={onValidateSSO}
      displayRGPD={data?.data.rgpd_display_mention_information}
      rgpdMention={data?.data.rgpd_mention_information}
      allow_email_auth={data?.data.allow_email_auth}
      ssoInformation={first(data?.data?.sso_informations)}
    />,
    <Form
      onValidate={signUp}
      data={signUpData}
      error={signUpError}
      isSaleCode={data?.data.sale_code}
      lastnameRequire={data?.data.last_name_required}
      passwordRequire={data?.data.password_enabled}
      passwordMessage={data?.data.password_enabled_message}
      passwordHint={data?.data.hint}
      passwordLevel={data?.data.level}
    />,
  ]

  return (
    <Container>
      <BrandedContainer fullscreen={IS_NATIVE}>
        <Content>{screens[page]}</Content>
      </BrandedContainer>
    </Container>
  )
}
