import * as React from 'react'
import { OnDevice } from '../OnDevice'
import { useTranslation } from 'react-i18next'
import { AppointmentConfirmationProps } from './AppointmentConfirmation.types'
import {
  BigImage,
  ImageContainer,
  PageContainer,
  EventDate,
  Container,
  Name,
  ColContainer,
  RowContainer,
  PsychologistImage,
  Card,
  ButtonContainer,
} from './AppointmentConfirmation.style'
import moment from 'moment'
import emailLogo from '../../assets/event_confirmation.png'
import { useTheme } from '../utils/styled'
import { useEffect, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_HOME } from '@core/constants'
import { BigParagraph, Button, Title, Text, Paragraph } from '@moodwork-ui'
import { useDevice } from '@ui/utils'
import 'moment/min/locales'

export default function AppointmentConfirmation({
  eventDate,
  eventType,
  avatarUrl,
  psychologistFullname,
}: AppointmentConfirmationProps) {
  const { i18n, t } = useTranslation(['appointments'])
  const navigation = useNavigation()
  const device = useDevice()
  const theme = useTheme()

  moment.locale(i18n.language)

  const goHome = useCallback(() => navigation.push(SCREEN_HOME), [])

  return (
    <PageContainer device={device}>
      <Container>
        <ColContainer>
          <Title marginSize={`0px 0px ${theme.spacings.L}px 0px`}>
            {t('psychologists.confirmation.title')}
          </Title>
          <BigParagraph marginSize={'0px 0px 32px 0px'}>
            {t('psychologists.confirmation.email')}
          </BigParagraph>
          <Card borderColor={theme.colors.secondaryLight} device={device}>
            <EventDate size='S' weight='medium'>
              {moment(eventDate).format('dddd Do MMMM YYYY - HH:mm') + '  '}
              <Text style={{ flex: 1 }} size='XS' color='grey'>
                {t('psychologists.confirmation.' + eventType)}
              </Text>
            </EventDate>
            <RowContainer>
              <PsychologistImage uri={avatarUrl} />
              <Name>
                <Paragraph color='grey'>
                  {t('psychologists.confirmation.with')}
                </Paragraph>
                <Text size='S' weight='medium'>
                  {psychologistFullname}
                </Text>
              </Name>
            </RowContainer>
          </Card>
        </ColContainer>
        <OnDevice size={['medium', 'large']}>
          <ImageContainer>
            <BigImage resizeMode={'contain'} source={emailLogo} />
          </ImageContainer>
        </OnDevice>
      </Container>
      <ButtonContainer device={device}>
        <Button
          trackLabel='clicked_appointment_go_home'
          label={t('psychologists.confirmation.go-home')}
          onPress={goHome}
        />
      </ButtonContainer>
    </PageContainer>
  )
}
