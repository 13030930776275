import * as React from 'react'
import { ShowableProps } from './Showable.types'
import { Container } from './Showable.style'
import { IS_BROWSER } from '../utils/device'

export default function Showable({
  show,
  children,
  approximateHeight = 100,
}: ShowableProps) {
  if (IS_BROWSER) return show ? children : null
  return (
    <Container
      animate={{
        maxHeight: show ? approximateHeight : 0,
        opacity: show ? 1 : 0,
      }}>
      {children}
    </Container>
  )
}
