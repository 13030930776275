import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-native-phone-number-input'
import { PhoneNumberProps } from './index.type'
import { useToggle } from '@core/lib/hooks'

export default function PhoneNumberInput({
  phone,
  setPhone,
  setPhoneValidation,
}: PhoneNumberProps) {
  const phoneInput = useRef<PhoneInput>(null)
  const [placeholder, setPlaceholder] = useState('0612345678')
  const [focus, , , toggleFocus] = useToggle(false)

  useEffect(() => {
    if ((!phone || phone === '') && focus) {
      setPlaceholder(' ')
    } else if (!phone || phone) {
      setPlaceholder('0612345678')
    }
  }, [phone, focus])

  return (
    <PhoneInput
      ref={phoneInput}
      containerStyle={{ width: '100%', backgroundColor: '#F4F5FD' }}
      flagButtonStyle={{ width: 65, marginRight: 0 }}
      textContainerStyle={{
        width: '100%',
        padding: 8,
        backgroundColor: '#F4F5FD',
      }}
      textInputStyle={{ width: '100%' }}
      defaultValue={phone}
      defaultCode='FR'
      layout='first'
      textInputProps={{ onFocus: toggleFocus, onBlur: toggleFocus }}
      placeholder={placeholder}
      onChangeText={(e) => {
        setPhone(e)
        setPhoneValidation(phoneInput.current?.isValidNumber(e))
      }}
      value={phone}
    />
  )
}
