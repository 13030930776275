import { Text } from '@ui/Moodwork-UI/atoms'
import { IS_NATIVE } from '@ui/utils'
import styled, { css } from 'styled-components/native'

export const FooterContainer = styled.View<{ listMode: boolean }>`
  height: 10%;
  align-items: flex-end;
  padding: 0 16px 4px 4px;
  flex-direction: row;
  justify-content: space-between;
  ${IS_NATIVE && css`flex: 1;`}
`

export const Footer = styled(Text)`
  font-size: ${({ theme }) => theme.textSizes.XXS};
  color: ${({ theme }) => theme.colors.grey};

  margin-left: ${({ theme }) => theme.spacings.S}px;
`

export const ShareImage = styled.Image`
  width: 17px;
  height: 17px;
  margin-left: 8px;
`
