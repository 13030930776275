import * as React from 'react'
import styled from 'styled-components/native'

const Container = styled.View`
  align-items: center;
  justify-content: center;
`

const Content = styled.Text``

export default function CommingSoon() {
  return (
    <Container>
      <Content>Comming Soon</Content>
    </Container>
  )
}
