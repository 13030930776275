import React, { useMemo, useCallback, useState, useEffect } from 'react'
import {
  BodyContainer,
  Head,
  MainContent,
  LeftContent,
  RightContent,
  RightColumn,
  LeftColumn,
} from './index.style'
import { IS_BROWSER, IS_NATIVE, useTheme } from '@ui/utils'
import { DashboardProps, DashboardColumns, DashboardCard } from './index.types'
import SectionList from '@ui/Moodwork-UI/organisms/Dashboard/SectionList'
import { LayoutChangeEvent, View } from 'react-native'
import { PageContent } from '../Page'
import SurveyNPS from '@ui/Moodwork-UI/organisms/Survey/SurveyNPS'
import { useSendDeviceToken, useUserInfo } from '@core/services/viewer'
import { useCompanyInfo } from '@core/services/users'
import { fetchModal } from '@core/lib/modal'
import { useNavigation } from '@react-navigation/native'
import { MODAL_CONFIRMATION, MODAL_WELCOME } from '@core/constants'
import { useSyncReady } from '@core/lib/ready'
import messaging from '@react-native-firebase/messaging'

const useBuilder = (columns: DashboardColumns) => {
  const theme = useTheme()

  const [rightColumnHeight, setRightColumnHeight] = useState(0)
  const [leftCardColumnY, setLeftCardColumnY] = useState({})
  const [componentsVisible, setComponentsVisible] = useState({})

  const onLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setRightColumnHeight(nativeEvent.layout.height)
  }, [])

  return useMemo(() => {
    const renderComponent =
      (column: string, ContentWrapper: any) =>
      (dashboardCard: DashboardCard, index: number) => {
        const key = `${column}-${index}`
        const options = dashboardCard[1]
        const Component = Array.isArray(dashboardCard)
          ? dashboardCard[0]
          : dashboardCard

        const onLayoutCard =
          column === 'left'
            ? ({ nativeEvent }: LayoutChangeEvent) => {
                setLeftCardColumnY((state) => ({
                  ...state,
                  [index]: nativeEvent.layout.y,
                }))
              }
            : () => {}

        const hasRightSpace =
          column === 'left' &&
          rightColumnHeight != 0 &&
          leftCardColumnY[index] > rightColumnHeight - 70 // remove for title of next component

        return (
          <ContentWrapper
            onLayout={onLayoutCard}
            key={key}
            {...options}
            hidden={options?.hideByDefault && !componentsVisible[key]}
            hasRightSpace={hasRightSpace}>
            <Component
              {...options?.props}
              onVisible={(visible: boolean) => {
                setComponentsVisible((state) => ({
                  ...state,
                  [key]: visible,
                }))
              }}
            />
          </ContentWrapper>
        )
      }

    if (theme.device === 'large') {
      return (
        <>
          <LeftColumn minHeight={rightColumnHeight}>
            {columns.leftColumn.map(renderComponent('left', LeftContent))}
          </LeftColumn>
          <RightColumn onLayout={onLayout}>
            {columns.rightColumn.map(renderComponent('right', RightContent))}
          </RightColumn>
        </>
      )
    }

    return <>{columns.mainColumn.map(renderComponent('main', MainContent))}</>
  }, [theme, columns, rightColumnHeight, leftCardColumnY, componentsVisible])
}

export const Dashboard = ({ components }: DashboardProps) => {
  const navigation = useNavigation()
  const { data: userInfoData, isSuccess: userInfoReady } = useUserInfo()
  const { mutateAsync } = useSendDeviceToken()
  const { data: companyInfoData, isSuccess: companyInfoReady } =
    useCompanyInfo()
  const isReady = useSyncReady(
    'dashboard',
    MODAL_WELCOME,
    userInfoReady && companyInfoReady
  )

  useEffect(() => {
    if (IS_NATIVE) {
      messaging()
        .getToken()
        .then((token) => {
          mutateAsync(token)
        })
    }
  }, [])

  useEffect(() => {
    if (isReady && !userInfoData.privacy_consent) {
      navigation.navigate(MODAL_CONFIRMATION)
    } else if (
      isReady &&
      !!companyInfoData?.onboarding_intro_text &&
      companyInfoData?.onboarding_intro_text.length > 0
    ) {
      fetchModal(userInfoData?.email, MODAL_WELCOME).then((response) => {
        if (response != 'false') {
          navigation.navigate(MODAL_WELCOME)
        }
      })
    }
  }, [isReady, userInfoData?.email, companyInfoData?.onboarding_intro_text])

  return (
    <PageContent>
      <SurveyNPS />
      <BodyContainer>{useBuilder(components)}</BodyContainer>
      <SectionList />
    </PageContent>
  )
}
