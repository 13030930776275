import { Session } from '../store'
import { AxiosRequestConfig } from 'axios'
import {
  AxiosError,
  createHttpClient,
  refreshToken,
  shouldSendToken,
} from '../lib/http'
import { useCallback, useMemo } from 'react'
import { fetchAuthTokens } from '../lib/auth'
import * as Sentry from 'sentry-expo'
import { NavigationProp } from '@react-navigation/native'
import { useSignOut } from '../lib/navigation'

type Subscriber = (token: string) => AxiosRequestConfig & { _retry: boolean }
/**
 * TODO:
 *   - see if we can avoid top level state variables
 */
// locker to avoid calling refresh_token multiple time
let isAlreadyFetchingAccessToken = false
// list of api call waiting for new access_token
let subscribers: Subscriber[] = []
export function useHttp(navigation?: NavigationProp<any>) {
  const session = Session.useState()
  const signOut = useSignOut()
  /**
   * Handles the process of refreshing the token,
   * and provides a mechanism to queue requests that
   * are waiting for the new token
   */
  const onError = useCallback(
    async function onError(
      error: AxiosError,
    ) {
      const { config, response, instance } = error
      const { refreshToken: rt } = await fetchAuthTokens()
      /**
       * If the request is not authorized, we provided an access token, and
       * this request was not already retried,
       * then it must be dued to the token's expiration.
       * We can refresh it and retry
       *
       * BUG :
       *  The first call returning 401 is not called again
       */
      if (
        response?.status === 401 &&
        rt &&
        !config._retry &&
        shouldSendToken(config.url)
      ) {
        if (!isAlreadyFetchingAccessToken) {
          try {
            isAlreadyFetchingAccessToken = true
            const refreshResponse = await refreshToken()
            session.dispatch(
              Session.setTokens({
                accessToken: refreshResponse?.data?.access_token || null,
                refreshToken: refreshResponse?.data?.refresh_token || null,
                tokenExpiration:
                  refreshResponse?.data?.created_at +
                    refreshResponse?.data?.expires_in || null,
              })
            )
            subscribers.forEach((s) => s(refreshResponse?.data?.access_token))
            isAlreadyFetchingAccessToken = false
          } catch (error) {
            if (error?.response?.status === 401) {
              signOut()
            }

            Sentry.Browser.captureException(error)
            isAlreadyFetchingAccessToken = false
            session.dispatch(Session.clear())
            return Promise.reject(error)
          }
        }

        /**
         * TODO : fix this ignore
         */
        // @ts-ignore
        subscribers.push((token: string | null) => {
          if (!token) {
            console.error('No Token')
            return Promise.reject(error)
          }
          config._retry = true
          config.headers.Authorization = `Bearer ${token}`
          return instance(config)
        })
      }

      return Promise.reject(error)
    },
    [session]
  )

  const onRefreshToken = useCallback(
    (access: string, refresh: string, tokenExpiration: string) => {
      return session.dispatch(
        Session.setTokens({
          accessToken: access,
          refreshToken: refresh,
          tokenExpiration: '' + tokenExpiration,
        })
      )
    },
    [session]
  )
  return useMemo(
    () => createHttpClient({ onRefreshToken, onError, onSignOut: signOut }),
    [session]
  )
}
