import React from 'react'
import { useFonts } from 'expo-font'
import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import { FontIconProps } from './interface'
import { Container } from './FontIconStyle'
import { ViewProps } from 'react-native'

const iconset = require('../../../../../assets/icomoon/icomoon.ttf')

const Icon = createIconSetFromIcoMoon(
  require('../../../../../assets/icomoon/selection.json'),
  'IcoMoon',
  'icomoon.ttf'
)

export const FontIcon: React.FC<FontIconProps & Pick<ViewProps, 'style'>> = ({
  name,
  size = 24,
  color = '#000',
  style,
}) => {
  const [fontsLoaded] = useFonts({ IcoMoon: iconset })
  return (
    <Container style={style}>
      {fontsLoaded && <Icon name={name} size={size} color={color} />}
    </Container>
  )
}
