import React, { useCallback, useMemo } from 'react'
import { ResourcePDFProps } from './interface'
import { Container } from './ResourcePDF.styles'
import { Button, HTMLView } from '@ui/Moodwork-UI'
import { useTheme } from '@utils'
import { HtmlContainer } from '@ui/Moodwork-UI/organisms/Resource/ResourceBlock/ResourceBlock.styles'
import { downloadOpenFile } from '@core/lib/file'

export const ResourcePDF = ({
  file,
  content,
  title,
  background = 'transparent',
  trackableParams,
}: ResourcePDFProps) => {
  const theme = useTheme()
  const HTMLTextColor = useMemo(() => {
    if (background === 'dark') {
      return theme.colors.white
    }
  }, [background])

  const color = useMemo(() => {
    switch (background) {
      case 'transparent':
        return 'primary'
      case 'dark':
        return 'white'
      case 'light':
        return 'primary'
      default:
        return 'primary'
    }
  }, [background])

  const textColor = useMemo(() => {
    switch (background) {
      case 'transparent':
        return 'black'
      case 'dark':
        return 'secondary'
      case 'light':
        return 'black'
      default:
        return 'black'
    }
  }, [background])

  const onPress = useCallback(() => {
    downloadOpenFile(file.url).then()
  }, [])

  return (
    <Container>
      <HtmlContainer background={background} bottomMargin>
        <HTMLView
          source={content || ''}
          textColor={HTMLTextColor}
          big={theme.device === 'large'}
        />
        {!!file && (
          <Button
            textColor={textColor}
            color={color}
            trackLabel='clicked_resource_download_file'
            trackProperties={trackableParams}
            label={title}
            onPress={onPress}
          />
        )}
      </HtmlContainer>
    </Container>
  )
}
