import styled, { css } from 'styled-components/native'
import { IS_BROWSER, IS_NATIVE } from '../utils/device'
import { LogoProps } from './Logo.types'

const web =
  IS_BROWSER &&
  css`
    ${({ big }: LogoProps) =>
      !big &&
      css`
        height: 36px;
        width: 71px;
      `}
  `

export const Container = styled.ImageBackground.attrs({
  resizeMode: 'contain',
  source: require('../../assets/logo.png'),
})`
  margin-right: 12px;
  height: ${IS_NATIVE ? '36px' : '53px'};
  width: ${IS_NATIVE ? '71px' : '104px'};
  ${({ centered }: LogoProps) =>
    centered &&
    css`
      align-self: center;
    `}
  ${web};
`
