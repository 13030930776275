import React, { useEffect, useRef } from 'react'
import { View, Animated, Easing } from 'react-native'
import { LoadingCircleProps } from './interface'
import { styles } from './LoadingCircle.styles'

const startRotationAnimation = (
  durationMs: number,
  rotationDegree: Animated.Value
): void => {
  Animated.loop(
    Animated.timing(rotationDegree, {
      toValue: 360,
      duration: durationMs,
      easing: Easing.linear,
      useNativeDriver: true,
    })
  ).start()
}

export const LoadingCircle = ({
  color = '#000',
  durationMs = 1000,
}: LoadingCircleProps): JSX.Element => {
  const rotationDegree = useRef(new Animated.Value(0)).current

  useEffect(() => {
    startRotationAnimation(durationMs, rotationDegree)
  }, [durationMs, rotationDegree])

  return (
    <View style={styles.container} accessibilityRole='progressbar'>
      <Animated.View
        style={[
          styles.progress,
          {
            borderTopColor: color,
            borderRightColor: color,
            borderBottomColor: color,
          },
          {
            transform: [
              {
                rotateZ: rotationDegree.interpolate({
                  inputRange: [0, 360],
                  outputRange: ['0deg', '360deg'],
                }),
              },
            ],
          },
        ]}
      />
    </View>
  )
}
