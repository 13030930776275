import React, { useEffect } from 'react'
import { ErrorPage, Resource as ResourceComponent } from '@moodwork-ui'
import { useRoute } from '@react-navigation/core'
import { useNavigation } from '@react-navigation/native'
import { CollectionNavigation } from '../../lib/types'
import { ErrorType } from '@utils'
import { useCollection } from '@core/services/resources'
import { CollectionTemplate } from '@ui/Moodwork-UI/templates/Collection'
import { trackEvent } from '@core/lib/analytics'

const Collection = () => {
  const { id } = useRoute<CollectionNavigation>().params
  const navigation = useNavigation()
  const { data, isLoading, isError } = useCollection(id)
  useEffect(() => {
    if (data?.title?.length > 0) {
      navigation.setOptions({ title: data.title })
    }
  }, [data?.title])

  useEffect(() => {
    if (data) {
      trackEvent('viewed_collections_screen', {
        collection_uuid: data.uuid,
        collection_title: data.title,
        nb_collection_items_total: data.content.length,
        nb_collection_items_viewed: data.content.filter(
          (c) => c.state?.status === 'done'
        ).length,
        nb_collection_items_favorited: data.content.filter((c) => c.favorite)
          .length,
      })
    }
  }, [data])

  return isError ? (
    <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  ) : (
    !!data && <CollectionTemplate {...data} />
  )
}

export default Collection
