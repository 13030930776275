import { BigParagraph } from '@ui/Moodwork-UI'
import styled from 'styled-components/native'

export const Container = styled.View`
  padding: ${({ theme }) =>
    theme.device === 'small' ? '64px 16px' : '48px 70px'};
  flex: 1;
  justify-content: space-evenly;
`

export const Title = styled(BigParagraph)`
  font-weight: bold;
  margin-bottom: 25px;
`

export const Image = styled.Image`
  height: 110px;
  width: 100%;
  marginVertical: 16px;
`
