import React from 'react'
import { useRoute } from '@react-navigation/native'
import { PsychologistAppointmentConfirmationRoute } from '../lib/types'
import AppointmentConfirmation from '../../ui/AppointmentConfirmation'
import { PageView } from '../../ui/PageView'
import { useUserInfo } from '@core/services/viewer'
import { ErrorPage } from '@ui/Moodwork-UI'
import { ErrorType } from '@ui/utils'

export default function PsychologistAppointmentConfirmation() {
  let { eventDate, eventType, avatarUrl, psychologistFullname } =
    useRoute<PsychologistAppointmentConfirmationRoute>().params
  const userInfo = useUserInfo()?.data

  if (!userInfo?.care_access) {
    return <ErrorPage type={ErrorType.CARE_ACCESS_ERROR} />
  } else {
    return (
      <PageView>
        <AppointmentConfirmation
          eventDate={eventDate}
          eventType={eventType}
          avatarUrl={avatarUrl}
          psychologistFullname={psychologistFullname}
        />
      </PageView>
    )
  }
}
