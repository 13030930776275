import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { Text } from '@moodwork-ui'

const web =
  IS_BROWSER &&
  css`
    margin-bottom: 40px;
  `

export const Container = styled.View`
  width: 100%;
`

export const Content = styled(Text)`
  line-height: 34px;
  margin-right: 64px;
  ${web};
`

export const SurContent = styled(Text)`
  margin: 0 16px;
`
