import { SearchApi } from '@core/types/search'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useHttp } from './http'

export function useSearch(
  params: { q?: string, theme_ids?: number[], sticker_keys?: string[], favorites?: boolean } = {}
): UseInfiniteQueryResult<SearchApi> {
  const http = useHttp()
  const { i18n } = useTranslation()

  const fetchSearch = async ({ pageParam = 1 }) => {

    const types = (params?.q != null && params.q?.length > 0) || params.favorites ? {} : {type: 'diary'}
    return (
      await http.get('/api/v3/search', {
        params: { ...params, page: pageParam, ...types  },
      })
    )?.data
  }

  return useInfiniteQuery(['search', params, i18n.language], fetchSearch, {
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.total_pages) {
        return lastPage.page + 1
      }
    },
  })
}
