import { FontIcon } from '@ui/Moodwork-UI/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, View } from 'react-native'
import { BookmarkButton } from '../../pressables/BookmarkButton'
import { Footer, FooterContainer, ShareImage } from './index.styles'
import shareIcon from '../../../../../assets/images/ic_share.png'

export const FooterInformations = ({
  hover,
  listMode,
  title,
  date,
  theme,
  fulldate,
  duration,
  count,
  bottom,
  progression,
  read,
  favorite,
  onFavorite,
  onShare,
}) => {
  const { t } = useTranslation(['resources', 'dashboard'])

  return (
    <>
      {(duration || count) != null && !bottom && (
        <FooterContainer listMode={listMode}>
          <Footer>
            {duration
              ? t('resources.subHeader.shortReadingTime', {
                  count: duration,
                })
              : t('resources.subHeader.count', {
                  count: count,
                })}
          </Footer>
          <View style={{ flexDirection: 'row' }}>
            {read && (
              <FontIcon // todo : backend validation
                style={{ marginRight: 8 }}
                name='check-circle'
                size={17}
                color={'#51CB9F'}
              />
            )}

            {favorite != undefined && (
              <BookmarkButton favorite={favorite} onFavorite={onFavorite} />
            )}
            <Pressable onPress={onShare}>
              <ShareImage source={shareIcon} />
            </Pressable>
          </View>
        </FooterContainer>
      )}
      {count && !read && (
        <View
          style={{
            height: 10,
            width: progression,
            borderBottomRightRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: '#51CB9F',
          }}
        />
      )}
    </>
  )
}
