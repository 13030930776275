import React, { useEffect } from 'react'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { StackActions, useNavigation } from '@react-navigation/native'
import { SCREEN_HOME, SCREEN_PROFILING } from '@core/constants'
import { useUserInfo } from '@core/services/viewer'
import { useRegistrationQuestion } from '@core/services/profile'
import RegistrationQuestions from '@ui/Moodwork-UI/organisms/RegistrationQuestions'
import { InteractionManager } from 'react-native'

export default function Registration() {
  const { data, isError, isSuccess, isFetched } = useRegistrationQuestion()
  const { data: userInfos, isFetched: userInfoFetched } = useUserInfo()
  const navigation = useNavigation()

  useEffect(() => {
    if (
      isSuccess &&
      isFetched &&
      userInfos &&
      userInfos.questions_to_answer_type !== 'registration'
    ) {
      InteractionManager.runAfterInteractions(() => {
        if (userInfos.questions_to_answer_type === 'profile') {
          navigation.dispatch(StackActions.replace(SCREEN_PROFILING))
        } else {
          navigation.dispatch(StackActions.replace(SCREEN_HOME))
        }
      })
    }
  }, [isSuccess, isFetched, userInfoFetched])

  useEffect(() => {
    if (isError) {
      navigation.dispatch(StackActions.replace(SCREEN_HOME))
    }
  }, [isError])

  return isFetched && data && data.length > 0 ? (
    <NavigationModal
      backgroundColor='secondary'
      isClosable={false}
      hasBackgroundOpacity={false}>
      <RegistrationQuestions
        questions={data.slice(0).sort((a, b) => a.priority - b.priority)}
      />
    </NavigationModal>
  ) : (
    <></>
  )
}
