import React from 'react'
import { RoundedImageStyle } from './RoundedImageStyle'
import { RoundedImageProps } from './interface'

export const RoundedImage: React.FC<RoundedImageProps> = ({
  imageSize,
  uri,
}) => {
  return (
    <>
      <RoundedImageStyle size={imageSize} source={uri} />
    </>
  )
}
