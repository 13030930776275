import React from 'react'
import { ViewProps } from 'react-native'
import { MenuProps } from './interface'
import { MenuContainer } from './Menu.styles'

export const Menu = ({
  width,
  backgroundColor,
  renderItem = () => {},
  ...props
}: MenuProps & ViewProps) => {
  return (
    <MenuContainer
      width={width}
      backgroundColor={backgroundColor}
      style={props.style}>
      {props.children}
      {renderItem()}
    </MenuContainer>
  )
}
