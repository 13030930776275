import * as React from 'react'
import './debug/reactotron'
import { Provider } from './store'
import Router from './components/Router'
import { StatusBar } from 'react-native'
import ErrorBoundary from '../ui/Page/ErrorBoundary'
import StandaloneTechnicalError from '@ui/StandaloneTechnicalError'

export const FatalErrorPage = () => {
  return <StandaloneTechnicalError />
}

export function AppCore() {
  const sentryFallback = React.useCallback(() => <FatalErrorPage />, [])

  return (
    <ErrorBoundary fallback={sentryFallback}>
      <Provider>
        <>
          <StatusBar hidden />
          <Router />
        </>
      </Provider>
    </ErrorBoundary>
  )
}
