import { ACCESS_TOKEN_KEY, HOST, REFRESH_TOKEN_KEY } from '@core/constants'
import { getStoredItem } from '@core/lib/storage'
import React, { useState } from 'react'
import WebView from 'react-native-webview'

export default function Program() {
  const [accessToken, setToken] = useState()
  const [refreshToken, setRefreshToken] = useState()
  getStoredItem(ACCESS_TOKEN_KEY).then((token) => {
    setToken(token)
  })

  getStoredItem(REFRESH_TOKEN_KEY).then((refresh) => {
    setRefreshToken(refresh)
  })

  if (!accessToken) {
    return <></>
  }
  return (
    <WebView
      source={{
        uri: HOST + '/program?webview=true',
        headers: {
          Cookie: `mw_access_token=${accessToken}; mw_refresh_token=${refreshToken}`,
        },
      }}
      sharedCookiesEnabled
    />
  )
}
