import * as React from 'react'
import { initReactI18next, I18nextProvider as Provider } from 'react-i18next'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import { DEFAULT_LANGUAGE } from '../../constants'
import * as translations from '../../translations'
import { View } from 'react-native'
import { getDeviceLocale } from '../../lib/locale'
import { IS_NATIVE } from '@ui/utils'
import * as Sentry from 'sentry-expo'

interface I18nProviderProps {
  children: JSX.Element
}

/**
 * Custom formatting for provided values
 * Right now we can only format date,
 * defaults to the raw value stringified
 *
 */
function format(value: any, format?: string, lng?: string): string {
  if (!!value && !!format && !!lng) {
    if (value instanceof Date) {
      return DateTime.fromJSDate(value).setLocale(lng).toFormat(format)
    } else if (value instanceof DateTime) {
      return value.setLocale(lng).toFormat(format, { locale: lng })
    }
  }
  return value
}

export default function I18nProvider({ children }: I18nProviderProps) {
  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    // TODO : Fix Init for stories
    if (false && i18next.isInitialized) {
      return
    }
    i18next
      .use(initReactI18next)
      .init({
        lng: getDeviceLocale(),
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: { format, escapeValue: false },
        resources: translations,
      })
      .then(() => setReady(true))
      .catch((e) => {
        console.error(e)
        Sentry.Browser.captureException(e)
        setReady(true)
      })
  }, [])

  if (!ready) {
    return <View />
  }

  return <Provider i18n={i18next}>{children}</Provider>
}
