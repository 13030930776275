import { Theme } from './UIProvider.types'

export default {
  colors: {
    primary: '#F4CB50',
    secondary: '#1E32F5',
    primaryLight: '#FBEAB9',
    secondaryLight: '#c9d4eb',
    secondaryLighter: '#F4F5FE',
    secondaryBackground: '#F4F5FD',

    error: '#FB6744',
    red: '#FF6086',
    blue: '#a3ade2',
    yellow: '#f7d696',
    green: '#6fcca4',
    lightGreen: '#51CB9F',
    orange: '#f5bb96',
    violet: '#7a56c5',
    black: '#000',
    link: '#3A4CF6',
    white: '#FFF',

    lightBlue: '#EDF8FF',
    searchBlue: '#80BBFF',
    greyBlue: '#e9f2ff',
    grey: '#676767',
    darkerGrey: '#DCDCDC',
    lightGrey: '#999999',
    lightSecondaryGrey: '#dcdcdc',
    separatorGrey: '#c4c4c4',
    transparent: 'transparent',

    // alerts
    mistyRose: '#ffe4e1',
    crimson: '#ed143d',
    paleGreen: '#e3f3e3',
    forestGreen: '#228b22',
    lightSteelBlue: '#e0efff',
  },
  fonts: {
    bold: 'Asap_700Bold',
    regular: 'Asap_400Regular',
    medium: 'Asap_500Medium',
    semiBold: 'Asap_600SemiBold',
  },
  textSizes: {
    XXXS: '10px',
    XXS: '12px',
    XS: '14px',
    S: '16px',
    M: '18px',
    L: '20px',
    XL: '24px',
    XXL: '30px',
  },
  sizes: {
    XXS: 12,
    XS: 14,
    S: 16,
    M: 18,
    L: 20,
    XL: 24,
    XXL: 30,
  },
  textLineHeights: {
    XXXS: 14,
    XXS: 16,
    XS: 20,
    S: 22,
    M: 25,
    L: 28,
    XL: 30,
    XXL: 40,
  },
  spacings: {
    XXS: 2,
    XS: 5,
    S: 10,
    M: 14,
    L: 16,
    XL: 20,
    XXL: 40,
    XXXL: 56,
    FOOTER: 74,
    PAGE: 120,
    none: 0,
  },
  heights: {
    Button: '50px',
    HoveredButton: '56px',
    HoveredRoundedButton: '58px',
    ScoreIndicator: '70px',
  },
  widths: {
    Button: '293px',
    HoveredRoundedButton: '58px',
    RoundedButton: '50px',
    SelectionButton: '150px',
  },
  borderRadius: {
    rounded: '1000px',
    small: '4px',
    button: '25px',
  },
  borderWidth: {
    XS: '0.5px',
  },
  device: 'medium',
} as Theme

export const old = {
  program: '#7a56c5',
}
