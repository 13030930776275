import styled, { css } from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'

// negative margin to handle ScoreCard margin-right
export const CategoriesContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) =>
    theme.device === 'small' || theme.device == 'medium'
      ? css`
          justify-content: center;
          align-items: center;
        `
      : css`
          margin-top: 32px;
          flex-direction: row;
          margin-right: -80px;
        `};
`

export const CategoriesWrapper = styled.View`
  margin-bottom: ${({ theme }) => `${theme.spacings.XXL}px`}
    ${({ theme }) =>
      theme.device !== 'small'
        ? css`
            padding-horizontal: ${({ theme }) => `${theme.spacings.PAGE}px`};
          `
        : css`
            padding-horizontal: ${({ theme }) => `${theme.spacings.L}px`};
          `};
`

export const RecommendationContainer = styled.View`
  padding-horizontal: ${({ theme }) =>
    theme.device !== 'small'
      ? `${theme.spacings.PAGE}px`
      : `${theme.spacings.XXL}px`};
  padding-vertical: ${({ theme }) => `${theme.spacings.XXL}px`};
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.separatorGrey};
      border-style: solid;
      border-radius: ${({ theme }) => theme.borderRadius.small};
    `};
`

export const RecommendationActionsContainer = styled(RecommendationContainer)`
  flex-wrap: wrap;
`

export const RecommendationWrapper = styled(LinearGradient)`
  display: flex;
  flex-basis: auto;
  padding-top: 20px;
  padding-bottom: 53px;
  background-color: #fafafe;
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
      padding-horizontal: ${({ theme }) => `${theme.spacings.PAGE}px`};
    `};
`

export const AppointmentImage = styled.Image`
  height: 349px;
  width: 237px;
`
export const AppointmentImageContainer = styled.View`
  flex: 0.7;
  align-items: center;
`

export const ImageAndInformationCardWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-vertical: ${({ theme }) => theme.spacings.XL};
`

export const WorkPlanImageWrapper = styled.View`
  flex: 0.7;
  align-items: center;
  padding-right: 40px;
`
export const WorkPlanImage = styled.Image`
  width: 282px;
  height: 375px;
`

export const RecommendationsDiariesWrapper = styled.View`
  margin-top: 32px;
  
`

export const ButtonContainer = styled.View`
  margin-top: ${({ theme }) =>
    `${theme.device === 'small' ? theme.spacings.S : theme.spacings.XS}px`};
  margin-left: auto;
`

export const CompanyRefererContainer = styled.View`
  ${({ theme }) =>
    theme.device !== 'small'
      ? css`
          padding-horizontal: ${({ theme }) => `${theme.spacings.PAGE}px`};
        `
      : css`
          padding-horizontal: ${({ theme }) => `${theme.spacings.L}px`};
        `};
`

export const RefererSeparator = styled.View`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black};
  margin-bottom: 24px;
`

export const MainTitleWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  ${({ theme }) =>
    theme.device !== 'small'
      ? css`
          justify-content: center;
        `
      : css`
          margin-horizontal: ${({ theme }) => `${theme.spacings.L}px`};
        `};
`

export const IconWrapper = styled.View`
  align-self: flex-start;
  margin-right: ${({ theme }) => `${theme.spacings.L}px`};
  margin-top: ${({ theme }) =>
    theme.device !== 'large' ? '2px' : `${theme.spacings.S}px`};
`

export const MainSeparator = styled.View`
  margin: ${({ theme }) => `${theme.spacings.XL}px`}
    ${({ theme }) => `${theme.spacings.L}px`};
  border: 1px solid ${({ theme }) => theme.colors.separatorGrey};
`
