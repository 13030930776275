import { FontIcon } from '@ui/Moodwork-UI'
import React, { createContext, useEffect, useState } from 'react'
import { Pressable, ViewProps } from 'react-native'
import { AlertContainer, Alert, AlertText } from './index.styles'
import { AlertContextTypes, AlertHandlerTypes } from './index.types'

export const AlertContext = createContext<AlertContextTypes>({
  message: null,
  setMessage: (message: string | null) => {},
  type: 'default',
  setType: (type: AlertHandlerTypes | null) => {},
  offsetY: 0,
  setOffsetY: (offsetValue: number | null) => {},
})

export default function AlertHandler({ children }: ViewProps) {
  const [message, setMessage] = useState<string | null>(null)
  const [type, setType] = useState<AlertHandlerTypes>('default')
  const [offsetY, setOffsetY] = useState<number | null>(0)

  const clearAlert = () => {
    setMessage(null)
    setType('default')
  }

  useEffect(() => {
    if (message !== null) {
      setTimeout(() => {
        clearAlert()
      }, 15000)
    }
  }, [message])

  return (
    <AlertContext.Provider
      value={{ message, setMessage, type, setType, offsetY, setOffsetY }}>
      {message && (
        <Alert style={{ top: offsetY || 0 }}>
          <AlertContainer type={type}>
            <AlertText type={type}>{message}</AlertText>
            <Pressable onPress={clearAlert}>
              <FontIcon name='cancel' size={16} />
            </Pressable>
          </AlertContainer>
        </Alert>
      )}
      {children}
    </AlertContext.Provider>
  )
}
