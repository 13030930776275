import React from 'react'
import { DEFAULT_LANGUAGE, LOCALE_KEY, SUPPORTED_LOCALES } from '../constants'
import * as Localization from 'expo-localization'
import { SupportedLocale } from './types'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import * as Sentry from 'sentry-expo'
import { getStoredItem } from './storage'
import { IS_BROWSER } from '@ui/utils'

function isSupported(value: string) {
  return SUPPORTED_LOCALES.includes(value)
}

export function useNextSlotContent(
  nextSlot: { date: string },
  psyName: string,
  language: string
) {
  const { t } = useTranslation(['appointments', 'dates'])
  const nextSlotContent = React.useMemo(() => {
    if (!nextSlot?.date) {
      return t('psychologists.calendar.noNext', { psyName: psyName })
    }
    const date = DateTime.fromISO(nextSlot.date)
    return t('psychologists.calendar.next', {
      date:
        date.startOf('day').toISO() === DateTime.now().startOf('day').toISO()
          ? t('dates:today')
          : date.minus({ day: 1 }).startOf('day').toISO() ===
            DateTime.now().startOf('day').toISO()
          ? t('dates:tomorrow')
          : DateTime.fromISO(nextSlot.date).toFormat('dd MMMM', {
              locale: language,
            }),
    })
  }, [])
  return nextSlotContent
}

export function getDeviceLocale(userLanguage?: string): SupportedLocale {
  let localeKey
  if (IS_BROWSER) {
    localeKey = getStoredItem(LOCALE_KEY)
  }

  try {
    let userLanguages = userLanguage ? [userLanguage] : []
    return userLanguages
      .concat(localeKey || '')
      .concat(Localization.locales.map((local) => local.replace(/-.+/, '')))
      .concat(DEFAULT_LANGUAGE)
      .find(isSupported) as SupportedLocale
  } catch (e) {
    Sentry.Browser.captureException(e)
    console.error(e)
    return DEFAULT_LANGUAGE
  }
}
