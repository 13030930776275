import React, { useEffect } from 'react'
import { ScoreCard, InformationCardWithButton } from '../../organisms'
import { useTranslation } from 'react-i18next'
import { useRecommendations } from '@core/services/results'
import {
  FontIcon,
  Title,
  SubTitle,
  SmallParagraph,
  HTMLView,
} from '../../atoms'
// @ts-ignore
import appointmentPicture from '../../../../assets/images/img_accompagnement.png'
// @ts-ignore
import WorkPlan from '../../../../assets/images/illu_burnout_plan_de_travail.png'
import { OnDevice } from '../../../OnDevice'
import {
  RecommendedDiaries,
  WellbeingDiaries,
  WellBeingProps,
} from './interface'
import { LinkWithIcon, PageView } from '../../..'
import {
  CategoriesContainer,
  RecommendationActionsContainer,
  RecommendationWrapper,
  AppointmentImage,
  AppointmentImageContainer,
  ImageAndInformationCardWrapper,
  WorkPlanImageWrapper,
  WorkPlanImage,
  RecommendationsDiariesWrapper,
  CategoriesWrapper,
  ButtonContainer,
  CompanyRefererContainer,
  RefererSeparator,
  MainTitleWrapper,
  IconWrapper,
  MainSeparator,
} from './WellBeing.styles'
import { HOST, SCREEN_ASSESSMENT, SCREEN_PSYCHOLOGISTS } from '@core/constants'
import { useNavigationLink } from '@core/lib/navigation'
import { useNavigation } from '@react-navigation/native'
import { useCompanyInfo } from '@core/services/users'
import { PsychologistRefererCard } from '@ui/Moodwork-UI/organisms/PsychologistRefererCard'
import { FlatList, useWindowDimensions } from 'react-native'
import RecommandationsLoader from './Recommandations.loading'
import { useTheme } from '@utils'
import RecommendedResources from '@ui/Moodwork-UI/organisms/Dashboard/RecommendedResources'
import { trackEvent } from '@core/lib/analytics'
import { useUserInfo } from '@core/services/viewer'
import { BurnoutChatImage } from '../Burnout/Burnout.styles'
import burnout from '../../../../assets/images/burnout.png'

export const WellBeing = ({ testId, result }: WellBeingProps) => {
  const { t, i18n } = useTranslation('result')
  const navigation = useNavigation()
  const { data: userInfo } = useUserInfo()
  const { data: recommendedDiariesList, isLoading } = useRecommendations(testId)
  const {
    final_score,
    title,
    categories,
    recommended_programs: programs,
    burnout_test_path: burnoutPath,
    could_be_burned_out: couldBeBurnOut,
    specialist_content: specialistContent,
  } = result

  useEffect(() => {
    if (recommendedDiariesList) {
      trackEvent('viewed_wellbeing_results_screen', {
        user_test_id: testId,
        test_score: final_score,
        nb_negative_factors: categories.flatMap((cat) =>
          cat.factors?.filter((c) => c.kind === 'risk')
        ).length,
        nb_positive_factors: categories.flatMap((cat) =>
          cat.factors?.filter((c) => c.kind !== 'risk')
        ).length,
        nb_recommended_items: recommendedDiariesList.flatMap((rd) => rd.diaries)
          .length,
        bo_test_is_recommended: couldBeBurnOut,
      })
    }
  }, [result, recommendedDiariesList])

  const hasDiariesRecommendedList =
    recommendedDiariesList &&
    !!recommendedDiariesList.find(
      (recommendedDiaries: RecommendedDiaries) =>
        recommendedDiaries?.diaries?.length > 0
    )
  const { width } = useWindowDimensions()
  const refererWidth = 500
  // 180 = margin left and right | 500 = width + margin of a refererCard
  const numColumn = ~~((width - 180) / refererWidth)

  const company = useCompanyInfo()?.data
  const burnoutEnabled = !company?.disabled_burnout
  const theme = useTheme()
  const device = theme.device
  const linkTo = useNavigationLink()

  return (
    <>
      <MainTitleWrapper>
        <IconWrapper>
          <FontIcon
            name={'check-circle'}
            size={theme.sizes.XL}
            color={theme.colors.secondary}
          />
        </IconWrapper>
        <Title>{t('main-title-wellbeing')}</Title>
      </MainTitleWrapper>
      <OnDevice size={['small']}>
        <MainSeparator />
      </OnDevice>
      <CategoriesWrapper>
        <SubTitle>{title}</SubTitle>
        <CategoriesContainer>
          {categories.map((item) => {
            return (
              <ScoreCard
                device={device}
                score={item.score}
                title={item.name}
                onPress={() =>
                  trackEvent('clicked_wellbeing_results_category', {
                    category_id: item.uuid,
                    category_score: item.score.toFixed(0),
                    category_name: item.name,
                  })
                }
                key={item.uuid}>
                <HTMLView source={item.description} />
              </ScoreCard>
            )
          })}
        </CategoriesContainer>
        <SmallParagraph color='grey'>
          {t('main-medical-warning')}
        </SmallParagraph>
        <ButtonContainer>
          <LinkWithIcon
            label={t('see-test-result-details')}
            onPress={() => linkTo(`${HOST}/user/wellbeing_tracker`, true)}
            trackLabel='clicked_wellbeing_results_see_more'
            paddingSize='0px'
            IconPosition={'right'}
            iconColor='secondary'
            textColor='secondary'
            iconName={'arrow-right'}
            spacing={0}
            iconSize={22}
            style={{
              width: theme.device === 'large' ? '100%' : '67%',
              alignItems: 'flex-end',
            }}
          />
        </ButtonContainer>
      </CategoriesWrapper>
      <RecommendationWrapper
        colors={['#F4F5FD', '#FAFAFE', '#FFFFFF']}
        start={{ x: 0, y: 0.5 }}
        locations={[0, 0.5, 1]}>
        {final_score <= 60 && (
          <>
            <Title alignPosition='center' marginSize='0px 0px 0px 0px'>
              {t('recommanded-actions')}
            </Title>
            {!userInfo?.care_access && userInfo?.hotline && (
              <ImageAndInformationCardWrapper>
                <InformationCardWithButton
                  title={t('hotline-title')}
                  buttonLabel={t('hotline-button')}
                  description={t('hotline-description')}
                  onPress={() => linkTo(userInfo?.hotline?.link)}
                  trackLabel={'clicked_wellbeing_results_hotline'}
                  disclaimer={
                    i18n.language === 'fr' ? t('hotline-disclaimer') : undefined
                  }
                />
                <OnDevice size={['large', 'medium']}>
                  <WorkPlanImageWrapper>
                    <BurnoutChatImage source={burnout} resizeMode='contain' />
                  </WorkPlanImageWrapper>
                </OnDevice>
              </ImageAndInformationCardWrapper>
            )}
            {userInfo?.care_access && (
              <ImageAndInformationCardWrapper>
                <InformationCardWithButton
                  title={t('wellbeing-appointment-title')}
                  buttonLabel={t('appointment-action')}
                  trackLabel={'clicked_wellbeing_results_appointment'}
                  description={specialistContent}
                  onPress={() => navigation.push(SCREEN_PSYCHOLOGISTS)}
                />
                <OnDevice size={['large', 'medium']}>
                  <AppointmentImageContainer>
                    <AppointmentImage
                      source={appointmentPicture}
                      resizeMode='contain'
                    />
                  </AppointmentImageContainer>
                </OnDevice>
              </ImageAndInformationCardWrapper>
            )}
          </>
        )}
        {burnoutEnabled && final_score <= 40 && (
          <ImageAndInformationCardWrapper>
            <OnDevice size={['medium', 'large']}>
              <WorkPlanImageWrapper>
                <WorkPlanImage source={WorkPlan} resizeMode='contain' />
              </WorkPlanImageWrapper>
            </OnDevice>
            <InformationCardWithButton
              title={t('wellbeing-to-burnout-title')}
              buttonLabel={t('wellbeing-to-burnout-action')}
              description={t('wellbeing-to-burnout-text')}
              trackLabel={'clicked_wellbeing_results_burnout_test'}
              onPress={() =>
                navigation.push(SCREEN_ASSESSMENT, {
                  key: 'further_assessment',
                })
              }
            />
          </ImageAndInformationCardWrapper>
        )}
        {isLoading ? (
          <RecommandationsLoader />
        ) : (
          <RecommendationActionsContainer>
            {hasDiariesRecommendedList && (
              <>
                <SubTitle alignPosition='center'>
                  {t('recommendations-title')}
                </SubTitle>

                {recommendedDiariesList.map((item: RecommendedDiaries) => (
                  <RecommendationsDiariesWrapper>
                    <RecommendedResources
                      title={item.title}
                      trackLabel='clicked_wellbeing_results_rec_item'
                      trackParentProps={{
                        recommendation_type: item.title,
                      }}
                      data={item.diaries.map((diary: WellbeingDiaries) => ({
                        ...diary,
                        image: diary.icon,
                        sound: diary.audio?.url != null,
                        link: HOST + '/diaries/' + diary.uuid,
                        type: 'diaries',
                        sticker: { name: 'Fiche', key: 'diary' },
                      }))}
                    />
                  </RecommendationsDiariesWrapper>
                ))}
              </>
            )}
          </RecommendationActionsContainer>
        )}
      </RecommendationWrapper>

      {final_score <= 60 &&
        company != null &&
        company?.psy_referer?.length > 0 && (
          <CompanyRefererContainer>
            <SubTitle
              marginSize={
                theme.device !== 'large'
                  ? `0px 0px ${theme.spacings.L}px 0px`
                  : '0px 0px 25px 0px'
              }>
              {t('psy-referer-title', { company: company.name })}
            </SubTitle>
            <FlatList
              key={numColumn}
              numColumns={numColumn}
              ItemSeparatorComponent={() =>
                numColumn <= 1 ? <RefererSeparator /> : null
              }
              data={company?.psy_referer}
              renderItem={({ item, index }) => (
                <PsychologistRefererCard
                  name={item.name}
                  email={item.email}
                  phone={item.phone}
                  role={item.role}
                  index={index}
                  refererCount={company?.psy_referer?.length}
                  numColumn={numColumn}
                  itemWidth={refererWidth}
                  trackLabel={'clicked_wellbeing_results_representative'}
                />
              )}
            />
          </CompanyRefererContainer>
        )}
    </>
  )
}
