import * as React from 'react'
import { PageContent } from '@ui/Moodwork-UI/templates/Page'
import { useDashboard } from '@core/services/dashboard'
import SupportTemplate from '@ui/Moodwork-UI/templates/Support'
import { SCREEN_CONTACT } from '@core/constants'
import { FormHeading } from '@ui/Moodwork-UI/templates/Support/index.styles'
import { Text, SubTitle, Link, Line } from '@ui/Moodwork-UI'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Button } from '@ui/Moodwork-UI/molecules/pressables'
import { useNavigationLink } from '@core/lib/navigation'
import { SeparatorContainer } from '@ui/Moodwork-UI/organisms/Test/index.styles'
import { IS_NATIVE } from '@ui/utils'

export default function FAQ() {
  const { data, isSuccess } = useDashboard()
  const linkTo = useNavigationLink()
  const { t } = useTranslation('contact')

  const FAQData = useMemo(() => {
    return data?.endpoint_entries.filter((e) => e.destination == 'faq')
  }, [data])

  return (
    <PageContent>
      <SupportTemplate>
        {isSuccess && data && (
          <>
            {FAQData?.map(
              (e, i) =>
                e?.title &&
                e?.subtitle &&
                e?.link && (
                  <>
                    {i > 0 && (
                      <SeparatorContainer>
                        <Line height={1} color='separatorGrey' />
                      </SeparatorContainer>
                    )}
                    <FormHeading>
                      <SubTitle>{e?.title}</SubTitle>
                      <Text>{e?.subtitle}</Text>
                      <Link
                        url={SCREEN_CONTACT}
                        external={false}
                        trackLabel={'clicked_faq_contact'}
                        text={t('tabs.q-and-a.contact')}
                      />
                    </FormHeading>
                    <FormHeading>
                      <Button
                        label={t('tabs.q-and-a.read-more')}
                        onPress={() => linkTo(e?.link, true)}
                        compact
                        style={{ width: IS_NATIVE ? 167 : 136, marginTop: 36 }}
                      />
                    </FormHeading>
                  </>
                )
            )}
          </>
        )}
      </SupportTemplate>
    </PageContent>
  )
}
