import { Slider } from '@react-native-assets/slider'
import { Text } from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'

export const PlayPauseText = styled(Text)`
  width: 42px;
`

export const SliderComponent = styled(Slider)`
  flex: 1;
  margin-right: 8px;
`
export const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ theme }) =>
    theme.device === 'small' ? 'center' : 'flex-start'};
`

export const PlayButton = styled.Pressable`
  flex-direction: row;
  align-items: center;
`

export const Stop = styled.View`
  margin: 7px;
  width: 6px;
  height: 9px;
  border-right-width: 2px;
  border-right-color: ${({ theme }) => theme.colors.secondary};
  border-left-width: 2px;
  border-left-color:  ${({ theme }) => theme.colors.secondary};
`

export const Play = styled.View`
  margin: 7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 0;
  border-bottom-width: 4px;
  border-left-width: 7px;
  border-top-color:  ${({ theme }) => theme.colors.transparent};
  border-right-color: ${({ theme }) => theme.colors.transparent};
  border-bottom-color: ${({ theme }) => theme.colors.transparent};
  border-left-color:  ${({ theme }) => theme.colors.secondary};
`
export const PlayContainer = styled.View`
  margin-horizontal: 8px;
  border-color:  ${({ theme }) => theme.colors.secondary};
  border-width: 1px;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  align-content: center;
  justify-content: center;
`
