import React, { useCallback } from 'react'
import { useTheme } from '@ui/utils'
import { BigParagraph, Paragraph } from '@ui/Moodwork-UI/atoms'
import { EntrypointProps } from './index.types'
import { useNavigationLink } from '@core/lib/navigation'
import { Border, Container, Icon, TextContainer } from './index.style'
import { useToggle } from '@core/lib/hooks'
import { trackEvent } from '@core/lib/analytics'

export default function Entrypoint({
  title,
  subtitle,
  color,
  link,
  icon,
}: EntrypointProps) {
  const [isHover, onHoverIn, onHoverOut] = useToggle(false)
  const theme = useTheme()
  const linkTo = useNavigationLink()
  const navigate = useCallback(() => {
    trackEvent('clicked_db_entry_item', {
      url_redirection: link || 'no-link',
      item_name: title,
    }).then(() => {
      if (link) {
        linkTo(link)
      }
    })
  }, [link])

  return (
    <Container
      // @ts-ignore
      onMouseEnter={onHoverIn}
      // @ts-ignore
      onMouseLeave={onHoverOut}
      onPress={navigate}
      color={color}>
      <Border isHover={isHover}>
        <Icon resizeMode='contain' source={{ uri: icon?.url }} />
        <TextContainer>
          <BigParagraph
            weight={'semiBold'}
            alignPosition={theme.device !== 'large' ? 'center' : undefined}>
            {title}
          </BigParagraph>
          {theme.device === 'large' && <Paragraph>{subtitle}</Paragraph>}
        </TextContainer>
      </Border>
    </Container>
  )
}
