import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    height: '100%',
    borderRadius: 24 / 2,
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderWidth: 4,
    position: 'absolute',
  },
})
