import styled, { css } from 'styled-components/native'
import { IconButton } from '../IconButton'
import { themeColor } from '../utils/styled'
import { SafeAreaView } from 'react-native-safe-area-context'
import { DeviceSizeType, IS_BROWSER } from '../utils/device'
import {
  LARGE_HEADER_SIZE,
  MEDIUM_SMALL_HEADER_SIZE,
  LARGE_HEADER_REDUCED_SIZE,
} from './Navigation'

const web = css`
  box-shadow: 0 -2px 13px rgb(51, 51, 51);
  transition: all 0.15s ease-in-out;
`

export const getHeaderHeight = (device: DeviceSizeType, big: boolean) =>
  device !== 'large'
    ? MEDIUM_SMALL_HEADER_SIZE
    : big
    ? LARGE_HEADER_SIZE
    : LARGE_HEADER_REDUCED_SIZE

export const Container = styled.View.attrs({
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,
  elevation: 5,
})<{ big: boolean }>`
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ theme, big }) => getHeaderHeight(theme.device, big)};
  background: white;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  ${IS_BROWSER && web};
  padding: ${({ theme }) => (theme.device === 'large' ? '0 30px' : '0 16px')};
`

export const Icon = styled(IconButton)`
  color: ${themeColor('secondary')};
`

export const Column = styled.View`
  flex-direction: column;
  width: 100%;
`

export const RowLarge = styled.View`
flex-direction: row;
height: 100%
align-items: center;
z-index: 2;
`

export const Row = styled.View`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: space-between;
  z-index: 2;
`

export const RightColumn = styled.View`
  z-index: 99999;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  height: 100%;
  align-items: center;
`

export const SearchBarContainer = styled.View`
  height: 40px;
  ${({ theme }) =>
    theme.device === 'large' &&
    css`
      width: 288px;
      margin-left: 16px;
      margin-right: 18px;
  `}
  ${({ theme }) =>
    theme.device != 'large' &&
    css`
      width: 100%;
      margin-right: 20px;
      margin-top: ${({ theme }) => `${theme.spacings.L}px`};
  `}
`

export const HotlineIconContainer = styled.View`
  width: 26px;
  border-width: 2;
  border-radius: 50%;
  padding: 3px;
  margin-right: 4px;
  border-color: ${({ theme }) => theme.colors.red}
`
