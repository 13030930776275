import React, { useCallback, useEffect, useState } from 'react'
import WellbeingGraph from '@ui/Moodwork-UI/organisms/WellbeingGraph'
import {
  filterCategories,
  HistoryFactors,
} from '@ui/Moodwork-UI/organisms/HistoryFactors'
import { PageContent } from '@ui/Moodwork-UI/templates/Page'
import { useResults, useResultsHistory } from '../../services/results'
import { trackEvent } from '@core/lib/analytics'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { IS_NATIVE } from '@ui/utils'
import { useTranslation } from 'react-i18next'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'

export default function WellbeingTracking() {
  const [selectedUserTest, setSelectedUserTest] = useState<string>()
  const navigation = useNavigation()
  const [alreadySent, setAlreadySent] = useState(false)
  const { data } = useResults(
    selectedUserTest,
    false
  )
  const { data: historyData, remove, isFetching } = useResultsHistory()
  const { t } = useTranslation(['resources'])

  useEffect(() => {
    if (data && historyData && !alreadySent) {
      trackEvent('viewed_tracker_screen', {
        nb_tests_taken: historyData.length,
        test_uuid: data.uuid,
        test_date: data.finish_at,
        test_score: data.result.final_score,
        wellbeing_level: data.result.risk_level_name,
        nb_main_negative_factors: filterCategories(
          data.result.categories,
          'risk'
        ).length,
        nb_main_positive_factors: filterCategories(
          data.result.categories,
          'protection'
        ).length,
        nb_categories: data.result.categories.length,
        category_id: data.result.categories[0].uuid,
        category_name: data.result.categories[0].name,
        category_rank: 1,
        category_score: Math.round(data.result.categories[0].score),
        category_nb_negative_factors:
          data.result.categories[0].factors.filter((f) => f.kind === 'risk')
            .length ?? 0,
        category_nb_positive_factors:
          data.result.categories[0].factors?.filter(
            (f) => f.kind === 'protection'
          ).length ?? 0,
      })
      setAlreadySent(true)
    }
  }, [data, historyData])

  useFocusEffect(
    useCallback(() => {
      return () => {
        remove()
        setSelectedUserTest(undefined)
      }
    }, [remove])
  )

  /* TODO:
     handle error
  */

  return (
    <PageContent>
      {IS_NATIVE && <BackButton navigation={navigation} />}
      <WellbeingGraph
        data={historyData}
        selected={selectedUserTest}
        setSelected={setSelectedUserTest}
        isFetching={isFetching}
      />
      <HistoryFactors
        finishAt={data?.finish_at}
        id={data?.uuid}
        data={data?.result}
      />
    </PageContent>
  )
}
