import { BigParagraph } from '@ui/Moodwork-UI/atoms'
import { TextInput } from '@ui/TextInput'
import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`

export const Content = styled.View`
  width: 100%;
  max-width: 1200px;
  padding: 40px 16px;
  align-self: center;
  flex-shrink: 0;
`

export const Title = styled(BigParagraph)`
  margin-bottom: 8px;
`

export const CommentInput = styled(TextInput)`
  width: 100%;
  max-width: 997px;
  margin-right: 16px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`
