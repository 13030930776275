import React from 'react'
import image from '@assets/searchImage.png'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import { FlexCenter, FlexRow } from '@ui/Moodwork-UI/templates'
import { SubTitle, Text } from '@ui/Moodwork-UI/atoms'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_SEARCH } from '@core/constants'
import { Bookmark, Container, Image } from './index.styles'
import { trackEvent } from '@core/lib/analytics'
import { useToggle } from '@core/lib/hooks'

export default function EmptySearch({
  search,
  short,
  favorites,
  eventComponentName,
}: {
  search?: string | undefined
  short: boolean
  favorites: boolean
  eventComponentName: string
}) {
  const { t } = useTranslation('search')
  const navigation = useNavigation()
  const [bookmark, a, b, toogleBookmark] = useToggle(true)

  const withSearchText = search?.length > 0
  const displayHint = withSearchText && !short && !favorites

  const noResultSearchHint = [
    t('searchHint1'),
    t('searchHint2'),
    t('searchHint3'),
  ]

  return (
    <Container>
      <FlexCenter>
        {displayHint && <Image source={image} />}
        {favorites && !withSearchText ? (
          <FlexRow>
            <Bookmark favorite={bookmark} onFavorite={toogleBookmark} />
            <Text size='M' weight='bold'>
              {t('emptyFavorites')}
            </Text>
          </FlexRow>
        ) : (
          <Text size='M'>
            {t('noResult')}
            {withSearchText && [
              ' “',
              <Text size='M' weight='bold'>
                {search}
              </Text>,
              '”',
            ]}
          </Text>
        )}
      </FlexCenter>
      {displayHint && (
        <FlexCenter>
          <Text marginSize='0 0 24px 0' size='M'>
            {t('retry')}
          </Text>
          {noResultSearchHint.map((hint) => (
            <Pressable
              onPress={() => {
                trackEvent(`clicked_${eventComponentName}_suggested_query`, {
                  search_query: search,
                  suggested_search_query: hint,
                }).then(() => navigation.push(SCREEN_SEARCH, { q: hint }))
              }}>
              <SubTitle marginSize={'4px 0'} color={'secondary'}>
                {hint}
              </SubTitle>
            </Pressable>
          ))}
        </FlexCenter>
      )}
    </Container>
  )
}
