import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'
import { Platform } from 'react-native'
import {
  AUTH_CLIENT_ID,
  AUTH_CLIENT_SECRET,
  HOST,
  LOCALE_KEY,
  TIMEZONE_KEY,
} from '../../constants'
import { fetchAuthTokens } from '../auth'
import { IS_BROWSER, IS_NATIVE } from '../../../ui/utils/device'
import * as Sentry from 'sentry-expo'
import { getStoredItem } from '../storage'
import { merge } from 'lodash'
import moment from 'moment-timezone'

type RefreshTokenCallback = (
  accessToken: string,
  refreshToken: string,
  tokenExpiration: string
) => void

export interface AxiosError {
  response?: {
    status: number
  }
  config: AxiosRequestConfig & { _retry?: boolean }
  instance: AxiosInstance
}

const basicConfig = {
  baseURL: HOST,
  // TODO: reduce timeout when backend respond more quickly
  timeout: 40000,
  headers: {
    Source: IS_BROWSER ? 'web' : 'mobile',
    OS: Platform.OS,
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}

const locationConfig = {
  headers: {
    'Accept-Language': getStoredItem(LOCALE_KEY),
    'Time-Zone':
      getStoredItem(TIMEZONE_KEY) ?? moment.tz.guess() ?? 'Europe/Paris',
  },
}

export async function refreshToken() {
  const { refreshToken: token } = await fetchAuthTokens()

  const data = stringify({
    grant_type: 'refresh_token',
    client_id: AUTH_CLIENT_ID,
    client_secret: AUTH_CLIENT_SECRET,
    scope: 'openid profile',
    refresh_token: token,
  })

  const config: AxiosRequestConfig = {
    method: 'post',
    url: `${HOST}/oauth/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  }

  return axios(config)
}

async function refresthTokenAndSave(onRefreshToken: RefreshTokenCallback) {
  const refreshResponse = await refreshToken()
  const access = refreshResponse?.data?.access_token || null
  const refresh = refreshResponse?.data?.refresh_token || null
  const tokenExpiration =
    refreshResponse?.data?.created_at + refreshResponse?.data?.expires_in ||
    null
  onRefreshToken(access, refresh, tokenExpiration)
  return access
}

export function shouldSendToken(url?: string) {
  return (
    url &&
    (url.includes('userinfo') ||
      (!url.includes('oauth') && !url.includes('sso_redirect_url')) ||
      url.includes('sign_up') ||
      url.includes('password'))
  )
}

function setAuthorization(
  onRefreshToken: RefreshTokenCallback,
  onSignOut: () => void
) {
  return async (config: AxiosRequestConfig) => {
    let { accessToken, refreshToken, tokenExpiration } = await fetchAuthTokens()
    if (!accessToken && !refreshToken) {
      onSignOut()
    }

    try {
      if (shouldSendToken(config.url)) {
        if (
          !accessToken ||
          !tokenExpiration ||
          parseInt(tokenExpiration) > Date.now()
        ) {
          accessToken = await refresthTokenAndSave(onRefreshToken)
          if (!accessToken) {
            onSignOut()
          }
        }
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
    } catch (e) {
      IS_NATIVE
        ? Sentry.Native.captureException(e)
        : Sentry.Browser.captureException(e)
    } finally {
      return config
    }
  }
}

export function createHttpClient({
  onRefreshToken,
  onError,
  onSignOut,
}: {
  onRefreshToken: RefreshTokenCallback
  onError: (p: AxiosError) => void
  onSignOut?: () => void
}) {
  let axiosConfig = basicConfig
  if (!__DEV__ && !IS_NATIVE) {
    axiosConfig = merge(locationConfig, axiosConfig)
  }
  const instance = axios.create(axiosConfig)
  /**
   * If we are not in the process of actually
   * getting a token, we should set the authorization
   * header.
   */
  instance.interceptors.request.use(
    setAuthorization(onRefreshToken, onSignOut),
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use(undefined, (params) =>
    onError({ ...params, instance })
  )

  return instance
}
