import { useEffect, useContext } from "react"
import { useReadyContext, ReadyContext } from "./ReadyContentProvider"


export const useSyncReady = (namespace: string, key: string, isReady: boolean): boolean => {
  const [heap, setReady] = useContext(ReadyContext)
  useEffect(() => {
      setReady(namespace, key, isReady)
  }, [isReady])
  return isReady && !!heap && (!heap[namespace] || !!heap[namespace] && Object.values(heap[namespace]).every((ready) => ready))
}