import styled from 'styled-components/native'
import { PAGE_HORIZONTAL_MARGINS } from '@core/constants'
import { themeColor } from '../utils/styled'
import { ContainerProps } from './ChatBanner.types'
import { Text } from '@moodwork-ui'

export const Container = styled.View<ContainerProps>`
  background-color: ${themeColor('secondary')};
  align-items: center;
  flex-direction: row;
  max-width: ${({ width }) => width - PAGE_HORIZONTAL_MARGINS * 2}px;
  padding: 16px 8px;
  border-radius: 8px;
  margin-bottom: 16px;
`

export const IconContainer = styled.View`
  width: 32px;
  margin-left: 8px;
`
export const ChatIcon = styled.Image<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 0 16px 0 8px;
`

export const TextContainer = styled.View`
  flex: 1;
`

export const Title = styled(Text)`
  margin-bottom: 4px;
`

export const Content = styled(Text)``
