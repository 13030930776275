import styled from 'styled-components/native'

export const Container = styled.Pressable`
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 8px;
  max-width: 100%;
`

export const Box = styled.View`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
`
