import { useHttp } from './http'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'
import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { Company } from '../types/company'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

/**
 * Update User attributes, language for exemple
 */
export function useSetUser(): [number | undefined, (params: {}) => void] {
  const http = useHttp(useNavigation())
  const [data, setData] = useState<number>()
  const callApi = (params: {}) => {
    http
      .put('/api/v1/user', params, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((result) => setData(result?.status))
  }
  return [data, callApi]
}

export function useCompanyInfo(): UseQueryResult<Company, AxiosError> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getCompany = () =>
    http.get('/api/v1/company/info').then((result) => result?.data)
  return useQuery(['company', i18n.language], getCompany, {
    retry: 1,
  })
}
