import React from 'react'
import { Pressable } from 'react-native'
import { useDevice } from '@utils/device'
import { BullContainer, BullRow, Bull } from './BullList.style'
import { BullListProps } from './BullList.types'
import { trackEvent } from '@core/lib/analytics'

export default function BullList({
  activeStep,
  setCurrentStep,
  numberOfSteps,
  analyticsEventName,
}: BullListProps) {
  const device = useDevice()

  return (
    <BullContainer device={device}>
      <BullRow>
        {new Array(numberOfSteps).fill(0).map((_, i) => (
          <Pressable
            key={'bull-list-' + i}
            onPress={() => {
              trackEvent(analyticsEventName, {
                popin_step: activeStep + 1,
                target_popin_step: i + 1,
              }).then(() => setCurrentStep(i))
            }}>
            <Bull active={activeStep === i}>&bull;</Bull>
          </Pressable>
        ))}
      </BullRow>
    </BullContainer>
  )
}
