import React, { useMemo, useCallback, useRef } from 'react'
import {
  ImageContainer,
  Image,
  Heading,
  HtmlContainer,
  ContentContainer,
  InTitle,
  PrefixedContainer,
  VideoContainer,
  Video,
} from './ResourceBlock.styles'
import { ResourceBlocksProps } from './interface'
import {
  FlexColumn,
  HTMLView,
  PrefixedContent,
  SectionTitle,
} from '@ui/Moodwork-UI'
import { useTheme } from '@ui/utils'
import { ImageContent } from '@ui/Moodwork-UI/molecules/ImageContent/ImageContent'

export const ResourceTitleLine = ({
  title,
  quote,
}: {
  title: string
  quote?: string
}) => {
  return (
    <Heading>
      <PrefixedContent prefix='line'>
        <SectionTitle>
          {title}
          {!!quote && <SectionTitle weight='regular'> “{quote}” </SectionTitle>}
        </SectionTitle>
      </PrefixedContent>
    </Heading>
  )
}

export const ResourceBlock = ({
  title,
  imageUrl,
  imagePosition,
  imageDimension,
  background = 'transparent',
  content,
  contentWidth,
  prefix,
  video,
  bottomMargin,
  smallText = false,
  trackableParams,
}: ResourceBlocksProps) => {
  const theme = useTheme()
  const textColor = useMemo(() => {
    if (background === 'dark') {
      return theme.colors.white
    }
  }, [background])

  const renderContent = useMemo(() => {
    return (
      <HTMLView
        source={content || ''}
        textColor={textColor}
        contentWidth={contentWidth}
        big
        small={smallText}
      />
    )
  }, [content, textColor, theme.device, contentWidth])

  const HtmlComponent = useCallback(
    () => (
      <HtmlContainer background={background} bottomMargin={bottomMargin}>
        {background != 'transparent' && (
          <InTitle color={background == 'dark' ? 'white' : 'black'}>
            {title}
          </InTitle>
        )}
        {renderContent}
      </HtmlContainer>
    ),
    [background, renderContent, title]
  )

  return (
    <FlexColumn>
      {title && background === 'transparent' && (
        <ResourceTitleLine title={title} />
      )}
      {imageUrl && imagePosition ? (
        <HtmlContainer>
          <ImageContent imageUrl={imageUrl} rounded>
            {renderContent}
          </ImageContent>
        </HtmlContainer>
      ) : (
        <>
          {video && (
            <VideoContainer>
              <Video
                source={{ uri: video.url }}
                resizeMode='contain'
                useNativeControls
                trackableParams={trackableParams}
              />
            </VideoContainer>
          )}
          {imageUrl && (
            <ImageContainer>
              <Image source={{ uri: imageUrl }} resizeMode='contain' />
            </ImageContainer>
          )}
          {content &&
            (prefix ? (
              <PrefixedContainer prefix={prefix}>
                <ContentContainer>{renderContent}</ContentContainer>
              </PrefixedContainer>
            ) : (
              <HtmlComponent />
            ))}
        </>
      )}
    </FlexColumn>
  )
}
