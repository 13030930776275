import {
  ProfileQuestion,
  RegistrationQuestion,
  ResponsesPayload,
} from '@core/types/profile'
import { AxiosError } from 'axios'
import { UseQueryResult, useMutation, useQuery } from 'react-query'
import { useHttp } from './http'
import { useTranslation } from 'react-i18next'

export function useProfileQuestion(): UseQueryResult<
  ProfileQuestion[],
  AxiosError
> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getProfileQuestions = () =>
    http.get('/api/v3/profile_questions').then((result) => result?.data)
  return useQuery(['profile-questions', i18n.language], getProfileQuestions, {
    retry: 1,
  })
}

export function useProfileResponses() {
  const http = useHttp()
  const updateProfileResponses = (responses: ResponsesPayload) => {
    return http.post(
      '/api/v3/user_profile_responses',
      { user_profile_responses: responses },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
  }
  return useMutation(updateProfileResponses, { retry: 1 })
}

export function useRegistrationQuestion(): UseQueryResult<
  RegistrationQuestion[],
  AxiosError
> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getRegistrationQuestions = () =>
    http.get('/api/v3/registration_questions').then((result) => result?.data)
  return useQuery(
    ['registration-questions', i18n.language],
    getRegistrationQuestions,
    {
      retry: 1,
    }
  )
}

export function useRegistrationResponses() {
  const http = useHttp()
  const updateRegistrationResponses = (responses: ResponsesPayload) => {
    return http.post(
      '/api/v3/user_registration_responses',
      { user_registration_responses: responses },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
  }
  return useMutation(updateRegistrationResponses, { retry: 1 })
}
