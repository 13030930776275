import React, { useEffect } from 'react'
import { SCREEN_RESOURCE, SCREEN_SIGNIN } from '@core/constants'
import { useWebinar } from '@core/services/webinars'
import { useNavigation, useRoute } from '@react-navigation/native'
import Webinar from '@ui/Moodwork-UI/organisms/Webinar'

export default function Webinars() {
  const route = useRoute()
  const params = route.params
  const { data, isLoading, error, isSuccess } = useWebinar(params.id)
  const navigation = useNavigation()

  useEffect(() => {
    if (data && data?.is_diary && data?.diary_id) {
      navigation.push(SCREEN_RESOURCE, { id: data.diary_id })
    }
  }, [data])

  useEffect(() => {
    if (!isLoading && error) {
      navigation.push(SCREEN_SIGNIN)
    }
  }, [error])

  return isSuccess && data ? <Webinar data={data} /> : <></>
}
