import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FontIcon, Text, Title } from '@ui/Moodwork-UI/atoms'
import AudioComponent from '../AudioComponent'
import { HeaderTopContext } from '@ui/Page/Page'
import { useTheme } from '@ui/utils'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  ButtonContainer,
  Container,
  TitleContainer,
  Bookmark,
} from './index.styles'
import { Resource } from '@types/diary'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@core/lib/analytics'
import { goBack } from '@core/lib/navigation'
import { Pressable, View } from 'react-native'
import { BookmarkButton } from '@ui/Moodwork-UI/molecules/pressables/BookmarkButton'
import shareIcon from '../../../../../assets/images/ic_share.png'
import { ShareImage } from '@ui/Moodwork-UI/molecules/Resource/FooterInformations/index.styles'
import { ViewProps } from 'react-native'

export default function ResourceSubHeader({
  resource,
  favorite,
  onFavorite,
  onShare,
  ...props
}: {
  resource: Resource
  favorite: boolean
  onFavorite: (value: boolean) => void
  onShare: () => void
} & ViewProps) {
  const headerIsTop = useContext(HeaderTopContext)
  const [isPlaying, setIsPlaying] = useState(false)
  const theme = useTheme()
  const navigation = useNavigation()
  const { t } = useTranslation(['resources'])
  const [audioHasPlaying, setAudioHasPlaying] = useState(false)
  const route = useRoute()
  const params = route.params

  useEffect(() => {
    if (isPlaying) {
      setAudioHasPlaying(true)
    }
  }, [isPlaying])

  const handleBackButton = useCallback(() => {
    trackEvent('clicked_resource_go_back', {
      uuid: resource.uuid,
      title: resource.title,
      theme: resource.theme?.title,
      total_audio_duration: resource.audio?.duration ?? 0,
    }).then(() => goBack(navigation))
  }, [navigation])

  const shouldNotDisplay =
    !headerIsTop && !isPlaying && !audioHasPlaying && theme.device !== 'small'

  return (
    <View style={{ display: shouldNotDisplay ? 'none' : undefined }}>
      {theme.device === 'small' && (
        <ButtonContainer onPress={handleBackButton} reduce={!headerIsTop}>
          <FontIcon
            size={19}
            name='arrow-left'
            color={theme.colors.secondary}
          />
        </ButtonContainer>
      )}
      <Container reduce={!headerIsTop} {...props}>
        {((headerIsTop && theme.device !== 'small') ||
          theme.device === 'small') && (
          <TitleContainer>
            <Title marginSize={'0 0 8px 0'}>{resource.title}</Title>
            <Bookmark>
              <BookmarkButton favorite={favorite} onFavorite={onFavorite} />
              <Pressable onPress={onShare}>
                <ShareImage source={shareIcon} />
              </Pressable>
            </Bookmark>
          </TitleContainer>
        )}
        {headerIsTop && resource.duration && (
          <Text
            alignPosition={theme.device === 'small' ? 'center' : 'left'}
            size='XXS'
            marginSize='0 0 16px 0'>
            {t('resources.subHeader.readingTime', {
              count: resource.duration,
            })}
          </Text>
        )}
        {resource.audio?.url && (
          <AudioComponent
            setIsPlaying={setIsPlaying}
            duration={resource.audio?.duration}
            uri={resource.audio?.url}
            trackableParams={{
              collection_uuid: params?.collection,
              uuid: resource.uuid,
              title: resource.title,
              theme: resource.theme?.title,
            }}
          />
        )}
      </Container>
    </View>
  )
}
