import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ContactFormProps } from './index.types'
import {
  MessageTextArea,
  SubjectInput,
  styles,
  SubmitButton,
} from './index.styles'
import { useTranslation } from 'react-i18next'
import SelectPicker from '@ui/Moodwork-UI/atoms/SelectPicker/SelectPicker'
import { useContactForm } from '@core/services/contact'
import { AlertContext } from '@core/components/Alerthandler'
import { useCompanyInfo } from '@core/services/users'
import { HTMLView } from '@ui/Moodwork-UI/atoms'
import { htmlStyles } from '@ui/AppointmentInfo/disclaimerHTMLView.style'

export default function ContactTemplate({ categories }: ContactFormProps) {
  const { t } = useTranslation('contact')
  const [subject, setSubject] = useState<string | undefined>()
  const [category, setCategory] = useState<number | null>(
    categories.length > 0 ? categories[0]?.id : null
  )
  const [body, setBody] = useState<string | undefined>()
  const requestSupport = useContactForm()
  const { setMessage, setType, setOffsetY } = useContext(AlertContext)
  const { data } = useCompanyInfo()

  useEffect(() => {
    setOffsetY(80)
  }, [])

  const pickerOptions = useMemo(() => {
    return categories
      .sort((a, b) => a.priority - b.priority)
      .map((category) => {
        return {
          value: category.id,
          name: category.title,
        }
      })
      .filter((category) => {
        return category.name && category.value
      })
  }, [categories])

  const handleSubmit = useCallback(() => {
    if (subject && body) {
      let payload = {
        subject: subject,
        message: body,
      }
      requestSupport.mutate(
        Object.assign(
          payload,
          category ? { contact_category_id: category } : {}
        )
      )
      return
    }
    setType('warning')
    setMessage(t('validation.empty'))
  }, [subject, category, body, requestSupport])

  useEffect(() => {
    if (requestSupport.isError) {
      setType('warning')
      setMessage(t('validation.error'))
    }
  }, [requestSupport.isError])

  useEffect(() => {
    if (requestSupport.isSuccess) {
      setType('success')
      setMessage(t('validation.success'))
      setSubject('')
      setBody('')
      setCategory(null)
    }
  }, [requestSupport.isSuccess])

  return (
    <>
      {pickerOptions.length > 0 && (
        <SelectPicker
          label={t('form.category-label')}
          onChange={setCategory}
          value={category}
          pickerStyle={[styles.input, styles.inputTextStyle]}
          hoverStyle={styles.hoveredInputStyle}
          itemStyle={[styles.pickerItemStyle, styles.inputTextStyle]}
          options={pickerOptions}
        />
      )}
      <SubjectInput
        label={t('form.subject-label')}
        value={subject}
        onChange={setSubject}
        hoverStyle={styles.hoveredInputStyle}
        inputStyle={[styles.input, styles.inputTextStyle]}
      />
      <MessageTextArea
        label={t('form.message-label')}
        onChange={setBody}
        value={body}
        multiline={true}
        numberOfLines={10}
        hoverStyle={styles.hoveredInputStyle}
        inputStyle={[styles.input, styles.textAreaStyle]}
      />
      <SubmitButton
        compact
        onPress={handleSubmit}
        label={t('form.submit')}
        trackLabel='clicked_contact_send'
        trackProperties={{
          subject_type: categories.find((c) => c.id == category)?.title,
        }}
      />
      {data?.rgpd?.display_disclaimer && data?.rgpd?.disclaimer && (
        <HTMLView source={data.rgpd.disclaimer} customHtmlStyles={htmlStyles} />
      )}
    </>
  )
}
