import AlertHandler from '@core/components/Alerthandler'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import ConfirmationModalContent from '@ui/Moodwork-UI/molecules/Dashboard/ConfirmationModalContent'
import React from 'react'

export default function ConfirmationModal() {
  return (
    <NavigationModal isClosable={false}>
      <AlertHandler>
        <ConfirmationModalContent />
      </AlertHandler>
    </NavigationModal>
  )
}
