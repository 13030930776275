import { trackEvent } from '@core/lib/analytics'
import { SearchNavigation } from '@core/lib/types'
import { Sticker } from '@core/types/search'
import { useRoute } from '@react-navigation/native'
import { FontIcon, Text } from '@ui/Moodwork-UI/atoms'
import { toggleKeyList, useTheme } from '@ui/utils'
import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../pressables/Checkbox'

import FilterButton from '../FilterButton'
import {
  Button,
  Container,
  FilterContainer,
  SearchTitle,
  TextContainer,
} from './index.styles'
import { SearchFilterProps } from './index.types'
import { SCREEN_SEARCH } from '@core/constants'

export default function SearchFilter({
  numberOfSearch,
  search,
  themes,
  activeThemes,
  setActiveThemes,
  stickers,
  activeStickers,
  setActiveStickers,
  activeSpecialFilters,
  setActiveSpecialFilters,
  eventComponentName,
}: SearchFilterProps) {
  const theme = useTheme()
  const route = useRoute<SearchNavigation>()
  const params = route.params
  const { t } = useTranslation(['search', 'header'])

  const onThemePress = useCallback((id: number) => {
    return setActiveThemes(toggleKeyList(id))
  }, [])

  const onStickerPress = useCallback((stickerKey: string) => {
    return setActiveStickers(toggleKeyList(stickerKey))
  }, [])

  const onSpecialFilterPress = useCallback((key: string) => {
    return setActiveSpecialFilters(toggleKeyList(key))
  }, [])

  const renderItem = useCallback(
    (theme) => {
      return theme?.map((t) => (
        <FilterButton
          key={t.id}
          element={t.id}
          title={t.title}
          onThemePress={onThemePress}
          isActive={activeThemes.includes(t.id)}
          numberOfSearch={numberOfSearch}
          eventName={`${eventComponentName}_filter_theme`}
          propertyName={'filter_theme'}
        />
      ))
    },
    [activeThemes]
  )

  const renderStickers = useCallback(
    (stickers) => {
      return stickers?.map(
        (sticker: Sticker) =>
          sticker?.key &&
          sticker?.name?.length > 0 && (
            <FilterButton
              key={`sticker-${sticker.key}`}
              element={sticker.key}
              title={sticker.name}
              onThemePress={onStickerPress}
              isActive={activeStickers.includes(sticker.key)}
              numberOfSearch={numberOfSearch}
              eventName={`${eventComponentName}_filter_type`}
              propertyName={'filter_resource_type'}
            />
          )
      )
    },
    [activeStickers]
  )

  const renderSpecialFilters = useCallback(() => {
    return (
      <Checkbox
        element='favorites'
        text='Mes favoris'
        isActive={activeSpecialFilters?.includes('favorites')}
        onPress={onSpecialFilterPress}
        eventName={eventComponentName}
      />
    )
  }, [activeSpecialFilters])

  let eventProperties = { search_query: params?.q }
  if (eventComponentName === 'search_results') {
    eventProperties = { ...eventProperties, nb_search_results: numberOfSearch }
  }

  const resetSearch = useCallback(() => {
    trackEvent(
      `clicked_${eventComponentName}_reset_filters`,
      eventProperties
    ).then()
    setActiveStickers([])
    setActiveThemes([])
    setActiveSpecialFilters([])
  }, [])

  return (
    <Container>
      <TextContainer>
        {search?.length ? (
          <SearchTitle weight='regular'>
            {t('result', { count: numberOfSearch }) + ' “'}
            <SearchTitle>{search}</SearchTitle>
            {'”'}
          </SearchTitle>
        ) : (
          <SearchTitle>{t('header.library', { ns: 'header' })}</SearchTitle>
        )}
        {(!isEmpty(activeThemes) ||
          !isEmpty(activeStickers) ||
          !isEmpty(activeSpecialFilters)) && (
          <Button onPress={resetSearch}>
            <FontIcon
              name='cancel'
              size={14}
              color={theme.colors.link}
              style={{ marginRight: 8 }}
            />
            <Text color={'link'}>{t('reset')}</Text>
          </Button>
        )}
      </TextContainer>
      <Text weight='bold'>{t('themes')}</Text>
      <FilterContainer>{renderItem(themes)}</FilterContainer>
      <Text marginSize={'24px 0 0 0'} weight='bold'>
        {t('type')}
      </Text>
      <FilterContainer>{renderStickers(stickers)}</FilterContainer>
      <Text marginSize={'24px 0 8px 0'} weight='bold'>
        {t('filter')}
      </Text>
      <FilterContainer>{renderSpecialFilters()}</FilterContainer>
    </Container>
  )
}
