import * as React from 'react'
import Article from '../Article/Article'
import { Spacer } from '../Spacer'
import { TitleSurTitle } from '../TitleSurTitle'
import { ContentsMap, DiaryGroup } from '@core/types/diary'
import { Container } from './DiaryGroup.style'
import { View } from 'react-native'

export default function DiaryGroupList({
  group,
  contents,
}: {
  group: DiaryGroup
  contents: ContentsMap
}) {
  return (
    <Container>
      <Spacer size={32} />
      <TitleSurTitle surTitle='Plus de contenu'>{group.title}</TitleSurTitle>
      <Spacer size={32} />
      {group.content.slice(0, 3).map((content) => (
        <Article
          key={content.uuid}
          title={contents[content.uuid]?.[0]?.title || 'Error'}
          content={contents[content.uuid]?.[0]?.problematic || 'Error'}
          details={contents[content.uuid]?.[0]?.theme.title}
          image={contents[content.uuid]?.[0]?.icon}
        />
      ))}
    </Container>
  )
}
