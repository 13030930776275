import React from 'react'
import { GridView } from '@ui/Moodwork-UI/templates/GridWiew'
import { useNavigationLink } from '@core/lib/navigation'
import { ResourceCard } from '@ui/Moodwork-UI/molecules/pressables/ResourceCard'
import { getResourcesCardHeight } from '@ui/Moodwork-UI/molecules/pressables/ResourceCard/ResourceCard'
import { ResourcePreview } from '@core/types/diary'
import { ResourceCardLayoutProps } from '@ui/Moodwork-UI/molecules/pressables/ResourceCard/ResourceCard.types'
import { HOST, SCREEN_COLLECTION, SCREEN_RESOURCE } from '@core/constants'
import { useFavorites } from '@core/services/resources'
import { useNavigation } from '@react-navigation/native'
import { GestureResponderEvent } from 'react-native'

export const ResourceGrid = ({
  listMode,
  itemWidth,
  listHeight,
  resources,
  collection,
  trackLabel,
  cardLayout,
  collectionLayout,
  trackParentProps,
}: {
  listMode?: boolean
  itemWidth: string
  listHeight: string
  resources: ResourcePreview[]
  collection: string
  cardLayout?: ResourceCardLayoutProps
  collectionLayout?: ResourceCardLayoutProps
  trackLabel: string
  trackParentProps?: {
    uuid?: string
    title?: string
    theme?: string
    search_query?: string
  }
}) => {
  const navigation = useNavigation()
  const linkTo = useNavigationLink()
  const { mutate, data: favorites } = useFavorites()

  const renderItem = React.useCallback(
    (item: ResourcePreview, index: number) => {
      if (!item) {
        return null
      }
      let collectionProps
      if (!!item.collection_count) {
        collectionProps = collectionLayout
      }

      const isFavorite =
        favorites != null && favorites[item.uuid] !== undefined
          ? favorites[item.uuid]
          : item.favorite

      const link = (event: GestureResponderEvent) => {
        // Check if Cmd or Ctrl key is pressed (depends on system)
        if (event.metaKey || event.ctrlKey) {
          linkTo(item.link, true)
        } else if (item.type === 'diary_groups') {
          navigation.push(SCREEN_COLLECTION, {
            id: item.uuid,
          })
        } else if (item.type === 'diaries') {
          navigation.push(SCREEN_RESOURCE, {
            id: item.uuid,
            collection: collection,
          })
        } else {
          linkTo(item.link)
        }
      }
      return (
        <ResourceCard
          uuid={item.uuid}
          type={item.type}
          key={item.title}
          title={item.title}
          tag={item.sticker?.name}
          tagKey={item.sticker?.key}
          duration={item.duration}
          image={item.image?.optimized_url || item.image?.url || item.icon?.url}
          sound={item.sound || item.audio?.url != null}
          onCardPress={link}
          onFavorite={mutate}
          favorite={isFavorite}
          link={
            (item.link || `${HOST}/diaries/${item.uuid}`) +
            `?collection=${collection || undefined}`
          }
          bottomElement={item.bottom}
          count={item.collection_count}
          trackLabel={trackLabel}
          first={index === 0}
          last={index === resources.length - 1}
          state={item?.state}
          {...cardLayout}
          {...collectionProps}
          trackProperties={{
            ...trackParentProps,
            item_name: item.title,
            item_tag: item.sticker?.name,
            url_redirection: item.link,
            is_already_viewed: item.state?.status === 'done',
            is_favorited: item.favorite,
          }}
        />
      )
    },
    [favorites, collection]
  )

  if (resources?.length > 0) {
    const itemHeight = getResourcesCardHeight(resources)
    return (
      <GridView
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        listHeight={listHeight}
        listMode={listMode}
        items={resources}
        renderItem={renderItem}
      />
    )
  }
  // TODO: add skeleton

  return null
}
