import styled from 'styled-components/native'
import { TextInput } from '@ui/TextInput'

export const Container = styled.View`
  min-height: 100%;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
`

export const Content = styled.View`
  flex: 1;
  flex-grow: 0;
  width: 85%;
  max-height: 100%;
  margin-top: 32px; 
  margin-bottom: 16px;
  flex-basis: undefined;
  align-items: center;
`

export const FormContainer = styled.View`
  width: 100%;
  margin-vertical: 32px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
`

export const Input = styled(TextInput)`
  width: 48%;
`
