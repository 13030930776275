import React, { useCallback, useState } from 'react'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import StarCounterProps from './StarCounter.types'
import { useTheme } from '../../../../utils'
import { FontIcon } from '../../../atoms'
import {
  FlexContainer,
  StarContainer,
  IconsContainer,
  Circle,
  CircleText,
} from './StarCounter.style'
import { useNavigation } from '@react-navigation/native'

export default function StarCounter({
  mark = 0,
  totalStar = 5,
  onPress,
  trackLabel,
  trackableParams,
  item = 'star',
}: StarCounterProps & TrackableEventProps) {
  const theme = useTheme()
  const [hoverMark, setHoverMark] = useState(0)
  const pressable = mark == null
  const displayedMark = mark || hoverMark

  const _onPress = useCallback(
    (index) => {
      if (pressable) {
        trackEvent(trackLabel, {
          ...trackableParams,
          mark: index,
        }).then()
        onPress && onPress(index)
      }
    },
    [pressable]
  )

  const onHoverIn = useCallback(
    (index) => {
      if (pressable) {
        setHoverMark(index)
      }
    },
    [pressable]
  )

  const onHoverOut = useCallback(() => {
    if (pressable) {
      setHoverMark(0)
    }
  }, [pressable])

  return (
    <FlexContainer>
      {[...Array(totalStar)].map((key, index) => (
        <IconsContainer key={`star-${index}`}>
          {
            <StarContainer
              onPress={() => _onPress(index + 1)}
              onHoverIn={() => onHoverIn(index + 1)}
              onHoverOut={onHoverOut}
              pressable={pressable}>
              {item === 'circle' ? (
                <Circle hover={index < displayedMark}>
                  <CircleText hover={index < displayedMark}>{index}</CircleText>
                </Circle>
              ) : (
                <FontIcon
                  name={index < displayedMark ? 'filled-star' : 'empty-star'}
                  size={theme.sizes.XXL}
                  color={
                    index < displayedMark
                      ? theme.colors.primary
                      : theme.colors.black
                  }
                />
              )}
            </StarContainer>
          }
        </IconsContainer>
      ))}
    </FlexContainer>
  )
}
