import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { View } from 'react-native'
import { AppointmentCalendarLoadingProps } from './AppointmentCalendar.types'

const APPOINTMENT_CALENDAR_DEFAULT_HEIGHT = 350
export default function AppointmentCalendarLoading({
  height,
}: AppointmentCalendarLoadingProps) {
  return (
    <View
      style={{
        zIndex: 999,
        flex: 1,
        flexDirection: 'row',
        position: 'absolute',
        top: 10,
        bottom: 0,
        left: 0,
        right: 0,
        height: height || APPOINTMENT_CALENDAR_DEFAULT_HEIGHT,
      }}>
      <ContentLoader
        speed={2}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        width={686}
        height={height || APPOINTMENT_CALENDAR_DEFAULT_HEIGHT}
        viewBox={`0 0 686 ${height || APPOINTMENT_CALENDAR_DEFAULT_HEIGHT}`}>
        <Rect
          x='0'
          y='0'
          rx='0'
          ry='0'
          width='686'
          height={height || APPOINTMENT_CALENDAR_DEFAULT_HEIGHT}
        />
      </ContentLoader>
    </View>
  )
}
