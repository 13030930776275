import { AlertContext } from '@core/components/Alerthandler'
import {
  PASSWORD_REGEX,
  PASSWORD_REGEX_MATCH_LIST,
  SCREEN_HOME,
  SCREEN_PROFILING,
  SCREEN_REGISTRATION,
  SCREEN_SIGNUP_EMAIL_CONFIRMATION,
} from '@core/constants'
import { StackActions, useNavigation } from '@react-navigation/native'
import {
  Button,
  FontIcon,
  Link,
  SmallParagraph,
  Text,
  Title,
} from '@ui/Moodwork-UI'
import { useTheme } from '@ui/utils'
import { first } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormContainer, Input } from './index.styles'
import { FormProps } from './index.type'
import { View } from 'react-native'
import { useUserInfo } from '@core/services/viewer'
import Checkbox from '@ui/Moodwork-UI/molecules/pressables/Checkbox'
import { useToggle } from '@core/lib/hooks'
import { LinearGradient } from 'expo-linear-gradient'

export default function Form({
  onValidate,
  data,
  error,
  isSaleCode,
  lastnameRequire,
  passwordRequire,
  passwordMessage,
  passwordHint,
  passwordLevel,
}: FormProps) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [professionalEmail, setProfessionalEmail] = useState('')
  const [professionalEmailConfirmation, setProfessionalEmailConfirmation] =
    useState('')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isPasswordGood, setPasswordGood] = useState<boolean | undefined>()
  const [passwordPercentage, setPasswordPercentage] = useState(0)

  const [usePersonalEmail, , , toggleUsePersonalEmail] = useToggle(false)
  const [personalEmail, setPersonalEmail] = useState('')
  const [personalEmailConfirmation, setPersonalEmailConfirmation] = useState('')

  const [subToNewsletter, , , toggleNewsletter] = useToggle(false)
  const [acknowledgeInfo, , , toggleAcknowledgeInfo] = useToggle(false)

  const [passwordIsFocus, setPasswordIsFocus] = useState(false)

  const theme = useTheme()
  const { refetch } = useUserInfo()

  const { t } = useTranslation('sign')
  const navigation = useNavigation()
  const { setMessage, setType } = useContext(AlertContext)

  const onPasswordChange = (value: string) => {
    setPassword(value)
    setPasswordGood(!!value.match(PASSWORD_REGEX[passwordLevel]))
  }

  const checkAllData = () => {
    setType('warning')
    if (passwordRequire && !isSaleCode) {
      if (!isPasswordGood) {
        setMessage("Le mot de passe n'a pas la bonne complexité")
        return
      }
      if (passwordConfirmation !== password) {
        setMessage('Mot de passe et confirmation non identique')
        return
      }
    }
    if (!firstName) {
      setMessage('Prenom obligatoire')
      return
    }
    if (lastnameRequire && !lastName) {
      setMessage('Nom obligatoire')
      return
    }
    if (!professionalEmail) {
      setMessage('Email professionnel obligatoire')
      return
    }
    if (
      !professionalEmail.match(/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setMessage('Email professionnel non valide')
      return
    }

    if (!isSaleCode && professionalEmail !== professionalEmailConfirmation) {
      setMessage('Les emails professionnels ne sont pas identiques')
      return
    }

    if (!acknowledgeInfo) {
      setMessage(
        'Veuillez valider avoir pris connaissance de toutes les informations nécessaires'
      )
      return
    }

    onValidate(
      firstName,
      lastName,
      professionalEmail,
      usePersonalEmail ? personalEmail : '',
      password,
      subToNewsletter
    )
  }

  useEffect(() => {
    if (error) {
      setMessage(
        first(error.response.data.errors[0].detail.password) ??
          first(error.response.data.errors[0].detail.email) ??
          first(error.response.data.errors[0].detail.global)
      )
    }
  }, [error])

  useEffect(() => {
    if (data) {
      if (passwordRequire) {
        refetch().then((d) => {
          navigation.dispatch(
            StackActions.replace(
              isSaleCode ? SCREEN_HOME : SCREEN_SIGNUP_EMAIL_CONFIRMATION,
              { isPersonnal: usePersonalEmail }
            )
          )
        })
      }
    }
  }, [data])

  useEffect(() => {
    const regexList = PASSWORD_REGEX_MATCH_LIST[passwordLevel]
    let nbMatch = 0
    regexList.forEach((r) => {
      if (password.match(r)) {
        nbMatch++
      }
    })

    setPasswordPercentage(nbMatch / regexList.length)
  }, [password])

  return (
    <View style={{ marginHorizontal: 16, alignItems: 'center' }}>
      <Title marginSize='32px 0 0 0'>
        {t('up.form.title')}
        <Text weight='bold' color='grey' size='L'>
          {' ' + 3}
        </Text>
        <Text weight='bold' color='grey' size='M'>
          /3
        </Text>
      </Title>
      {data && !passwordRequire && (
        <View
          style={{ flexDirection: 'row', alignItems: 'center', margin: 32 }}>
          <FontIcon name='check-circle' color={theme.colors.lightGreen} />
          <Text marginSize='0 0 0 8px'>{passwordMessage}</Text>
        </View>
      )}
      {!(data && !passwordRequire) && (
        <FormContainer>
          <Input
            fullWidth
            onChange={setFirstName}
            value={firstName}
            placeholder={t('up.form.firstname')}
          />
          {lastnameRequire && (
            <Input
              onChange={setLastName}
              value={lastName}
              placeholder={t('up.form.lastname')}
            />
          )}
          <Input
            fullWidth={isSaleCode}
            onChange={(text) => {
              if (text.length - professionalEmail.length <= 1) {
                setProfessionalEmail(text)
              }
            }}
            value={professionalEmail}
            autoCapitalize='none'
            type='email'
            placeholder={t('up.form.email')}
          />
          {!isSaleCode && (
            <Input
              onChange={(text) => {
                if (text.length - professionalEmailConfirmation.length <= 1) {
                  setProfessionalEmailConfirmation(text)
                }
              }}
              value={professionalEmailConfirmation}
              autoCapitalize='none'
              type='email'
              placeholder={t('up.form.confirm-email')}
            />
          )}
          {passwordRequire && !isSaleCode && (
            <View
              style={{
                width: theme.device === 'small' ? '100%' : '48%',
                flexDirection: 'column',
              }}>
              <Input
                fullWidth
                onFocus={() => {
                  setPasswordIsFocus(true)
                }}
                onBlur={() => {
                  setPasswordIsFocus(false)
                }}
                type='password'
                onChange={(text) => {
                  if (text.length - password.length <= 1) {
                    onPasswordChange(text)
                  }
                }}
                value={password}
                placeholder={t('up.form.password')}
              />
              <View
                style={{
                  marginTop: passwordIsFocus ? -10 : -9,
                  height: 8,
                  marginLeft: passwordIsFocus ? 2 : 1,
                  marginRight: passwordIsFocus ? 2 : 1,
                }}>
                <LinearGradient
                  colors={[
                    password === ''
                      ? theme.colors.lightSecondaryGrey
                      : isPasswordGood
                      ? 'green'
                      : 'red',
                    theme.colors.lightSecondaryGrey,
                  ]}
                  style={{
                    height: '100%',
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                  }}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 0 }}
                  locations={[passwordPercentage, passwordPercentage, 1]}
                />
              </View>
            </View>
          )}
          {passwordRequire && !isSaleCode && (
            <Input
              type='password'
              onChange={(text) => {
                if (text.length - passwordConfirmation.length <= 1) {
                  setPasswordConfirmation(text)
                }
              }}
              value={passwordConfirmation}
              placeholder={t('up.form.confirm-password')}
            />
          )}
          {passwordRequire && !isSaleCode && (
            <Text
              color={
                isPasswordGood === undefined
                  ? 'grey'
                  : isPasswordGood
                  ? 'green'
                  : 'red'
              }
              style={{ marginTop: -12 }}>
              {passwordHint}
            </Text>
          )}
          {!isSaleCode && (
            <Checkbox
              isActive={usePersonalEmail}
              onPress={toggleUsePersonalEmail}
              text={t('up.form.use-personnal-email')}
            />
          )}
          {usePersonalEmail && (
            <>
              <Input
                onChange={(text) => {
                  if (text.length - personalEmail.length <= 1) {
                    setPersonalEmail(text)
                  }
                }}
                value={personalEmail}
                autoCapitalize='none'
                type='email'
                placeholder={t('up.form.personnal-email')}
              />
              <Input
                onChange={(text) => {
                  if (text.length - personalEmailConfirmation.length <= 1) {
                    setPersonalEmailConfirmation(text)
                  }
                }}
                value={personalEmailConfirmation}
                autoCapitalize='none'
                type='email'
                placeholder={t('up.form.confirm-personnal-email')}
              />
            </>
          )}
          <Checkbox
            isActive={subToNewsletter}
            onPress={toggleNewsletter}
            text={t('up.form.newsletter')}
          />
          <Checkbox
            isActive={acknowledgeInfo}
            onPress={toggleAcknowledgeInfo}
            text={
              <SmallParagraph>
                {t('up.form.acknowledge')}
                <Link
                  lineHeight={'XS'}
                  size={'XS'}
                  text={t('up.form.acknowledge-2')}
                  url={'https://moodwork.com/fr/legale/rgpd'}
                />
              </SmallParagraph>
            }
          />
          <SmallParagraph color='grey'>
            {t('up.form.newsletter-info')}
          </SmallParagraph>

          <SmallParagraph color='grey'>
            {t('up.form.acknowledge-info')}
          </SmallParagraph>
        </FormContainer>
      )}
      {!(data && !passwordRequire) && (
        <Button
          trackLabel='clicked_signup_register'
          label={t('up.form.button')}
          onPress={checkAllData}
        />
      )}
    </View>
  )
}
