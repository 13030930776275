import { Button, FontIcon } from '@ui/Moodwork-UI'
import { IS_NATIVE, useTheme } from '@ui/utils'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  ContentContainer,
  ImageContainer,
  MailImage,
  TextArea,
  Title,
  IsSharedContainer,
  IsSharedText,
} from './ShareResourceModal.styles'
import { TagInput } from '../TagInput'
import { styles } from './ShareResourceModal.styles'
import ContactImage from '../../../../assets/images/img_contact.png'
import { useShare } from '@core/services/resources'
import { LoadingCircle } from '@ui/Moodwork-UI/atoms/LoadingCircle'
import { AlertContext } from '@core/components/Alerthandler'
import { useNavigation, useRoute } from '@react-navigation/native'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { ShareModalNavigation } from '@core/lib/types'
import { trackEvent } from '@core/lib/analytics'
import { Keyboard, KeyboardAvoidingView, View } from 'react-native'

export const ShareResourceModal = () => {
  const { t } = useTranslation(['resources', 'errors'])
  const { resourceType, resourceUuid, resourceStickerKey, resourceTitle } =
    useRoute<ShareModalNavigation>().params
  const theme = useTheme()
  const navigation = useNavigation()
  const [emails, setEmails] = useState({
    tag: '',
    tagsArray: [],
  })
  const [body, setBody] = useState(
    t('share.messagePlaceholder', {
      title: resourceTitle,
      niceType: resourceStickerKey
        ? t(`niceTypes.${resourceStickerKey.toLowerCase()}`)
        : t('niceTypes.default'),
    })
  )
  const { message, setMessage, setType } = useContext(AlertContext)

  const share = useShare()

  const sendShare = () => {
    if (emails.tagsArray.length < 1) {
      if (!message) {
        setType && setType('warning')
        setMessage(t('share.emailEmptyError'))
      }
      return
    }
    trackEvent('clicked_resource_share_confirm', {
      uuid: resourceUuid,
      title: resourceTitle,
      tag: resourceStickerKey,
    }).then(() => {!share.isLoading &&
      resourceType &&
      resourceUuid &&
      share.mutate({
        resourceType: resourceType,
        resourceUuid: resourceUuid,
        message: body,
        emails: emails.tagsArray,
      })})
  }

  const handleError = () => {
    setType && setType('warning')
    setMessage(t('errors:technical.title'))
    setTimeout(() => {
      navigation.goBack()
    }, 3000)
  }

  const checkEmail = (email: string): boolean => {
    return /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  useEffect(() => {
    share.isError && handleError()
  }, [share.isError])

  useEffect(() => {
    share.isSuccess &&
      setTimeout(() => {
        navigation.goBack()
      }, 3000)
  }, [share.isSuccess])

  return (
    <View style={{ flex: 1 }}>
      <NavigationModal>
        <Container onPress={() => IS_NATIVE && Keyboard.dismiss()}>
          <ContentContainer>
            <Title>{t('share.title')}</Title>
            <TagInput
              label={t('share.inputLabel')}
              labelStyle={styles.labelStyle}
              placeholder={t('share.inputPlaceholder')}
              placeholderTextColor={theme.colors.grey}
              containerStyle={styles.container}
              inputStyle={styles.input}
              updateState={setEmails}
              tags={emails}
              leftElement={<FontIcon name={'black-email'} size={18} />}
              leftElementContainerStyle={styles.leftElement}
              condition={checkEmail}
              conditionErrorMessage={t('share.emailFormatError')}
            />
            <TextArea
              type='text'
              placeholder={t('share.messagePlaceholder', {
                title: resourceTitle,
                niceType: resourceStickerKey
                  ? t(`niceTypes.${resourceStickerKey.toLowerCase()}`)
                  : t('niceTypes.default'),
              })}
              multiline={true}
              numberOfLines={10}
              inputStyle={styles.textAreaStyle}
              onChangeText={(newText) => setBody(newText)}
              defaultValue={body}
            />
            {share.isSuccess ? (
              <IsSharedContainer>
                <IsSharedText>{t('share.isShared')}</IsSharedText>
                <FontIcon name='check-circle' color={theme.colors.lightGreen} />
              </IsSharedContainer>
            ) : share.isLoading ? (
              <LoadingCircle />
            ) : (
              <Button
                onPress={sendShare}
                label={t('share.shareSubmit')}
                trackLabel={''}
              />
            )}
          </ContentContainer>
          {!IS_NATIVE && (
            <ImageContainer>
              <MailImage source={ContactImage} resizeMode='contain' />
            </ImageContainer>
          )}
        </Container>
      </NavigationModal>
    </View>
  )
}
