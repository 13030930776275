import styled from 'styled-components/native'

export const PageContent = styled.View`
  display: flex;
  padding-vertical: ${({ theme }) => theme.spacings.XXL}px;
  padding-horizontal: ${({ theme }) =>
    theme.device === 'small' ? theme.spacings.L : theme.spacings.XXXL}px;
`

export const Container = styled.View`
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.device === 'large' ? 'row' : 'column-reverse'};
  justify-content: space-between;
`

export const LeftContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: ${({ theme }) => (theme.device === 'large' ? 66 : 100)}%;
`

export const GraphContainer = styled.View`
  display: flex;
  width: 100%;
  height: 100%;
`

export const RightContainer = styled.View`
  display: flex;
  max-width: ${({ theme }) => (theme.device === 'large' ? 30 : 100)}%;
  margin-left: ${({ theme }) => (theme.device === 'large' ? 3 : 0)}%;
  margin-bottom: ${({ theme }) => (theme.device === 'large' ? 0 : 3)}%;
`

export const AssesmentContainer = styled.View`
  background-color: #F4F5FD;
  padding-vertical: 32px;
  padding-horizontal: 24px;
  border-radius: 15px;
`
