import { HomePressable } from '@ui/Moodwork-UI/atoms/HomePressable'
import * as React from 'react'
import { Container } from './BrandedContainer.styles'
import { BrandedContainerProps } from './interface'

export const BrandedContainer = ({
  children,
  fullscreen,
}: BrandedContainerProps) => {
  return (
    <Container fullscreen={fullscreen}>
      <HomePressable />
      {children}
    </Container>
  )
}
