import React from 'react'
import { useTheme } from '@ui/utils'
import { FontIcon } from '@ui/Moodwork-UI/atoms'
import { ProgressComponentProps } from './index.types'
import { Circle, Container, Gradient } from './index.styles'

export default function ProgressComponent({
  read,
  first,
  last,
}: ProgressComponentProps) {
  const theme = useTheme()
  return (
    <Container>
      <Gradient
        read={read}
        colors={
          first
            ? ['white', 'transparent']
            : last
            ? ['transparent', 'white']
            : ['transparent', 'transparent']
        }
        locations={[0.5, 0.5]}>
        <Circle>
          {read && (
            <FontIcon
              style={{ width: 38, height: 38 }}
              name='check-circle'
              color={theme.colors.lightGreen}
              size={38}
            />
          )}
        </Circle>
      </Gradient>
    </Container>
  )
}
