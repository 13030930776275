const downloadOpenFile = async (url: string) => {
  const fileWindow = window.open('', '_blank')

  fetch(url, {
    method: 'GET',
    referrerPolicy: 'no-referrer',
  })
    .then((res) => {
      return res.blob()
    })
    .then((blob) => {
      var resURL = URL.createObjectURL(blob)
      fileWindow.location = resURL
    })
    .catch(console.warn)
}

export { downloadOpenFile }
