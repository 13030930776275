import { FlexCenter } from '@ui/Moodwork-UI/templates'
import { IS_NATIVE } from '@ui/utils'
import styled from 'styled-components/native'

export const Container = styled.View`
  margin-left: ${({ theme }) =>
    theme.device === 'small' ? (IS_NATIVE ? '16px' : '32px') : '64px'};
  margin-bottom: 32px;
`

export const ButtonContainer = styled(FlexCenter)`
  margin-right: 64px;
  margin-top: 32px;
`
