import { getCookie, setCookie } from './cookies'

export function getStoredItem(key: string) {
  return getCookie(key)
}

export function storeItem(key: string, value: string | null) {
  setCookie(key, value)
}

export function deleteItems(keys: string[], callback?: () => void) {
  keys.forEach((key) => setCookie(key, null))
  if (callback) {
    callback()
  }
}
