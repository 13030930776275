import { useState, useCallback, useEffect, useContext, createContext } from 'react'

export function useToggle(
  defaultValue?: boolean
): [boolean, () => void, () => void, () => void] {
  const [value, setValue] = useState<boolean>(!!defaultValue)
  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const setInverse = () => setValue(!value)
  return [value, setTrue, setFalse, setInverse]
}

