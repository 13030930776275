import { useNavigation, useRoute } from '@react-navigation/core'
import React, { useCallback } from 'react'
import AppointmentType from '../../ui/AppointmentType'
import { PsychologistAppointmentTypeRoute } from '../lib/types'
import { useUserInfo } from '@core/services/viewer'
import { ErrorPage } from '@ui/Moodwork-UI'
import { ErrorType } from '@ui/utils'

export default function PsychologistAppointmentType() {
  const navigation = useNavigation()
  const userInfo = useUserInfo()?.data
  const { eventSlotId, eventDate, psyName, psyAvatar } =
    useRoute<PsychologistAppointmentTypeRoute>().params

  // TODO: change with right page + pass arguments
  const onPress = useCallback((type: string) => {
    navigation.push('PsychologistAppointmentInfo', {
      eventSlotId: eventSlotId,
      eventType: type,
      eventDate: eventDate,
      psyName: psyName,
      psyAvatar: psyAvatar,
    })
  }, [])

  const onPhonePress = useCallback(() => {
    onPress('call')
  }, [])

  const onVisioPress = useCallback(() => {
    onPress('visio')
  }, [])

  if (!userInfo?.care_access) {
    return <ErrorPage type={ErrorType.CARE_ACCESS_ERROR} />
  } else {
    return (
      <AppointmentType
        onPhonePress={onPhonePress}
        onVisioPress={onVisioPress}
        eventDate={eventDate}
        psyName={psyName}
        psyAvatar={psyAvatar}
      />
    )
  }
}
