import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'

const web =
  IS_BROWSER &&
  css`
    z-index: 3;
  `

export const HomePressable = styled.Pressable`
  position: absolute;
  outline: none;
  ${web};
  ${({ theme }) =>
    theme.device === 'large'
      ? css`top: -68px`
      : css`
  top: -47px`}
`

export const Container = styled.View`
  flex: 1;
  align-items: center;
  ${({ theme }) =>
    theme.device === 'large'
      ? css`margin-top: 72px`
      : css`
  margin-top: 55px`}
`
