import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { Text } from '../Moodwork-UI/atoms'
import { Button } from '@ui/Moodwork-UI'

export const Container = styled.View`
  flex: 1;
  align-items: center;
`

export const ButtonContainer = styled.View`
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const PreviousContainer = styled.Pressable<{ displayElement: boolean }>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  ${(props) =>
    IS_BROWSER && `cursor: ${props.displayElement ? 'pointer' : 'default'}`};
  opacity: ${(props) => (props.displayElement ? 1 : 0)};
`

export const NextContainer = styled.Pressable`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  ${IS_BROWSER && 'cursor: pointer'};
`

export const Previous = styled(Text)`
  color: ${({ theme }) => theme.colors.secondaryLight}
  margin-left: 15px;
`

export const Next = styled(Text)`
  color: ${({ theme }) => theme.colors.secondaryLight}
  margin-right: 15px;
`

export const EndButtonContainer = styled(Button)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  justify-content: flex-end;
  ${IS_BROWSER && 'cursor: pointer'};
`
