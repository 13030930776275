import React, { useEffect } from 'react'
import { ErrorPage } from '@moodwork-ui'
import { useRoute } from '@react-navigation/core'
import { useNavigation } from '@react-navigation/native'
import { PortalsNavigation } from '../../lib/types'
import { ErrorType } from '@utils'
import { usePortals } from '@core/services/resources'
import { CollectionTemplate } from '@ui/Moodwork-UI/templates/Collection'

const Portals = () => {
  const { id } = useRoute<PortalsNavigation>().params
  const navigation = useNavigation()
  const { data, isError } = usePortals(id)
  useEffect(() => {
    if (data?.title?.length > 0) {
      navigation.setOptions({ title: data.title })
    }
  }, [data?.title])

  return isError ? (
    <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  ) : (
    !!data && <CollectionTemplate {...data} />
  )
}

export default Portals
