import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { ContainerProps } from './IconButton.types'

export const Container = styled.View<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  ${IS_BROWSER && css`cursor: pointer;`}
`

export const BackgroundContainer = styled.View<ContainerProps>`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${themeColor('secondaryLight')};
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
  ${({ big }) =>
    big &&
    css`
      transform: scale(1);
    `};
`
