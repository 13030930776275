import * as React from 'react'
import { Container, Icon } from './NavigationIcon.style'
import { NavigationIconProps } from './NavigationIcon.types'
import { useTheme } from '@ui/utils'

/**
 * TODO:
 * better image handling for size and other, svg support, micon
 */
export default function NavigationIcon({ big, source }: NavigationIconProps) {
  const theme = useTheme()
  return (
    <Container testID='NavigationIcon' big={big}>
      <Icon
        name={source}
        color={theme.colors.white}
        size={big && theme.device === 'large' ? 28 : 18}
      />
    </Container>
  )
}
