import React from 'react'
import { Dashboard as DashboardTemplate } from '@ui/Moodwork-UI/templates'
import UserWellbeing from '@ui/Moodwork-UI/organisms/Dashboard/UserWellbeing'
import Appointments from '@ui/Moodwork-UI/organisms/Dashboard/UserAppointments'
import Campaigns from '@ui/Moodwork-UI/organisms/Dashboard/UserCampaigns'
import EntrypointList from '@ui/Moodwork-UI/organisms/Dashboard/EntrypointList'
import UpcomingWebinars from '@ui/Moodwork-UI/organisms/Dashboard/UpcomingWebinars'
import { DashboardCardWithOptions } from '@ui/Moodwork-UI/templates/Dashboard/index.types'
import RecommendedResourcesForYou from '@ui/Moodwork-UI/organisms/Dashboard/RecommendedResourcesForYou'
import RecommendedResourcesWellBeing from '@ui/Moodwork-UI/organisms/Dashboard/RecommendedResourcesWellBeing'
import HighlightList from '@ui/Moodwork-UI/organisms/Dashboard/HighlightList'
import { useUserInfo } from '@core/services/viewer'
import { Viewer } from '@core/types/viewer'
import { useWebinars } from '@core/services/webinars'
import { Webinar } from '@core/types/webinar'

const useDashboardComponents = (
  viewer: Viewer | undefined,
  webinars: Webinar[] | undefined
) => {
  const leftColumn = [
    [Campaigns, { hideByDefault: true, props: { positions: ['high'] } }],
    UserWellbeing,
    [
      HighlightList,
      { removeHorizontalPadding: true },
    ] as DashboardCardWithOptions,

    [RecommendedResourcesWellBeing, { hideByDefault: true }],
    [
      Campaigns,
      { hideByDefault: true, props: { positions: ['medium', 'low'] } },
    ],
    [RecommendedResourcesForYou, { hideByDefault: true }],
  ]
  const rightColumn = [EntrypointList]

  const mainColumn = [
    UserWellbeing,
    [
      HighlightList,
      { removeHorizontalPadding: true },
    ] as DashboardCardWithOptions,
    [Campaigns, { hideByDefault: true }],
    UpcomingWebinars,
    [
      EntrypointList,
      { removeHorizontalPadding: true },
    ] as DashboardCardWithOptions,
    RecommendedResourcesWellBeing,
    RecommendedResourcesForYou,
  ]

  // I cut it there and add them manually cause letting the
  // components handle their visibility makes the template
  // add an useless margin when empty, we can improve how
  // it's rendered in the future
  Array.isArray(webinars) &&
    webinars?.length > 0 &&
    rightColumn.unshift(UpcomingWebinars)

  viewer &&
    viewer?.care_access &&
    rightColumn.unshift(Appointments) &&
    mainColumn.splice(1, 0, Appointments)

  return {
    leftColumn,
    rightColumn,
    mainColumn,
  }
}

const Dashboard = () => {
  const { data, isFetched } = useUserInfo()
  const { data: webinarsData } = useWebinars()
  const dashboardComponents = useDashboardComponents(data, webinarsData)
  return isFetched && data ? (
    <DashboardTemplate components={dashboardComponents} />
  ) : null
}

export default Dashboard
