import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../../../../utils/device'
import { DisabledState, HoverState } from '../../../../utils/types'

const web =
  IS_BROWSER &&
  css`
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  `

export const Wrapper = styled.View<
  HoverState & { device: DeviceSizeType; color?: string }>`
  padding: 2px;
  border-radius: 500px;
  min-height: 52px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.transparent};
  justify-content: center;
  ${web};
  ${({ hover, color }) =>
    hover &&
    css`
      border-color: ${({ theme }) =>
        color ? theme.colors[color] : theme.colors.primary}};
    `}
`

export const Container = styled.View<
  DisabledState & { outline?: boolean; color?: string; compact?: boolean }
>`
  display: flex;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.primary};
  padding: ${({ compact }) => (compact ? '8px' : '16px')} 45px;
  min-height: 44px;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  ${web};
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.primaryLight};
    `}
  ${({ outline }) =>
    outline &&
    css`
      background-color: ${({ theme }) => theme.colors.transparent};
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.primary};
    `}
`
