import styled from 'styled-components/native'
import { TextInput } from '@ui/TextInput'
import { StyleSheet } from 'react-native'
import { Button } from '@moodwork-ui'
import theme from '../../../UIProvider/defaultTheme'

export const SubjectInput = styled(TextInput)`
  margin-bottom: 16px;
`

export const MessageTextArea = styled(TextInput)`
  margin-bottom: 16px;
`

export const SubmitButton = styled(Button)`
  max-width: 160px;
  margin-bottom: 16px;
`

export const styles = StyleSheet.create({
  input: {
    outlineStyle: 'none',
    backgroundColor: theme.colors.secondaryBackground,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  textAreaStyle: {
    height: 200,
  },
  inputTextStyle: {
    height: 40,
  },
  hoveredInputStyle: {
    borderColor: theme.colors.searchBlue,
  },
  pickerItemStyle: {
    backgroundColor: theme.colors.secondaryBackground,
    borderColor: 'transparent',
  },
})
