import styled, { css } from 'styled-components/native'
import { ResourceContentCSS } from '../ResourceBlock/ResourceCommon.styles'
import { Colors } from '@ui/UIProvider/UIProvider.types'

export const Container = styled.View<{ lastItem?: boolean }>`
  margin-bottom: ${({ theme, lastItem }) =>
    lastItem ? (theme.device === 'small' ? 24 : theme.spacings.XXL) : 0}px;
`
export const ResponseContainer = styled.TouchableOpacity<{
  selected: boolean
  hover: boolean
  typeColor: keyof Colors
}>`
  border-radius: 10px;
  border-width: 1px;
  margin-bottom: 8px;
  opacity: ${({ hover }) => (hover ? 0.7 : 1)}
  padding: ${({ theme }) => theme.spacings.M}px;
  ${({ selected, theme, typeColor }) =>
    selected
      ? css`
    background-color: ${theme.colors[typeColor]}
    border-width: 0px;
   `
      : css`
    border-color: ${theme.colors.secondary};
    background-color: ${theme.colors.white}
   `}
  ${ResourceContentCSS}
`

export const FeedbackContainer = styled.View`
  margin-bottom: 8px;
  ${ResourceContentCSS}
`

export const Feedback = styled.View<{ typeColor: keyof Colors }>`
  border-width: 1px;
  border-color: ${({ theme, typeColor }) => theme.colors[typeColor]};
  padding-horizontal: ${({ theme }) => theme.spacings.M}px;
  padding-bottom: ${({ theme }) => theme.spacings.M}px;
  width: 100%;
`
