import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import * as React from 'react'
import { BackHandler, View } from 'react-native'
import { PsychologistCalendarRoute } from '../lib/types'
import AppointmentCalendar from '../components/AppointmentCalendar'
import { ErrorPage, Title } from '@ui/Moodwork-UI'
import { useTranslation } from 'react-i18next'
import { PageView } from '@ui/PageView'
import { IS_NATIVE, useTheme } from '@utils'
import { useUserInfo } from '@core/services/viewer'
import { ErrorType } from '@ui/utils'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'

/**
 * This is the calendar screen view. It is only available
 * on mobile devices
 * TODO: when resizing to desktop, go back to the
 * psychologists list
 */
export default function PsychologistCalendar() {
  let {
    psychologistId,
    psychologistName,
    psychologistAvatar,
    psychologistRank,
    nextSlotsDate,
    eventKind,
  } = useRoute<PsychologistCalendarRoute>().params
  const navigation = useNavigation()
  const userInfo = useUserInfo()?.data
  const isFocused = useIsFocused()
  const theme = useTheme()
  const { t } = useTranslation(['appointments'])

  /**
   * TODO: change this behaviour
   */
  if (nextSlotsDate === 'null') nextSlotsDate = null

  useFocusEffect(
    React.useCallback(() => {
      let handler
      if (isFocused) {
        handler = BackHandler.addEventListener('hardwareBackPress', () => {
          navigation.goBack()
          return true
        })
      } else if (handler) {
        BackHandler.removeEventListener('hardwareBackPress', handler)
      }
      return handler?.remove
    }, [isFocused])
  )

  if (!userInfo?.care_access) {
    return <ErrorPage type={ErrorType.CARE_ACCESS_ERROR} />
  } else {
    return (
      <PageView>
        {IS_NATIVE && <BackButton navigation={navigation} />}
        <View style={{ flex: 1, paddingHorizontal: 16 }}>
          <Title size='XXL' weight='bold'>
            {t('psychologists.calendar.title')}
          </Title>
          <AppointmentCalendar
            psychologistId={psychologistId}
            psychologistName={psychologistName}
            psychologistAvatar={psychologistAvatar}
            psychologistRank={psychologistRank}
            nextSlot={{ id: 0, date: nextSlotsDate }}
            eventKind={eventKind}
          />
        </View>
      </PageView>
    )
  }
}
