import { BigParagraph } from '@ui/Moodwork-UI/atoms'
import { OnDevice } from '@ui/OnDevice'
import { useTheme } from '@ui/utils'
import React from 'react'
import { DateTime } from 'luxon'
import image from '../../../../../assets/images/dashboardBilan.png'
import { Button } from '../../pressables'
import {
  ButtonContainer,
  Container,
  FollowContainer,
  FollowIcon,
  Icon,
  ImageContainer,
  RightContainer,
  TextContent,
  TitleContainer,
  DecorateImage,
  MainText,
  FollowText,
} from './index.style'
import { DashboardWellbeingProps } from './index.types'
import { useTranslation } from 'react-i18next'

export default function WellbeingSummary({
  showImage,
  title,
  text,
  mainAction,
  followAction,
  date,
  onPress,
}: DashboardWellbeingProps) {
  // TODO: add trackLabel on buttons + add actions + change default text
  const theme = useTheme()
  const { i18n, t } = useTranslation(['dashboard'])
  return (
    <Container highlight>
      {showImage && (
        <OnDevice size={['large', 'medium']}>
          <ImageContainer>
            <DecorateImage resizeMode='contain' source={image} />
          </ImageContainer>
        </OnDevice>
      )}
      <RightContainer>
        <TextContent>
          <TitleContainer>
            {!showImage && (
              <Icon name='check-circle' color={theme.colors.secondary} />
            )}
            {title && (
              <MainText style={{ flex: 1 }}>
                {t(title, {
                  date: DateTime.fromISO(date).toLocaleString({
                    locale: i18n.language,
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }),
                })}
              </MainText>
            )}
          </TitleContainer>
          {text && <BigParagraph>{t(text)}</BigParagraph>}
        </TextContent>
        <ButtonContainer>
          {mainAction && (
            <Button
              compact={theme.device === 'small'}
              label={t(mainAction.text)}
              trackLabel={mainAction.trackableLabel}
              trackProperties={mainAction.trackableProperties}
              onPress={() => onPress(mainAction)}
            />
          )}
          {followAction && (
            <FollowComponent onPress={() => onPress(followAction)}>
              {t(followAction.text)}
            </FollowComponent>
          )}
        </ButtonContainer>
      </RightContainer>
    </Container>
  )
}

function FollowComponent({
  children,
  onPress,
}: {
  onPress: () => void
  children: JSX.Element
}) {
  const theme = useTheme()

  return (
    <FollowContainer onPress={onPress}>
      <FollowText>{children}</FollowText>
      <FollowIcon
        size={theme.device === 'small' ? theme.sizes.XS : theme.sizes.S}
        name='arrow-right'
        color={theme.colors.secondary}
      />
    </FollowContainer>
  )
}
