import { IS_NATIVE } from '@ui/utils'
import styled from 'styled-components/native'

export const Container = styled.ScrollView`
  height: 100%;
`

export const InputContainer = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  row-gap: 16px;
  margin-bottom: ${() => (IS_NATIVE ? 16 : 32)}px;
`
