import { AssessmentKeys } from '@ui/Moodwork-UI/organisms/Test/interface'
import { DateTime } from 'luxon'

export const WELLBEING_ASSESSMENT_KEY = 'wellbeing_assessment'
export const BURNOUT_ASSESSMENT_KEY = 'further_assessment'

export interface Assessment {
  test: TestWording
  user_test: UserTest
  investigation: Investigation
}

export interface TestWording {
  title: string
  description: string
  subtitle: string
}
export interface UserTest {
  uuid: string
  key: AssessmentKeys
  status: string
  created_at: DateTime
  updated_at: DateTime
  answers: AssessmentAnswer[]
}

export interface Investigation {
  responses: ResponseConfig
  inspections: AssessmentQuestion[]
}

export interface ResponseConfig {
  frequency_1: {
    type: string
    score: {
      min: number
      max: number
      default: number
    }
    labels: Object
  }
  binary_1: {
    type: string
    score: {
      min: number
      max: number
      default: number
    }
  }
}

export interface AssessmentQuestion {
  uuid: string
  question: {
    type: string
    neutral: boolean
    left: {
      text: string
      info: string
    }
    right: {
      text: string
      info: string
    }
  }
  response: {
    uuid: string
  }
}

export interface AssessmentAnswer {
  uuid: string
  score: number
}
