import { Button, HTMLView, Link, Text, Title } from '@ui/Moodwork-UI'
import React, { useState } from 'react'
import { Image, Pressable, ScrollView, View } from 'react-native'
import signUp from '@assets/signUp.png'
import Checkbox from '@ui/Moodwork-UI/molecules/pressables/Checkbox'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@core/lib/analytics'
import { RGPDProps } from './index.type'

export default function RGPD({
  onValidate,
  onValidateSSO,
  displayRGPD,
  rgpdMention,
  ssoInformation,
  allow_email_auth,
}: RGPDProps) {
  const [isPress, setIsPress] = useState(false)
  const { t } = useTranslation('sign')
  return (
    <View
      style={{
        alignItems: 'center',
        maxHeight: '100%',
        // justifyContent: 'center',
      }}>
      <Image style={{ width: 158, height: 125 }} source={signUp} />
      <Title marginSize='32px 0 0 0'>
        {t('up.rgpd.title')}
        <Text weight='bold' color='grey' size='L'>
          {' ' + 2}
        </Text>
        <Text weight='bold' color='grey' size='M'>
          /3
        </Text>
      </Title>
      <Text marginSize='16px 0 0 0' alignPosition='left' lineHeight='XL'>
        {t('up.rgpd.subtitle')}
      </Text>
      {displayRGPD && (
        <View style={{ width: '75%' }}>
          <HTMLView source={rgpdMention} />
        </View>
      )}
      <Link
        trackLabel='clicked_signup_privacy_statement'
        url='https://moodwork.com/fr/legale/confidentialite'
        marginSize='16px 0'
        text={t('up.rgpd.confidentiality')}
      />
      <Pressable
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => {
          setIsPress(!isPress)
        }}>
        <Checkbox
          isActive={isPress}
          onPress={() => {
            !isPress && trackEvent('checked_signup_privacy_statement')
            setIsPress(!isPress)
          }}
        />
        {rgpdMention ? (
          <Text weight='bold' style={{ width: '75%' }}>
            {t('up.rgpd.special-mentions')}
          </Text>
        ) : (
          <Text weight='bold' style={{ width: '75%' }}>
            {t('up.rgpd.terms-1')}
            <Link
              weight='bold'
              url='https://moodwork.com/fr/legale/cgu'
              text={t('up.rgpd.terms-2')}
            />
            {t('up.rgpd.terms-3')}
            <Link
              weight='bold'
              url='https://moodwork.com/fr/legale/cgs'
              text={t('up.rgpd.terms-4')}
            />
            {t('up.rgpd.terms-5')}
            <Link
              weight='bold'
              url='https://moodwork.com/fr/legale/rgpd'
              text={t('up.rgpd.terms-6')}
            />
            {t('up.rgpd.terms-7')}
          </Text>
        )}
      </Pressable>
      <View style={{ flexDirection: 'row', marginBottom: 32 }}>
        {ssoInformation && (
          <Button
            trackLabel='clicked_signup_go_to_registration'
            disabled={!isPress}
            onPress={onValidateSSO}
            style={{ marginTop: 32 }}
            label={ssoInformation?.oauth2_sign_message}
          />
        )}
        {allow_email_auth && (
          <Button
            trackLabel='clicked_signup_go_to_registration'
            disabled={!isPress}
            onPress={onValidate}
            style={{ marginTop: 32 }}
            label={t('up.rgpd.button')}
          />
        )}
      </View>
    </View>
  )
}
