import React, { useState } from 'react'
import { Text } from '../../atoms'
import { LinkWithIcon, GradientScoreIndicator } from '../../molecules'
import {
  Container,
  LinkAndTextContainer,
  TextWrapper,
} from './ScoreCard.styles'
import { ScoreCardProps } from './interface'
import { Pressable } from 'react-native'

export const ScoreCard = ({
  score,
  title,
  device,
  children,
  onPress,
  defaultShowValue = false,
}: ScoreCardProps) => {
  const [showDetails, setShowDetails] = useState(defaultShowValue)

  const _onPress = () => {
    !showDetails && onPress()
    setShowDetails(!showDetails)
  }

  return (
    <Container device={device} onPress={_onPress}>
      <LinkAndTextContainer>
        <TextWrapper>
          <Text style={{ flex: 1, flexGrow: 0 }} weight='bold'>
            {title}
          </Text>
        </TextWrapper>
        <LinkWithIcon
          style={{ flex: 1 }}
          iconName={showDetails ? 'chevron-up' : 'chevron-down'}
          iconColor='link'
          iconSize={8}
          textSize='S'
          IconPosition='right'
          onPress={_onPress}
        />
      </LinkAndTextContainer>
      <GradientScoreIndicator score={score} />
      {showDetails && children}
    </Container>
  )
}
