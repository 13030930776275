import {
  HOST,
  SCREEN_ASSESSMENT,
  SCREEN_HOME,
  SCREEN_PSYCHOLOGISTS,
  SCREEN_SEARCH,
  SCREEN_CONTACT,
} from '@core/constants'
import { Viewer } from '@core/types/viewer'
import { SCREEN_WELLBEING_TRACKING } from '@core/constants'

import _ from 'lodash'

export const dropdownUser = [
  {
    title: 'header.user-dropdown.profile.title',
    url: `${HOST}/user/profile/edit?query=profile`,
    trackLabel: 'clicked_menu_profile',
  },
  {
    title: 'header.support-dropdown.questions.title',
    screen: SCREEN_CONTACT,
    trackLabel: 'clicked_menu_faq',
  },
  {
    title: 'header.user-dropdown.log-out.title',
    url: `${HOST}/users/sign_out`,
    trackLabel: 'clicked_menu_log_out',
  },
]

export const dropdownReduced = [
  {
    title: 'header.home',
    screen: SCREEN_HOME,
    trackLabel: 'clicked_header_home',
  },
  {
    title: 'header.calendar',
    screen: SCREEN_PSYCHOLOGISTS,
    condition: (view: Viewer) => view && view?.care_access,
    trackLabel: 'clicked_header_appointment',
  },
  {
    title: 'header.library',
    screen: SCREEN_SEARCH,
    trackLabel: 'clicked_header_library',
  },
  {
    title: 'header.personal-tracking',
    screen: (view: Viewer) =>
      view && view?.user_tests > 0
        ? SCREEN_WELLBEING_TRACKING
        : SCREEN_ASSESSMENT,
    trackLabel: 'clicked_header_assessment',
  },
  {
    title: 'header.hotline',
    url: (view: Viewer) => view?.hotline?.link,
    condition: (view: Viewer) =>
      view && !view?.care_access && view?.hotline?.link,
    trackLabel: 'clicked_header_hotline',
  },
  {
    title: 'header.user-dropdown.profile.title',
    url: `${HOST}/user/profile/edit?query=profile`,
    trackLabel: 'clicked_menu_profile',
  },
  {
    title: 'header.support-dropdown.questions.title',
    screen: SCREEN_CONTACT,
    trackLabel: 'clicked_menu_faq',
  },
  {
    title: 'header.user-dropdown.log-out.title',
    url: `${HOST}/users/sign_out`,
    trackLabel: 'clicked_menu_log_out',
  },
]
