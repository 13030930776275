import styled from 'styled-components/native'
import { BookmarkButton } from '../../pressables/BookmarkButton'

export const Container = styled.View`
  flex: 1;
  flex-basis: undefined;
  justify-content: center;
`

export const Image = styled.Image`
  height: 280px;
  width: 335px;
  margin-bottom: 24px;
`

export const Bookmark = styled(BookmarkButton)`
  margin-top: 4px;
  margin-right: 8px;
`
