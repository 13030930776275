import { DateTime } from 'luxon'
import * as React from 'react'
import { View } from 'react-native'
import CalendarLimit from '../CalendarLimit/CalendarLimit'
import { IconButton } from '../IconButton'
import { Spacer } from '../Spacer'
import { Swiper } from '../Swiper'
import { TimeSlotDay } from '../TimeSlotDay'
import { useDevice } from '../utils/device'
import { dec, inc } from '../utils/helpers'
import { EmptyItem } from './AppointmentCalendar.empty'
import AppointmentCalendarLoading from './AppointmentCalendar.loading'
import {
  Container,
  ListItemContainer,
  Wrapper,
} from './AppointmentCalendar.style'
import {
  AppointmentCalendarProps,
  ItemProps,
} from './AppointmentCalendar.types'
import { useTranslation } from 'react-i18next'
import { Day } from '@core/lib/helpers'
import { AppointmentCardCalendarLoader } from '@ui/AppointmentCard/AppointmentCard.loading'
import { useMemo } from 'react'

/**
 * Each Item is a window of N days, as well as their
 * associated slots.
 * The number of days is computed using the size available.
 * This implies that this is re-computed everytime the window size changes..
 * TODO:
 *  - implement change item on click
 */

export function Item({
  limited,
  nextSlotContent,
  width,
  days,
  nextSlot,
  emptyStateClick,
  onSelect,
  chunkHours,
  inactive_status,
  inactive_message,
}: ItemProps) {
  const device = useDevice()
  const empty_ =
    days[days.length - 1].date.startOf('day') <
    DateTime.fromISO(nextSlot.date).startOf('day')
  const { i18n } = useTranslation(['appointments'])

  const isInactive = useMemo(() => {
    return inactive_status && inactive_message != undefined
  }, [inactive_message, inactive_status])

  return (
    <ListItemContainer width={width || 0}>
      <Spacer size={16} />
      {days.map((day, index) => {
        return (
          <React.Fragment key={`${index}`}>
            <TimeSlotDay
              limited={device === 'small' ? false : limited}
              day={day.prettyDay(i18n.language)}
              date={day.prettyDetails(i18n.language)}
              slots={isInactive ? [] : day.slots}
              onPressOne={onSelect}
              chunkHours={chunkHours}
            />
            {index < days.length - 1 && <Spacer size={16} />}
          </React.Fragment>
        )
      })}
      {(empty_ || !nextSlot.date || isInactive) && (
        <EmptyItem
          content={nextSlotContent}
          onPress={emptyStateClick}
          clickable={nextSlot?.date != null}
          inactive_status={inactive_status}
          inactive_message={inactive_message}
        />
      )}
      <Spacer size={16} />
    </ListItemContainer>
  )
}

/**
 * Given a list of slots and a psychologist
 * compute and return the calendar view.
 */
export default function AppointmentCalendar({
  renderItem,
  limited,
  loading,
  setLimited,
  setIndex,
  slotHeight,
  setSlotHeight,
  width,
  setWidth,
  index,
  data,
  seeMoreLabel,
  seeLessLabel,
  chunkHours,
  inactive_status,
}: AppointmentCalendarProps) {
  const device = useDevice()
  const [loadingScreen, setLoadingScreen] = React.useState(true)
  const [height, setHeight] = React.useState(0)
  const [dataToUse, setData] = React.useState<Day[][]>()

  const scroll = React.useRef<Swiper>(null)

  const isEmpty = React.useMemo(() => {
    return data[index]?.every((day) => day.empty)
  }, [data, index])

  const isCalendarExpendable = React.useMemo(() => {
    return data[index]?.some((day) => day.slots.length > chunkHours)
  }, [data, index])

  // TODO: make an animation to avoid the flickering on web
  // TODO: maybe use previous implementation on mobile for swiping between each week ?
  React.useEffect(() => {
    // make shallow copy of data to avoid mutating the original
    let d = data.slice(0)
    // use only the chunk of days to render in the calendar
    // improve performance by rendering only 1 chunk + fix bug when the calendar expand too much
    setData(d.slice(index, index + 1))
  }, [index, data])

  React.useEffect(() => {
    if (!loading) setLoadingScreen(false)
  }, [loading])

  /**
   * Is the desired index a valid item index ?
   */
  const validIndex = React.useCallback(
    (i: number) => i >= 0 && i < data.length - 1,
    [data]
  )

  /**
   * Will scroll to the desired view if
   * the targeted index is a valid one
   */
  const [next, previous] = React.useMemo(
    () =>
      [inc, dec].map((f) => () => {
        if (!validIndex(f(index)) || inactive_status) return
        setIndex(f(index))
      }),
    [setIndex, validIndex, index]
  )

  const onChangeIndex = React.useCallback(({ index }) => setIndex(index), [])
  const keyExtractor = React.useCallback((_, index) => `${index}`, [])
  const onLayout = React.useCallback(
    (event) => {
      setWidth(event.nativeEvent.layout.width)
      const currentHeight = event.nativeEvent.layout.height
      if (slotHeight == 0 && currentHeight > 0) {
        setSlotHeight(currentHeight - 100)
      }
    },
    [slotHeight]
  )

  return (
    <View style={{ height: '100%' }}>
      {loadingScreen ? (
        <AppointmentCardCalendarLoader height={height} />
      ) : (
        <>
          <View
            style={{ flex: 1, flexDirection: 'row', height: '100%' }}
            onLayout={(event) => {
              setHeight(event.nativeEvent.layout.height)
            }}>
            <IconButton
              name='cheveron-left'
              onPress={previous}
              size={22}
              containerSize={device === 'small' ? 32 : 64}
              color={
                validIndex(index - 1) && !inactive_status ? 'black' : '#e1e1e1'
              }
            />
            <View onLayout={onLayout} style={{ flex: 1 }}>
              <Container
                ref={scroll}
                data={dataToUse}
                keyExtractor={keyExtractor}
                horizontal
                // renderAll
                // onChangeIndex={onChangeIndex}
                width={width || 0}
                renderItem={renderItem}
                showsHorizontalScrollIndicator={false}
              />
            </View>
            <IconButton
              name='cheveron-right'
              onPress={next}
              size={22}
              containerSize={device === 'small' ? 32 : 64}
              color={
                validIndex(index + 1) && !inactive_status ? 'black' : '#e1e1e1'
              }
            />
          </View>
          {(device === 'large' || device === 'medium') &&
            !isEmpty &&
            isCalendarExpendable && (
              <CalendarLimit
                limited={limited}
                onChange={setLimited}
                seeMoreLabel={seeMoreLabel}
                seeLessLabel={seeLessLabel}
              />
            )}
        </>
      )}
    </View>
  )
}
