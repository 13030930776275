import * as React from 'react'
import { combineProviders } from '../lib/store'
import { SessionProvider } from './session'
import { LocaleProvider } from './locale'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UIProvider, ErrorPage } from '../../ui'
import { I18nProvider } from '../components/I18nProvider'
import ErrorBoundary from '@ui/Page/ErrorBoundary'
import { ErrorType } from '@ui/utils'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { SlotsQueryKeyName } from '@services/appointments'
import ReadyContextProvider from '@core/lib/ready/ReadyContentProvider'
import UpdateProvider from './updateProvider'

const cacheTime = 1000 * 60 * 60 * 24 // 24 hours

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime,
    },
  },
})

const localStoragePersistor = createWebStoragePersistor({
  storage: window.sessionStorage,
})

const BlacklistQueries: string[] = [SlotsQueryKeyName]

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: cacheTime,
  hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => {
      return !(
        Array.isArray(queryKey) &&
        BlacklistQueries.some((blacklistKeyName: string) =>
          queryKey.includes(blacklistKeyName)
        )
      )
    },
  },
})

/**
 * Used to be able to issue
 * requests through react-query
 * https://react-query.tanstack.com/quick-start
 */
function QueryProvider({ children }: { children: JSX.Element }) {
  const sentryFallback = React.useCallback(
    () => <ErrorPage disabledNavigation type={ErrorType.TECHNICAL_ERROR} />,
    []
  )
  return (
    <ErrorBoundary fallback={sentryFallback}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ErrorBoundary>
  )
}

export default combineProviders([
  I18nProvider,
  ReadyContextProvider,
  UIProvider,
  QueryProvider,
  LocaleProvider,
  SessionProvider,
  UpdateProvider,
])
