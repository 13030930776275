import * as React from 'react'
import { TitleSurTitleProps } from './TitleSurTitle.types'
import { Container, Content, SurContent } from './TitleSurTitle.style'
import { PageCentered } from '../PageCentered'

export default function Title({ children, surTitle }: TitleSurTitleProps) {
  return (
    <PageCentered>
      <Container>
        {surTitle && (
          <SurContent size='S' weight='bold' color='secondary'>
            {surTitle}
          </SurContent>
        )}
        <Content size='XXL' weight='bold'>
          {children}
        </Content>
      </Container>
    </PageCentered>
  )
}
