import React from 'react'
import { SubTitle } from '@ui/Moodwork-UI/atoms'
import { ResourceGrid } from '../../Resource/ResourceGrid'
import { isEmpty } from 'lodash'

export default function RecommendedResources({
  title,
  data,
  trackLabel,
  trackParentProps,
}: {
  title: string
  data?: any[]
  trackLabel: string
  trackParentProps: {}
}) {
  if (!data || isEmpty(data)) {
    return null
  }

  return (
    <>
      <SubTitle>{title}</SubTitle>
      <ResourceGrid
        itemWidth='298px'
        listHeight='145px'
        resources={data || []}
        trackLabel={trackLabel}
        trackParentProps={trackParentProps}
      />
    </>
  )
}
