import React from 'react'
import {
  Container,
  DescriptionContainer,
  FactorsContainer,
  MainFactorsContainer,
  PannelSelected,
  PannelSelectionContainer,
  PannelSelectionItem,
  PannelSelectionItemContainer,
  Separator,
} from './HistoryFactors.styles'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@ui/utils'
import { FlexCenter } from '@ui/Moodwork-UI'

export const HistoryFactorsLoading = () => {
  const theme = useTheme()
  return (
    <Container>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='75%'
        height='52'
        style={{ marginBottom: 18 }}>
        <Rect x='0' y='0' rx='3' ry='3' width='100%' height='28' />
        <Rect x='0' y='36' rx='3' ry='3' width='200' height='16' />
      </ContentLoader>
      <MainFactorsContainer>
        <ContentLoader
          backgroundColor={theme.colors.lightSecondaryGrey}
          width='100%'
          height='160'
          style={{
            maxWidth: 400,
            marginHorizontal: 10,
            marginBottom: theme.device === 'large' ? 56 : 32,
          }}>
          <Rect x='0' y='0' rx='3' ry='3' width='100%' height='160' />
        </ContentLoader>
        <ContentLoader
          backgroundColor={theme.colors.lightSecondaryGrey}
          width='100%'
          height='160'
          style={{
            maxWidth: 400,
            marginHorizontal: 10,
            marginBottom: theme.device === 'large' ? 56 : 32,
          }}>
          <Rect x='0' y='0' rx='3' ry='3' width='100%' height='160' />
        </ContentLoader>
      </MainFactorsContainer>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='75%'
        height='28'
        style={{ marginBottom: theme.device === 'large' ? 16 : 24 }}>
        <Rect x='0' y='0' rx='3' ry='3' width='100%' height='28' />
      </ContentLoader>
      {theme.device === 'large' ? (
        <PannelSelectionContainer>
          <PannelSelectionItemContainer>
            <PannelSelectionItem selected>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
            <PannelSelectionItem>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
            <PannelSelectionItem>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
            <PannelSelectionItem>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
            <PannelSelectionItem>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
            <PannelSelectionItem>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='55'>
                <Rect x='0' y='0' rx='3' ry='3' width='200px' height='16' />
                <Rect x='0' y='22' rx='3' ry='3' width='100%' height='16' />
              </ContentLoader>
            </PannelSelectionItem>
          </PannelSelectionItemContainer>
          <PannelSelected>
            <DescriptionContainer>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='60px'>
                <Rect x='0' y='0' rx='3' ry='3' width='100%' height='60' />
              </ContentLoader>
            </DescriptionContainer>
            <FactorsContainer>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='160'
                style={{
                  maxWidth: 400,
                  marginHorizontal: 10,
                  marginBottom: 32,
                }}>
                <Rect x='0' y='0' rx='3' ry='3' width='100%' height='160' />
              </ContentLoader>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width='100%'
                height='160'
                style={{
                  maxWidth: 400,
                  marginHorizontal: 10,
                  marginBottom: 56,
                }}>
                <Rect x='0' y='0' rx='3' ry='3' width='100%' height='160' />
              </ContentLoader>
            </FactorsContainer>
          </PannelSelected>
        </PannelSelectionContainer>
      ) : (
        <FlexCenter>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            width='100%'>
            <Rect x='0' y='0' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='24' rx='3' ry='3' width='100%' height='16' />
            <Rect x='0' y='48' rx='3' ry='3' width='100%' height='106' />
          </ContentLoader>
          <Separator height={1} color={'lightSecondaryGrey'} />
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            width='100%'
            height={308}
            style={{ marginBottom: 24 }}>
            <Rect x='0' y='0' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='24' rx='3' ry='3' width='100%' height='106' />
            <Rect x='0' y='154' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='178' rx='3' ry='3' width='100%' height='130' />
          </ContentLoader>
          <ContentLoader
            backgroundColor={theme.colors.lightSecondaryGrey}
            width='100%'
            height={280}>
            <Rect x='0' y='0' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='24' rx='3' ry='3' width='100%' height='16' />
            <Rect x='0' y='56' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='80' rx='3' ry='3' width='100%' height='16' />
            <Rect x='0' y='112' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='136' rx='3' ry='3' width='100%' height='16' />
            <Rect x='0' y='168' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='192' rx='3' ry='3' width='100%' height='16' />
            <Rect x='0' y='224' rx='3' ry='3' width='200' height='16' />
            <Rect x='0' y='248' rx='3' ry='3' width='100%' height='16' />
          </ContentLoader>
        </FlexCenter>
      )}
    </Container>
  )
}
