import React, { useState } from 'react'
import { SubTitle, Text } from '@ui/Moodwork-UI/atoms'
import { Container, Response, ResponseContainer, Wrapper } from './index.styles'
import { ProfilingQuestionsProps } from './index.types'

export default function ProfilingQuestions({
  question,
  responses,
  onPress,
  responseSelected,
  currentQuestion,
  questionsLength,
}: ProfilingQuestionsProps) {
  const [isSelected, setSelected] = useState(responseSelected)

  return (
    <Container>
      <SubTitle marginSize={'74px 0 16px 0'} alignPosition='center'>
        {question.trim()}
        <Text weight='bold' color={'grey'}>
          {' ' + currentQuestion + '/' + questionsLength}
        </Text>
      </SubTitle>
      <ResponseContainer>
        {(responses ?? []).map((r) => (
          <Wrapper hover={r.uuid === isSelected} color='secondary'>
            <Response
              onMouseEnter={() => setSelected(r.uuid)}
              onMouseLeave={() => setSelected(responseSelected)}
              onPress={() => onPress(r)}
              isSelected={
                isSelected ? r.uuid === isSelected : r.uuid === responseSelected
              }>
              <Text
                color={
                  (
                    isSelected
                      ? r.uuid === isSelected
                      : r.uuid === responseSelected
                  )
                    ? 'white'
                    : 'black'
                }
                alignPosition='center'
                weight='bold'>
                {r.content.trim()}
              </Text>
            </Response>
          </Wrapper>
        ))}
      </ResponseContainer>
    </Container>
  )
}
