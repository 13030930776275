import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppointmentCard,
  AppointmentCardLoading,
  AppointmentList,
  PageCentered,
  useDevice,
} from '../../ui'
import { ErrorType, Psychologist, IS_NATIVE } from '../../ui/utils'
import AppointmentCalendar from '../components/AppointmentCalendar'
import { useNextSlotContent } from '../lib/locale'
import { PsychologistsNavigation } from '../lib/types'
import { usePsychologists } from '../services/appointments'
import { ErrorPage } from '@moodwork-ui'
import { fetchModal } from '@core/lib/modal'
import { useUserInfo } from '@core/services/viewer'
import { MODAL_APPOINTMENT_TUTORIAL } from '@core/constants'
import { trackEvent } from '@core/lib/analytics'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'
import AppointmentComment from '@ui/Moodwork-UI/molecules/AppointmentComment'

function LoadingItem() {
  return (
    <PageCentered>
      <AppointmentCardLoading />
    </PageCentered>
  )
}

function Item({
  item: {
    next_event_slot,
    uuid,
    fullname,
    avatar,
    psy_job,
    psy_job_desc,
    event_kind_enabled,
    appointment_inactive,
    appointment_inactive_message,
    spoken_languages,
  },
  index,
}: {
  item: Psychologist
  index: number
}) {
  const device = useDevice()
  const navigation = useNavigation<PsychologistsNavigation>()
  const { i18n } = useTranslation()
  const nextSlotContent = useNextSlotContent(
    next_event_slot,
    fullname,
    i18n.language
  )

  const onPress = React.useCallback(() => {
    trackEvent('clicked_appointment_psychologist', {
      psychologist_picked: fullname,
      psychologist_rank: index + 1,
    }).then(() => {
      navigation.push('PsychologistCalendar', {
        nextSlotsDate: next_event_slot.date,
        psychologistName: fullname,
        psychologistAvatar: avatar.optimized_url || avatar.url,
        psychologistRank: index,
        psychologistId: uuid,
        eventKind: event_kind_enabled,
      })
    })
  }, [device])

  return (
    <PageCentered>
      <AppointmentCard
        psychologistId={uuid}
        locale={i18n.language}
        title={fullname}
        avatar={avatar.optimized_url || avatar.url}
        description={psy_job_desc}
        position={psy_job}
        nextSlotContent={nextSlotContent}
        onPress={onPress}
        next_event_slot={next_event_slot}
        event_kind={event_kind_enabled}
        inactive_status={appointment_inactive}
        inactive_message={appointment_inactive_message}
        spoken_languages={spoken_languages}
        renderCalendar={() => (
          <AppointmentCalendar
            psychologistId={uuid}
            psychologistName={fullname}
            psychologistAvatar={avatar.optimized_url || avatar.url}
            psychologistRank={index}
            nextSlot={next_event_slot}
            eventKind={event_kind_enabled}
            inactive_status={appointment_inactive}
            inactive_message={appointment_inactive_message}
          />
        )}
      />
    </PageCentered>
  )
}

export default function Psychologists() {
  const { data, isLoading, isError } = usePsychologists()
  const { data: userInfoData, isSuccess, isFetchedAfterMount } = useUserInfo()
  const navigation = useNavigation()
  const { t } = useTranslation('appointments')
  const [displayComment, setDisplayComment] = useState(false)
  const renderItem = React.useCallback(
    (item, index) => <Item key={item.uuid} item={item} index={index} />,
    []
  )
  const renderLoadingItem = React.useCallback(
    (_, index) => <LoadingItem key={index} />,
    []
  )

  useEffect(() => {
    if (isSuccess) {
      fetchModal(userInfoData?.email, MODAL_APPOINTMENT_TUTORIAL).then(
        (response) => {
          if (response != 'false' && userInfoData?.care_access) {
            navigation.navigate(MODAL_APPOINTMENT_TUTORIAL)
          }
        }
      )
    }
  }, [userInfoData?.email])

  useEffect(() => {
    if (isFetchedAfterMount) {
      setDisplayComment(!!userInfoData?.comment_last_event_id)
    }
  }, [isFetchedAfterMount])

  if (isError) {
    return <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  } else if (!userInfoData?.care_access) {
    return <ErrorPage type={ErrorType.CARE_ACCESS_ERROR} />
  } else {
    return (
      <>
        {IS_NATIVE && <BackButton navigation={navigation} />}
        {displayComment && (
          <AppointmentComment
            setVisible={setDisplayComment}
            eventId={userInfoData?.comment_last_event_id}
          />
        )}
        <AppointmentList
          chatBannerTitle={t('psychologists.list.chatBanner.title')}
          chatBannerEmptyTitle={t('psychologists.list.chatBanner.emptyTitle')}
          chatBannerContent={t('psychologists.list.chatBanner.content')}
          chatBannerEmptyContent={t(
            'psychologists.list.chatBanner.emptyContent'
          )}
          psychologists={data}
          isLoading={isLoading}
          renderItem={renderItem}
          renderLoadingItem={renderLoadingItem}
        />
      </>
    )
  }
}
