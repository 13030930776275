import * as Sentry from 'sentry-expo'
import * as React from 'react'

export default class ErrorBoundary extends React.Component<
  { fallback: () => JSX.Element },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.Browser.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.Browser.captureException(error)
    })
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback()
    }

    return this.props.children
  }
}
