import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../../../ui/utils/device'
import { Text } from '@moodwork-ui'

const web = css`
  box-shadow: -3px -3px 3px rgba(200, 200, 200, 0.1);
  transition: all 0.15s ease-in-out;
`
// TODO : remove negative margin-top
export const Box = styled.View<{ device: DeviceSizeType }>`
  shadowColor: "#000",
  shadow-offset: 0 1px;
  display: flex;
  flex-wrap: wrap;
  shadow-opacity: 0.22;
  shadow-radius: 2.22px;
  elevation: 5;
  background: #fff;
  width: 100%;
  margin-top: -74px;
  height: 74px;
  ${IS_BROWSER && web};
  ${({ device }) =>
    device === 'small' &&
    css`
      height: 100px !important;
      margin-top: -100px;
    `}
`

export const Row = styled.View`
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 50px !important;
  justify-content: center;
`

export const RightRow = styled.View<{ device: DeviceSizeType }>`
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 50px !important;
  justify-content: flex-end;
  ${({ device }) =>
    device === 'small' &&
    css`
      padding: 0 30px !important;
      justify-content: center;
    `}
`

export const RightColumn = styled.View`
  flex-direction: row;
  align-items: center;
  flex: auto;
  align-self: center;
`

export const Column = styled.View`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`

export const Link = styled(Text)`
  ${IS_BROWSER && css`cursor: pointer;`}
`

export const Bull = styled(Text)`
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
`

export const Pressable = styled.Pressable<{ device: DeviceSizeType }>`
  ${({ device }) =>
    device === 'small' &&
    css`
      margin-bottom: 10px;
      margin-right: 10px;
      align-items: center;
    `}
`

export const Logo = styled.Image`
  height: 54px;
  width: 105px;
  margin-top: 10px;
`

export const Language = styled.Pressable<{ device: DeviceSizeType }>`
  margin-left: 5px;
  margin-right: 5px;
  ${
    IS_BROWSER &&
    css`
    cursor: pointer;
  `
  }
  ${({ device }) =>
    device === 'small' &&
    css`
      margin-bottom: 10px !important;
      margin-left: 10px !important;
      margin-right: 10px;
    `}
`

export const SelectedLanguage = styled(Text)`
  margin-left: 5px;
  margin-right: 5px;
  ${
    IS_BROWSER &&
    css`
    cursor: pointer;
    pointer-events: none;
  `
  }
  ${({ device }) =>
    device === 'small' &&
    css`
      margin-bottom: 10px !important;
      margin-left: 10px !important;
      margin-right: 10px;
      margin-bottom: 10px;
    `}
`
export const StoreButton = styled.Pressable`
  flex: 1;
`

export const AndroidImageContainer = styled.View`
  flex: 1;
  margin-vertical: ${({ theme }) => `${theme.spacings.L}px`};
  min-width: 100px;
  max-width: 150px;
  min-height: 20px;
`

export const IosImageContainer = styled.View`
  flex: 1;
  min-width: 100px;
  max-width: 150px;
  min-height: 30px;
  margin-vertical: ${({ theme }) => `${theme.spacings.L}px`};
  ${({ theme }) =>
    theme.device != 'small' &&
    css`
      margin-right: ${({ theme }) => `${theme.spacings.L}px`};
   `}
   ${({ theme }) =>
     theme.device === 'large' &&
     css`
      margin-left: ${({ theme }) => `${theme.spacings.XXL}px`};
   `}
`

export const IosImage = styled.Image`
  flex: 1;
`

export const AndroidImage = styled.Image`
  flex: 1;
`
