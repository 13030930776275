import React, { useEffect } from 'react'
import { ResourceRecommendationCardProps } from './interface'
import { Resources } from '@services'
import { ResourceTitleLine } from '../ResourceBlock'
import { useTranslation } from 'react-i18next'
import { HtmlContainer } from '../ResourceBlock/ResourceBlock.styles'
import { ResourceGrid } from '../ResourceGrid'
import { trackEvent } from '@core/lib/analytics'

export const ResourceRecommendationCard = ({
  resourceUuid,
  resourceTitle,
  resourceTheme,
  trackableParams,
  collectionId,
}: ResourceRecommendationCardProps) => {
  const { data } = Resources.useResourceSuggestions(resourceUuid)
  const { t } = useTranslation(['resources'])

  useEffect(() => {
    if (data) {
      trackEvent('viewed_resource_nb_recommended_items', {
        ...trackableParams,
        nb_recommended_items: data.length,
        nb_recommended_items_read: data.filter((d) => d.state.status === 'done')
          .length,
      })
    }
  }, [data])

  if (data?.length > 0) {
    return (
      <>
        <ResourceTitleLine title={t('resources.suggestions.title')} />
        <HtmlContainer bottomMargin>
          <ResourceGrid
            itemWidth='270px'
            listHeight='150px'
            resources={data}
            trackLabel='clicked_resource_go_to_recommendation'
            trackParentProps={{
              collection_uuid: collectionId,
              uuid: resourceUuid,
              title: resourceTitle,
              theme: resourceTheme,
            }}
          />
        </HtmlContainer>
      </>
    )
  }
  // TODO: add skeleton

  return null
}
