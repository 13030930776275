import styled from 'styled-components/native'

export const Container = styled.TouchableOpacity<{ hover: boolean }>`
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: ${({ theme }) => `${theme.spacings.M}px`};
`

export const ImageContainer = styled.View`
  width: 100%;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
`
