import React, { useEffect } from 'react'
import { MarkProps } from './interface'
import { useTheme } from '@utils'
import {
  ContentWrapper,
  MarkContainer,
  RowContainer,
  StarsContainer,
  TextContainer,
  CheckIconContainer,
  FeedbackContainer,
} from './Mark.styles'
import { useTranslation } from 'react-i18next'
import { Text, StarCounter } from '@moodwork-ui'
import { Feedback } from '@ui/Moodwork-UI/molecules/Feedback'
import { Line } from '@ui/Moodwork-UI/atoms/Separator/Line'
import { FontIcon } from '@ui/Moodwork-UI/atoms'
import { useMark, useSendDiaryMark } from '@services/resources'
import { trackEvent } from '@core/lib/analytics'

export const Mark = ({ uuid, trackableParams }: MarkProps) => {
  const { i18n, t } = useTranslation(['resources'])
  const [mark, onChangeMark] = React.useState<number | null>(null)
  const [feedbackSent, onChangeFeedbackSent] = React.useState(false)
  const sendMark = useSendDiaryMark(uuid)
  const { data, isLoading, isError } = useMark(uuid)
  const theme = useTheme()

  const starPressed = (index: number) => {
    sendMark.mutate({ rate: index })
    onChangeMark(index)
  }

  const feedbackPress = (feedback: string, rate: number) => {
    sendMark.mutate({ rate: rate, comment: feedback })
    onChangeFeedbackSent(true)
  }

  useEffect(() => {
    if (sendMark.isError) {
      onChangeMark(null)
    }
  }, [sendMark.isError])

  useEffect(() => {
    if (data || isError) {
      trackEvent('viewed_resource_rating', {
        ...trackableParams,
        has_been_rated: data?.rate && !isError ? true : false,
        mark: data?.rate,
      })
    }
    if (data?.rate) {
      onChangeMark(data.rate)
      onChangeFeedbackSent(true)
    }
  }, [data, isError])

  if (isLoading) {
    return <></>
  }

  return (
    <MarkContainer>
      <Line color={'separatorGrey'} height={1} />
      <ContentWrapper>
        {!feedbackSent && mark && mark <= 3 ? (
          <Feedback
            text={t('resources.mark.feedback')}
            mark={mark}
            onPress={feedbackPress}
            trackableParams={{ ...trackableParams }}
          />
        ) : (
          <RowContainer>
            {mark ? (
              <FeedbackContainer>
                <CheckIconContainer>
                  <FontIcon
                    name='check-circle'
                    color={theme.colors.secondary}
                  />
                </CheckIconContainer>
                <Text size={theme.device === 'small' ? 'S' : 'M'}>
                  {t('resources.mark.feedback-thanks')}
                </Text>
              </FeedbackContainer>
            ) : (
              <>
                <TextContainer>
                  <Text size='M' weight='medium'>
                    {t('resources.mark.recommend')}
                  </Text>
                </TextContainer>
                <StarsContainer>
                  <StarCounter
                    trackLabel='clicked_resource_mark'
                    trackableParams={{ ...trackableParams, mark: data?.rate }}
                    onPress={starPressed}
                    mark={mark}
                  />
                </StarsContainer>
              </>
            )}
          </RowContainer>
        )}
      </ContentWrapper>
      <Line color={'separatorGrey'} height={1} />
    </MarkContainer>
  )
}
