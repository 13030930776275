import styled from 'styled-components/native'

export const Button = styled.Pressable<{ isActive: boolean }>`
width: 200px;
min-height: 56px;
margin: 8px 16px 0 0;
padding-horizontal: 32px;
padding-vertical: 4px;
border-radius: 9999px;
justify-content: center;
align-items: center;
background-color: ${({ theme, isActive }) =>
  isActive ? theme.colors.secondary : theme.colors.white};
`
