import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;
  flex-basis: undefined;
  background-color: #F5FAFF;
  flex-direction: row;
  overflow: hidden;
`

export const Content = styled.View`
  max-width: 100%;
  margin-right: -52px;
  width: 100%;
`

export const Separator = styled.View`
  height: 16px;
  width: 32px;
`

export const ArrowContainer = styled.Pressable`
  width: 52px;
  background-color: #F5FAFF;
  z-index: 10;
  box-shadow: -3px 0px 9px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
`
