import HTML, {RenderHTMLProps} from 'react-native-render-html'
import React from 'react'
import { HTMlViewProps } from './HTMLView.types'
import { htmlStyles } from './HTMLView.style'
import { useTheme } from '@ui/utils'

/**
 * TODO: on link press
 */
export const HTMLView = ({
  source,
  customHtmlStyles,
  textColor,
  big = false,
  small = false,
  ...restProps
} : HTMlViewProps & Pick<RenderHTMLProps, "contentWidth"> ) => {
  const theme = useTheme()
  return (
    <HTML
      key={theme.device}
      children='HTMLView'
      source={{ html: source }}
      systemFonts={[theme.fonts.bold, theme.fonts.regular, theme.fonts.medium, theme.fonts.semiBold]}
      tagsStyles={{ ...htmlStyles(theme, small ? 'small' : big ? 'big' : 'default', textColor), ...customHtmlStyles }}
      defaultTextProps={{ selectable: true }}
      {...restProps}
    />
  )
}
