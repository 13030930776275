import styled from 'styled-components/native'

export const Container = styled.View`
  min-height: 100%;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
`
export const Content = styled.View`
  flex: 1;
  flex-grow: 0;
  width: 100%;
  max-height: 100%;
  margin-top: 64px; 
  margin-bottom: 16px;
  padding-horizontal: 16px;
  flex-basis: undefined;
  align-items: center;
`
