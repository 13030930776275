import { FlexColumn, FlexRow } from '@ui/Moodwork-UI/templates/Flex'
import { Menu } from '@ui/Moodwork-UI/molecules/Menu'
import styled, { css } from 'styled-components/native'
import { HoverState, IS_BROWSER } from '@ui/utils'
import { Colors } from '@ui/UIProvider/UIProvider.types'
import { SmallParagraph } from '@ui/Moodwork-UI/atoms'

export const Page = styled(FlexRow)`
  height: 100%;
`

export const MenuLeft = styled(Menu)`
  padding: ${({ theme }) => `0px ${theme.spacings.XXXL}px`};
`

export const MenuContent = styled.View<{ headerIsTop: boolean }>`
  ${
    IS_BROWSER &&
    css`
      position: sticky;
      top: ${({ headerIsTop }) => (headerIsTop ? '132px' : '100px')};
      justify-content: flex-start;
      align-items: flex-start;
    `
  };
`

export const BackContainer = styled.View<HoverState>`
  padding-vertical: ${({ theme }) => `${theme.spacings.XXS}px`};
  background-color: ${({ theme, hover }) =>
    hover ? theme.colors.secondary : theme.colors.darkerGrey};
  border-radius: 3px;
  align-self: flex-start;
  margin-bottom: ${({ theme }) => `${theme.spacings.XL}px`};
`

export const ResourceContent = styled(FlexColumn)<{
  isLoading: boolean
  hasAudio?: string
}>`
  padding-top: ${({ isLoading, hasAudio, theme }) => {
    if (isLoading) {
      return '0px;'
    }
    if (theme.device === 'small') {
      return hasAudio ? '124px;' : '108px;'
    }
    if (theme.device === 'medium') {
      return hasAudio ? '206px;' : '184px;'
    }
    if (theme.device === 'large') {
      return hasAudio ? '220px;' : '188px;'
    }
  }};
  height: 100%;
  width: ${({ theme }) => (theme.device === 'large' ? '75%' : '100%')};
  ${({ theme }) =>
    theme.device === 'medium' &&
    css`
      padding-horizontal: 30px;
    `};
`
export const MiddleContent = styled(FlexColumn)`
  width: ${({ theme }) => (theme.device === 'large' ? '67%' : '100%')}
`

export const Bookmark = styled(FlexRow)`
  margin-top: 8px;
`

export const FeedbackContainer = styled.View<{ top: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  width: 25%;
  top: ${({ top }) => top}px;
  padding-right: ${({ theme }) => (theme.device === 'large' ? 50 : 20)}px;
  padding-left: 32px;
`

export const Feedback = styled.View<{ backgroundColor: keyof Colors }>`
  border-width: 1px;
  border-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  padding-horizontal: ${({ theme }) => theme.spacings.M}px;
  padding-bottom: ${({ theme }) => theme.spacings.M}px;
  width: 100%
`

export const HintText = styled(SmallParagraph)`
  color: ${({ theme }) => theme.colors.grey}
  ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return 'margin-left: 32px;'
      case 'medium':
        return 'margin-left: 26px;'
      case 'large':
        return 'margin-left: 50px;'
      default:
        return 'margin-left: 12px;'
    }
  }}
`
