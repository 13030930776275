import React, { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Pressable } from 'react-native'
import { Logo } from '../Logo'
import { OnDevice } from '../OnDevice'
import {
  Column,
  Container,
  HotlineIconContainer,
  RightColumn,
  Row,
  RowLarge,
  SearchBarContainer,
} from './Navigation.style'
import { NavigationDropdown } from '../NavigationDropdown'
import { NavigationProps } from './Navigation.types'
import { NavigationIcon } from '../NavigationIcon'
import { IS_BROWSER, useDevice } from '../utils/device'
import { NavigationLink } from '../NavigationLink'
import { dropdownReduced, dropdownUser } from './DropdownMenu'
import { useTranslation } from 'react-i18next'
import { Viewer } from '@core/services'

import {
  HOST,
  SCREEN_ASSESSMENT,
  SCREEN_HOME,
  SCREEN_LIBRARY,
  SCREEN_PSYCHOLOGISTS,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO,
  SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE,
  SCREEN_PSYCHOLOGIST_CALENDAR,
  SCREEN_SEARCH,
  SCREEN_WELLBEING_TRACKING,
} from '@core/constants'
import { SearchBar } from '@ui/Moodwork-UI/molecules/pressables/SearchBar'
import { FontIcon } from '@ui/Moodwork-UI/atoms/images/FontIcon'
import { useTheme } from '@ui/utils'
import { trackEvent } from '@core/lib/analytics'

export const LARGE_HEADER_SIZE = '80px'
export const LARGE_HEADER_REDUCED_SIZE = '50px'
export const MEDIUM_SMALL_HEADER_SIZE = '130px'

function Navigation({ expand }: NavigationProps) {
  const device = useDevice()
  const navigation = useNavigation()
  const viewerUserInfo = Viewer.useUserInfo()
  const theme = useTheme()

  const goHome = () => {
    trackEvent('clicked_header_home').then(() => {
      navigation.push(SCREEN_HOME)
    })
  }

  const goSearh = (text: string) => navigation.push(SCREEN_SEARCH, { q: text })

  const renderMenu = useCallback(
    (open) => (
      <NavigationIcon
        big={expand && IS_BROWSER}
        source={open ? 'cancel' : 'bars'}
      />
    ),
    [expand]
  )

  const { t } = useTranslation('header')

  if (viewerUserInfo.isLoading) {
    return (
      <Container big={expand} device={device}>
        <Column>
          <Pressable onPress={goHome}>
            <Logo big={expand} />
          </Pressable>
        </Column>
      </Container>
    )
  }

  const userInfo = viewerUserInfo.data

  return (
    <Container big={expand} device={device}>
      <OnDevice size={['small', 'medium']}>
        <>
          <Column>
            <Row>
              <Pressable onPress={goHome}>
                <Logo />
              </Pressable>
              <NavigationDropdown
                render={renderMenu}
                userInfo={userInfo}
                right={true}
                items={dropdownReduced}
                big={false}
              />
            </Row>
            <SearchBarContainer>
              <SearchBar
                inputPlaceHolder={t('header.search.placeholder')}
                onSearch={goSearh}
              />
            </SearchBarContainer>
          </Column>
        </>
      </OnDevice>
      <OnDevice size='large'>
        <>
          <RowLarge>
            <Pressable onPress={goHome}>
              <Logo big={expand} />
            </Pressable>
            <NavigationLink
              content={t('header.home')}
              screen={SCREEN_HOME}
              trackLabel='clicked_header_home'
            />
            {userInfo?.care_access && (
              <NavigationLink
                content={t('header.calendar')}
                screen={SCREEN_PSYCHOLOGISTS}
                groupScreen={[
                  SCREEN_PSYCHOLOGISTS,
                  SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE,
                  SCREEN_PSYCHOLOGIST_CALENDAR,
                  SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO,
                  SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION,
                ]}
                trackLabel='clicked_header_appointment'
              />
            )}
            <NavigationLink
              content={t('header.library')}
              screen={SCREEN_LIBRARY}
              trackLabel='clicked_header_library'
            />
            {!!userInfo && (
              <NavigationLink
                content={t('header.personal-tracking')}
                screen={
                  userInfo.user_tests > 0
                    ? SCREEN_WELLBEING_TRACKING
                    : SCREEN_ASSESSMENT
                }
                screenParams={
                  userInfo.user_tests === 0 && { key: 'wellbeing_assessment' }
                }
                trackLabel='clicked_header_assessment'
              />
            )}
            {!userInfo?.care_access && userInfo?.hotline?.link && (
              <NavigationLink
                prefix={
                  <HotlineIconContainer>
                    <FontIcon
                      name={'phone'}
                      size={16}
                      color={theme.colors.red}
                    />
                  </HotlineIconContainer>
                }
                content={t('header.hotline')}
                url={userInfo?.hotline?.link}
                trackLabel='clicked_header_hotline'
              />
            )}
          </RowLarge>
          <RightColumn>
            <SearchBarContainer>
              <SearchBar
                inputPlaceHolder={t('header.search.placeholder')}
                onSearch={goSearh}
              />
            </SearchBarContainer>
            <NavigationDropdown
              userInfo={userInfo}
              render={renderMenu}
              right={true}
              items={dropdownUser}
              big={expand}
            />
          </RightColumn>
        </>
      </OnDevice>
    </Container>
  )
}

export default Navigation
