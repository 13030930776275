import styled, { css } from 'styled-components/native'

export const Wrapper = styled.Pressable<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 80px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.small}
    ${({ selected }) =>
      selected &&
      css`
        border-color: ${({ theme }) => theme.colors.primary};
        border-width: 2px;
      `};
`
