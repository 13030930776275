import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../../../../utils/device'
import { DisabledState, HoverState } from '../../../../utils/types'

const web =
  IS_BROWSER &&
  css`
    
    cursor: pointer;
  `

export const Wrapper = styled.View<HoverState & { device: DeviceSizeType }>`
  display: flex;
  flex: 1;
  padding: 2px;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  width: 100%;
  min-height: 58px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.transparent};
  justify-content: center;
  ${(disabled) =>
    IS_BROWSER &&
    css`
      transition: all 0.3s ease-in-out;
      cursor: ${disabled ? 'default' : 'pointer'};
  `};
  ${({ hover }) =>
    hover &&
    css`
      border-color: ${({ theme }) => theme.colors.primary}};
    `}
`

export const Container = styled.View<DisabledState & { outline?: boolean }>`
  display: flex;
  flex: 1;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  align-items: center;
  justify-content: center;
  ${({ outline }) =>
    outline &&
    css`
      background-color: ${({ theme }) => theme.colors.transparent};
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.primary};
    `}
`

export const PressableIcon = styled.Pressable<DisabledState>`
    ${(disabled) =>
      IS_BROWSER && css`cursor: ${disabled ? 'default' : 'pointer'}`};
`
