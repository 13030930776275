import * as React from 'react'
import { ThemeProvider } from 'styled-components/native'
import defaultTheme from './defaultTheme'
import UIProviderProps from './UIProvider.types'
import { useFonts } from 'expo-font'
import { View } from 'react-native'
import { useDeviceSizeType } from '@utils'
import * as Sentry from 'sentry-expo'

import Asap_700Bold from '../../assets/fonts/Asap_700Bold.ttf'
import Asap_500Medium from '../../assets/fonts/Asap_500Medium.ttf'
import Asap_400Regular from '../../assets/fonts/Asap_400Regular.ttf'
import Inter_500Medium from '../../assets/fonts/Inter_500Medium.ttf'
import Asap_600SemiBold from '../../assets/fonts/Asap_600SemiBold.ttf'
import Inter_700Bold from '../../assets/fonts/Inter_700Bold.ttf'

export default function UIProvider({
  theme,
  children,
  locale,
}: UIProviderProps) {
  const [fontLoaded, error] = useFonts({
    Asap_700Bold: Asap_700Bold,
    Asap_500Medium: Asap_500Medium,
    Asap_400Regular: Asap_400Regular,
    Inter_500Medium: Inter_500Medium,
    Asap_600SemiBold: Asap_600SemiBold,
    Inter_700Bold: Inter_700Bold,
  })

  const device = useDeviceSizeType()
  const childrenWithTheme = React.useMemo(
    () => (
      <ThemeProvider theme={{ ...(theme || defaultTheme), device }}>
        {children}
      </ThemeProvider>
    ),
    [device]
  )

  React.useEffect(() => {
    if (error) {
      console.error(error)
      Sentry.Browser.captureException(error)
    }
  }, [error])

  /**
   * TODO:
   *  - Implement the loading page
   *  - or let the splashscreen
   */
  if (!fontLoaded && !error) {
    return <View />
  }
  return childrenWithTheme
}
