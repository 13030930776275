import React from 'react'
import { FontIcon } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import { Pressable } from 'react-native'

export const BookmarkButton = ({
  favorite,
  style,
  onFavorite,
}: {
  favorite: boolean
  onFavorite?: (value: boolean) => void
}) => {
  const theme = useTheme()
  return (
    <Pressable
      style={style}
      onPress={() => onFavorite && onFavorite(!favorite)}>
      <FontIcon
        name={favorite ? 'full-heart' : 'empty-heart'}
        size={17}
        color={favorite ? theme.colors.red : theme.colors.secondary}
      />
    </Pressable>
  )
}
