import React, { useCallback } from 'react'
import { useState } from 'react'
import { ActivityIndicator, Pressable } from 'react-native'
import { useDevice } from '../../../../utils/device'
import { FontIcon } from '@moodwork-ui'
import { Container, Wrapper, PressableIcon } from './Icon.style'
import IconProps from './Icon.types'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import { useToggle } from '@core/lib/hooks'
import { useNavigation } from '@react-navigation/native'

/**
 * TODO: remove the typescript ignores
 */
export const Icon = ({
  onPress,
  disabled,
  icon,
  trackLabel,
  outline,
  trackProperties,
}: IconProps & TrackableEventProps) => {
  const device = useDevice()
  const [hover, setHover, setNonHover] = useToggle(false)

  const onTrackedPress = useCallback(() => {
    trackEvent(trackLabel, trackProperties).then(() => {
      onPress && onPress()
    })
  }, [trackLabel, onPress])

  return (
    <PressableIcon
      testID='Button'
      onPress={onTrackedPress}
      // @ts-ignore
      onMouseEnter={setHover}
      // @ts-ignore
      onMouseLeave={setNonHover}
      disabled={disabled}
      focusable>
      <Wrapper hover={!disabled && hover} device={device}>
        <Container disabled={disabled} outline={outline}>
          <FontIcon name={icon} color='black' size={22} />
        </Container>
      </Wrapper>
    </PressableIcon>
  )
}
