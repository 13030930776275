import { Paragraph } from '@ui/Moodwork-UI/atoms'
import styled, { css } from 'styled-components/native'

export const TitleWrapper = styled.View`
    ${({ theme }) =>
      theme.device === 'large' &&
      css`
        flex: 1
    `}
    margin-right: 40px;
`
export const MedicalWarning = styled(Paragraph)`
  margin-top: ${({ theme }) => `${theme.spacings.M}px`};
`

export const AppointmentImage = styled.Image`
    height: 349px;
    width: 238px;
`
export const AppointmentImageContainer = styled.View`
    flex:1; 
    align-items: center;
`

export const HeaderWrapper = styled.View`
    flex-direction: ${({ theme }) =>
      theme.device === 'small' || theme.device === 'medium' ? 'column' : 'row'};
    margin-bottom: ${({ theme }) => `${theme.spacings.M}px`};
`

export const BurnoutChatImage = styled.Image`
width: 292px;
height: 262px;
`