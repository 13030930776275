import React from 'react'
import { useTheme } from '@ui/utils'
import image from '../../../../../assets/images/calendar.png'
import { useTranslation } from 'react-i18next'
import { OnDevice } from '@ui/OnDevice'
import { Button } from '@moodwork-ui'
import { HOST, SCREEN_PSYCHOLOGISTS } from '@core/constants'
import { useNavigation } from '@react-navigation/native'
import {
  ButtonContainer,
  DecorateImage,
  Description,
  RowContainer,
  Title,
} from './index.style'
import { DashboardCardContainer } from '@ui/Moodwork-UI/templates/Dashboard/index.style'

export default function EmptyAppointment({
  needAppointment,
  title,
  text,
}: {
  needAppointment: boolean
  title: string
  text: string
}) {
  const { t } = useTranslation(['dashboard', 'dates'])
  const navigation = useNavigation()

  return (
    <DashboardCardContainer highlight={needAppointment}>
      <Title>{t(title)}</Title>
      <OnDevice size={['large', 'medium']}>
        <RowContainer>
          <DecorateImage resizeMode='contain' source={image}></DecorateImage>
          <Description>{t(text)}</Description>
        </RowContainer>
      </OnDevice>
      <OnDevice size={['small']}>
        <Description>{t(text)}</Description>
      </OnDevice>
      <ButtonContainer>
        <Button
          label={t('dashboard.appointment.take-appointment')}
          trackLabel='clicked_db_book_appointment'
          onPress={() => navigation.push(SCREEN_PSYCHOLOGISTS)}
        />
      </ButtonContainer>
    </DashboardCardContainer>
  )
}
