import React from 'react'
import { useDevice, useTheme } from '@utils'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { RecommendationContainer } from './WellBeing.styles'
import { Text } from '@moodwork-ui'
import { useTranslation } from 'react-i18next'

export default function RecommandationsLoader() {
  const theme = useTheme()
  const device = useDevice()
  const { t } = useTranslation('result')

  return (
    <RecommendationContainer device={device}>
      <Text size='XXL' weight='bold' alignPosition='center'>
        {t('recommendations-title')}
      </Text>

      <ContentLoader
        backgroundColor={theme.colors.lightGrey}
        width='100%'
        height='150'>
        <Rect x='35%' y='30' rx='6' ry='6' width='30%' height='14' />
      </ContentLoader>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='100%'
        height='100%'>
        <Rect x='30%' y='0' rx='100' ry='100' width='90' height='90' />
        <Rect x='30%' y='95' rx='9' ry='9' width='90' height='14' />
        <Rect x='30%' y='120' rx='6' ry='6' width='90' height='7' />

        <Rect x='48%' y='0' rx='100' ry='100' width='90' height='90' />
        <Rect x='48%' y='95' rx='9' ry='9' width='90' height='14' />
        <Rect x='48%' y='120' rx='6' ry='6' width='90' height='7' />

        <Rect x='66%' y='0' rx='100' ry='100' width='90' height='90' />
        <Rect x='66%' y='95' rx='9' ry='9' width='90' height='14' />
        <Rect x='66%' y='120' rx='6' ry='6' width='90' height='7' />
      </ContentLoader>
    </RecommendationContainer>
  )
}
