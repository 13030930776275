import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@core/constants'

export function getCookie(name: string) {
  let cookie = {}
  document.cookie.split(';').forEach(function (el) {
    let [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[name]
}

export function setCookie(
  cname: string,
  cvalue: string | null,
  exdays: number = 30
) {
  if (!cvalue) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
    return
  }
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  let maxAge = null
  if (cname === ACCESS_TOKEN_KEY) {
    maxAge = 24 * 60 * 60 // 24 hours
  }
  if (cname === REFRESH_TOKEN_KEY) {
    maxAge = 3 * 30 * 24 * 60 * 60 // 3 months
  }
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    expires +
    ';path=/;secure' +
    (maxAge ? ';Max-Age=' + maxAge : '')
}
