import React, { useCallback, useEffect, useState } from 'react'
import { Spacer } from '../Spacer'
import psycho from '../../assets/appointmentInfo.png'
import { OnDevice } from '../OnDevice'
import { useSendAppointment } from '@core/services/appointments'
import {
  Content,
  InfoContent,
  Image,
  ImageContent,
  Label,
  SubjectTextInput,
  LoaderContainer,
  ActivityContainer,
  ActivityText,
  PhoneLabel,
  ReasonLabel,
} from './AppointmentInfo.style'
import { ErrorBlock } from '../ErrorBlock'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from 'react-native'
import { useTheme } from '../utils/styled'
import PhoneNumberInput from '../Moodwork-UI/atoms/PhoneNumberInput'
import { useDevice } from '@utils'
import { Button, Title } from '@moodwork-ui'
import { HTMLView } from '../Moodwork-UI/atoms/HTMLView'
import { useCompanyInfo } from '@core/services/users'
import { htmlStyles } from './disclaimerHTMLView.style'
import { AppointmentInfoProps } from './AppointmentInfo.types'
import AppointmentReminder from '@ui/AppointmentReminder'
import moment from 'moment-timezone'

export default function AppointmentInfo({
  eventId,
  eventType,
  eventDate,
  psyName,
  psyAvatar,
}: AppointmentInfoProps) {
  const companyInfo = useCompanyInfo()
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>()
  const navigation = useNavigation()
  const device = useDevice()
  const { t } = useTranslation(['appointments', 'dates'])
  const theme = useTheme()
  const [phoneValidation, setPhoneValidation] = useState(false)

  const [
    sendAppointment,
    appointmentResponse,
    error,
    isError,
    isLoading,
    isSuccess,
  ] = useSendAppointment()

  const onSend = useCallback(() => {
    if (!phoneValidation) {
      setPhoneNumberError(
        t('appointments:psychologists.appointmentInfo.phoneError')
      )
    } else {
      setPhoneNumberError(undefined)
      if (!isLoading) {
        sendAppointment({
          eventId: eventId,
          eventType: eventType,
          reason: subject,
          phoneNumber: phone,
        })
      }
    }
  }, [isLoading, phone, subject])

  useEffect(() => {
    if (!isLoading && isSuccess && appointmentResponse) {
      navigation.push('PsychologistAppointmentConfirmation', {
        eventDate: appointmentResponse.data.slot.date,
        eventType: appointmentResponse.data.kind,
        avatarUrl: appointmentResponse.data.slot.psychologist.avatar.url,
        psychologistFullname:
          appointmentResponse.data.slot.psychologist.fullname,
      })
    }
  }, [appointmentResponse, isError, isLoading, isSuccess])

  return (
    <>
      {isLoading && (
        <LoaderContainer>
          <ActivityContainer>
            <ActivityIndicator size='large' color={theme.colors.secondary} />
            <ActivityText color='secondary'>
              {t('appointments:psychologists.appointmentInfo.loading')}
            </ActivityText>
          </ActivityContainer>
        </LoaderContainer>
      )}
      <Content device={device}>
        <InfoContent>
          <AppointmentReminder
            date={eventDate}
            name={psyName}
            pictureUrl={psyAvatar}
          />
          <Title marginSize='0px'>
            {t(`appointments:psychologists.appointmentInfo.title.${eventType}`)}
          </Title>
          <>
            <Label size='L' weight='bold'>
              {t('appointments:psychologists.appointmentInfo.phoneTitle')}
            </Label>
            <PhoneLabel size='L'>
              {t('appointments:psychologists.appointmentInfo.phoneLabel')}
            </PhoneLabel>
            {phoneNumberError && <ErrorBlock content={phoneNumberError} />}
            <PhoneNumberInput
              value={phone}
              setPhone={setPhone}
              setPhoneValidation={setPhoneValidation}
            />
          </>
          <ReasonLabel size='L' weight='bold'>
            {t('appointments:psychologists.appointmentInfo.reasonLabel')}
          </ReasonLabel>
          <SubjectTextInput
            multiline={true}
            numberOfLines={8}
            value={subject}
            textAlignVertical='top'
            onChangeText={setSubject}
            autoCorrect={true}
          />
          {isError && (
            <>
              <ErrorBlock
                content={Object.values(error?.response.data.errors[0].detail)}
              />
              <Spacer size={32} />
            </>
          )}

          {companyInfo?.data?.rgpd?.display_disclaimer &&
            companyInfo?.data?.rgpd?.disclaimer && (
              <HTMLView
                source={companyInfo.data.rgpd.disclaimer}
                customHtmlStyles={htmlStyles}
              />
            )}

          <Button
            onPress={onSend}
            trackLabel='clicked_appointment_confirm'
            trackProperties={{
              appointment_type: eventType,
              psychologist_picked: psyName,
              date_picked: moment(eventDate.toString()).format('DD/MM/YYYY'),
              time_picked: moment(eventDate.toString()).format('HH[h]mm'),
            }}
            label={t(
              'appointments:psychologists.appointmentInfo.confirmationLabel'
            )}
          />
        </InfoContent>
        <OnDevice size={['medium', 'large']}>
          <ImageContent>
            <Image resizeMode={'contain'} source={psycho} />
          </ImageContent>
        </OnDevice>
      </Content>
    </>
  )
}
