import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@utils'

export default function WebinarListLoading() {
  const theme = useTheme()
  return (
    <ContentLoader
      backgroundColor={theme.colors.lightSecondaryGrey}
      width={'100%'}
      height={'280'}>
      <Rect x='0' y='0' rx='3' ry='3' width='200' height='18' />
      <Rect x='0' y='60' rx='3' ry='3' width='400' height='16' />
      <Rect x='0' y='80' rx='3' ry='3' width='120' height='12' />
      <Rect x='0' y='120' rx='3' ry='3' width='100%' height='2' />
      <Rect x='0' y='160' rx='3' ry='3' width='400' height='16' />
      <Rect x='0' y='180' rx='3' ry='3' width='120' height='12' />
    </ContentLoader>
  )
}
