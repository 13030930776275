import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { WebinarRegisterContainer, Icon, SuccessContainer } from './index.style'
import { LinkWithIcon } from '@moodwork-ui'
import { BigParagraph, SmallParagraph } from '@moodwork-ui'
import { Webinar } from '@types/webinar'
import { useTheme } from '@ui/utils'
import { DateTime } from 'luxon'

export default function WebinarCard({
  title,
  date,
  has_subscribed,
  onPress,
}: Webinar & { onPress: () => any }) {
  const { t, i18n } = useTranslation(['dashboard', 'dates'])
  const theme = useTheme()
  const fulldate = useCallback(
    (date) => {
      const datetime = DateTime.fromISO(date)
      return [
        datetime.toLocaleString({
          locale: i18n.language,
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        t('dates:at'),
        datetime.toLocaleString({
          locale: i18n.language,
          hour: '2-digit',
          minute: '2-digit',
        }),
      ].join(' ')
    },
    [i18n]
  )

  return (
    <>
      <BigParagraph weight='bold'>{title}</BigParagraph>
      <SmallParagraph color='grey'>
        {t('dashboard.webinar.online')} ‑ {fulldate(date)}
      </SmallParagraph>
      <WebinarRegisterContainer>
        {has_subscribed ? (
          <SuccessContainer>
            <SmallParagraph color='lightGreen'>
              {t('dashboard.webinar.has_subscribe')}
            </SmallParagraph>
            <Icon name='check-circle' color={theme.colors.lightGreen} />
          </SuccessContainer>
        ) : (
          <LinkWithIcon
            trackLabel=''
            IconPosition='right'
            iconName='arrow-right'
            iconSize={16}
            label={t('dashboard.webinar.subscribe')}
            onPress={onPress}
          />
        )}
      </WebinarRegisterContainer>
    </>
  )
}
