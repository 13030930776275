import styled, { css } from 'styled-components/native'
import { DeviceSizeType, IS_BROWSER } from '../utils/device'
import { themeColor } from '../utils/styled'
import { DisabledState, HoverState } from '../utils/types'

const web =
  IS_BROWSER &&
  css`
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  `

export const Wrapper = styled.View<HoverState & { device: DeviceSizeType }>`
  padding: 2px;
  border-radius: 27px;
  min-height: 52px;
  border-width: 2px;
  border-color: transparent;
  min-width: 230px;
  ${web};
  ${({ hover }) =>
    hover &&
    css`
      border-color: ${themeColor('primary')};
    `}
  ${({ device }) =>
    (device === 'medium' || device === 'large') &&
    css`
      max-width: 230px;
    `}
`

export const Container = styled.View<DisabledState & { outline?: boolean }>`
  background-color: ${themeColor('primary')};
  padding: 16px;
  min-height: 44px;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${web};
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${themeColor('primaryLight')};
    `}
  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;
      border-width: 2px;
      border-color: ${themeColor('primary')};
    `}
`

export const Content = styled.Text<{ outline?: boolean }>`
  color: black;
  font-family: Asap_700Bold;
  font-size: 16px;
  letter-spacing: 0.24px;
  ${({ outline }) =>
    outline &&
    css`
      color: black;
    `}
`
