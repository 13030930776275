import { Line, ActionTitle } from '@ui/Moodwork-UI/atoms'
import { DashboardCardContainer } from '@ui/Moodwork-UI/templates/Dashboard/index.style'
import { Text } from '@moodwork-ui'
import { Pressable } from 'react-native'
import styled from 'styled-components/native'

export const Container = styled(DashboardCardContainer)``

export const Title = styled(ActionTitle)`
  flex: 1;
  margin-bottom: ${({ theme }) => `${theme.spacings.L}px`};
`

export const ButtonContainer = styled.View`
  margin-top: ${({ theme }) => `${theme.spacings.XL}px`};
  align-self: flex-end;
  width: 100%;
`

export const AppointmentContainer = styled.View`
  flex-direction: row;
  align-items: center;
  display: flex;
`

export const Picture = styled.Image`
  height: 42px;
  width: 42px;
  margin-horizontal: ${({ theme }) => `${theme.spacings.S}px`};
`

export const ContainerRight = styled.View`
  display: flex;
  flex-direction: column;
`

export const Separator = styled(Line)`
  margin-vertical: ${({ theme }) => `${theme.spacings.XL}px`};
`

export const CancelButton = styled(Pressable)`
  align-self: ${({ theme }) =>
    theme.device === 'large' ? 'flex-end' : 'flex-start'}
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`

export const CancelText = styled(Text)`
  color: ${({ theme }) => theme.colors.red};
  margin-left: 8px;
`
