import styled from 'styled-components/native'
import { ContentProps } from './PageCentered.types'

export const Content = styled.View<ContentProps>`
  width: 100%;
  max-width: 1200px;
  padding: 0px 16px;
  align-self: center;
  flex-shrink: 0;
`
