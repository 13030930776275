import { SCREEN_COLLECTION, SCREEN_RESOURCE } from '@core/constants'
import { useNavigationLink } from '@core/lib/navigation'
import { useFavorites } from '@core/services/resources'
import { useNavigation } from '@react-navigation/native'
import { ResourceCard } from '@ui/Moodwork-UI/molecules/pressables/ResourceCard'
import React from 'react'
import { View } from 'react-native'
import HorizontalList from '../HorizontalList'
import { Container } from './index.styles'
import { useTheme } from '@utils'

export default function Highlight({ data }) {
  const theme = useTheme()
  const navigation = useNavigation()
  const linkTo = useNavigationLink()

  const { mutate, data: favorites } = useFavorites()

  const renderItem = React.useCallback(
    ({ item }) => {
      const isFavorite =
        favorites != null && favorites[item.uuid] !== undefined
          ? favorites[item.uuid]
          : item.favorite

      const link = () => {
        if (item.type === 'diary_groups') {
          navigation.push(SCREEN_COLLECTION, {
            id: item.uuid,
          })
        } else if (item.type === 'diaries') {
          navigation.push(SCREEN_RESOURCE, {
            id: item.uuid,
          })
        } else {
          linkTo(item.link)
        }
      }

      return (
        <View
          key={item.title}
          style={{
            width: 250,
            height: theme.device !== 'small' ? 240 : 200,
            backgroundColor: 'white',
          }}>
          <ResourceCard
            padding='0'
            uuid={item.uuid}
            type={item.type}
            date={item.date}
            title={item.title}
            tag={item.sticker?.name}
            tagKey={item.sticker?.key}
            duration={item.duration}
            image={item.image?.optimized_url || item.image?.url}
            favorite={isFavorite}
            state={item.state}
            sound={item.sound}
            onCardPress={link}
            onFavorite={mutate}
            link={item.link}
            bottomElement={item.bottom}
            sizeForInline={[]}
            count={item.collection_count}
            trackLabel='clicked_db_news_item'
            trackProperties={{
              url_redirection: item.link,
              item_name: item.title,
              item_tag: item?.sticker?.name,
            }}
          />
        </View>
      )
    },
    [favorites]
  )

  return (
    <Container>
      <HorizontalList
        arrowBackgroundColor='#F5FAFF'
        title={data?.title}
        data={data?.resources}
        bigTitle
        renderItem={renderItem}
      />
    </Container>
  )
}
