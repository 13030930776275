import * as React from 'react'
import { IS_BROWSER, useTheme } from '@ui/utils'
import { Spacer } from '../Spacer'

import { Input, Label } from './TextInput.style'
import { TextInputInterface } from './TextInput.types'
import { Pressable, TextInputProps, View, ViewProps } from 'react-native'
import { useToggle } from '@core/lib/hooks'
import { FontIcon } from '@ui/Moodwork-UI'

export default function TextInput({
  error,
  label,
  placeholder,
  value,
  onChange,
  disabled,
  type = 'text',
  onBlur,
  onFocus,
  style,
  inputStyle,
  hoverStyle,
  children,
  ...props
}: TextInputInterface & Pick<ViewProps, 'style'> & TextInputProps) {
  const theme = useTheme()
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const [hidePassword, , , toggleHidePassword] = useToggle(true)
  return (
    <View style={style}>
      <Label error={error}>{label}</Label>
      <Spacer size={4} />
      <Input
        onMouseEnter={onHoverIn}
        onMouseLeave={onHoverOut}
        error={error}
        editable={!disabled}
        keyboardType={type === 'email' ? 'email-address' : undefined}
        secureTextEntry={type === 'password' && hidePassword}
        autoCorrect={type === 'text'}
        placeholder={placeholder}
        placeholderTextColor={theme.colors.grey}
        value={value}
        onChangeText={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        style={hovered ? [inputStyle, hoverStyle] : inputStyle}
        {...props}
      />
      {type === 'password' && (
        <Pressable
          onPress={toggleHidePassword}
          style={{ position: 'absolute', right: 8, top: IS_BROWSER ? 14 : 28 }}>
          <FontIcon name={hidePassword ? 'eye-blocked' : 'eye'} size={24} />
        </Pressable>
      )}
      {children}
    </View>
  )
}
