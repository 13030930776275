import { trackEvent } from '@core/lib/analytics'
import { SearchNavigation } from '@core/lib/types'
import { useRoute } from '@react-navigation/native'
import { FontIcon, SmallParagraph } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import React from 'react'
import { Container, Box } from './index.styles'

export default function Checkbox({
  element,
  text,
  isActive,
  onPress,
  eventName,
}: {
  element?: string
  text: string
  isActive: boolean
  eventName?: string
  onPress: (key?: string) => void
}) {
  const route = useRoute<SearchNavigation>()
  const params = route.params
  const theme = useTheme()
  // TODO: use a key props instead of route for eventName
  const _onPress = (element?: string) => {
    if (element && eventName) {
      trackEvent(
        isActive
          ? `unchecked_${eventName}_display_box`
          : `checked_${eventName}_display_box`,
        {
          search_query: params?.q,
          display_only_box: element,
        }
      )
    }
    onPress(element)
  }

  return (
    <Container onPress={() => _onPress(element)}>
      <Box>
        {isActive && (
          <FontIcon name='check' size={12} color={theme.colors.primary} />
        )}
      </Box>
      <SmallParagraph>{text}</SmallParagraph>
    </Container>
  )
}
