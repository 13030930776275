import * as _ from 'lodash'
import { DateTime } from 'luxon'
import { chunk } from '../../ui/utils/helpers'
import { Slot } from '@ui/utils'

export type DaysMap = Map<string, Day>

export type SlotsQueryResult = {
  data: DaysMap
  pageParam: any
}[]

/**
 * TODO: functional class ? (return this)
 */
export class Day {
  empty: null | boolean
  slots: Slot[]
  date: DateTime
  locale: string

  static keyFormat = 'dd/MMMM/yy'

  constructor(date: DateTime, locale: string = 'fr', slots: Slot[] = []) {
    this.date = date
    this.slots = slots
    this.empty = !this.slots.length
    this.locale = locale
  }

  addSlot(slot: Slot) {
    return new Day(this.date, this.locale, this.slots.concat([slot]))
  }

  prettyDay(locale: string) {
    return this.date.setLocale(locale).weekdayLong
  }

  prettyDetails(locale: string) {
    return this.date.toFormat('dd MMMM', { locale: locale })
  }

  clone() {
    return new Day(this.date, this.locale, this.slots)
  }

  merge(day?: Day) {
    if (!day) return this.clone()
    const slots = _.unionWith(
      this.slots,
      day.slots,
      (a, b) => a.id === b.id
    ).sort((a, b) => {
      if (a.date.startOf('day') < b.date.startOf('day')) return -1
      return 1
    })
    let res = new Day(this.date, this.locale, slots)
    return res
  }

  get key() {
    return this.date.toFormat(Day.keyFormat)
  }
}

/**
 *
 * From a list of Slots and a list of Days, we attribute
 * each slot to their corresponding day
 *
 * The type of data as a parameter don't really matter
 * because it is an API response, we don't have control
 * over it
 *
 * the format is the following
 * {
 * date: string
 * id: string
 * }
 *
 */
export function deserializeSlots(days: DaysMap) {
  return function (result: { data?: any[] }): DaysMap {
    if (!result.data) return days
    for (const slot of result.data) {
      const date = DateTime.fromISO(slot.date)
      const day = new Day(date, undefined, [{ id: slot.id, date }])
      const newDay = days.get(day.key)?.merge(day)
      if (newDay) days.set(day.key, newDay)
    }
    return days
  }
}

export function isBefore(a: DateTime, b: DateTime) {
  return a.startOf('day') < b.startOf('day')
}

/**
 * Given a list of days, paginate them
 * according to the window size
 */
export function paginateDays(nextDays: DaysMap, pageSize: number) {
  const data = [...nextDays.values()]
  const ret = chunk(data, pageSize)
  return ret
}

/**
 * The calendar width depends on the screen width,
 * and we cannot predict screen resizing nor how many days will
 * appear per visible page.
 * So we need a way to merge all the pages into a single map
 */
export function flatSlotsPages(
  pages: SlotsQueryResult,
  starting?: DateTime
): DaysMap {
  let results = new Map() as DaysMap
  for (const page of pages) {
    /**
     * For each day of the next page,
     * we find corresponding days if applicable,
     * and merge them
     *
     * if the user wants the slots startign from a specific date,
     * we can just filter the previous
     * ones out
     *
     * NOTE: it should not happen as we specify the
     * days in the query filter
     */
    const keys = [...page.data.keys()]
    keys.forEach((key: string) => {
      const pageDay = page.data.get(key)
      const existing = results.get(key)
      if (pageDay && starting && isBefore(pageDay.date, starting)) {
        results.delete(key)
      } else if (existing) {
        results.set(key, existing.merge(pageDay))
      } else if (pageDay) {
        results.set(key, pageDay)
      } else {
        console.error('SHOULD NOT BE UNDEFINED')
      }
    })
  }

  return results
}

export function htmlToText(html: string) {
  return html.replace(/(<([^>]+)>)/gi, '')
}
