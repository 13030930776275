import styled from 'styled-components/native'
import { IS_BROWSER } from '@utils'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export const Container = styled.View`
  display: flex;
  flex: 1;
  background-color: white;
`

export const NavigationContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`

export const Content = styled(KeyboardAwareScrollView).attrs({
  keyboardShouldPersistTaps: 'handled',
  contentContainerStyle: {
    minHeight: IS_BROWSER ? '100vh' : '100%',
    flexGrow: 1,
  },
})`
  flex: 1;
  height: 100%;
  width: 100%;
  z-index: 1;
`
