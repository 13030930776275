import { Flex } from '@ui/Moodwork-UI/templates'
import { DashboardCardContainer } from '@ui/Moodwork-UI/templates/Dashboard/index.style'
import { IS_BROWSER } from '@ui/utils'
import styled, {css} from 'styled-components/native'

export const Container = styled(DashboardCardContainer)`
  flex-direction: row;
  align-items: center;
  ${({theme}) => IS_BROWSER && theme.device === 'small' && `cursor: pointer;`}
`

export const Image = styled.Image`
border-radius: 120px;
height: ${({ theme }) => {
  switch (theme.device) {
    case 'small':
      return `70px`
    default:
      return `120px`
  }
}};
aspect-ratio: 1;
margin-right: ${({ theme }) => theme.device === 'small' ? '16px' : '24px'};
`

export const TextContainer = styled(Flex)`
  flex: 1;
`

export const ButtonContainer = styled.View`
  justify-content: center;
  margin-left: 16px;
`

