import styled from 'styled-components/native'

export const Content = styled.View`
  flex: 1;
`

export const Input = styled.TextInput`
  margin-top: 16px
  margin-bottom: 16px
  width: 100%
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black};
`

export const ButtonWrapper = styled.View`
  width: ${({ theme }) => theme.widths.SelectionButton}
  margin-left: auto;
`

export const RowContainer = styled.View`
  flex-direction: row
  width: 100%
`

export const TextContainer = styled.View`
`
