import styled from 'styled-components/native'
import { themeColor } from '../utils/styled'

export const Container = styled.View`
  flex-direction: row;
  background-color: ${themeColor('error')};
  padding: 8px;
  border-radius: 4px;
`

export const IconContainer = styled.View`
  width: 32px;
  align-items: center;
  justify-content: center;
`
