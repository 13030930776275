import React, { useEffect, useMemo, useState } from 'react'
import { filter, find, isEmpty } from 'lodash'
import { useSearch } from '@core/services/search'
import { useRoute } from '@react-navigation/native'
import EmptySearch from '@ui/Moodwork-UI/molecules/Search/EmptySearch'
import SearchResults from '@ui/Moodwork-UI/molecules/Search/SearchResults'
import SearchLoading from '@ui/Moodwork-UI/molecules/Search/SearchResults/Search.loading'
import { ResourcePreview } from '@core/types/diary'
import SearchFilter from '@ui/Moodwork-UI/molecules/Search/SearchFilter'
import { PageView } from './index.styles'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@ui/utils'
import { SearchNavigation } from '@core/lib/types'
import { ResourceTheme, Sticker } from '@core/types/search'
import { trackEvent } from '@core/lib/analytics'
import { SCREEN_SEARCH } from '@core/constants'
import { PageContent } from '@ui/Moodwork-UI/templates/Page'

export default function Search() {
  const route = useRoute<SearchNavigation>()
  const params = route.params

  const theme = useTheme()
  const [themes, setThemes] = useState<ResourceTheme[]>([])
  const [stickers, setStickers] = useState<Sticker[]>([])

  let eventComponentName = ''
  if (route.name === SCREEN_SEARCH) {
    eventComponentName = 'search_results'
  } else {
    eventComponentName = 'library'
  }

  const [activeThemes, setActiveThemes] = useState<number[]>([])
  const [activeStickers, setActiveStickers] = useState<string[]>([])
  const [activeSpecialFilters, setActiveSpecialFilters] = useState<string[]>([])
  const specialFilters = activeSpecialFilters.reduce(
    (previous, current) => ({
      ...previous,
      [current]: true,
    }),
    {}
  )

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useSearch({
      q: params?.q,
      theme_ids: activeThemes,
      sticker_keys: activeStickers,
      ...specialFilters,
    })
  const results = useMemo(
    () =>
      data?.pages?.reduce(
        (array: ResourcePreview[], searchResult) =>
          array.concat(searchResult.results),
        []
      ),
    [data?.pages]
  )

  useEffect(() => {
    try {
      if (params?.themes) {
        setActiveThemes(JSON.parse(params?.themes))
      } else {
        setActiveThemes([])
      }
    } catch (e) {}
  }, [params?.themes])

  useEffect(() => {
    try {
      if (params?.favorites) {
        setActiveSpecialFilters(['favorites'])
      } else {
        setActiveSpecialFilters([])
      }
    } catch (e) {}
  }, [params?.favorites])

  useEffect(() => {
    if (data) {
      setThemes(data?.pages[0]?.themes)
      setStickers(data?.pages[0]?.stickers)
      route.name === SCREEN_SEARCH &&
        trackEvent(`viewed_${eventComponentName}_screen`, {
          search_query: data?.pages[0]?.search_terms,
          nb_search_results: data?.pages[0]?.total_count,
          active_filters: activeThemes
            .map((f) => find(themes, (t) => t.id === f)?.title)
            .concat(activeStickers)
            .concat(activeSpecialFilters),
        }).then()
    }
  }, [data])

  return (
    <PageContent>
      {!isEmpty(themes) ? (
        <SearchFilter
          activeStickers={activeStickers}
          setActiveStickers={setActiveStickers}
          numberOfSearch={data?.pages[0]?.total_count || 0}
          themes={themes}
          search={params?.q || ''}
          stickers={stickers}
          setActiveThemes={setActiveThemes}
          activeThemes={activeThemes}
          activeSpecialFilters={activeSpecialFilters}
          setActiveSpecialFilters={setActiveSpecialFilters}
          eventComponentName={eventComponentName}
        />
      ) : isLoading ? (
        <ContentLoader
          backgroundColor={theme.colors.lightSecondaryGrey}
          width='100%'
          height='250px'
          style={{ marginBottom: 24 }}>
          <Rect x='0' y='0' rx='3' ry='3' width='100%' height='100%' />
        </ContentLoader>
      ) : null}
      {isLoading ? (
        <SearchLoading />
      ) : isEmpty(results) ? (
        <EmptySearch
          search={params?.q}
          short={!isEmpty(activeThemes) || !isEmpty(activeStickers)}
          favorites={specialFilters['favorites']}
          eventComponentName={eventComponentName}
        />
      ) : (
        <SearchResults
          totalCount={data?.pages[0]?.total_count}
          results={results}
          loadMore={fetchNextPage}
          displayMore={!!hasNextPage}
          isLoading={isFetchingNextPage}
          eventComponentName={eventComponentName}
          activeFilters={activeThemes
            .map((f) => find(themes, (t) => t.id === f)?.title)
            .concat(activeStickers)
            .concat(activeSpecialFilters)}
        />
      )}
    </PageContent>
  )
}
