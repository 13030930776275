import {
  MEDIUM_SMALL_HEADER_SIZE,
  LARGE_HEADER_SIZE,
} from '@ui/Navigation/Navigation'
import styled, { css } from 'styled-components/native'
import { View } from 'react-native'
import { getHeaderHeight } from '@ui/Navigation/Navigation.style'
import { FlexRow } from '@ui/Moodwork-UI/templates'
import { BookmarkButton } from '@ui/Moodwork-UI/molecules/pressables/BookmarkButton'

export const Container = styled.View<{ reduce: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ theme }) => (theme.device === 'large' ? '25%' : '0')};
  right: 0;
  padding-left: ${({ theme }) => theme.spacings.XXXL + 'px'};
  padding-top: ${({ reduce, theme }) =>
    reduce
      ? theme.device === 'small'
        ? theme.spacings.XXL + 'px'
        : theme.spacings.L + 'px'
      : theme.spacings.XXXL + 'px'};
  padding-bottom: ${({ theme }) => theme.spacings.L + 'px'};
  padding-right: ${({ theme }) =>
    theme.device === 'large' ? '25%' : theme.spacings.XXXL + 'px'};
  background-color: ${({ theme }) =>
    theme.device === 'large'
      ? theme.colors.white
      : theme.colors.secondaryBackground};
  ${({ reduce }) => reduce && css`box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)`}
`

export const ButtonContainer = styled.Pressable<{ reduce: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spacings.L + 'px'};
  left: ${({ theme }) => theme.spacings.L + 'px'};
  z-index: 1;
`

export const TitleContainer = styled(FlexRow)`
  align-items: center;
  justify-content: ${({ theme }) =>
    theme.device === 'small' ? 'center' : 'flex-start'};
`

export const Bookmark = styled(FlexRow)`
  margin-bottom: 8px;
  margin-left: 8px;
`
