import styled from 'styled-components/native'

export const Flex = styled.View`
  display: flex;
`
export const FlexRow = styled(Flex)`
  flex-direction: row;
`

export const FlexRowCenter = styled(FlexRow)`
  align_items: center;
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const FlexCenter = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const FlexWrap = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
`
