import * as React from 'react'
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { Container, Content } from './Page.style'
import { PageProps } from './Page.types'
import { Footer } from '../../core/components/Footer'
import { IS_NATIVE } from '../utils/device'
import Header from '../../core/components/Header'
import { ErrorPage } from '@moodwork-ui'
import { ErrorType } from '@ui/utils'
import ErrorBoundary from './ErrorBoundary'
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { useUserInfo } from '../../core/services/viewer'
import createJumpgate from 'react-jumpgate'
import { setAnalyticUser, trackScroll } from '../../core/lib/analytics'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'
import { getDeviceLocale } from '@core/lib/locale'
import i18next from 'i18next'
import * as Sentry from 'sentry-expo'
import AlertHandler from '@core/components/Alerthandler'
import {
  SCREEN_PROFILING,
  SCREEN_REGISTRATION,
  SCREEN_SIGNUP_EMAIL_CONFIRMATION,
} from '@core/constants'

// Jumpgate to teleport component here
export const { Anchor, Consumer, Provider } = createJumpgate()
export const HeaderTopContext = React.createContext(true)
export const OnScrollContext = React.createContext<{
  onScroll: NativeSyntheticEvent<NativeScrollEvent> | Object
  setOnScroll: (
    onScroll: NativeSyntheticEvent<NativeScrollEvent> | Object
  ) => void
}>({
  onScroll: {},
  setOnScroll: () => {},
})
const SCROLL_SCALE_PERCENT = 20 // Tracking will be sent every SCROLL_SCALE incrementation
const SCROLL_BOTTOM_MARGIN_PX = 200 // remove 200 PX for 100%

export default function Page({
  children,
  allowGuest,
  hideHeader,
  hideFooter = false,
}: PageProps) {
  const [top, setTop] = React.useState(true)
  const [onScroll, setOnScroll] = React.useState<
    NativeSyntheticEvent<NativeScrollEvent> | Object
  >({})
  const topRef = React.useRef(true)
  const [trackedPercentScroll, setTrackedPercentScroll] = React.useState(0)
  const [analyticsReady, setAnalyticsReady] = React.useState(allowGuest)

  React.useEffect(() => {
    if (trackedPercentScroll > 0) {
      trackScroll(
        children.props?.route?.name,
        children.props?.route?.params,
        trackedPercentScroll
      ).then()
    }
  }, [trackedPercentScroll])

  const onContentScroll = React.useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      setOnScroll(event)
      const scroll = event.nativeEvent.contentOffset.y
      const totalPageHeight = event.nativeEvent.contentSize.height
      const frameHeight = event.nativeEvent.layoutMeasurement.height || 0
      const scrollRatio = Math.min(
        scroll / (totalPageHeight - frameHeight - SCROLL_BOTTOM_MARGIN_PX),
        1
      )

      const scrollScaledPercentage =
        Math.floor((scrollRatio * 100) / SCROLL_SCALE_PERCENT) *
        SCROLL_SCALE_PERCENT

      if (scroll > 100 && topRef.current) {
        topRef.current = false
        setTop(false)
      } else if (scroll <= 50 && !topRef.current) {
        topRef.current = true
        setTop(true)
      }

      if (scrollScaledPercentage > trackedPercentScroll) {
        setTrackedPercentScroll(scrollScaledPercentage)
      }
    },
    [top, trackedPercentScroll]
  )

  const navigation = useNavigation()
  const { data, isFetched, isFetching, error, isError } = useUserInfo()

  const isAllowed = allowGuest || !isError

  const sentryFallback = React.useCallback(
    () => <ErrorPage type={ErrorType.TECHNICAL_ERROR} />,
    []
  )

  const route = useRoute()

  const displayHeader = !hideHeader
  const displayFooter = !hideFooter

  React.useEffect(() => {
    if (route.path === '/messages' || route.params?.chat === 'true') {
      IntercomWrapper.displayMessenger()
    }
  }, [])

  React.useEffect(() => {
    const navigationHistory = navigation.getState().routes

    if (!data || !isFetched || isFetching) {
      return
    }

    if (route.name !== SCREEN_SIGNUP_EMAIL_CONFIRMATION) {
      if (
        route.name !== SCREEN_REGISTRATION &&
        navigationHistory[navigationHistory.length - 2]?.name !==
          SCREEN_REGISTRATION
      ) {
        if (data.questions_to_answer_type === 'registration') {
          navigation.push(SCREEN_REGISTRATION)
        }
      }

      if (
        route.name !== SCREEN_PROFILING &&
        navigationHistory[navigationHistory.length - 2]?.name !==
          SCREEN_PROFILING
      ) {
        if (data.questions_to_answer_type === 'profile') {
          navigation.push(SCREEN_PROFILING)
        }
      }
    }
  }, [isFetched, isFetching])

  React.useEffect(() => {
    if (data) {
      setAnalyticUser(data).then(() => {
        setAnalyticsReady(true)
      })
      const language = getDeviceLocale(data.selected_language)
      if (i18next.language !== language) {
        i18next.changeLanguage(route?.params?.locale ?? language)
      }
      Sentry.Browser.setUser({ id: data.marketing_id })
      if (!allowGuest) {
        IntercomWrapper.registerIdentifiedUser({
          email: data.chat_email,
        }).then()
        // change for both platforms (not the same variable name)
        // @ts-ignore
        IntercomWrapper.updateUser({
          languageOverride: language,
          language_override: language,
        }).then()
      }
    }
  }, [data])

  /**
   * get teleported component here to permit to display a sticky subHeader
   * due to some limitation with multiple scrollView / FlatList
   */

  return (
    <Container testID='Page'>
      <Anchor>
        <HeaderTopContext.Provider value={top}>
          <OnScrollContext.Provider
            value={{ onScroll: onScroll, setOnScroll: setOnScroll }}>
            <Content
              onScroll={onContentScroll}
              stickyHeaderIndices={[0]}
              scrollEventThrottle={50}>
              <>
                {displayHeader && <Header isTop={top} />}
                <Consumer />
              </>
              <ErrorBoundary fallback={sentryFallback}>
                {/* AlertHandler HOC to display throught AlertContext a message for the user */}
                <AlertHandler>
                  {isAllowed && analyticsReady
                    ? children
                    : !isFetching && (
                        <ErrorPage type={ErrorType.NOT_LOGGED_IN_ERROR} />
                      )}
                </AlertHandler>
              </ErrorBoundary>
              {displayFooter && <Footer />}
            </Content>
          </OnScrollContext.Provider>
        </HeaderTopContext.Provider>
      </Anchor>
    </Container>
  )
}
