import styled from 'styled-components/native'
import { ContainerProps } from './FeaturedContent.types'

export const Container = styled.View<ContainerProps>`
  flex-direction: row;
  width: 320px;
  height: 180px;
  background: ${({ theme, color }) => theme.colors[color] || 'red'};
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;
`

export const IconContainer = styled.View`
  width: 32px;
  align-items: center;
`

export const Content = styled.View`
  flex: 1;
`
