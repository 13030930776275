import React, { useMemo } from 'react'
import { DiaryGroup } from '@core/types/diary'
import { Container, TitleCollection, Description } from './index.styles'
import { DeviceSizeType, IS_NATIVE } from '@ui/utils'
import { ResourceGrid } from '@ui/Moodwork-UI/organisms/Resource/ResourceGrid'
import BackButton from '@ui/Moodwork-UI/molecules/pressables/BackButton'
import { useNavigation } from '@react-navigation/native'

export const CollectionTemplate = ({
  uuid,
  title,
  display_type,
  description,
  content,
}: DiaryGroup) => {
  const listMode = display_type === 'list'
  const sizeForInline = useMemo(
    () => (listMode ? ['small', 'medium', 'large'] : ['small', 'medium']),
    [listMode]
  ) as DeviceSizeType[]
  const navigation = useNavigation()
  return (
    <Container>
      {IS_NATIVE && <BackButton navigation={navigation} />}
      <TitleCollection>{title}</TitleCollection>
      {!!description && (
        <Description weight='medium'>{description}</Description>
      )}
      <ResourceGrid
        listMode={listMode}
        collectionLayout={{
          removeImageOverlay: true,
        }}
        cardLayout={{
          sizeForInline,
          displayCollectionProgress: true,
        }}
        resources={content}
        collection={uuid}
        trackParentProps={{
          collection_uuid: uuid,
          collection_title: title,
          nb_collection_items_total: content.length,
        }}
        trackLabel='clicked_collections_go_to_resource'
      />
    </Container>
  )
}
