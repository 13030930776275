import { queryClient } from '@core/store/Provider'
import { NavigationProp, useLinkTo } from '@react-navigation/native'
import { IS_BROWSER, IS_NATIVE } from '../../ui/utils/device'
import { ENV, HOST, INTERCOM_APP_ID, SCREEN_HOME } from '../constants'
import { last } from 'lodash'
import { deleteItems } from './storage'
import {
  MARKETING_ID_TOKEN_KEY,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_EXPIRATION_KEY,
  TIMEZONE_KEY,
  LOGGED_IN_ENV,
  SESSION_ID_KEY,
} from '../constants'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'
import { Linking } from 'react-native'

export const DISPLAY_IN_PROGRESS_SCREENS = ENV == 'staging' || IS_NATIVE

export function useNavigationLink() {
  const linkTo = useLinkTo()
  const signOut = useSignOut()
  return (url: string, newTab?: boolean) => {
    if (!url || process.env.IS_STORYBOOK) {
      return
    }

    if (url.includes('/users/sign_out')) {
      signOut(() => linkTo('/users/sign_in'))
    } else if (newTab && IS_BROWSER) {
      window.open(url, '_blank')
    } else if (IS_NATIVE) {
      try {
        let newUrl = url.replace(HOST, '')
        if (!url.startsWith('/')) {
          newUrl = '/' + newUrl
        }
        linkTo(newUrl)
      } catch (e) {
        if (IS_BROWSER) {
          window.location.href = url
        } else {
          Linking.openURL(url)
        }
      }
    } else {
      if (!url.startsWith(HOST)) {
        window.location.href = url
      } else {
        if (url.startsWith('/')) {
          linkTo(url)
        } else {
          Linking.openURL(url)
        }
      }
    }
  }
}

export function useSignOut() {
  const linkTo = useLinkTo()
  return (callback: () => void) => {
    if (
      !IS_NATIVE &&
      !window.location.href.endsWith('/users/sign_in') &&
      !window.location.href.includes('/users/sign_up') &&
      !window.location.href.includes('/password') &&
      !window.location.href.includes('/webinars') &&
      !window.location.href.includes('/confirmation')
    ) {
      queryClient.clear()
      fetch(`${HOST}/users/sign_out`, {
        method: 'GET',
        redirect: 'follow',
      }).then((res) => {
        console.log('DISCONNECT')
        sessionStorage.clear()
        window.location.href = '/users/sign_in'
      })
    } else if (IS_NATIVE) {
      deleteItems([ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY], callback)
    }
  }
}

// We don't use navigation.goBack which don't re-render the component, instead we find the last different component if none component is find we go on Dashboard
export function goBack(navigation: NavigationProp<any>) {
  if (!!navigation) {
    if (IS_NATIVE) {
      navigation.goBack()
      return
    }
    const state = navigation.getState()
    const routes = state.routes
    const currentRoute = last(state.routes)
    const lastRoutes = routes.filter((r) => r.name !== currentRoute?.name)
    if (lastRoutes.length > 0) {
      navigation.push(last(lastRoutes).name, last(lastRoutes).params)
    } else {
      navigation.push(SCREEN_HOME)
    }
  } else if (!IS_NATIVE) {
    window.history.back()
  }
}
