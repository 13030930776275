import styled from 'styled-components/native'
import { PAGE_HORIZONTAL_MARGINS } from '@core/constants'

export const List = styled.ScrollView.attrs({
  horizontal: true,
  showsVerticalScrollIndicator: false,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    paddingHorizontal: PAGE_HORIZONTAL_MARGINS,
  },
})``

export const Container = styled.View``

export const IconContainer = styled.View`
  width: 32px;
  align-items: center;
`
