import styled, { css } from 'styled-components/native'

export const Container = styled.Pressable<{ color: string }>`
  border-radius: 15px;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  padding: 4px;
  ${({ theme }) =>
    theme.device !== 'large' &&
    css`
    height: 170px;
    width: 128px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px
    `};
`

export const Border = styled.View<{ isHover?: boolean }>`
  padding: ${({ theme }) =>
    theme.device === 'large' ? '10px 26px' : '20px 8px'};
  flex-direction: ${({ theme }) =>
    theme.device === 'large' ? 'row' : 'column'};
  border-radius: 15px;
  align-items: center;
  ${({ isHover, theme }) => {
    if (theme.device !== 'large') return
    return (
      isHover &&
      css`
        padding: 8px 24px;
        border-color: white;
        border-width: 2px;
        border-style: solid;
      `
    )
  }};
`

export const Icon = styled.Image`
  width: 54px;
  height: 54px;
  ${({ theme }) =>
    theme.device === 'large' &&
    css`
    margin-right: 16px;
  `};
`

export const TextContainer = styled.View`
  flex-direction: column;
  flex-shrink: 1;
`
