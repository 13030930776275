import styled from 'styled-components/native'
import { Text } from '@moodwork-ui'

export const Container = styled.View`
  width: 84px;
  margin-bottom: 32px;
`

export const DateTimeText = styled(Text)`
  font-size: ${({ theme }) =>
    theme.device === 'small' ? theme.textSizes.S : theme.textSizes.XS};
`

export const Header = styled.View`
  height: 48px;
  margin-bottom: 32px;
`
