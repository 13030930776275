import * as styled from 'styled-components/native'
import defaultTheme from '../UIProvider/defaultTheme'
import { Theme } from '../UIProvider/UIProvider.types'

/**
 * gets a color from the theme
 * Usage:
 * background-color: ${themeColor('primary')}
 */
export function themeColor(name: keyof Theme['colors']) {
  return ({ theme }: { theme: Theme }) =>
    theme?.colors?.[name] || defaultTheme.colors[name]
}

export function useTheme() {
  return (styled.useTheme() || defaultTheme) as Theme
}
