import React from 'react'
import { CalendarLimitProps } from './CalendarLimit.types'
import { LinkWithIcon } from '@moodwork-ui'

export default function CalendarLimit({
  limited,
  onChange,
  seeMoreLabel,
  seeLessLabel,
}: CalendarLimitProps) {
  return (
    <LinkWithIcon
      iconName={!limited ? 'chevron-up' : 'chevron-down'}
      IconPosition='right'
      onPress={() => onChange(!limited)}
      textSize='S'
      label={limited ? seeMoreLabel : seeLessLabel}
      iconColor='link'
      iconSize={8}
    />
  )
}
