import { Theme } from '@ui/UIProvider'
import { DeviceSizeType } from '@ui/utils'
import theme from '../../../UIProvider/defaultTheme'
import { HtmlProps, HtmlTextProps } from './HTMLView.types'

export const h1 = (
  device: DeviceSizeType,
  textColor: string
): HtmlTextProps => ({
  fontFamily: theme.fonts.bold,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.L : theme.textSizes.XXL,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.L : theme.textLineHeights.XXL,
  marginBottom: device !== 'large' ? theme.spacings.L : theme.spacings.XXXL,
})

export const h2 = (
  device: DeviceSizeType,
  textColor: string
): HtmlTextProps => ({
  fontFamily: theme.fonts.bold,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.M : theme.textSizes.XL,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.M : theme.textLineHeights.XL,
  marginBottom: 16,
})

export const h3 = (
  device: DeviceSizeType,
  textColor: string
): HtmlTextProps => ({
  fontFamily: theme.fonts.bold,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.S : theme.textSizes.L,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.S : theme.textLineHeights.L,
  marginBottom: 8,
})

export const defaultText = (device: DeviceSizeType, textColor: string): HtmlTextProps => ({
  fontFamily: theme.fonts.regular,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.XS : theme.textSizes.S,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.XS : theme.textLineHeights.S,
  marginBottom: 0,
})

export const bigText = (device: DeviceSizeType, textColor: string): HtmlTextProps => ({
  fontFamily: theme.fonts.regular,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.S : theme.textSizes.M,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.S : theme.textLineHeights.M,
  marginBottom: 0,
})

export const smallText = (device: DeviceSizeType, textColor: string): HtmlTextProps => ({
  fontFamily: theme.fonts.regular,
  color: textColor,
  fontSize: device !== 'large' ? theme.textSizes.XXXS : theme.textSizes.XXS,
  lineHeight:
    device !== 'large' ? theme.textLineHeights.XXXS : theme.textLineHeights.XXS,
  marginBottom: 0,
})

export const paragraphText = (transform: 'big' | 'small' | 'default') => {
  switch(transform) {
    case 'big': 
      return bigText
      case 'small': 
      return smallText
      default:
        return defaultText
  }
}

export const htmlStyles = ({device, colors}: Theme, transform: 'big' | 'small' | 'default', customTextColor?: string): HtmlProps => {
  const textColor = customTextColor || colors.black

  let paragraph = paragraphText(transform)(device, textColor) 
  let css = {
    p: paragraph,
    div: paragraph,
    body: paragraph,
    a: {
      ...paragraph,
      color: customTextColor || colors.secondary,
      textDecorationColor: customTextColor || colors.secondary,
    },
    li: {
      ...paragraph,
      paddingLeft: 8,
      marginBottom: 0,
    },
    ul: {
      ...paragraph,
      paddingLeft: 16,
      marginTop: 0,
    },
    span: paragraph,
    b: {
      ...paragraph,
      fontFamily: theme.fonts.semiBold,
    },
    strong: {
      ...paragraph,
      fontFamily: theme.fonts.semiBold,
    },
    h1: h1(device, textColor),
    h2: h2(device, textColor),
    h3: h3(device, textColor),
  }
  return css
}
