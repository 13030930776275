import styled, { css } from 'styled-components/native'
import { TextProps } from './interface'

export const Text = styled.Text<TextProps>`
    color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.black};
    font-family:${({ weight, theme }) =>
      typeof weight === 'string' ? theme.fonts[weight] : theme.fonts.regular};
    text-align: ${({ alignPosition }) =>
      alignPosition ? alignPosition : 'left'};
    font-size: ${({ size, theme }) =>
      size ? theme.textSizes[size] : theme.textSizes.S};
    ${({ italic }) => italic && css`font-style: italic;`};
    text-decoration: ${({ underline }) => underline && 'underline'};
    text-decoration-color:${({ color, theme }) =>
      color ? theme.colors[color] : theme.colors.black};
    ${({ isLink }) =>
      isLink &&
      css`
        color: ${({ theme }) => theme.colors.blue};
    `};
    line-height: ${({ size, lineHeight, theme }) =>
      (lineHeight && theme.textLineHeights[lineHeight]) ||
      (size && theme.textLineHeights[size]) ||
      theme.textLineHeights.M}px;
    margin: ${({ marginSize }) => (marginSize ? marginSize : '0px')};
`

export const Title = styled(Text)`
  font-family: ${({ weight, theme }) =>
    typeof weight === 'string' ? theme.fonts[weight] : theme.fonts.bold};
  font-size: ${({ theme }) =>
    theme.device !== 'large' ? theme.textSizes.XL : theme.textSizes.XXL};
  line-height: ${({ theme }) =>
    theme.device !== 'large'
      ? `${theme.textLineHeights.L}px`
      : `${theme.textLineHeights.XXL}px`};
  margin: ${({ marginSize, theme }) =>
    marginSize ||
    (theme.device !== 'large'
      ? `0px 0px ${theme.spacings.L}px 0px`
      : `0px 0px ${theme.spacings.XXXL}px 0px`)};
`

export const SubTitle = styled(Text)`
  font-family: ${({ weight, theme }) =>
    typeof weight === 'string' ? theme.fonts[weight] : theme.fonts.bold};
  font-size: ${({ theme }) =>
    theme.device !== 'large' ? theme.textSizes.M : theme.textSizes.XL};
  line-height: ${({ theme }) =>
    theme.device !== 'large'
      ? `${theme.textLineHeights.M}px`
      : `${theme.textLineHeights.XL}px`};
`

export const ActionTitle = styled(Text)`
  font-family: ${({ weight, theme }) =>
    typeof weight === 'string' ? theme.fonts[weight] : theme.fonts.bold};
  font-size: ${({ theme }) =>
    theme.device !== 'large' ? theme.textSizes.L : theme.textSizes.XL};
  line-height: ${({ theme }) =>
    theme.device !== 'large'
      ? `${theme.textLineHeights.L}px`
      : `${theme.textLineHeights.XL}px`};
`

export const SectionTitle = styled(Text)`
  font-family: ${({ weight, theme }) =>
    typeof weight === 'string' ? theme.fonts[weight] : theme.fonts.bold};
  font-size: ${({ theme }) =>
    theme.device !== 'large' ? theme.textSizes.M : theme.textSizes.L};
  line-height: ${({ theme }) =>
    theme.device !== 'large'
      ? `${theme.textLineHeights.M}px`
      : `${theme.textLineHeights.L}px`};
`

export const BigParagraph = styled(Text)`
    font-size: ${({ theme }) =>
      theme.device !== 'large' ? theme.textSizes.S : theme.textSizes.M};
    line-height: ${({ theme }) =>
      theme.device !== 'large'
        ? `${theme.textLineHeights.S}px`
        : `${theme.textLineHeights.M}px`};
`

export const Paragraph = styled(Text)`
    font-size: ${({ theme }) =>
      theme.device !== 'large' ? theme.textSizes.XS : theme.textSizes.S};
    line-height: ${({ theme }) =>
      theme.device !== 'large'
        ? `${theme.textLineHeights.XS}px`
        : `${theme.textLineHeights.S}px`};
`

export const SmallParagraph = styled(Text)`
    font-size: ${({ theme }) =>
      theme.device !== 'large' ? theme.textSizes.XXS : theme.textSizes.XS};
    line-height: ${({ theme }) =>
      theme.device !== 'large'
        ? `${theme.textLineHeights.XXS}px`
        : `${theme.textLineHeights.XS}px`};
`
