import { FontIcon, SubTitle, BigParagraph } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FlatList } from 'react-native'
import {
  ArrowContainer,
  ArrowLeftContainer,
  ListContainer,
  Content,
  Separator,
} from './index.styles'
import { HorizontalListProps } from './index.types'

export default function HorizontalList({
  title = '',
  data = [],
  renderItem,
  titleInline = false,
  leftTitleMargin = '5%',
  arrowBackgroundColor = 'white',
  displayArrowShadow = true,
  bigTitle = false,
}: HorizontalListProps) {
  const listRef = useRef()
  const theme = useTheme()
  const [displayLeftArrow, setDisplayLeftArrow] = useState(
    theme.device !== 'small'
  )
  const [displayRightArrow, setDisplayRightArrow] = useState(
    theme.device !== 'small'
  )
  const [indexes, setIndexes] = useState<{
    first: number | null
    next: number | null
  }>({ first: null, next: null })

  const onViewableItemChanged = useCallback((e) => {
    const firstIndex = e.viewableItems[0]?.index
    const nextIndex = e.viewableItems[e.viewableItems.length - 1]?.index + 1
    setIndexes({ first: firstIndex, next: nextIndex })
  }, [])

  const viewabilityConfig = useMemo(() => {
    return { itemVisiblePercentThreshold: 100 }
  }, [])

  useEffect(() => {
    if (indexes.next && indexes.next >= data.length) {
      setDisplayRightArrow(false)
    } else {
      setDisplayRightArrow(true)
    }

    if (indexes.first && indexes.first > 0) {
      setDisplayLeftArrow(true)
    } else {
      setDisplayLeftArrow(false)
    }
  }, [indexes, data?.length])

  const TitleComponent = bigTitle ? SubTitle : BigParagraph

  return (
    <>
      <Content titleInline={titleInline}>
        <TitleComponent
          style={{ flex: 1 }}
          weight='bold'
          marginSize={`${
            titleInline ? '16' : '32'
          }px 0 -16px ${leftTitleMargin}`}>
          {title}
        </TitleComponent>

        {
          <ListContainer titleInline={titleInline}>
            <FlatList
              showsHorizontalScrollIndicator={false}
              ref={listRef}
              horizontal
              onViewableItemsChanged={onViewableItemChanged}
              viewabilityConfig={viewabilityConfig}
              onEndReachedThreshold={0}
              onEndReached={() => setDisplayRightArrow(false)}
              data={data || []}
              style={{ flex: 1 }}
              renderItem={renderItem}
              contentContainerStyle={{
                paddingTop: 16,
                paddingBottom: 64,
                paddingLeft: '5%',
                paddingRight: '10%',
              }}
              ItemSeparatorComponent={() => <Separator />}
            />
            {theme.device !== 'small' && displayLeftArrow && (
              <ArrowLeftContainer
                titleInline={titleInline}
                arrowBackgroundColor={arrowBackgroundColor}
                displayArrowShadow={displayArrowShadow}
                onPress={() => {
                  listRef?.current?.scrollToIndex({
                    index: indexes.first - 1,
                    viewPosition: 0.25,
                  })
                }}>
                <FontIcon
                  style={{ transform: [{ rotate: '-90deg' }] }}
                  size={13}
                  name='chevron-up'
                />
              </ArrowLeftContainer>
            )}
          </ListContainer>
        }
      </Content>

      {theme.device !== 'small' && displayRightArrow && (
        <ArrowContainer
          titleInline={titleInline}
          arrowBackgroundColor={arrowBackgroundColor}
          displayArrowShadow={displayArrowShadow}
          onPress={() => {
            listRef?.current?.scrollToIndex({
              index: indexes.next,
              viewPosition: 0.75,
            })
          }}>
          <FontIcon
            style={{ transform: [{ rotate: '90deg' }] }}
            size={13}
            name='chevron-up'
          />
        </ArrowContainer>
      )}
    </>
  )
}
