import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_EXPIRATION_KEY,
} from '../constants'
import { getCookie, setCookie } from './cookies'

export function fetchAuthTokens() {
  const [accessToken, refreshToken, tokenExpiration] = [
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    TOKEN_EXPIRATION_KEY,
  ].map(getCookie)
  return { accessToken, refreshToken, tokenExpiration }
}

export async function storeAuthTokens(
  accessToken: string | null,
  refreshToken: string | null,
  tokenExpiration: string | null
) {
  // TODO:  add encryption
  setCookie(ACCESS_TOKEN_KEY, accessToken)
  setCookie(REFRESH_TOKEN_KEY, refreshToken)
  setCookie(TOKEN_EXPIRATION_KEY, tokenExpiration)
}
