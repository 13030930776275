import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RegistrationQuestionsProps, ResponsesType } from './index.types'
import { StackActions, useNavigation } from '@react-navigation/native'
import { useRegistrationResponses } from '@core/services/profile'
import { useUserInfo } from '@core/services/viewer'
import { Container } from '../ProfilingSlides/index.styles'
import { Logo } from '@ui/Logo'
import { Button } from '@moodwork-ui'
import { useTranslation } from 'react-i18next'
import { RegistrationQuestion as RegistrationQuestionType } from '@core/types/profile'
import { RegistrationQuestion } from '@ui/Moodwork-UI/molecules/RegistrationQuestion'
import { AlertContext } from '@core/components/Alerthandler'
import { SCREEN_HOME, SCREEN_PROFILING } from '@core/constants'
import _ from 'lodash'
import { trackEvent } from '@core/lib/analytics'

export default function RegistrationQuestions({
  questions,
}: RegistrationQuestionsProps) {
  const { t } = useTranslation(['profiling', 'errors'])
  const navigation = useNavigation()
  const { mutateAsync, isSuccess, isError } = useRegistrationResponses()

  const [activeQuestions, setActiveQuestions] = useState<
    RegistrationQuestionType[]
  >([])
  const [responses, setResponses] = useState<ResponsesType[]>([])
  const { setMessage, setType } = useContext(AlertContext)
  const { remove } = useUserInfo()

  useEffect(() => {
    questions && setActiveQuestions([questions[0]])
  }, [questions])

  const handleSubmit = useCallback(() => {
    trackEvent('clicked_subsidiary_submit').then(() => {
      if (_.compact(activeQuestions).length === responses.length) {
        mutateAsync(responses.map((r) => r.rrUuid))
      } else {
        setType && setType('warning')
        setMessage(t('responseMissingError'))
      }
    })
  }, [activeQuestions, responses])

  useEffect(() => {
    if (isSuccess) {
      remove()
      navigation.dispatch(StackActions.replace(SCREEN_PROFILING))
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setType && setType('warning')
      setMessage(t('errors:technical.title'))
      setTimeout(() => {
        navigation.dispatch(StackActions.replace(SCREEN_HOME))
      }, 3000)
    }
  }, [isError])

  const nextQuestion = (currentResponse: ResponsesType) => {
    if (!currentResponse.rrUuid) {
      return
    }

    let newResponses = [currentResponse]
    responses.forEach((response) => {
      if (
        response.rqUuid !== currentResponse.rqUuid &&
        currentResponse.rqPriority > response.rqPriority
      ) {
        newResponses.push(response)
      }
    })

    const registrationResponseUuids = newResponses.map(
      (response) => response.rrUuid
    )
    const data = JSON.parse(JSON.stringify(questions))
    const newActiveQuestions = data.reduce((rqAcc, rq, rqIndex) => {
      var i = 0
      const rrs = rq.registration_responses.reduce((rrAcc, rr) => {
        if (
          !rr.required_response_uuid ||
          registrationResponseUuids.includes(rr.required_response_uuid)
        ) {
          rrAcc[i] = rr
          i++
        }
        return rrAcc
      }, [])
      rq.registration_responses = rrs
      if (
        currentResponse.rqPriority + 1 >= rq.priority &&
        rq.registration_responses.length &&
        (!rq.required_response_uuid ||
          registrationResponseUuids.includes(rq.required_response_uuid))
      ) {
        rqAcc[rqIndex] = rq
      }
      return rqAcc
    }, [])

    setActiveQuestions(newActiveQuestions)
    setResponses(newResponses)
  }

  return (
    <Container>
      <Logo big />
      {activeQuestions &&
        activeQuestions.map((question) => {
          return (
            <RegistrationQuestion
              key={question.uuid}
              currentQuestionValue={
                responses.filter((x) => x.rqUuid === question.uuid)[0]?.rrUuid
              }
              registrationQuestion={question}
              nextQuestion={nextQuestion}
            />
          )
        })}
      <Button label={t('submit')} onPress={handleSubmit} trackLabel={''} />
    </Container>
  )
}
