import styled from 'styled-components/native'

export const ContentWrapper = styled.View`
  width:  ${({ theme }) => (theme.device !== 'small' ? '600px' : '100%')} ;
  padding-horizontal: ${({ theme }) => `${theme.spacings.L}px;`}
  margin-vertical: ${({ theme }) => `${theme.spacings.XXL}px;`}
`

export const RowContainer = styled.View`
  flex-direction: row
  width: 100%
`

export const TextContainer = styled.View`
  margin-bottom: ${({ theme }) => `${theme.spacings.L}px;`}
`

export const NumbersContainer = styled.View`
`

export const CheckIconContainer = styled.View`
  margin-right: ${({ theme }) => `${theme.spacings.S}px;`} 
`

export const FeedbackContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`
