import * as React from 'react'
import { useState } from 'react'
import { ActivityIndicator, Pressable } from 'react-native'
import { useDevice } from '../utils/device'
import { Container, Content, Wrapper } from './Button.style'
import ButtonProps from './Button.types'

/**
 * TODO: remove the typescript ignores
 */
export default function Button({
  onPress,
  disabled,
  children,
  outline,
  loading,
}: ButtonProps) {
  const device = useDevice()
  const [hover, setHover] = useState(false)

  return (
    <Pressable
      testID='Button'
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={() => setHover(true)}
      // @ts-ignore
      onMouseLeave={() => setHover(false)}
      focusable>
      <Wrapper hover={!disabled && hover} device={device}>
        <Container disabled={disabled} outline={outline}>
          {loading ? (
            <ActivityIndicator color='white' />
          ) : (
            <Content outline={outline}>{children}</Content>
          )}
        </Container>
      </Wrapper>
    </Pressable>
  )
}
