import React from 'react'
import { GridViewProps } from './interface'
import { Grid, Container, GridElement } from './GridView.styles'
import { ScrollView } from 'react-native'

export const GridView = ({
  itemWidth = '33%',
  itemHeight = '50%',
  listHeight = '120px',
  listMode,
  items,
  renderItem,
}: GridViewProps) => {
  return (
    <Container>
      <Grid>
        {items.map((renderProps, index) => {
          return (
            <GridElement
              key={renderProps?.uuid}
              listMode={listMode}
              width={itemWidth}
              height={itemHeight}
              listHeight={listHeight}>
              {renderItem(renderProps, index)}
            </GridElement>
          )
        })}
      </Grid>
    </Container>
  )
}
