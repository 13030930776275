import React from 'react'
import WebinarList from '@ui/Moodwork-UI/organisms/Dashboard/WebinarList.tsx'
import { useWebinars } from '@core/services/webinars'
import WebinarListLoading from '../WebinarList.tsx/index.loading'
import { useSyncReady } from '@core/lib/ready'

export default function UpcomingWebinars() {
  const { data } = useWebinars()

  const isReady = useSyncReady('dashboard', 'upcomingwebinars', !!data)
  if (!isReady) {
    return <WebinarListLoading />
  }

  if (Array.isArray(data) && data?.length > 0) {
    return <WebinarList webinars={data} />
  } else {
    return null
  }
}
