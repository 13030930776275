import React from 'react'
import { Picker } from '@react-native-picker/picker'
import { SelectPickerProps } from './interface'
import { useTranslation } from 'react-i18next'
import { styles } from './SelectPicker.styles'
import { Text } from '@ui/Moodwork-UI'
import { useToggle } from '@core/lib/hooks'

export default function SelectPicker({
  label,
  emptyable = false,
  emptyLabel = '',
  value = '',
  onChange,
  options,
  pickerStyle,
  hoverStyle,
  itemStyle,
}: SelectPickerProps) {
  const { t } = useTranslation('profiling')
  const [hovered, onHoverIn, onHoverOut] = useToggle()

  return (
    <>
      {label && <Text size={'XS'}>{label}</Text>}
      <Picker
        onMouseEnter={onHoverIn}
        onMouseLeave={onHoverOut}
        selectedValue={value}
        onValueChange={onChange}
        style={
          hovered
            ? [styles.picker, pickerStyle, hoverStyle]
            : [styles.picker, pickerStyle]
        }
        itemStyle={itemStyle}>
        {emptyable && (
          <Picker.Item
            key={`select-picker-option-0-${Math.random() * 1000}`}
            label={emptyLabel || t('emptySelectLabel')}
            value={''}
          />
        )}
        {options &&
          options.map((option, index) => {
            return (
              <Picker.Item
                key={`select-picker-option-${emptyable ? index + 1 : index}-${
                  option.name
                }-${option.value}`}
                label={option.name}
                value={option.value}
              />
            )
          })}
      </Picker>
    </>
  )
}
