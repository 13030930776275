import { CancelEventModalNavigation } from '@core/lib/types'
import { useNavigation, useRoute } from '@react-navigation/native'
import { ActionTitle, Link } from '@ui/Moodwork-UI/atoms'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../pressables'
import { useCancelAppointments } from '@core/services/appointments'
import { ModalContent } from './index.styles'
import { SCREEN_HOME } from '@core/constants'
import { AlertContext } from '@core/components/Alerthandler'

export const CancelEventModal = () => {
  const { t } = useTranslation(['dashboard', 'errors'])
  const {
    eventId,
    appointment_type,
    psychologist_picked,
    date_picked,
    time_picked,
  } = useRoute<CancelEventModalNavigation>().params
  const navigation = useNavigation()
  const cancelEvent = useCancelAppointments()
  const { setMessage, setType, setOffsetY } = useContext(AlertContext)

  const handlePressConfirm = useCallback(() => {
    cancelEvent.mutate(eventId)
  }, [cancelEvent, eventId])

  useEffect(() => {
    if (cancelEvent.isSuccess) {
      setType && setType('success')
      setMessage(t('dashboard.appointment.cancel-appointment-modal.success'))
      setTimeout(() => {
        navigation.push(SCREEN_HOME)
      }, 3000)
    }
  }, [cancelEvent.isSuccess])

  useEffect(() => {
    if (cancelEvent.isError) {
      setType && setType('warning')
      setMessage(t('errors:technical.title'))
      setTimeout(() => {
        navigation.goBack()
      }, 3000)
    }
  }, [cancelEvent.isError])

  useEffect(() => {
    setOffsetY(80)
  }, [])

  return (
    <NavigationModal>
      <ModalContent>
        <ActionTitle marginSize='0px 0px 18px 0px'>
          {t('dashboard.appointment.cancel-appointment-modal.title')}
        </ActionTitle>
        <Button
          compact
          trackLabel={'clicked_db_cancel_appointment_yes'}
          trackProperties={{
            appointment_type: appointment_type,
            psychologist_picked: psychologist_picked,
            date_picked: date_picked,
            time_picked: time_picked,
          }}
          onPress={handlePressConfirm}
          label={t('dashboard.appointment.cancel-appointment-modal.confirm')}
        />
        <Link
          goBack
          trackLabel={'clicked_db_cancel_appointment_no'}
          text={t('dashboard.appointment.cancel-appointment-modal.cancel')}
          style={{ fontSize: 18, marginTop: 16 }}
        />
      </ModalContent>
    </NavigationModal>
  )
}
