import { TextInput } from '@ui/TextInput'
import styled from 'styled-components/native'

export const FormContainer = styled.View`
  width: 100%;
  margin-vertical: 32px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
`

export const Input = styled(TextInput)<{ fullWidth: boolean }>`
  width: ${({ theme, fullWidth }) =>
    theme.device === 'small' || fullWidth ? '100%' : '48%'};
`
