import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SCREEN_HOME } from '@core/constants'
import imgStep1 from '../../../../assets/images/profiling/profiling_1.png'
import {
  ButtonContainer,
  Next,
  NextContainer,
  Previous,
  PreviousContainer,
} from '@ui/AppointmentTutorial/AppointmentTutorial.style'
import { Icon } from '@ui/Moodwork-UI/molecules/pressables/Icon'
import { Logo } from '@ui/Logo'
import { BigParagraph, Title } from '@ui/Moodwork-UI/atoms'
import ProfilingQuestions from '@ui/Moodwork-UI/molecules/ProfilingQuestions'
import { StackActions, useNavigation } from '@react-navigation/native'
import { Container, Content, Image, ImageContainer } from './index.styles'
import { trackEvent } from '@core/lib/analytics'
import { ProfilingSlidesProps } from './index.types'
import { useProfileResponses } from '@core/services/profile'

export default function ProfilingSlides({
  questions,
  isRefresh = false,
}: ProfilingSlidesProps) {
  const { t } = useTranslation(['appointments', 'profiling'])
  const navigation = useNavigation()
  const [displayNext, setDisplayNext] = useState(false)
  const [displayPrevious, setDisplayPrevious] = useState(false)
  const [displayStart, setDisplayStart] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const { mutateAsync, isSuccess } = useProfileResponses()

  const [responses, setResponses] = useState<string[]>([])

  const handleStart = useCallback(
    (newResponses) => {
      mutateAsync(newResponses).then((e) => {
        setCurrentStep(0)
      })
    },
    [navigation]
  )

  useEffect(() => {
    if (isSuccess) {
      navigation.dispatch(StackActions.replace(SCREEN_HOME))
    }
  }, [isSuccess])

  const stepsGenerated = useMemo(() => {
    return questions.map((question, i) => {
      return {
        screen: (
          <Container>
            <Logo big />
            <ProfilingQuestions
              responseSelected={responses[i]}
              onPress={(res) => {
                trackEvent('clicked_profiling_answer', {
                  popin_step: currentStep + 1,
                  nb_steps_total: steps.length,
                  question: question.content,
                  question_id: question.uuid,
                  answer_id: res.uuid,
                  answer: res.content,
                })
                let newResponses = responses.splice(0)
                newResponses[i] = res.uuid
                setResponses(newResponses)
                if (questions.length === i + 1) {
                  handleStart(newResponses)
                } else {
                  handleNextStep()
                }
              }}
              question={question.content}
              responses={question.profile_responses}
              currentQuestion={currentStep}
              questionsLength={questions.length}
            />
          </Container>
        ),
        enableNext: responses[i],
        enablePrevious: true,
        enableStart: false,
      }
    })
  }, [questions, currentStep, responses])

  const steps = [
    {
      screen: (
        <Container>
          <Logo big />
          <ImageContainer>
            <Image resizeMode={'contain'} source={imgStep1} />
          </ImageContainer>
          <Content>
            <Title alignPosition='center' marginSize={'0 0 24px 0'}>
              {t(isRefresh ? 'profiling:refreshTitle' : 'profiling:title')}
            </Title>
            <BigParagraph alignPosition='center'>
              {t(
                isRefresh
                  ? 'profiling:refreshDescription'
                  : 'profiling:description'
              )}
            </BigParagraph>
          </Content>
        </Container>
      ),
      enableNext: true,
      enablePrevious: false,
      enableStart: false,
    },
    ...stepsGenerated,
  ]

  const handleNextStep = useCallback(() => {
    //if (!steps[currentStep + 1]) return
    setCurrentStep(currentStep + 1)
  }, [currentStep, questions])

  const handlePreviousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }, [currentStep, questions])

  useEffect(() => {
    const step = steps[currentStep]
    if (step) {
      setDisplayNext(step['enableNext'])
      setDisplayPrevious(step['enablePrevious'])
      setDisplayStart(step['enableStart'])
    }
  }, [currentStep])

  return (
    <>
      <Container>
        {currentStep >= 0 && steps[currentStep]['screen']}
        <ButtonContainer>
          <PreviousContainer
            displayElement={displayPrevious}
            onPress={handlePreviousStep}>
            <Icon
              disabled={!displayPrevious}
              icon='arrow-left'
              onPress={handlePreviousStep}
              trackLabel='clicked_profiling_previous'
              trackProperties={{
                popin_step: currentStep + 1,
                nb_steps_total: steps.length,
              }}
            />
            <Previous>{t('tuto.buttons.back')}</Previous>
          </PreviousContainer>
          {displayNext && (
            <NextContainer onPress={handleNextStep}>
              <Next>
                {t(currentStep === 0 ? 'profiling:start' : 'tuto.buttons.next')}
              </Next>
              <Icon
                icon='arrow-right'
                onPress={handleNextStep}
                trackLabel='clicked_profiling_begin'
                trackProperties={{ nb_steps_total: steps.length }}
              />
            </NextContainer>
          )}
          {!displayNext && !displayStart && <NextContainer />}
        </ButtonContainer>
      </Container>
    </>
  )
}
