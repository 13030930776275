import styled from 'styled-components/native'

export const Container = styled.View<{ type: 'primary' | 'secondary' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => `${theme.spacings.XS}px ${theme.spacings.L}px`};
  background-color: ${({ theme, type }) =>
    type === 'primary' ? theme.colors.lightBlue : theme.colors.grey};
  margin-right: ${({ theme }) => `${theme.spacings.M}px`};
  margin-bottom: ${({ theme }) => `${theme.spacings.S}px`};
`
