/**
 *  Copied from the version I made on V2:
 *
 *  TODO:
 *    - adapt to the project architecture
 *    - use styled-components/native
 *    - Make a AuthBrowser.web.tsx to just launched a popup or something else
 */
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { BackHandler, View } from 'react-native'
import { WebView } from 'react-native-webview'
import { HOST } from '../../constants'
import * as Linking from 'expo-linking'
import { AuthBrowserRoute } from '../../lib/types'
import * as Session from '../../store/session'
import style from './style'

export default function AuthBrowser() {
  const navigation = useNavigation()
  const session = Session.useState()

  /**
   * cancelURL: => If the authentication fail, what
   * app route should the component redirect to ?
   * It should probably be Signin or Signup at this stage
   */
  const { cancel: cancelUrl, url } = useRoute<AuthBrowserRoute>().params

  /**
   * The code is an Interval that will check the buttons,
   * and look for a moodwork deep link inside the webview
   * Once it is found, it will send a message to the React Native Host
   * NOTE: This function is executed inside the webview
   */
  const runFirst = `
    setInterval(() => {
      const button = document.querySelector('#redirect')
      window.ReactNativeWebView.postMessage(button.href)
    }, 1500)
  `

  const cancel = () =>
    navigation.push(cancelUrl, { error: 'cancel', data: null })

  /**
   * On success, we parse the deep link,
   * then use its informations and store it in the
   * client state.
   * If something went wrong, we call cancel
   * otherwise we redirect to the HomePage
   *
   * It is not optimal. We should be using expo auth session I think
   * TODO
   */
  const logged = (link: string) => {
    const parsed = Linking.parse(link).queryParams
    if (
      typeof parsed?.access_token === 'string' &&
      typeof parsed?.refresh_token === 'string'
    ) {
      session.dispatch(
        Session.setTokens({
          accessToken: parsed.access_token,
          refreshToken: parsed.refresh_token,
          tokenExpiration: '' + Date.now(),
        })
      )
      return navigation.push('Home')
    } else cancel()
  }

  /**
   * Back button press needs to close the modal
   * and not actually going back
   */
  useEffect(
    () =>
      BackHandler.addEventListener('hardwareBackPress', () => {
        cancel()
        return true
      }).remove,
    []
  )

  /**
   * Each Message from the webview will be caught in this function
   * If one of the message is a moodwork URL,
   * then open it and close the modal
   *
   * TODO: remove any
   */
  const onMessage = (e: any) => {
    if (e.nativeEvent.data.startsWith('moodwork://')) {
      logged(e.nativeEvent.data)
    }
  }

  return (
    <View style={style.container}>
      {url && (
        <WebView
          style={style.webview}
          source={{ uri: `${HOST}${url}` }}
          javaScriptEnabled={true}
          userAgent='Mozilla/5.0 (Linux; Android 4.1.1; Galaxy Nexus Build/JRO03C) AppleWebKit/535.19 (KHTML, like Gecko) Chrome/18.0.1025.166 Mobile Safari/535.19'
          onError={(e) => {
            console.error('🚨', e)
          }}
          onMessage={onMessage}
          scrollEnabled={false}
          onHttpError={cancel}
          injectedJavaScript={runFirst}
        />
      )}
    </View>
  )
}
