import { DashboardData } from '@core/types/dashboard'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { useHttp } from './http'

export function useDashboard(): UseQueryResult<DashboardData, AxiosError> {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getEntrypoint = async () => (await http.get(`/api/v3/dashboard`))?.data
  return useQuery(['dashboard', i18n.language], getEntrypoint, {
    retry: 1,
    staleTime: 30,
    refetchOnWindowFocus: false,
  })
}

export function useRecommendedResources(
  numberOfResult: number = 4,
  lastReadAnalyse: number = 3
) {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getRecommendedResources = async () =>
    (
      await http.get(
        `/api/v3/recommendations/for_you?max_recommended_diaries=${numberOfResult}&last_n_read_diaries=${lastReadAnalyse}`
      )
    )?.data
  return useQuery(
    ['recommendedResourcesForYou', i18n.language],
    getRecommendedResources,
    {
      retry: 1,
      staleTime: 1000 * 60,
    }
  )
}

export function useRecommendedResourcesWellBeing() {
  const http = useHttp()
  const { i18n } = useTranslation()
  const getRecommendedResourcesWellBeing = async () =>
    (await http.get(`/api/v3/recommendations/wellbeing_assessment`))?.data
  return useQuery(
    ['recommendedResourcesWellBeing', i18n.language],
    getRecommendedResourcesWellBeing,
    {
      retry: 1,
      staleTime: 1000 * 60,
    }
  )
}

export function useSendNPS() {
  const http = useHttp()

  const sendNPS = ({
    counter,
    comment,
    mark,
  }: {
    counter: number
    mark: number
    comment?: string
  }) =>
    http.post(
      `/api/v1/feedback`,
      {
        counter,
        comment,
        mark,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

  return useMutation('post:nps', sendNPS, { retry: 1 })
}
