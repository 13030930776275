import { initializeApp } from 'firebase/app'
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import { User } from '../types/user'
import * as Sentry from 'sentry-expo'
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_SENDER_ID,
  FIREBASE_STORAGE_BUCKET,
} from '@core/constants'

export type TrackableEventProps = {
  trackLabel: string
  trackProperties?: any
}

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
const webAnalytics = getAnalytics(app)

export async function trackScroll(
  scope: string,
  params: {},
  percentage: number
) {
  try {
    logEvent(webAnalytics, 'Scroll', {
      scope: scope,
      params: params,
      percentage: percentage,
    })
  } catch (e) {
    Sentry.Browser.captureException(e)
  }
}

export async function trackScreen(screenName: string, screenClass: string) {
  try {
    // https://firebase.google.com/docs/analytics/screenviews#web-version-9
    logEvent(webAnalytics, 'screen_view', {
      screen_name: screenName,
      screenClass: screenClass,
      firebase_screen: screenName,
      firebase_screen_class: screenClass,
    })
  } catch (e) {
    Sentry.Browser.captureException(e)
  }
}

export async function trackEvent(name: string, properties?: any) {
  try {
    return new Promise((res) =>
      setTimeout(() => {
        logEvent(webAnalytics, name, {
          ...properties,
        })
        res(true)
      }, 1)
    )
  } catch (e) {
    Sentry.Browser.captureException(e)
  }
}

export async function setAnalyticMarketingId(marketingId: string) {
  setUserId(webAnalytics, marketingId)
  await setUserProperties(webAnalytics, { marketing_id: marketingId })
}

export async function setAnalyticUser(user: User) {
  try {
    return new Promise((res) => {
      setTimeout(async () => {
        if (user != null) {
          if (user.marketing_id != null) {
            await setAnalyticMarketingId(user.marketing_id)
          }
          if (user.entity_id != null) {
            await setUserProperties(webAnalytics, { entity_id: user.entity_id })
          }
          if (user.company_id != null) {
            await setUserProperties(webAnalytics, {
              company_id: user.company_id,
            })
          }

          await setUserProperties(webAnalytics, {
            user_type:
              user.user_type || (user.email != null ? 'logged' : 'anonymous'),
          })
        }
        res(true)
      }, 1)
    })
  } catch (e) {
    Sentry.Browser.captureException(e)
    console.log('Failed to capture user details', e)
  }
}
