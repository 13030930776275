import { IS_NATIVE } from '@ui/utils'
import styled, { css } from 'styled-components/native'

const web =
  !IS_NATIVE &&
  css`
    cursor: pointer;
  `

export const Container = styled.View`
  padding: ${({ theme }) => (theme.device === 'large' ? '56px 0' : '0')};
`

export const Content = styled.View`
  margin: ${({ theme }) =>
    theme.device === 'large' ? '32px 10%' : '32px 16px'};
  display: flex;
  flex-direction: row;
`

export const ContentNavigation = styled.View`
  width: ${({ theme }) => (theme.device === 'small' ? '15%' : '28%')};
`

export const ChildContent = styled.View`
  width: ${({ theme }) => (theme.device === 'small' ? '85%' : '72%')};
`

export const SupportTab = styled.Pressable<{ active: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.colors.secondary : theme.colors.greyBlue};
  ${web}
  height: 98px;
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
    padding-left: 23px;
    padding-right: 10px;
  `}
  display: flex;
  justify-content: center;
}
`

export const FormHeading = styled.View`
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
      height: 98px;
  `}
  padding: ${({ theme }) =>
    theme.device === 'small' ? '0px 18px' : '0px 0px 0px 48px'};
  display: flex;
  justify-content: center;
`

export const FormContent = styled.View`
  padding: ${({ theme }) =>
    theme.device === 'small' ? '18px' : '15px 48px 48px'};
`
