import React, { useEffect } from 'react'
import { Page } from './SignUpIntro.style'
import SignUpIntroSlides from '@ui/SignUpIntroSlides/SignUpIntroSlides'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { IS_BROWSER, IS_NATIVE } from '@ui/utils'
import { getStoredItem } from '@core/lib/storage'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@core/constants'
import { InteractionManager } from 'react-native'
import { StackActions, useNavigation } from '@react-navigation/native'

export default function SignUpIntro() {
  const navigation = useNavigation()
  useEffect(() => {
    if (IS_NATIVE) {
      getStoredItem(ACCESS_TOKEN_KEY).then((accessToken) => {
        getStoredItem(REFRESH_TOKEN_KEY).then((refreshToken) => {
          accessToken &&
            refreshToken &&
            InteractionManager.runAfterInteractions(() =>
              navigation.dispatch(StackActions.replace('Home'))
            )
        })
      })
    }
  }, [])
  useEffect(() => {
    if (
      IS_BROWSER &&
      getStoredItem(ACCESS_TOKEN_KEY) &&
      getStoredItem(REFRESH_TOKEN_KEY)
    ) {
      InteractionManager.runAfterInteractions(() =>
        navigation.dispatch(StackActions.replace('Home'))
      )
    }
  }, [])

  return (
    <Page>
      <NavigationModal
        backgroundColor='secondary'
        isClosable={false}
        hasBackgroundOpacity={false}>
        <SignUpIntroSlides />
      </NavigationModal>
    </Page>
  )
}
