import React from 'react'
import styled from 'styled-components/native'
// add exact path instead of '../..' to avoid cycling require
import { RoundedImage, Text, ContainerWithBorder } from '../../atoms'
import { LinkWithIcon } from '../../molecules'

interface AppointmentCardProps {}

const DateAndLabelContainer = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const ButtonsContainer = styled.View`
  display: flex;
  justify-content: space-between;
`

const AvatarWithButtonsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DoctorNameAndLabelContainer = styled.View`
  display: flex;
  /* justify-content: center;
    align-items: center; */
`

const ImageWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CalendarButtonWrapper = styled.View`
  display: flex;
  margin-bottom: 10px;
  align-self: flex-start;
`

export const AppointmentCard: React.FC = ({}) => {
  return (
    <ContainerWithBorder>
      <DateAndLabelContainer>
        <Text color='black'>{'Jeud 4 mars 2021 à 10:30 '}</Text>
        <Text color='black'>en visioconférence</Text>
      </DateAndLabelContainer>
      <CalendarButtonWrapper>
        <LinkWithIcon
          iconName='calendar'
          iconColor='link'
          iconSize={16}
          spacing='5px'
          onPress={() => {}}
          label='Ajouter à mon calendrier'
        />
      </CalendarButtonWrapper>
      <AvatarWithButtonsContainer>
        <ImageWrapper>
          <RoundedImage
            uri='../../../../assets/images/Alexandre.png'
            imageSize='64px'
          />
        </ImageWrapper>
        <DoctorNameAndLabelContainer>
          <Text>avec</Text>
          <Text>Alexandre Bonhomme</Text>
        </DoctorNameAndLabelContainer>
        <ButtonsContainer>
          <LinkWithIcon
            iconName='calendar'
            iconColor='link'
            iconSize={16}
            spacing='5px'
            onPress={() => {}}
            label='Modifier'
          />
          <LinkWithIcon
            iconName='calendar'
            iconColor='link'
            iconSize={16}
            spacing='5px'
            onPress={() => {}}
            label='Annuler'
          />
        </ButtonsContainer>
      </AvatarWithButtonsContainer>
    </ContainerWithBorder>
  )
}
