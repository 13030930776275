import styled from 'styled-components/native'

export const WebinarContainer = styled.View`
  margin-top: 32px;
  align-items: center;
  min-height: 75%;
  padding-horizontal: ${({ theme }) =>
    theme.device !== 'large' ? '16px' : '64px'};
  margin-bottom: 128px;
`

export const HTMLContainer = styled.View`
  margin-bottom: 32px;
  margin-top: 16px;
`

export const SpeakersContainer = styled.View`
  border: 1px black;
  border-radius: 10px;
  padding: 32px;
  width: 100%;
`

export const SpeakerList = styled.View`
  flex-direction: ${({ theme }) =>
    theme.device !== 'large' ? 'column' : 'row'};
  justify-content: space-evenly;
`

export const Speaker = styled.View`
  align-items: center;
  margin: 16px;
`
