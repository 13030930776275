import React from 'react'
import {
  Container,
  MainFactorsContainer,
  FactorsRiskIcon,
  MainFactorItem,
  MainFactorItemList,
  Separator,
} from './HistoryFactors.styles'
import { HistoryFactorsLoading } from './HistoryFactors.loading'
import { HistoryFactorsProps } from './interface'
import {
  BigParagraph,
  SubTitle,
  Title,
  HTMLView,
  Paragraph,
} from '@ui/Moodwork-UI/atoms'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import riskIcon from '../../../../assets/images/history/mal_etre.png'
import protectionIcon from '../../../../assets/images/history/bien_etre.png'
import { useTheme } from '@ui/utils'
import { PrefixedContent, ScoreCard } from '@ui/Moodwork-UI'
import { WellBeingCategoryFactor } from '@ui/Moodwork-UI/templates/WellBeing/interface'
import { HistoryFactorsPannel } from './HistoryFactorsPannel'
import { trackEvent } from '@core/lib/analytics'
import { UserTestCategory } from '@core/types/history'
import 'moment/min/locales'

export const sortFactors = (
  array: WellBeingCategoryFactor[],
  slice?: number | false,
  reverse?: boolean
) => {
  let result = array.sort((a, b) =>
    reverse ? b.score - a.score : a.score - b.score
  )
  if (slice) {
    result = result.slice(0, slice)
  }
  return result
}

export const filterCategories = (categories, kind, isReverse = false) => {
  return sortFactors(
    categories
      .flatMap((categories) => categories.factors)
      .filter((e) => e.kind === kind),
    5,
    isReverse
  )
}

export const HistoryFactors = ({ finishAt, data, id }: HistoryFactorsProps) => {
  const { t, i18n } = useTranslation(['result'])
  const theme = useTheme()

  const sendEvent = (item: UserTestCategory, index: number) => {
    trackEvent('clicked_tracker_category', {
      test_uuid: id,
      category_id: item.uuid,
      category_name: item.name,
      category_rank: index + 1,
      category_score: Math.round(item.score),
      category_nb_negative_factors: item.factors.filter(
        (f) => f.kind === 'risk'
      ).length,
      category_nb_positive_factors: item.factors.filter(
        (f) => f.kind === 'protection'
      ).length,
    })
  }

  return data ? (
    <Container>
      <Title marginSize={`0 0 8px 0`}>
        {t('detail-title')}
        <Title weight={'regular'}>
          {` ${moment(finishAt)
            .locale(i18n.language)
            .format('DD MMMM YYYY, HH[h]mm')}`}
        </Title>
      </Title>
      <SubTitle weight={'regular'} marginSize={`0 0 32px 0`}>
        {data?.risk_level_name}
      </SubTitle>
      <MainFactorsContainer>
        <MainFactorItem>
          {theme.device === 'large' ? (
            <>
              <FactorsRiskIcon big source={riskIcon} resizeMode='contain' />
              <SubTitle>{t('main-risk-title')}</SubTitle>
            </>
          ) : (
            <PrefixedContent big alignPosition={'center'} prefix={'risk'}>
              <SubTitle>{t('main-risk-title')}</SubTitle>
            </PrefixedContent>
          )}
          <MainFactorItemList>
            {data?.categories
              .flatMap((categories) => categories.factors)
              .filter((e) => e.kind === 'risk').length > 0 ? (
              filterCategories(data?.categories, 'risk').map((e, idx) => (
                <BigParagraph key={e.uuid}>{`${idx + 1}. ${
                  e.content[e.kind]
                }`}</BigParagraph>
              ))
            ) : (
              <BigParagraph>{t('empty-factors')}</BigParagraph>
            )}
          </MainFactorItemList>
        </MainFactorItem>
        <MainFactorItem>
          {theme.device === 'large' ? (
            <>
              <FactorsRiskIcon
                big
                source={protectionIcon}
                resizeMode='contain'
              />
              <SubTitle>{t('main-protection-title')}</SubTitle>
            </>
          ) : (
            <PrefixedContent big alignPosition={'center'} prefix={'protection'}>
              <SubTitle>{t('main-protection-title')}</SubTitle>
            </PrefixedContent>
          )}
          <MainFactorItemList>
            {data?.categories
              .flatMap((categories) => categories.factors)
              .filter((e) => e.kind === 'protection').length > 0 ? (
              filterCategories(data?.categories, 'protection', true).map(
                (e, idx) => (
                  <BigParagraph key={e.uuid}>{`${idx + 1}. ${
                    e.content[e.kind]
                  }`}</BigParagraph>
                )
              )
            ) : (
              <BigParagraph>{t('empty-factors')}</BigParagraph>
            )}
          </MainFactorItemList>
        </MainFactorItem>
      </MainFactorsContainer>
      <SubTitle
        marginSize={`0 0 ${theme.device === 'large' ? '16px' : '24px'} 0`}>
        {t('all-detail-title')}
      </SubTitle>
      {theme.device === 'large' ? (
        <HistoryFactorsPannel
          onPress={sendEvent}
          data={data.categories}
          sortFactors={sortFactors}
        />
      ) : (
        data.categories.map((item, idx) => {
          return (
            <ScoreCard
              onPress={() => sendEvent(item, idx)}
              device={theme.device}
              score={item.score}
              title={item.name}
              key={item.uuid}
              defaultShowValue={idx === 0}>
              <>
                <HTMLView source={item.description} />
                <Separator height={1} color={'lightSecondaryGrey'} />
                <MainFactorItemList>
                  <PrefixedContent alignPosition={'center'} prefix={'risk'}>
                    <Paragraph weight={'bold'} marginSize={`0 0 8px 0`}>
                      {t('risk-title')}
                    </Paragraph>
                  </PrefixedContent>
                  {item?.factors.filter((e) => e.kind === 'risk').length > 0 ? (
                    sortFactors(
                      item?.factors.filter((e) => e.kind === 'risk')
                    ).map((e, idx) => (
                      <Paragraph key={e.uuid}>{`${idx + 1}. ${
                        e.content[e.kind]
                      }`}</Paragraph>
                    ))
                  ) : (
                    <Paragraph key={`risk-empty-${idx}`}>
                      {t('empty-factors')}
                    </Paragraph>
                  )}
                </MainFactorItemList>
                <MainFactorItemList>
                  <PrefixedContent
                    alignPosition={'center'}
                    prefix={'protection'}>
                    <Paragraph weight={'bold'} marginSize={`0 0 8px 0`}>
                      {t('protection-title')}
                    </Paragraph>
                  </PrefixedContent>
                  {item?.factors.filter((e) => e.kind === 'protection').length >
                  0 ? (
                    sortFactors(
                      item?.factors.filter((e) => e.kind === 'protection'),
                      false,
                      true
                    ).map((e, idx) => (
                      <Paragraph key={e.uuid}>{`${idx + 1}. ${
                        e.content[e.kind]
                      }`}</Paragraph>
                    ))
                  ) : (
                    <Paragraph key={`protection-empty-${idx}`}>
                      {t('empty-factors')}
                    </Paragraph>
                  )}
                </MainFactorItemList>
              </>
            </ScoreCard>
          )
        })
      )}
    </Container>
  ) : (
    <HistoryFactorsLoading />
  )
}
