import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Text } from '@moodwork-ui'
import { Audio, AVPlaybackStatus } from 'expo-av'
import { Sound } from 'expo-av/build/Audio'
import thumbTrack from '../../../../../assets/thumbTrack.png'
import { useToggle } from '@core/lib/hooks'
import {
  Play,
  Container,
  SliderComponent,
  PlayContainer,
  PlayButton,
  Stop,
  PlayPauseText,
} from './index.style'
import { AudioComponentProps } from './index.type'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@ui/utils'
import { trackEvent } from '@core/lib/analytics'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

export default function AudioComponent({
  uri,
  duration,
  setIsPlaying,
  trackableParams,
}: AudioComponentProps) {
  const [sound, setSound] = useState<Sound>()
  const [status, setStatus] = useState<AVPlaybackStatus>()
  const [sliderPosition, setSliderPosition] = useState(0)
  const { t } = useTranslation(['resources'])
  const theme = useTheme()
  const device = theme.device

  useFocusEffect(
    useCallback(() => {
      Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
      })
      let s = new Audio.Sound()
      s.loadAsync({
        uri: uri,
      })
      s.setOnPlaybackStatusUpdate((status) => {
        setStatus(status)
      })
      setSound(s)
      return () => {
        s.getStatusAsync().then((stats) => {
          handleTrackingEvent(stats, 'leave_page_audio_state')
        })
        s ? s.unloadAsync().then() : null
      }
    }, [])
  )

  const handleTrackingEvent = useCallback(
    (status: AVPlaybackStatus, trackingName: string) => {
      let totalAudioDuration = Math.round(status.durationMillis / 1000)
      let currentDurationPercentage = Math.floor(
        100 * (status.positionMillis / status.durationMillis)
      )
      let currentDuration = Math.round(status.positionMillis / 1000)

      trackEvent(trackingName, {
        ...trackableParams,
        total_audio_duration_sec: totalAudioDuration,
        current_duration_percentage: currentDurationPercentage,
        current_duration_sec: currentDuration,
      })
    },
    []
  )

  const handlePlay = useCallback(() => {
    if (status.isPlaying === undefined || !sound) {
      return
    }
    let totalAudioDuration = Math.round(status.durationMillis / 1000)
    let currentDurationPercentage = Math.floor(
      100 * (status.positionMillis / status.durationMillis)
    )
    let currentDuration = Math.round(status.positionMillis / 1000)
    if (status.isPlaying) {
      trackEvent('clicked_resource_audio_pause', {
        ...trackableParams,
        total_audio_duration_sec: totalAudioDuration,
        current_duration_percentage: currentDurationPercentage,
        current_duration_sec: currentDuration,
      }).then()
      sound.pauseAsync()
      setIsPlaying && setIsPlaying(false)
    } else {
      trackEvent('clicked_resource_audio_play', {
        ...trackableParams,
        total_audio_duration_sec: totalAudioDuration,
        current_duration_percentage: currentDurationPercentage,
        current_duration_sec: currentDuration,
      }).then()
      sound?.playAsync()
      setIsPlaying && setIsPlaying(true)
    }
  }, [sound, status])

  useEffect(() => {
    if (status && status.positionMillis && status.durationMillis) {
      setSliderPosition(status.positionMillis / status.durationMillis)
    }
  }, [status])

  const totalTime = useMemo(() => {
    let totalTime = isNaN(duration)
      ? 0
      : Math.abs(status?.positionMillis / 1000 - duration)
    const minutes = Math.floor(totalTime / 60)
    const seconds = ('0' + Math.floor(totalTime % 60)).slice(-2)
    return `${minutes}:${seconds}`
  }, [status, duration])

  const onValueChange = useCallback(
    (value: number) => {
      if (sound && !isNaN(status.durationMillis)) {
        sound.setPositionAsync(value * status.durationMillis)
        handleTrackingEvent(status, 'clicked_resource_audio_scrub')
      }
    },
    [sound, status]
  )

  return (
    <Container>
      <PlayButton onPress={handlePlay}>
        <PlayPauseText size='XXS' weight='medium' color='secondary'>
          {status?.isPlaying
            ? t('resources.audio.stop')
            : t('resources.audio.play')}
        </PlayPauseText>
        <PlayContainer>{status?.isPlaying ? <Stop /> : <Play />}</PlayContainer>
      </PlayButton>
      {device === 'small' && !status?.isPlaying ? null : (
        <SliderComponent
          value={sliderPosition}
          minimumTrackTintColor='#1E32F5'
          maximumTrackTintColor='#DCDCDC'
          thumbImage={thumbTrack}
          thumbSize={10}
          onSlidingComplete={onValueChange}
          thumbTintColor='#1E32F5'
        />
      )}
      {device === 'small' && !status?.isPlaying ? null : (
        <Text size='XXS' weight='medium' color='grey'>
          {totalTime}
        </Text>
      )}
    </Container>
  )
}
