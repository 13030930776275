import { useToggle } from '@core/lib/hooks'
import { useNavigationLink } from '@core/lib/navigation'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import * as React from 'react'
import { useCallback } from 'react'
import { TextProps } from 'react-native'
import { LinkProps } from './interface'
import { LinkText } from './styles'
import { TextProps as OtherTextProps } from '@moodwork-ui/atoms/Text/interface'
import { useNavigation } from '@react-navigation/native'

export const Link = ({
  text,
  url,
  style,
  trackLabel,
  trackProperties,
  external = true,
  goBack = false,
  ...props
}: LinkProps &
  TrackableEventProps &
  OtherTextProps &
  Pick<TextProps, 'style'>) => {
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const linkTo = useNavigationLink()
  const navigation = useNavigation()

  const navigate = useCallback(() => {
    trackEvent(trackLabel, trackProperties).then(() => {
      goBack
        ? navigation.goBack()
        : external
        ? linkTo(url, external)
        : navigation.navigate(url)
    })
  }, [url])

  return (
    <LinkText
      {...props}
      style={style}
      hover={hovered}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      onPress={navigate}>
      {text}
    </LinkText>
  )
}
