import { useTheme } from '@utils'
import React, { useContext, useEffect } from 'react'
import { useAppointments } from '@core/services/appointments'
import { Viewer } from '@core/services'
import EmptyAppointment from '@ui/Moodwork-UI/molecules/Dashboard/EmptyAppointment'
import AppointmentList from '@ui/Moodwork-UI/molecules/Dashboard/AppointmentList'
import AppointmentsLoading from '@ui/Moodwork-UI/molecules/Dashboard/AppointmentList/Appointment.loading'
import { useSyncReady } from '@core/lib/ready'
import { WellbeingStatus } from '@ui/Moodwork-UI/organisms/Dashboard/UserWellbeing/index.types'

export const getEmptyProps = (status: WellbeingStatus) => {
  const title =
    status === 'manage_high_risk' || status === 'take_assistance'
      ? 'dashboard.appointment.risk-title'
      : 'dashboard.appointment.default-title'
  const text = `dashboard.appointment.text.${status}`

  return {
    title,
    text,
  }
}

export default function Appointments() {
  const theme = useTheme()
  const {
    data: appointments,
    isLoading: appointmentsIsLoading,
    isError,
  } = useAppointments()
  const { data: userInfo, isLoading: userInfoIsLoading } = Viewer.useUserInfo()
  const needAppointment = userInfo?.test_report?.need_appointment || false
  const isReady = useSyncReady('dashboard', 'userappointments', !!appointments)

  if (!isReady) {
    return <AppointmentsLoading highlight={needAppointment} />
  }

  if (Array.isArray(appointments) && appointments?.length > 0) {
    const appointmentsSorted = appointments.sort(function (a, b) {
      return new Date(a.slot.date) - new Date(b.slot.date)
    })
    return (
      <AppointmentList
        needAppointment={needAppointment}
        appointments={appointmentsSorted}
      />
    )
  } else {
    const emptyProps = getEmptyProps(
      userInfo?.test_report?.status || 'take_wellbeing'
    )

    return (
      <EmptyAppointment {...emptyProps} needAppointment={needAppointment} />
    )
  }
}
