import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;
  width: 100%;
  flex-basis: 80%;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px;
`

export const Content = styled.View`
  padding: 0 16px;
  width: ${({ theme }) => (theme.device !== 'small' ? '75%' : '100%')};
`

export const Image = styled.Image`
  width: 100%;
  height: 100%;
/* 
  width: ${({ theme }) => (theme.device === 'small' ? '375px' : '494px')};
  height: ${({ theme }) => (theme.device === 'small' ? '294px' : '277px')}; */
`

export const ImageContainer = styled.View`
  flex: 1;
  align-items: center;
  margin: 32px 0 16px 0;
  flex-basis: 30%;
  width: 100%;
  height: auto;
`
