import * as React from 'react'
import { Paragraph } from '../Paragraph'
import { Container, ImagePlaceholder } from './Conference.style'

export default function Conference() {
  return (
    <Container>
      <ImagePlaceholder />
      <Paragraph
        title='Inégalités, comment les éviter ?'
        content='Arretez de vous fixer des objéctifs inatteignables'
        details='Jeudi 17 Juin 2021'
        ctaContent="S'inscrire"
        onPress={() => {}}
      />
    </Container>
  )
}
