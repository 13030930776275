import { useNavigation } from '@react-navigation/native'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, ListRenderItem } from 'react-native'
import { IS_NATIVE, useDevice } from '../../ui'
import { AppointmentCalendar as Calendar } from '../../ui/AppointmentCalendar'
import { Item } from '../../ui/AppointmentCalendar/AppointmentCalendar'
import { Slot, ErrorType } from '../../ui/utils'
import { Day, DaysMap, paginateDays } from '../lib/helpers'
import { useNextSlotContent } from '../lib/locale'
import { SLOTS_DAYS, useInfiniteSlots } from '../services/appointments'
import { ErrorPage } from '@moodwork-ui'
import { trackEvent } from '@core/lib/analytics'

export interface AppointmentCalendarProps {
  psychologistId: string
  nextSlot: Slot
}

/**
 * Given a locale,
 * returns a list of days for the next few months.
 *
 * TODO:
 *  - Is it an infinite list ?
 */
function getNextMonths({
  locale,
  starting,
}: {
  locale: string
  starting: DateTime
}): DaysMap {
  const days = new Map() as DaysMap
  Array.from({ length: 61 }).forEach((_, index) => {
    const date = starting.plus({ day: index })
    const day = new Day(date, locale)
    days.set(day.key, day)
  })
  return days
}

/**
 * This hook will compute the number of days to display
 * per window considering the available width. This s recomputed on
 * widow resize.
 *
 * It then re-compute the data into an array of array days Day[][]. Each
 * eleemnt of the top-level array is a flatlist item
 *
 * Only the next N months are computed of first render
 */
export default function AppointmentCalendar({
  psychologistId,
  psychologistName,
  psychologistAvatar,
  psychologistRank,
  nextSlot,
  eventKind,
  inactive_status,
  inactive_message,
}: any) {
  const { i18n, t } = useTranslation(['appointments'])
  const [limited, setLimited] = React.useState(true)
  const [width, setWidth] = React.useState(
    IS_NATIVE ? Dimensions.get('window').width : 0
  )
  const [slotHeight, setSlotHeight] = React.useState(0)
  const device = useDevice()
  const [starting, setStarting] = React.useState(
    device === 'small' && nextSlot
      ? DateTime.fromISO(nextSlot.date)
      : DateTime.now()
  )
  const nextMonths = React.useMemo(
    () => getNextMonths({ locale: i18n.language, starting }),
    [starting]
  )
  const navigation = useNavigation()
  const [index, setIndex] = React.useState(0)
  const [currentIndex, setCurrent] = React.useState(0)
  const nextSlotContent = useNextSlotContent(
    nextSlot,
    psychologistName,
    i18n.language
  )
  const chunkHours =
    slotHeight > 0 && Math.round((slotHeight - 80) / 58) > 5
      ? Math.round((slotHeight - 80) / 58)
      : 5 // 5 is the default number of slot displayed

  // /**
  //  * Fetch only when we need
  //  */
  React.useEffect(() => {
    if (index > currentIndex + 1) {
      slotsQuery.fetchNextPage()
      setCurrent(index)
    }
  }, [index])

  React.useEffect(() => {
    const diff = starting.diffNow().as('days')
    const i = diff / SLOTS_DAYS

    if (i > 0) {
      slotsQuery.fetchNextPage({ pageParam: Math.floor(i) })
    }
  }, [starting])

  /**
   * TODO: Find something cleaner and more generic
   */
  const chunkSize = React.useMemo(() => {
    if (width) {
      if (width < 216) return 1
      if (width < 316) return 2
      if (width < 416) return 3
      if (width < 532) return 4
      return 5
    }
    return 0
  }, [width])

  const [slotsQuery, slots] = useInfiniteSlots(
    psychologistId,
    nextMonths,
    starting
  )

  const data = React.useMemo(
    () => (chunkSize ? paginateDays(slots, chunkSize) : []),
    [chunkSize, slots]
  ) as Day[][]

  const selectEventKind = React.useCallback(
    (event: { item: Slot; index: number }) =>
      navigation.push('PsychologistAppointmentType', {
        eventSlotId: event.item.id,
        eventDate: event.item.date,
        psyName: psychologistName,
        psyAvatar: psychologistAvatar,
      }),
    []
  )

  const onSelect = (event: { item: Slot; index: number }) => {
    trackEvent('clicked_appointment_time', {
      psychologist_picked: psychologistName,
      psychologist_rank: psychologistRank + 1,
      date_picked: event.item.date.toFormat('dd/MM/yyyy'),
      time_picked: event.item.date.toFormat("HH'h'mm"),
    }).then(() => {
      eventKind.length > 1
        ? selectEventKind(event)
        : navigation.push('PsychologistAppointmentInfo', {
            eventSlotId: event.item.id,
            eventDate: event.item.date,
            eventType: eventKind[0],
            psyName: psychologistName,
            psyAvatar: psychologistAvatar,
          })
    })
  }

  const loading = slots && !Object.keys(slots).length && slotsQuery.isLoading

  const renderItem: ListRenderItem<Day[]> = React.useCallback(
    ({ item }) => (
      <Item
        limited={limited}
        onSelect={onSelect}
        width={width}
        days={item}
        nextSlotContent={nextSlotContent}
        nextSlot={nextSlot}
        emptyStateClick={() => setStarting(DateTime.fromISO(nextSlot.date))}
        chunkHours={chunkHours}
        inactive_status={inactive_status}
        inactive_message={inactive_message}
      />
    ),
    [slotsQuery.status, width, nextSlotContent, nextSlot, data, limited]
  )

  if (slotsQuery.isError) {
    return <ErrorPage type={ErrorType.TECHNICAL_ERROR} />
  } else {
    return (
      <Calendar
        data={data}
        width={width}
        setWidth={setWidth}
        index={index}
        slotHeight={slotHeight}
        setSlotHeight={setSlotHeight}
        setIndex={setIndex}
        loading={loading}
        renderItem={renderItem}
        limited={limited}
        setLimited={setLimited}
        chunkHours={chunkHours}
        inactive_status={inactive_status}
        seeMoreLabel={t('psychologists.calendar.seeMore')}
        seeLessLabel={t('psychologists.calendar.seeLess')}
      />
    )
  }
}
