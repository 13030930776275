import styled, { css } from 'styled-components/native'

export const Wrapper = styled.Pressable<{ selected: boolean }>`
   display: flex;
   justify-content: center;
   align-items: center;
   height: ${({ theme }) => theme.heights.Button};
   width: ${({ theme }) => theme.widths.SelectionButton};
   background-color: ${({ theme }) => theme.colors.white};
   border-radius: ${({ theme }) => theme.borderRadius.button}
   border-color:${({ theme }) => theme.colors.secondary};
   border-width: 2px;
   ${({ selected }) =>
     selected &&
     css`
       background-color: ${({ theme }) => theme.colors.secondary};
     `};
`
