import React, { useState } from 'react'
import {
  ActionContainer,
  ActionItem,
  Header,
  Loader,
  Logo,
} from './AssessmentHeader.styles'
import { AssessmentHeaderProps } from './interface'
import logo from '../../../../assets/logo.png'
import { Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_HOME } from '@core/constants'
import { useTranslation } from 'react-i18next'
import { FontIcon, Paragraph } from '@ui/Moodwork-UI/atoms'
import { LinkText } from '@ui/Moodwork-UI/atoms/Link/styles'
import { useToggle } from '@core/lib/hooks'
import { useTheme } from '@ui/utils'

export default function AssessmentHeader({
  maxCount,
  currentCount,
  onPress,
}: AssessmentHeaderProps) {
  const navigation = useNavigation()
  const goHome = () => navigation.push(SCREEN_HOME)
  const { t } = useTranslation(['assessment'])
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const theme = useTheme()

  return (
    <Header>
      <Pressable onPress={goHome}>
        {theme.device === 'large' ? (
          <Logo resizeMode='contain' source={logo} />
        ) : (
          <FontIcon name='arrow-left' color={theme.colors.secondary} />
        )}
      </Pressable>
      <ActionContainer>
        <ActionItem>
          <Paragraph>
            {theme.device === 'large'
              ? `${t('question')} ${currentCount} ${t('on')} ${maxCount}`
              : `${currentCount} / ${maxCount}`}
          </Paragraph>
        </ActionItem>
        <ActionItem last>
          <Pressable onPress={onPress}>
            <LinkText
              hover={hovered}
              onMouseEnter={onHoverIn}
              onMouseLeave={onHoverOut}
              style={{ fontSize: theme.device != 'large' ? 14 : 16 }}>
              {t('save-for-later')}
            </LinkText>
          </Pressable>
        </ActionItem>
      </ActionContainer>
    </Header>
  )
}
