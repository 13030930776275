import { FlexWrap, Title } from '@moodwork-ui'
import { SubTitle, Text } from '@ui/Moodwork-UI/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, View } from 'react-native'
import { IntercomWrapper } from '@core/Wrapper/IntercomWrapper'
import { ChatBanner } from '../ChatBanner'
import { PageCentered } from '../PageCentered'
import { AppointmentListProps } from './AppointmentList.types'
import {
  CheckboxContainer,
  FilterContainer,
  IntercomContainer,
} from './AppointmentList.style'
import ContentLoader from 'react-content-loader/native'
import { IS_NATIVE, toggleKeyList, useTheme } from '@utils'
import { Rect } from 'react-native-svg'
import { trackEvent } from '@core/lib/analytics'
import Checkbox from '@ui/Moodwork-UI/molecules/pressables/Checkbox'
import { useCallback, useState, useMemo } from 'react'
import SelectPicker from '@ui/Moodwork-UI/atoms/SelectPicker/SelectPicker'
import { styles } from '@ui/Moodwork-UI/molecules/ContactForm/index.styles'
import { isEqual, uniqWith } from 'lodash'

export default function AppointmentList({
  renderLoadingItem,
  renderItem,
  psychologists,
  isLoading,
  chatBannerContent,
  chatBannerTitle,
  chatBannerEmptyContent,
}: AppointmentListProps) {
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [activeLanguage, setActiveLanguage] = useState<string>('')
  const [activeThematic, setActiveThematic] = useState<string>('')
  const { t } = useTranslation(['appointments', 'search'])
  const theme = useTheme()
  const filters = useMemo(() => {
    return [
      ...new Set(
        psychologists
          ?.flatMap((psy) => psy.labels)
          .filter((label) => label.title)
          .sort((a, b) => a.priority - b.priority)
          .map((e) => e.title)
      ),
    ]
  }, [psychologists])

  const spokenLanguages = useMemo(() => {
    return uniqWith(
      [
        ...new Set(
          psychologists?.flatMap((psy) => psy.spoken_languages).sort()
        ),
      ],
      isEqual
    ).filter((lang) => lang !== undefined)
  }, [psychologists])

  const psychologistsThematics = useMemo(() => {
    return [
      ...new Set(
        psychologists
          ?.flatMap((psy) => psy.thematics)
          .filter((thematics) => thematics?.title)
          .sort((a, b) => a.priority - b.priority)
          .map((e) => e.title)
      ),
    ]
  }, [psychologists])

  const onFilterPress = useCallback((key: string) => {
    return setActiveFilters(toggleKeyList(key))
  }, [])

  const handleLanguageChange = (lang: string) => {
    trackEvent('selected_appointment_psy_language', {
      target_language: lang,
    }).then(() => {
      setActiveLanguage(lang)
    })
  }

  const handleThematicChange = (thematic: string) => {
    trackEvent('selected_appointment_psy_thematic', {
      target_thematic: thematic,
    }).then(() => {
      setActiveThematic(thematic)
    })
  }

  const activePsychologist = useMemo(() => {
    // display internal specialist first
    let sortedPsychologists = psychologists?.sort(
      (a, b) => b.is_internal - a.is_internal || a.priority - b.priority
    )

    if (activeFilters.length === 0 && !activeLanguage && !activeThematic) {
      return sortedPsychologists
    }

    if (activeFilters.length > 0) {
      sortedPsychologists = sortedPsychologists?.filter((e) =>
        e.labels.some((x) => activeFilters.includes(x.title))
      )
    }

    if (activeThematic) {
      sortedPsychologists = sortedPsychologists?.filter((e) =>
        e.thematics?.some((x) => activeThematic == x.title)
      )
    }

    return activeLanguage
      ? sortedPsychologists?.filter((e) =>
          e.spoken_languages?.some((x) => activeLanguage == x.code)
        )
      : sortedPsychologists
  }, [psychologists, activeFilters, activeLanguage, activeThematic])

  if (isLoading) {
    return (
      <PageCentered>
        <ContentLoader
          backgroundColor={theme.colors.lightGrey}
          width='50%'
          height={60}>
          <Rect x='16' y='0' rx='6' ry='6' width='90%' height='12' />
        </ContentLoader>
        {[1, 2, 3].map(renderLoadingItem)}
      </PageCentered>
    )
  }

  if (psychologists?.length === 0) {
    return (
      <PageCentered key={'chat banner'}>
        <Pressable onPress={() => IntercomWrapper.displayMessageComposer()}>
          <ChatBanner title={chatBannerEmptyContent} content={''} />
        </Pressable>
      </PageCentered>
    )
  }

  return (
    <View
      style={{
        marginTop: theme.device != 'large' ? 24 : 48,
        marginBottom: 96,
      }}>
      <PageCentered>
        <Title marginSize={'0 0 20px 0'} size='XXL' weight='bold'>
          {t('psychologists.list.title')}
        </Title>
        {filters.length > 0 && spokenLanguages.length > 0 && (
          <Text marginSize={'0 0 8px 0'} weight='bold'>
            {t('search:filter')}
          </Text>
        )}
        {filters && filters.length > 0 && (
          <FilterContainer>
            <CheckboxContainer>
              {filters.map((filter) => (
                <Checkbox
                  element={filter}
                  text={filter}
                  isActive={activeFilters?.includes(filter)}
                  onPress={onFilterPress}
                  eventName='psychologist'
                />
              ))}
            </CheckboxContainer>
          </FilterContainer>
        )}
        <FlexWrap>
          {psychologistsThematics && psychologistsThematics.length > 0 && (
            <View
              style={{
                width: '100%',
                marginBottom: IS_NATIVE ? 8 : 0,
                marginRight: 16,
              }}>
              <SelectPicker
                emptyable
                emptyLabel={t('psychologists.list.thematicsPlaceholder')}
                onChange={handleThematicChange}
                value={activeThematic}
                pickerStyle={[
                  styles.input,
                  styles.inputTextStyle,
                  { maxWidth: 200 },
                ]}
                hoverStyle={styles.hoveredInputStyle}
                itemStyle={[styles.pickerItemStyle, styles.inputTextStyle]}
                options={psychologistsThematics.map((thematic) => ({
                  name: thematic,
                  value: thematic,
                }))}
              />
            </View>
          )}
          {spokenLanguages && spokenLanguages.length > 0 && (
            <View style={{ width: '100%', marginBottom: IS_NATIVE ? 8 : 0 }}>
              <SelectPicker
                emptyable
                emptyLabel={t('psychologists.list.languagePlaceholder')}
                onChange={handleLanguageChange}
                value={activeLanguage}
                pickerStyle={[
                  styles.input,
                  styles.inputTextStyle,
                  { maxWidth: 200 },
                ]}
                hoverStyle={styles.hoveredInputStyle}
                itemStyle={[styles.pickerItemStyle, styles.inputTextStyle]}
                options={spokenLanguages.map((lang) => ({
                  name: lang?.nice_lang,
                  value: lang?.code,
                }))}
              />
            </View>
          )}
        </FlexWrap>
      </PageCentered>
      {activePsychologist && activePsychologist.length > 0 ? (
        <>
          <PageCentered>
            <ChatBanner content={chatBannerContent} title={chatBannerTitle} />
          </PageCentered>
          {activePsychologist?.map(renderItem).concat(
            <PageCentered key={'chat banner'}>
              <IntercomContainer
                onPress={() => {
                  trackEvent('clicked_appointment_open_chat_banner').then()
                  IntercomWrapper.displayMessageComposer()
                }}></IntercomContainer>
            </PageCentered>
          )}
        </>
      ) : (
        <SubTitle marginSize={'20px 16px'}>
          {t('psychologists.list.emptyPsychologistList')}
        </SubTitle>
      )}
    </View>
  )
}
