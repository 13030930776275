import React, { useCallback } from 'react'
import { useState } from 'react'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import {
  CardContainer,
  FlexContainer,
  Image,
  Wrapper,
} from './ButtonWithIcon.style'
import ButtonWithIconProps from './ButtonWithIcon.types'
import { useTheme } from '../../../../utils'
import { Text } from '../../../atoms'
import { useToggle } from '@core/lib/hooks'
import { useNavigation } from '@react-navigation/native'

export const ButtonWithIcon = ({
  onPress,
  label,
  trackLabel,
  image,
  device,
}: ButtonWithIconProps & TrackableEventProps) => {
  const borderColor = useTheme().colors.primary
  const [press, setPress] = useState(false)
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const selected = !!hovered

  const _onPress = useCallback(() => {
    setPress(true)
    trackEvent(trackLabel).then()
    setTimeout(() => {
      setPress(false)
      setTimeout(onPress, 200)
    }, 400)
  }, [trackLabel, onPress])

  return (
    <FlexContainer device={device}>
      <Wrapper>
        <CardContainer
          onPress={_onPress}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          selected={selected}>
          <Image resizeMode='contain' source={image} />
          <Text size='XS' color='secondary'>
            {label}
          </Text>
        </CardContainer>
      </Wrapper>
    </FlexContainer>
  )
}
