import * as React from 'react'
import styled from 'styled-components/native'
import Article from '../../ui/Article/Article'
import { FeaturedContentList } from '../../ui/FeaturedContentList'
import { Spacer } from '../../ui/Spacer'
import { DiaryGroupList } from '../../ui/DiaryGroupList'
import { useDiaries } from '../services/diaries'

export default function Contents() {
  const diaries = useDiaries()

  return (
    <>
      <FeaturedContentList
        contents={[
          { category: '', color: 'red', image: '', content: '', title: '' },
          { category: '', color: 'green', image: '', content: '', title: '' },
          { category: '', color: 'blue', image: '', content: '', title: '' },
          { category: '', color: 'orange', image: '', content: '', title: '' },
        ]}
      />
      <Spacer size={32} />
      {diaries?.groups.map((group) => (
        <DiaryGroupList
          contents={diaries.contents}
          group={group}
          key={group.uuid}
        />
      ))}
    </>
  )
}
