import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { IS_NATIVE, useTheme } from '@utils'
import styled from 'styled-components/native'

export const Wrapper = styled.View`
  flex-direction: row;
    height: 100%;
`

export const LeftMenuSkeleton = styled.View`
  width: 25%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`
export const RightSkeleton = styled.View`
  width: 50%;
  height: 100%;
`

export function MenuResourcesLoader() {
  const theme = useTheme()

  return (
    <ContentLoader
      backgroundColor={theme.colors.lightGrey}
      width='100%'
      height='100%'>
      <Rect x='0' y='0' rx='3' ry='3' width='70%' height='25' />
      <Rect x='0' y='41' rx='10' ry='10' width='80%' height='14' />
      <Rect x='0' y='64' rx='10' ry='10' width='50%' height='14' />
    </ContentLoader>
  )
}

export function ResourcesLoader() {
  const theme = useTheme()

  return (
    <>
      <ContentLoader
        backgroundColor={theme.colors.lightGrey}
        width='95%'
        height='32px'>
        <Rect x='20' y='0' rx='10' ry='10' width='80%' height='16' />
      </ContentLoader>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='95%'
        height='50px'>
        <Rect x='20' y='0' rx='10' ry='10' width='25%' height='16' />
      </ContentLoader>
      <ContentLoader
        backgroundColor={theme.colors.secondaryBackground}
        width='95%'
        height='190px'>
        <Rect x='20' y='0' rx='0' ry='0' width='100%' height='158' />
      </ContentLoader>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='95%'
        height='650px'>
        <Rect x='20' y='0' rx='0' ry='0' width='100%' height='316' />
        <Rect x='20' y='356' rx='10' ry='10' width='92%' height='16' />
        <Rect x='20' y='381' rx='10' ry='10' width='85%' height='16' />
        <Rect x='20' y='406' rx='10' ry='10' width='90%' height='16' />
        <Rect x='20' y='431' rx='10' ry='10' width='90%' height='16' />
        <Rect x='20' y='456' rx='10' ry='10' width='92%' height='16' />
        <Rect x='20' y='481' rx='10' ry='10' width='85%' height='16' />
        <Rect x='20' y='506' rx='10' ry='10' width='85%' height='16' />
        <Rect x='20' y='531' rx='10' ry='10' width='92%' height='16' />
      </ContentLoader>
    </>
  )
}
