import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FontIcon, Text } from '@moodwork-ui'
import { useNavigation } from '@react-navigation/native'
import {
  AppointmentContainer,
  ButtonContainer,
  CancelButton,
  CancelText,
  Container,
  ContainerRight,
  Picture,
  Separator,
  Title,
} from './index.style'
import { DateTime } from 'luxon'
import { FlatList } from 'react-native'
import _ from 'lodash'
import { MODAL_CANCEL_EVENT } from '@core/constants'
import { trackEvent } from '@core/lib/analytics'
import moment from 'moment'

export default function AppointmentList({
  needAppointment,
  appointments,
}: {
  needAppointment: boolean
  appointments: any[]
}) {
  const { t, i18n } = useTranslation(['dashboard', 'dates'])
  const navigation = useNavigation()
  const fulldate = useCallback(
    (date) => {
      const datetime = DateTime.fromISO(date)
      return [
        datetime.toLocaleString({
          locale: i18n.language,
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        t('dates:at'),
        datetime.toLocaleString({
          locale: i18n.language,
          hour: '2-digit',
          minute: '2-digit',
        }),
      ].join(' ')
    },
    [i18n]
  )

  const handlePressCancel = (
    eventId: number,
    kind: string,
    specialist: string,
    date: string,
    time: string
  ) => {
    trackEvent('clicked_db_cancel_appointment').then(() =>
      navigation.navigate(MODAL_CANCEL_EVENT, {
        eventId: eventId,
        appointment_type: kind,
        psychologist_picked: specialist,
        date_picked: date,
        time_picked: time,
      })
    )
  }

  return (
    <Container highlight={needAppointment}>
      <Title>
        {appointments?.length === 1
          ? t('dashboard.appointment.single-appointment')
          : t('dashboard.appointment.multi-appointment')}
      </Title>
      <FlatList
        data={appointments}
        renderItem={({ item, index }) => (
          <>
            <AppointmentContainer key={index}>
              <Text size='S'>{fulldate(item.slot.date)}</Text>
              <Picture source={{ uri: item.slot?.psychologist?.avatar?.url }} />
              <ContainerRight>
                <Text size='XS' weight='medium' color='grey'>
                  {t('dates:with')}
                </Text>
                <Text size='XS' weight='medium'>
                  {item.slot?.psychologist?.fullname}
                </Text>
              </ContainerRight>
            </AppointmentContainer>
            <CancelButton
              onPress={() =>
                handlePressCancel(
                  item?.id,
                  item?.kind,
                  item?.slot?.psychologist?.fullname,
                  item?.slot?.nice_date,
                  moment(item?.started_at.toString()).format('HH[h]mm')
                )
              }>
              <FontIcon name='cancel' color='red' size={16} />
              <CancelText size='XS' weight='medium'>
                {t('dashboard.appointment.cancel-appointment')}
              </CancelText>
            </CancelButton>
          </>
        )}
        ItemSeparatorComponent={() => (
          <Separator color={needAppointment ? 'white' : 'black'} height={2} />
        )}
      />
      <ButtonContainer>
        <Button
          label={t('dashboard.appointment.retake-appointment')}
          trackLabel='clicked_db_book_another_appointment'
          onPress={() => navigation.push('Psychologists')}
        />
      </ButtonContainer>
    </Container>
  )
}
