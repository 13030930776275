import styled from 'styled-components/native'

export interface ImagePlaceholderProps {
  size: number
}

export default styled.View`
  background-color: #000;
  opacity: 0.1;
  border-radius: 500px;
  width: ${(props: ImagePlaceholderProps) => props.size}px;
  height: ${(props: ImagePlaceholderProps) => props.size}px;
`
