import { FontIcon } from '@ui/Moodwork-UI'
import React, { useContext, useRef } from 'react'
import { TagInputProps, Tags } from './interface'
import { AlertContext } from '@core/components/Alerthandler'
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TextStyle,
  StyleProp,
  ViewStyle,
  TextInputProps,
} from 'react-native'
import { styles } from './TagInput.styles'

export const TagInput = ({
  disabled,
  leftElement,
  rightElement,
  customElement,
  label,
  tags,
  updateState,
  keysForTag,
  keysForTagsArray,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  disabledInputStyle,
  leftElementContainerStyle,
  rightElementContainerStyle,
  labelStyle,
  tagsViewStyle,
  tagStyle,
  tagTextStyle,
  condition,
  conditionErrorMessage,
  ...props
}: TagInputProps & TextInputProps) => {
  const inputRef = useRef<TextInput>()
  const { setMessage, setType } = useContext(AlertContext)

  const checkCondition = (tag: string): Boolean => {
    if (condition && !condition(tag)) {
      if (conditionErrorMessage) {
        setType && setType('warning')
        setMessage(conditionErrorMessage)
      }
      return false
    }
    return true
  }

  const renderLabel = (text: string, style: StyleProp<TextStyle>) => {
    return <Text style={style}>{text}</Text>
  }

  const renderLeftElement = (
    element: React.ReactNode,
    style: StyleProp<ViewStyle>
  ) => {
    return <View style={[styles.leftElement, style]}>{element}</View>
  }

  const renderRightElement = (
    element: React.ReactNode,
    style: StyleProp<ViewStyle>
  ) => {
    return <View style={[styles.rightElement, style]}>{element}</View>
  }

  // If characters remain in the input field after input is completed, add them to the tag.
  const onEndEditing = (tags: Tags, updateState: (tag: Tags) => void) => {
    if (tags.tag) {
      if (!checkCondition(tags.tag)) {
        return
      }
      const tempArray = tags.tagsArray.concat(tags.tag)
      const tempObject = {
        tag: '',
        tagsArray: [...new Set(tempArray)], // Deduplication
      }
      updateState(tempObject)
      return inputRef.current?.clear()
    }
  }

  const onChangeText = (
    text: string,
    tags: Tags,
    updateState: (tag: Tags) => void,
    keysForTags: string,
    keysForTagsArray: string[]
  ) => {
    if (keysForTagsArray) {
      return onChangeText2(text, tags, updateState, keysForTagsArray)
    }

    let keysStr
    if (typeof keysForTags === 'string') {
      keysStr = keysForTags
    } else {
      keysStr = ' '
    }

    if (text.includes(keysStr)) {
      if (text === keysStr) {
        return
      }
      let tempTag = text.replace(keysStr, '')
      if (!checkCondition(tempTag)) {
        return
      }
      const tempArray = tags.tagsArray.concat(tempTag)
      let tempObject = {
        tag: '',
        tagsArray: [...new Set(tempArray)], // Deduplication
      }
      updateState(tempObject)
      return inputRef.current?.clear()
    }
    let tempObject = {
      tag: text,
      tagsArray: tags.tagsArray,
    }
    return updateState(tempObject)
  }

  const onChangeText2 = (
    text: string,
    tags: Tags,
    updateState: (tag: Tags) => void,
    keysForTagsArray: string[]
  ) => {
    // Escaping special characters.
    const keys = keysForTagsArray.map((str) =>
      (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1')
    )

    const regexp = new RegExp(keys.join('|'))

    if (regexp.test(text)) {
      if (keysForTagsArray.includes(text)) {
        // The following processing is required because multiple characters may be specified as one delimiter.
        if (!checkCondition(text)) {
          return
        }
        let tempObject = {
          tag: '',
          tagsArray: tags.tagsArray,
        }
        updateState(tempObject)
        return inputRef.current?.clear()
      }
      const tempTag = text.replace(regexp, '')
      if (!checkCondition(tempTag)) {
        return
      }
      const tempArray = tags.tagsArray.concat(tempTag)
      let tempObject = {
        tag: '',
        tagsArray: [...new Set(tempArray)], // Deduplication
      }
      updateState(tempObject)
      return inputRef.current?.clear()
    }
    let tempObject = {
      tag: text,
      tagsArray: tags.tagsArray,
    }
    return updateState(tempObject)
  }

  const deleteTag = (
    tagToDelete: number,
    tags: Tags,
    updateState: (tag: Tags) => void
  ) => {
    let tempArray = tags.tagsArray
    tempArray.splice(tagToDelete, 1)

    let tempObject = {
      tag: tags.tag,
      tagsArray: tempArray,
    }
    updateState(tempObject)
  }

  return (
    <View style={[styles.container, containerStyle]}>
      {label ? renderLabel(label, [styles.labelStyle, labelStyle]) : null}
      <View style={[styles.inputContainer, inputContainerStyle]}>
        {leftElement
          ? renderLeftElement(leftElement, leftElementContainerStyle)
          : null}
        <TextInput
          underlineColorAndroid='transparent'
          editable={!disabled}
          ref={inputRef}
          style={[
            styles.input,
            inputStyle,
            disabled && styles.disabledInput,
            disabled && disabledInputStyle,
          ]}
          value={tags.tag}
          onChangeText={(text) =>
            onChangeText(text, tags, updateState, keysForTag, keysForTagsArray)
          }
          onEndEditing={() => onEndEditing(tags, updateState)}
          onSubmitEditing={() => onEndEditing(tags, updateState)}
          onBlur={() => onEndEditing(tags, updateState)}
          {...props}
        />
        {rightElement
          ? renderRightElement(rightElement, rightElementContainerStyle)
          : null}
      </View>
      {customElement ? customElement : null}
      <View style={[styles.tagsView, tagsViewStyle]}>
        {tags.tagsArray.map((item, count) => {
          return (
            <View style={[styles.tag, tagStyle]} key={count}>
              <Text style={[styles.tagText, tagTextStyle]}>{item}</Text>
              <TouchableOpacity
                onPressIn={() => deleteTag(count, tags, updateState)}>
                <FontIcon
                  name='cancel'
                  size={14}
                  style={styles.deleteIcon}
                  color={'#FFF'}
                />
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    </View>
  )
}
