import styled, { css } from 'styled-components/native'
import { StyleSheet } from 'react-native'
import { TextInput } from '@ui/TextInput'
import { BigParagraph, SubTitle } from '@ui/Moodwork-UI'
import theme from '../../../UIProvider/defaultTheme'

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 10,
  },
  labelStyle: {
    marginTop: 0,
    marginBottom: 4,
    fontSize: 14,
  },
  input: {
    paddingLeft: 36,
    fontSize: 16,
    width: '100%',
    marginHorizontal: 0,
  },
  leftElement: {
    marginRight: -32,
  },
  textAreaStyle: {
    height: 200,
    fontSize: 16,
    outlineStyle: 'none',
    fontFamily: theme.fonts.regular,
  },
})

export const Container = styled.Pressable`
  flex: 1;
  flex-direction: row;
  ${({ theme }) =>
    theme.device !== 'small' &&
    css`
    padding: 75px;
  `}
`

export const ContentContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const ImageContainer = styled.View`
  flex: ${({ theme }) => (theme.device === 'large' ? 1 : 0)};
`

export const Title = styled(SubTitle)`
  margin-bottom: 24px;
`

export const TextArea = styled(TextInput)`
  width: 100%;
  margin-bottom: 24px;
`

export const MailImage = styled.Image`
  flex: 1;
`

export const IsSharedContainer = styled.View`
  display: flex;
  flex-direction: row;
`

export const IsSharedText = styled(BigParagraph)`
  margin-right: 8px;
`
