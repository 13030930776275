import { StyleSheet } from 'react-native'
import theme from '../../../UIProvider/defaultTheme'

export const styles = StyleSheet.create({
  picker: {
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.secondaryLight,
    letterSpacing: 1,
    paddingVertical: 6,
    paddingHorizontal: 12,
    height: 44,
    minWidth: 250,
    color: '#303030',
    marginBottom: 15,
  },
})
