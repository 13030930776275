import styled, { css } from 'styled-components/native'

export const AssessmentContainer = styled.View`
 display: flex;
 justify-content: center;
`

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-vertical: ${({ theme }) =>
    theme.device === 'large' ? '56px' : '24px'}
  padding-horizontal: ${({ theme }) =>
    theme.device === 'large' ? '64px' : '16px'}

`

export const QuestionContainer = styled.View`
  margin-vertical: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ theme }) => (theme.device === 'large' ? 'nowrap' : 'wrap')};
`

export const Question = styled.View<{ isRight?: boolean }>`
  flex-direction: ${({ isRight }) => (isRight ? 'row-reverse' : 'row')};
  display: flex;
  align-items: center;
  ${({ isRight }) =>
    !isRight
      ? css`
    padding-right: ${({ theme }) =>
      theme.device === 'large' ? '32px' : '8px'};
    padding-left: ${({ theme }) => (theme.device === 'large' ? '30px' : 0)};
    order: 1;`
      : css`
    padding-left: ${({ theme }) => (theme.device === 'large' ? '32px' : '8px')};
    padding-right: ${({ theme }) => (theme.device === 'large' ? '30px' : 0)};
    order: ${({ theme }) => (theme.device === 'large' ? 3 : 2)};
    `}
  width: ${({ theme }) => (theme.device === 'large' ? '30%' : '50%')}
`

export const BurnoutQuestion = styled.View`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: ${({ theme }) => (theme.device === 'large' ? '50%' : '100%')}
  padding-right: ${({ theme }) => (theme.device === 'large' ? '32px' : 0)};
`

export const SliderContainer = styled.View`
  width: ${({ theme }) => (theme.device === 'large' ? '40%' : '100%')};
  order: ${({ theme }) => (theme.device === 'large' ? 2 : 3)};
  display: flex;
  justify-content: center;
`

export const BurnoutSliderContainer = styled.View`
  width: ${({ theme }) => (theme.device === 'large' ? '50%' : '100%')};
  display: flex;
  justify-content: center;
`
