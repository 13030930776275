import React, { useState, createContext, useContext } from 'react'

type ReadyContextReturn = [
  {},
  (namespace: string, key: string, isReady: boolean) => void
]
export const ReadyContext = createContext([
  {},
  (namespace: string, key: string, isReady: boolean) => {},
] as ReadyContextReturn)

function useContextFactory<T>(
  name: string,
  context: React.Context<T>
): () => T {
  return () => {
    const ctx = useContext(context)
    if (ctx === undefined) {
      throw new Error(
        `use${name}Context must be used withing a ${name}ContextProvider.`
      )
    }
    return ctx
  }
}

export const useReadyContext = useContextFactory('ReadyContext', ReadyContext)

const ReadyContextProvider = (props: any) => {
  const [state, setState] = useState({})

  const setReady = (namespace: string, key: string, isReady: boolean) => {
    const namespaceChildren = state[namespace] || {}
    setState({
      ...state,
      [namespace]: {
        ...namespaceChildren,
        [key]: isReady,
      },
    })
  }

  return (
    <ReadyContext.Provider value={[state, setReady]}>
      {props.children}
    </ReadyContext.Provider>
  )
}

export default ReadyContextProvider
