import { DeviceSizeType } from '@ui/utils/device'
import styled, { css } from 'styled-components/native'

export const Container = styled.View<{ device: DeviceSizeType }>`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-horizontal: 16px;
  align-items: flex-start;
`

export const ButtonContainer = styled.View<{ device: string }>`
  margin-top: ${(props) => (props.device === 'small' ? 'auto' : '16px')};
  ${({ device }) =>
    device === 'small' &&
    css`
      width: 100%;
    `}
`
