import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import React, { useCallback, useEffect } from 'react'
import { useToggle } from '@core/lib/hooks'
import {
  Container,
  ItemContainer,
  ItemTitle,
  ItemDescription,
  NavigationDropdownContainer,
} from './NavigationDropdown.style'
import {
  NavigationDropdownProps,
  DropdownItemProps,
} from './NavigationDropdown.types'
import { useTranslation } from 'react-i18next'
import { IS_BROWSER } from '../../ui/utils/device'
import { useNavigationLink } from '@core/lib/navigation'
import { trackEvent } from '@core/lib/analytics'
import { Pressable } from 'react-native'

function useOutsideAlerter(ref, isOpen: boolean, setFalse: () => void) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setFalse()
    }
  }

  useFocusEffect(
    useCallback(() => {
      if (isOpen && IS_BROWSER) {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }
    }, [ref, isOpen, setFalse])
  )
}

function DropdownItem({
  title,
  description,
  screen,
  url,
  big,
  userInfo,
  condition,
  trackLabel,
}: DropdownItemProps): JSX.Element {
  const [hover, onHoverIn, onHoverOut] = useToggle()
  const navigation = useNavigation()
  const linkTo = useNavigationLink()
  const displayItem = !condition || (!!userInfo && condition(userInfo))
  const onPress = useCallback(() => {
    trackEvent(trackLabel).then(() => {
      if (!!screen) {
        navigation.push(
          typeof screen === 'function' ? screen(userInfo) : screen
        )
      } else if (!!url) {
        linkTo(typeof url === 'function' ? url(userInfo) : url)
      }
    })
  }, [screen, url, trackLabel])

  return (
    <>
      {displayItem && (
        <ItemContainer
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
          hover={hover}
          onPress={onPress}
          big={big}>
          <ItemTitle size='L' weight='bold' color='secondary' hover={hover}>
            {title}
          </ItemTitle>
          {description && (
            <ItemDescription size='S' color='secondary' hover={hover}>
              {description}
            </ItemDescription>
          )}
        </ItemContainer>
      )}
    </>
  )
}

export default function NavigationDropdown({
  items,
  big,
  userInfo,
  render,
  right,
}: NavigationDropdownProps): JSX.Element {
  const [open, setTrue, setFalse, onPress] = useToggle()
  const [hover, onHoverIn, onHoverOut] = useToggle()
  const route = useRoute()
  const wrapperRef = React.useRef(null)
  const current = !!items.find((item) => item.screen == route.name)
  const { t } = useTranslation('header')
  useOutsideAlerter(wrapperRef, open, setFalse)

  const handleMenuOpen = () => {
    if (!open) {
      trackEvent('clicked_menu_button_open').then(() => {
        onPress()
      })
    }
    onPress()
  }

  return (
    <NavigationDropdownContainer
      ref={wrapperRef}
      isRight={right}
      onPress={handleMenuOpen}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}>
      {open && (
        <Pressable
          onPress={handleMenuOpen}
          style={{
            position: 'absolute',
            width: 2000,
            height: 2000,
            top: -100,
            bottom: 0,
            left: -1000,
            right: 0,
          }}
        />
      )}
      {render(!!open, !!hover, current)}
      <Container
        big={big}
        isRight={right}
        open={open && !!items.length}
        pointerEvents={open && !!items.length ? 'auto' : 'none'}>
        {items.map((item, index) => (
          <DropdownItem
            key={index}
            title={t(item.title)}
            description={t(item.description)}
            big={big}
            screen={item?.screen}
            url={item?.url}
            condition={item.condition}
            userInfo={userInfo}
            trackLabel={item.trackLabel}
          />
        ))}
      </Container>
    </NavigationDropdownContainer>
  )
}
