import React, { useEffect, useState } from 'react'
import {
  BurnSlider,
  Container,
  LeftQuestion,
  RightQuestion,
  Slider as WellbeingSlider,
} from './index.styles'
import { useTheme } from '@ui/utils'
import { AssessmentQuestionCardProps } from './index.types'

export default function AssessmentQuestionCard({
  question,
  onValueChange,
  isDisable,
  type,
  style,
}: AssessmentQuestionCardProps) {
  const theme = useTheme()
  const [value, setValue] = useState()
  const [isReverse] = useState(question.score ? false : question.reverse)

  useEffect(() => {
    value !== undefined &&
      onValueChange(isReverse ? value * -1 : value, question.uuid)
  }, [value])

  const Slider = type === 'wellbeing' ? WellbeingSlider : BurnSlider
  return (
    <>
      <Container isDisable={isDisable} style={style}>
        <LeftQuestion
          type={type}
          value={value ?? question.score}
          isRight={false}
          isDisabled={isDisable}
          text={
            type === 'burnout'
              ? question?.question?.main?.text
              : isReverse
              ? question.question.right.text
              : question.question.left.text
          }
          tooltipInfo={
            (isReverse
              ? question.question.right?.info
              : question.question.left?.info) ?? undefined
          }
        />
        {theme.device === 'large' && (
          <Slider
            value={value ?? question.score}
            minValue={question.response.score.min}
            maxValue={question.response.score.max}
            setValue={setValue}
            isDisable={isDisable}
          />
        )}
        {type === 'wellbeing' && (
          <RightQuestion
            type={type}
            value={value ?? question.score}
            isRight={true}
            isDisabled={isDisable}
            text={
              isReverse
                ? question.question.left.text
                : question.question.right.text
            }
            tooltipInfo={
              (isReverse
                ? question.question.left.info
                : question.question.right.info) || undefined
            }
          />
        )}
      </Container>
      {theme.device !== 'large' && (
        <Slider
          value={value ?? question.score}
          minValue={question.response.score.min}
          maxValue={question.response.score.max}
          setValue={setValue}
          isDisable={isDisable}
        />
      )}
    </>
  )
}
