import styled from 'styled-components/native'
import { HtmlContainer } from '../Resource/ResourceBlock/ResourceBlock.styles'
import {
  ResourceContentCSS,
  ResourceMarginBottomContentCSS,
} from '../Resource/ResourceBlock/ResourceCommon.styles'

export const MarkContainer = styled.View`
  ${ResourceContentCSS};
  ${ResourceMarginBottomContentCSS};
`

export const ContentWrapper = styled.View`
  margin-vertical: ${({ theme }) => `${theme.spacings.XXL}px`};
`

export const RowContainer = styled.View`
  flex-direction: row;
  width: 100%;
`

export const TextContainer = styled.View`
  width: 50%;
`

export const StarsContainer = styled.View`
  width: 50%;
  padding-left: ${({ theme }) => (theme.device === 'small' ? '0px' : '20%')};
`

export const CheckIconContainer = styled.View`
  margin-right: ${({ theme }) => `${theme.spacings.S}px`} ;
`

export const FeedbackContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`
