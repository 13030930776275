import styled from 'styled-components/native'

export const MenuContainer = styled.View<{
  width?: string
  backgroundColor?: string
}>`
  width: ${({ width }) => (width ? width : '25%')};
  height: 100%;
  position: relative;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.secondaryBackground};
`
