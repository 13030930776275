import React, { useState } from 'react'
import { Slider } from '@ui/Moodwork-UI/organisms/Slider'
import { range } from 'lodash'
import { BurnoutTrackMark, WellbeingTrackMark } from './index.styles'
import { WellbeingSliderProps } from './index.types'

export default function WellbeingSlider({
  value,
  setValue,
  minValue,
  maxValue,
  isDisable,
  type = 'wellbeing',
  style,
}: WellbeingSliderProps) {
  const [currentValue, setCurrentValue] = useState(value ?? null)
  return (
    <Slider
      containerStyle={style}
      maximumValue={maxValue}
      minimumValue={minValue}
      step={1}
      value={[currentValue]}
      startFromZero
      trackClickable
      disabled={isDisable}
      trackStyle={{
        backgroundColor: 'transparent',
      }}
      renderTrackMarkComponent={(index) => {
        return type === 'wellbeing' ? (
          <WellbeingTrackMark
            index={index}
            value={currentValue}
            valueLength={Math.abs(minValue) + maxValue}
          />
        ) : (
          <BurnoutTrackMark
            index={index}
            value={currentValue}></BurnoutTrackMark>
        )
      }}
      onValueChange={(value) => {
        setCurrentValue(value[0] ?? value)
      }}
      trackMarks={range(minValue, maxValue + 1)}
      onSlidingComplete={(value) => {
        setValue(value[0] ?? value)
      }}
      thumbStyle={{
        height: 50,
        width: 20,
        backgroundColor: value !== undefined ? '#1E32F5' : 'transparent',
      }}
    />
  )
}
