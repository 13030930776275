import styled, { css } from 'styled-components/native'
import { View } from 'react-native'

export const Container = styled(View)<{ padding?: string }>`
  height: auto;
  width: 100%;
  padding: ${({ padding }) => padding || '0px'};
`

export const Grid = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
`

export const GridElement = styled(View)<{
  width?: string
  height?: string
  listHeight?: string
  listMode?: boolean
}>`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
 ${({ theme, listMode }) =>
   (listMode || theme.device === 'small' || theme.device === 'medium') &&
   css`
      width: 100%;
      height: ${({ listHeight }) => listHeight || 'auto'};
    `};
`
