import React, { useCallback, useEffect } from 'react'
import Entrypoint from '@ui/Moodwork-UI/molecules/Dashboard/Entrypoint'
import { useTheme } from '@ui/utils'
import { FlatList, View } from 'react-native'
import { useDashboard } from '@core/services/dashboard'
import EntryPointLoading from '@ui/Moodwork-UI/molecules/Dashboard/Entrypoint/index.loading'
import { useSyncReady } from '@core/lib/ready'
import { DashboardEntrypoint } from '@core/types/dashboard'
import { trackEvent } from '@core/lib/analytics'

export default function EntrypointList() {
  const theme = useTheme()
  const { data, isLoading } = useDashboard()
  const isReady = useSyncReady('dashboard', 'entrypoints', !!data)

  const entrypoints = isReady
    ? data?.endpoint_entries.filter(
        (entry) => entry.destination === 'dashboard'
      ) || []
    : [{ uuid: 'skeleton-1' }, { uuid: 'skeleton-2' }]

  const renderItem = useCallback(
    ({ item }) => (!isReady ? <EntryPointLoading /> : <Entrypoint {...item} />),
    [isReady]
  )

  useEffect(() => {
    if (isReady) {
      trackEvent(
        'viewed_db_endpoint_entries',
        entrypoints
          .filter(
            (entry: DashboardEntrypoint) =>
              entry.api_version === 'all_versions' ||
              entry.api_version === 'v3_only'
          )
          .reduce(
            (acc, curr, index) => ({
              ...acc,
              ['endpoint_entry_' + (index + 1)]: curr.title,
            }),
            {}
          )
      )
    }
  }, [isReady])

  return (
    <FlatList
      style={{
        padding: theme.device !== 'large' ? 10 : 0, // flatlist padding is added to avoid shadow cropping
        marginVertical: theme.device !== 'large' ? -10 : 0, // remove vertical padding added
      }}
      contentContainerStyle={{
        paddingHorizontal: theme.device !== 'large' ? '5%' : 0,
        marginHorizontal: theme.device !== 'large' ? -10 : 0, // remove horizontal padding added
      }}
      horizontal={theme.device !== 'large'}
      ItemSeparatorComponent={() => <View style={{ height: 24, width: 24 }} />}
      showsHorizontalScrollIndicator={false}
      data={entrypoints.filter(
        (entry: DashboardEntrypoint) =>
          entry.api_version === 'all_versions' ||
          entry.api_version === 'v3_only'
      )}
      renderItem={renderItem}
    />
  )
}
