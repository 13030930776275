import React, { useCallback, useEffect } from 'react'
import { useCompanyInfo } from '@services/users'
import Banner from '@ui/Moodwork-UI/molecules/Dashboard/Banner'
import { useNavigationLink } from '@core/lib/navigation'
import { FlatList, View } from 'react-native'
import BannerLoading from '@ui/Moodwork-UI/molecules/Dashboard/Banner/Banner.loading'
import { Separator } from './index.style'
import { useSyncReady } from '@core/lib/ready'

const filterCampaigns =
  (positions: ('high' | 'medium' | 'low')[]) => (campaign) => {
    return positions != null ? positions.includes(campaign.position) : true
  }

export default function UserCampains({
  positions,
  onVisible,
}: {
  positions: ('high' | 'medium' | 'low')[]
  onVisible: (visible: boolean) => void
}) {
  const { data, isLoading } = useCompanyInfo()
  const linkTo = useNavigationLink()

  useEffect(() => {
    if (
      data?.campaigns != null &&
      data.campaigns.filter(filterCampaigns(positions)).length > 0
    ) {
      onVisible(true)
    }
  }, [data?.campaigns])

  const isReady = useSyncReady('dashboard', 'usercampaigns', !!data)

  const renderItem = useCallback(
    ({ item }) => (
      <Banner
        headline={item.headline}
        title={item.title}
        image={item.image}
        button={item.button_text}
        link={item.button_link}
        onPressLink={linkTo}
        openNewTab={item.is_blank_target}
      />
    ),
    [linkTo]
  )

  const campaigns = (data?.campaigns || []).filter(filterCampaigns(positions))

  if (!isReady || campaigns.length === 0) {
    return null
  }

  return (
    <FlatList
      ItemSeparatorComponent={() => <Separator />}
      data={campaigns}
      style={{ overflow: 'visible' }}
      keyExtractor={(item) => item.uuid}
      renderItem={renderItem}
    />
  )
}
