import React, { useEffect, useMemo } from 'react'
import { ResourceCollectionNextProps } from './interface'
import { ResourceTitleLine } from '../ResourceBlock'
import { useTranslation } from 'react-i18next'
import { HtmlContainer } from '../ResourceBlock/ResourceBlock.styles'
import { ResourceGrid } from '../ResourceGrid'
import { useCollection } from '@core/services/resources'
import { trackEvent } from '@core/lib/analytics'

export const ResourceCollectionNext = ({
  collection,
  resourceUuid,
  resourceTitle,
  resourceTheme,
  resourceTag,
}: ResourceCollectionNextProps) => {
  const { data, isLoading, isError } = useCollection(collection)
  const { t } = useTranslation(['resources'])

  let nextResource = useMemo(() => {
    if (data && data.content && data.content.length > 0) {
      const elementIndex = data.content.findIndex(
        (resource) => resource.uuid === resourceUuid
      )
      if (elementIndex >= 0 && elementIndex < data.content.length - 1) {
        return data.content[elementIndex + 1]
      }
    }
    return null
  }, [data, resourceUuid])

  useEffect(() => {
    if (data) {
      trackEvent('viewed_resource_collection_next', {
        collection_uuid: collection,
        uuid: resourceUuid,
        title: resourceTitle,
        tag: resourceTag,
        theme: resourceTheme,
        has_next_collection_item: nextResource ? true : false,
        next_collection_item_is_read: nextResource
          ? nextResource.state.status === 'done'
          : false,
      })
    }
  }, [data])

  if (nextResource) {
    return (
      <>
        <ResourceTitleLine
          title={t('resources.nextResource.title')}
          quote={data?.title}
        />
        <HtmlContainer bottomMargin>
          <ResourceGrid
            itemWidth='270px'
            listHeight='150px'
            collection={collection}
            resources={[nextResource]}
            trackLabel='clicked_resource_go_next_collection_item'
            trackParentProps={{
              collection_uuid: collection,
              uuid: resourceUuid,
              title: resourceTitle,
              theme: resourceTheme,
              is_already_viewed: nextResource.state.status === 'done',
              is_favorited: nextResource.favorite,
            }}
          />
        </HtmlContainer>
      </>
    )
  }
  // TODO: add skeleton

  return null
}
