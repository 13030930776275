import * as React from 'react'
import { Avatar } from '../Avatar'
import { HTMLView } from '../Moodwork-UI/atoms/HTMLView'
import { OnDevice } from '../OnDevice'
import { PsychologistMedium } from '../PsychologistMedium'
import { Spacer } from '../Spacer'
import { useDevice } from '../utils/device'
import { useTheme } from '../utils/styled'
import {
  CalendarColumn,
  CalendarContainer,
  Container,
  InfoColumn,
  NameContainer,
  TopBar,
  Wrapper,
  LinkWrapper,
  FlagImage,
} from './AppointmentCard.style'
import { AppointmentCardProps } from './AppointmentCard.types'
import { Text, LinkWithIcon, FlexRowCenter } from '@moodwork-ui'
import { PsychologistLabels } from '../PsychologistLabels'
import { useMemo } from 'react'

/**
 * TODO No animation on desktop
 *      Directly pass psychologist as a prop
 */
export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  avatar,
  title,
  description,
  position,
  onPress = () => {},
  nextSlotContent,
  event_kind,
  inactive_status,
  inactive_message,
  spoken_languages,
  renderCalendar,
}) => {
  const device = useDevice()
  const [press, setPress] = React.useState(false)
  const borderColor = useTheme().colors.primary

  /**
   * on press on the card, if we are in desktop mode,
   * then we don't need to animate aniything.
   * If we are on a mobile phone, or a really small screen
   * we will animate the selection before triggering
   * onPress because it's beautiful 💅
   *
   * TODO:
   * Add renderCalendar or calendarComponent that uses a container of core
   * to avoid deep props passing
   */
  const _onPress = React.useCallback(() => {
    setPress(true)
    setTimeout(() => {
      setPress(false)
      setTimeout(onPress, 200)
    }, 200)
  }, [device])

  const isInactive = useMemo(() => {
    return inactive_status && inactive_message != undefined
  }, [inactive_message, inactive_status])

  return (
    <Wrapper
      animate={{ backgroundColor: press ? borderColor : '#ffffff' }}
      onPress={() => {
        if (device === 'small' && !isInactive) _onPress()
      }}>
      <Container device={device}>
        <InfoColumn device={device}>
          <TopBar>
            <Avatar uri={avatar} />
            <Spacer size={16} />
            <NameContainer>
              <FlexRowCenter>
                <Text size='M' weight='bold'>
                  {title}
                </Text>
                {spoken_languages &&
                  spoken_languages.map((lang) => (
                    <FlagImage
                      source={{
                        uri: `https://flagcdn.com/h20/${lang.code}.png`,
                      }}
                    />
                  ))}
              </FlexRowCenter>
              <Spacer size={4} />
              <Text size='S' color='grey'>
                {position}
              </Text>
            </NameContainer>
          </TopBar>
          <Spacer size={8} />
          <HTMLView source={description} />
          <Spacer size={8} />
          <PsychologistMedium event_kind={event_kind} />
          <OnDevice size='small'>
            <LinkWrapper>
              {isInactive ? (
                <HTMLView source={inactive_message} big />
              ) : (
                <LinkWithIcon
                  textSize='M'
                  label={nextSlotContent}
                  textWeight='bold'
                  textUnderline={false}
                  IconPosition='right'
                  iconName='arrow-right'
                  iconSize={20}
                  onPress={_onPress}
                  spacing='S'
                  trackLabel='clicked_appointment_show_calendar'
                />
              )}
            </LinkWrapper>
          </OnDevice>
        </InfoColumn>
        <OnDevice size={['medium', 'large']}>
          <CalendarColumn hidden={false}>
            <CalendarContainer>{renderCalendar()}</CalendarContainer>
          </CalendarColumn>
        </OnDevice>
      </Container>
    </Wrapper>
  )
}
