import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webview: {
    flex: 1,
  },
  navContainer: {
    backgroundColor: 'white',
    height: 48,
    width: '100%',
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 2,
    justifyContent: 'center',
  },
})
