import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { Button, PageCentered } from '../../ui'
import { Logo } from '../../ui/Logo'
import { Spacer } from '../../ui/Spacer'
import { Title, CenteredLink } from '../../ui/Text'

export default function ConfidentialityInfo() {
  const navigation = useNavigation()
  return (
    <PageCentered>
      <Logo />
      <Spacer size={32} />
      <Title>You are entering a confidential area</Title>
      <Button onPress={() => navigation.push('SignupEmail')}>Next</Button>
      <CenteredLink>Dicover the confidentiality charter</CenteredLink>
    </PageCentered>
  )
}
