import React from 'react'
import { Text, RoundedImage } from '@moodwork-ui'
import { Container } from './CallConfTag.styles'
// @ts-ignore
import video from '../../../../../assets/images/ic_rdv_video.png'
// @ts-ignore
import call from '../../../../../assets/images/ic_rdv_phone.png'

interface CallConfTagProps {
  type: string
  label: string
}

export const CallConfTag = ({ type, label }: CallConfTagProps) => {
  return (
    <Container>
      <RoundedImage imageSize='22px' uri={type == 'call' ? call : video} />
      <Text size='XS' weight='semiBold' style={{ marginLeft: 5 }}>
        {label}
      </Text>
    </Container>
  )
}
