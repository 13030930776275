import { FontIcon } from '@ui/Moodwork-UI/atoms'
import { View } from 'react-native'
import styled from 'styled-components/native'

export const WebinarRegisterContainer = styled.View`
  flex-direction: row;
  align-self: flex-end;
`

export const Icon = styled(FontIcon)`
  margin-left: ${({ theme }) => `${theme.spacings.S}px`};
`

export const SuccessContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`