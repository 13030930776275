import React, { useContext, useEffect, useState } from 'react'
import { Container, InputContainer } from './index.style'
import {
  SmallParagraph,
  SubTitle,
  Text,
  Button,
  Title,
  FontIcon,
} from '@ui/Moodwork-UI'
import { useTranslation } from 'react-i18next'
import { Logo } from '@ui/Logo'
import { TextInput } from '@ui/TextInput'
import Checkbox from '../../pressables/Checkbox'
import { View } from 'react-native'
import { useToggle } from '@core/lib/hooks'
import { AlertContext } from '@core/components/Alerthandler'
import { IS_NATIVE, useTheme } from '@ui/utils'
import { useUpdateUser } from '@core/services/viewer'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_HOME } from '@core/constants'
import { Link } from '@ui/Text'

export default function ConfirmationModalContent() {
  const { t } = useTranslation(['sign'])
  const { setMessage, setType } = useContext(AlertContext)
  const theme = useTheme()
  const navigation = useNavigation()

  const { mutate, data, error, isLoading } = useUpdateUser()

  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')

  const [awaitMail, , , toggleAwait] = useToggle(false)
  const [usePersonnal, , , togglePersonnal] = useToggle(false)
  const [newsletter, , , toggleNewsletter] = useToggle(false)
  const [acknowledge, , , toggleAcknowledge] = useToggle(false)

  const validate = () => {
    if (email === '') {
      mutate({
        personalEmail: '',
        subToNewsletter: newsletter,
        privacyConsent: true,
      })
      navigation.push(SCREEN_HOME)
      return
    }
    if (!email.match(/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setType('warning')
      setMessage('Email non valide, veuillez le vérifier')
      return
    }
    mutate({
      personalEmail: email,
      subToNewsletter: newsletter,
      privacyConsent: true,
    })
  }

  useEffect(() => {
    if (data) {
      toggleAwait()
    } else if (error) {
      setType('warning')
      setMessage(
        'Une erreur est survenue, réessayez plus tard ou contactez le support'
      )
    }
  }, [data, error])

  return (
    <Container
      contentContainerStyle={{
        alignItems: 'center',
        marginLeft: `${IS_NATIVE ? 5 : 12.5}%`,
        paddingTop: IS_NATIVE ? 16 : 32,
        width: `${IS_NATIVE ? 90 : 75}%`,
      }}>
      <Logo />
      {!awaitMail ? (
        <View
          style={{
            marginTop: IS_NATIVE ? 16 : 32,
            height: '100%',
            alignItems: 'center',
          }}>
          <SubTitle marginSize={`${IS_NATIVE ? 16 : 32}px 0 16px 0`}>
            {t('up.email.reask-title')}
          </SubTitle>
          <Text
            marginSize={`0 0 ${IS_NATIVE ? 0 : 32}px 0`}
            alignPosition='center'>
            {t('up.email.reask-subtitle')}
          </Text>
          {!usePersonnal && (
            <InputContainer>
              <TextInput
                style={{ width: '48%' }}
                type='email'
                value={email}
                onChange={setEmail}
                placeholder={t('up.form.personnal-email')}
              />
              <TextInput
                style={{ width: '48%' }}
                type='email'
                value={confirmEmail}
                onChange={setConfirmEmail}
                placeholder={t('up.form.confirm-personnal-email')}
              />
            </InputContainer>
          )}
          <View style={{ rowGap: 16, marginBottom: IS_NATIVE ? 16 : 32 }}>
            <Checkbox
              isActive={usePersonnal}
              onPress={togglePersonnal}
              text={t('up.email.use-professionnal')}
            />
            <Checkbox
              isActive={newsletter}
              onPress={toggleNewsletter}
              text={t('up.form.newsletter')}
            />
            <Checkbox
              isActive={acknowledge}
              onPress={toggleAcknowledge}
              text={
                <SmallParagraph>
                  {t('up.form.acknowledge')}
                  <Link
                    lineHeight={'XS'}
                    size={'XS'}
                    text={t('up.form.acknowledge-2')}
                    url={'https://moodwork.com/fr/legale/rgpd'}
                  />
                </SmallParagraph>
              }
            />
          </View>
          <View>
            <SmallParagraph color='grey'>
              {t('up.form.newsletter-info')}
            </SmallParagraph>
            <SmallParagraph marginSize='16px 0 32px 0' color='grey'>
              {t('up.email.acknowledge-info')}
            </SmallParagraph>
          </View>
          <Button
            trackLabel='clicked_signup_register'
            label={t('up.common.validate')}
            onPress={validate}
            disabled={
              (email === '' || email !== confirmEmail || !acknowledge) &&
              (!usePersonnal || !acknowledge) &&
              !isLoading
            }
          />
        </View>
      ) : (
        <>
          <SubTitle marginSize='128px 0 0 0'>
            {t('up.email.await-title')}
          </SubTitle>
          <View style={{ marginTop: 32, flexDirection: 'row' }}>
            <FontIcon
              name='check-circle'
              size={19}
              color={theme.colors.lightGreen}
              style={{ marginRight: 8 }}
            />
            <Text
              marginSize='0'
              alignPosition={theme.device === 'small' ? 'left' : 'center'}>
              {t(
                `up.email.subtitle-${
                  usePersonnal ? 'private' : 'professionnal'
                }`
              )}
            </Text>
          </View>
        </>
      )}
    </Container>
  )
}
