import { Flex, FlexCenter, Line } from '@ui/Moodwork-UI'
import styled, { css } from 'styled-components/native'

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.greyBlue};
  padding-vertical: ${({ theme }) => theme.spacings.XXL};
  padding-horizontal: ${({ theme }) =>
    theme.device === 'small' ? theme.spacings.L : theme.spacings.XXXL}}
`

export const MainFactorsContainer = styled.View`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`

export const MainFactorItem = styled(FlexCenter)`
  max-width: 500px;
  width: 100%;
  margin-bottom: ${({ theme }) => (theme.device === 'large' ? '56px' : '32px')};
  ${({ theme }) =>
    theme.device !== 'large' &&
    css`
    align-items: flex-start;
  `}
`

export const MainFactorItemList = styled(Flex)`
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 16px;
`

export const PannelSelectionContainer = styled.View`
  display: flex;
  flex-direction: row;
`

export const PannelSelectionItemContainer = styled.View`
  display: flex;
  width: 33.333%
`

export const PannelSelectionItem = styled.Pressable<{
  selected?: boolean
  hover?: boolean
}>`
  display: flex;
  padding: 24px;
  background-color: ${({ theme, selected, hover }) =>
    selected
      ? theme.colors.white
      : hover
      ? `rgba(255, 255, 255, 0.6)`
      : 'transparent'}}
`

export const PannelSelected = styled.View`
  display: flex;
  width: 66.666%
  background-color: ${({ theme }) => theme.colors.white};
`

export const DescriptionContainer = styled.View`
  padding: 32px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.lightSecondaryGrey};
`

export const FactorsContainer = styled(MainFactorsContainer)`
  padding: 32px;
`

export const PannelFactorItem = styled(FlexCenter)`
  max-width: 350px;
  width: 100%;
  margin-bottom: 32px;
  margin-horizontal: 8px;
  ${({ theme }) =>
    theme.device !== 'large' &&
    css`
      align-items: flex-start;
  `}
`

export const Separator = styled(Line)`
  height: ${({ height }) => (height ? `${height}px` : '3px')};
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.primary};
  marginVertical: ${({ theme }) => theme.spacings.L};
`

export const FactorsRiskIcon = styled.Image<{ big?: boolean }>`
  height: ${({ big }) => (big ? '54px' : '39px')};
  width: ${({ big }) => (big ? '54px' : '39px')};
  margin-bottom: 8px;
`
