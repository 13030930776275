import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Title, Separator } from './index.style'
import { FlatList, Pressable, View } from 'react-native'
import { useNavigationLink } from '@core/lib/navigation'
import WebinarCard from '@ui/Moodwork-UI/molecules/Dashboard/Webinar'
import { Webinar } from '@types/webinar'
import { trackEvent } from '@core/lib/analytics'
import { SCREEN_PRIVATE_WEBINARS } from '@core/constants'

export default function WebinarList({ webinars }: { webinars: Webinar[] }) {
  const { t } = useTranslation(['dashboard', 'dates'])
  const navigation = useNavigation()

  //add tracking here if needed
  const openWebinar = useCallback(
    (url: string, title: string, date: string, id: string) => {
      trackEvent('clicked_db_upcoming_event_item', {
        url_redirection: url,
        item_name: title,
        item_tag: 'conférence',
        upcoming_event_date: date,
      }).then(() => navigation.push(SCREEN_PRIVATE_WEBINARS, { id: id }))
    },
    []
  )

  return (
    <View>
      <Title>{t('dashboard.webinar.title')}</Title>
      <FlatList
        data={webinars}
        keyExtractor={(item) => item.uuid}
        renderItem={({ item, index }) => {
          const onPress = () =>
            openWebinar(item.private_url, item.title, item.date, item.uuid)
          return (
            <Pressable onPress={onPress}>
              <WebinarCard {...item} onPress={onPress} />
            </Pressable>
          )
        }}
        ItemSeparatorComponent={() => (
          <Separator color='separatorGrey' height={1} />
        )}
      />
    </View>
  )
}
