import React, { useCallback } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { NavigationText } from './NavigationLink.style'
import { NavigationLinkProps } from './NavigationLink.types'
import { NavigationLinkContainer } from './NavigationLink.style'
import { useToggle } from '@core/lib/hooks'
import { useNavigationLink } from '@core/lib/navigation'
import { RootStackParamList } from '@core/lib/types'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'

export default function NavigationLink<
  RouteName extends keyof RootStackParamList
>({
  content,
  prefix,
  url,
  screen,
  screenParams,
  groupScreen,
  trackLabel,
  trackProperties,
}: TrackableEventProps & NavigationLinkProps<RouteName>) {
  const [hovered, onHoverIn, onHoverOut] = useToggle()
  const navigation = useNavigation()
  const linkTo = useNavigationLink()
  const route = useRoute()
  const selected = !!(
    hovered ||
    route.name == screen ||
    (groupScreen && groupScreen.includes(route.name))
  )
  const onPress = useCallback(() => {
    trackEvent(trackLabel, trackProperties).then(() => {
      if (!!screen) {
        navigation.push(screen, screenParams)
      } else if (!!url) {
        linkTo(url)
      }
    })
  }, [screen, url, trackLabel, trackLabel])
  return (
    <NavigationLinkContainer
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}>
      {prefix && prefix}
      <NavigationText size='S' weight='bold' selected={selected}>
        {content}
      </NavigationText>
    </NavigationLinkContainer>
  )
}
