import { SCREEN_HOME } from '@core/constants'
import { storeAuthTokens } from '@core/lib/auth'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Text } from '@ui/Moodwork-UI/atoms'
import React, { useEffect } from 'react'

export default function SSO() {
  const route = useRoute()
  const params = route.params
  const navigation = useNavigation()

  useEffect(() => {
    if (params.access_token && params.refresh_token) {
      storeAuthTokens(
        params.access_token,
        params.refresh_token,
        +params.created_at + +params.expires_in
      ).then(() => {
        navigation.push(SCREEN_HOME)
      })
    }
  }, [params])

  return <Text>TEST</Text>
}
