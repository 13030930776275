import { IS_BROWSER } from '@ui/utils'
import styled, { css } from 'styled-components/native'

const web =
  IS_BROWSER &&
  css`
    z-index: 3;
  `

export const Pressable = styled.Pressable`
  ${web};
  position: absolute;
  top: 32px;
`
