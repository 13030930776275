import { Title } from '@ui/Moodwork-UI/atoms'
import styled from 'styled-components/native'

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.greyBlue};
  padding: ${({ theme }) =>
    theme.device === 'small' ? '48px 32px' : '48px 64px'};
  margin-bottom: 32px;
`

export const TextContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
`

export const Button = styled.Pressable`
  margin-left: 32px;
  flex-direction: row;
  align-items: center;
`

export const FilterContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`

export const SearchTitle = styled(Title)`
  margin: 0 0 24px 0; 
`
