import React from 'react'
import { FeedbackProps } from './interface'
import { Input, ButtonWrapper, Content } from './Feedback.styles'
import { useTranslation } from 'react-i18next'
import { Button, Text, FlexRow } from '@moodwork-ui'

export const Feedback = ({
  mark,
  text,
  onPress,
  trackableParams,
  fromDashboard = false,
}: FeedbackProps) => {
  const { i18n, t } = useTranslation(['resources'])
  const [feedback, onChangeFeedback] = React.useState('')

  return (
    <FlexRow>
      <Content>
        <Text size='S'>{text}</Text>
        <Input
          placeholder={t('resources.mark.write')}
          value={feedback}
          onChangeText={onChangeFeedback}
        />
        <ButtonWrapper>
          <Button
            trackLabel={
              fromDashboard
                ? 'clicked_db_feedback_send'
                : 'clicked_resource_send_feedback'
            }
            trackProperties={{
              ...trackableParams,
            }}
            onPress={() => {
              onPress(feedback, mark)
            }}
            compact
            label={t('resources.mark.send_feedback')}
          />
        </ButtonWrapper>
      </Content>
    </FlexRow>
  )
}
