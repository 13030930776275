import React, { useCallback, useMemo } from 'react'
import { trackEvent, TrackableEventProps } from '@core/lib/analytics'
import ResourceCardProps from './ResourceCard.types'
import {
  CardWrapper,
  Container,
  Image,
  Tag,
  Title,
  Footer,
  FooterContainer,
  SoundContainer,
  ImageContainer,
  TopContainer,
  FooterButtonContainer,
  FooterButtonText,
  SuccessContainer,
} from './ResourceCard.style'
import { FontIcon, SmallParagraph } from '@moodwork-ui'
import { useTheme } from '@utils'
import { useToggle } from '@core/lib/hooks'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@moodwork-ui'
import { DateTime } from 'luxon'
import { capitalize } from 'lodash'
import ContentLoader from 'react-content-loader/native'
import { Rect } from 'react-native-svg'
import { GridView } from '@ui/Moodwork-UI/templates/GridWiew'

export default function ResourceCardLoading({
  items,
  itemWidth = '270px',
  itemHeight = '220px',
  listHeight = '120px',
}: {
  items: []
  itemWidth: string
  itemHeight: string
  listHeight: string
}) {
  const theme = useTheme()

  const renderItemLoading = useCallback(() => {
    return (
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='100%'
        height='100%'>
        <Rect x='0' y='0' rx='3' ry='3' width='90%' height='90%' />
      </ContentLoader>
    )
  }, [])
  return (
    <GridView
      items={items}
      listHeight={listHeight}
      itemHeight={itemHeight}
      itemWidth={itemWidth}
      renderItem={renderItemLoading}
    />
  )
}
