import { useNavigation } from '@react-navigation/native'
import { DiaryGroup, ResourceMark } from '@types/diary'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import { useHttp } from './http'

interface SharePayload {
  resourceType: string
  resourceUuid: string
  message?: string
  emails: string[]
}

export function useCollection(
  id: string
): UseQueryResult<DiaryGroup, AxiosError> {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getCollection = async () =>
    (await http.get(`/api/v1/diary_groups/${id}`))?.data
  return useQuery(['collection', id, i18n.language], getCollection, {
    retry: 1 /*, refetchOnWindowFocus: true*/,
  })
}

export function usePortals(id: string): UseQueryResult<DiaryGroup, AxiosError> {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getPortals = async () => (await http.get(`/api/v3/portals/${id}`))?.data
  return useQuery(['portals', id, i18n.language], getPortals, {
    retry: 1 /*, refetchOnWindowFocus: true*/,
  })
}

export function useResource(id: string) {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getResource = async () => (await http.get(`/api/v1/diary/${id}`))?.data
  return useQuery(['resource', id, i18n.language], getResource, { retry: 1 })
}

export function useResourceSuggestions(id: string) {
  const { i18n } = useTranslation()
  const http = useHttp()
  const getResourceSuggestions = async () =>
    (await http.get(`/api/v3/diaries/${id}/diary_suggestions`))?.data
  return useQuery(
    ['resource:suggestions', id, i18n.language],
    getResourceSuggestions,
    { retry: 1 }
  )
}

export function useSendDiaryMark(diaryId: string) {
  const http = useHttp()

  const sendMark = ({ rate, comment }: { rate?: number; comment?: string }) =>
    http.post(
      `/api/v1/diary/${diaryId}/mark`,
      {
        rate: rate,
        comment: comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

  return useMutation('post:diaryMark', sendMark, { retry: 1 })
}

export function useMark(
  resourceUuid: string
): UseQueryResult<ResourceMark, AxiosError> {
  const http = useHttp(useNavigation())
  const getMark = () =>
    http
      .get(`/api/v1/diary/${resourceUuid}/mark`)
      .then((result) => result?.data)
  return useQuery(['resource:mark', resourceUuid], getMark, { retry: 1 })
}

export function useFavorites() {
  const queryKey = 'post:favorites'
  const http = useHttp()
  const queryClient = useQueryClient()

  const sendFavorite = ({
    type,
    resourceUuid,
    addFavorite,
  }: {
    type?: string
    resourceUuid: string
    addFavorite: boolean
  }) =>
    http
      .post(
        `/api/v3/${type}/${resourceUuid}/favorites${
          !addFavorite ? '/remove' : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        const previousFavorites = queryClient.getQueryData(queryKey)
        return { ...previousFavorites, [resourceUuid]: addFavorite }
      })

  const { data, ...mutation } = useMutation(queryKey, sendFavorite, {
    // When mutate is called:
    onMutate: async (favoritePayload) => {
      // Snapshot the previous value
      const previousFavorites = queryClient.getQueryData(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (favorites) => ({
        ...favorites,
        [favoritePayload.resourceUuid]: favoritePayload.addFavorite,
      }))

      // Return a context object with the snapshotted value
      return { previousFavorites }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, payload, context) => {
      queryClient.setQueryData(queryKey, context.previousFavorites)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
    retry: 1,
  })

  return {
    ...mutation,
    data: data || queryClient.getQueryData(queryKey),
  }
}

export const useShare = () => {
  const http = useHttp()
  const share = ({
    resourceType,
    resourceUuid,
    message,
    emails,
  }: SharePayload) => {
    return http.post(
      '/api/v3/share',
      {
        resource_type: resourceType,
        resource_uuid: resourceUuid,
        message: message,
        emails: emails,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
  return useMutation(['share:resource'], share, {
    retry: 1,
  })
}
