import * as React from 'react'
import { HOST } from '@core/constants'
import { useNavigationLink } from '@core/lib/navigation'
import { useTheme } from '@ui/utils'
import { useCallback } from 'react'
import { Pressable } from './HomePressable.styles'
import { Logo } from '@ui/Logo'

export const HomePressable = () => {
  const device = useTheme().device
  const linkTo = useNavigationLink()

  const backToShowcase = useCallback(() => {
    linkTo(`${HOST}`)
  }, [])

  return (
    <Pressable onPress={backToShowcase}>
      <Logo big={device === 'large'} />
    </Pressable>
  )
}
