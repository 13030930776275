import { StyleSheet } from 'react-native'
import theme from '../../../UIProvider/defaultTheme'

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  disabledInput: {
    opacity: 0.5,
  },
  inputContainer: {
    flexDirection: 'row',
  },
  leftElement: {
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  rightElement: {
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  input: {
    fontSize: 16,
    flex: 1,
    minHeight: 40,
    marginLeft: 4,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.secondaryLight,
    letterSpacing: 1,
    paddingVertical: 12,
    paddingHorizontal: 6,
    height: 44,
    color: '#303030',
    outlineStyle: 'none',
    fontFamily: theme.fonts.regular,
  },
  tagsView: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  tag: {
    flexDirection: 'row',
    height: 26,
    borderRadius: 13,
    backgroundColor: theme.colors.secondary,
    minWidth: 40,
    maxWidth: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
    margin: 5,
    borderWidth: 0.5,
    borderColor: theme.colors.secondary,
  },
  tagText: {
    marginHorizontal: 5,
    color: theme.colors.white,
    fontFamily: theme.fonts.regular,
  },
  labelStyle: {
    fontSize: 12,
    marginTop: 12,
    marginBottom: -4,
    fontFamily: theme.fonts.regular,
  },
  deleteIcon: {
    opacity: 0.9,
    marginLeft: 5,
  },
})
