import { trackEvent } from '@core/lib/analytics'
import { useSyncReady } from '@core/lib/ready'
import { useRecommendedResourcesWellBeing } from '@services/dashboard'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import RecommendedResources from '../RecommendedResources'

export default function RecommendedResourcesWellBeing({
  onVisible,
}: {
  onVisible: (visible: boolean) => void
}) {
  const { data } = useRecommendedResourcesWellBeing()
  const { t } = useTranslation(['dashboard'])
  const isReady = useSyncReady('dashboard', 'recommended_wellbeing', !!data)

  useEffect(() => {
    if (data != null && data.length > 0) {
      onVisible(true)
    }
  }, [data?.length])

  useEffect(() => {
    if (isReady && data) {
      trackEvent('viewed_db_assessment_recommendation', {
        nb_recommended_items: data.length,
      })
    }
  }, [isReady])

  if (isReady) {
    return (
      <RecommendedResources
        title={t('dashboard.recommendation.wellbeing')}
        data={data}
        trackLabel='clicked_db_recommended_for_you_item'
      />
    )
  }
  return null
}
