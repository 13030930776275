import Constants from 'expo-constants'

export const HOST = Constants?.manifest?.extra?.REACT_APP_HOST
export const ENV = Constants?.manifest?.extra?.REACT_APP_ENV

export const MARKETING_ID_TOKEN_KEY = 'mw_marketing_id'
export const ACCESS_TOKEN_KEY = 'mw_access_token'
export const REFRESH_TOKEN_KEY = 'mw_refresh_token'
export const TOKEN_EXPIRATION_KEY = 'mw_token_expiration'
export const LOCALE_KEY = 'mw_locale'
export const TIMEZONE_KEY = 'timezone'
export const LOGGED_IN_ENV = 'mw_logged_in_' + ENV
export const SESSION_ID_KEY = '_moodwork_session'
export const APPOINTMENT_TUTORIAL = 'mw_appointment_tutorial'

export const SENTRY_DSN =
  'https://7dbb69514f1f44f2865707735cec8b1e@sentry.moodwork.com/5'

export const INTERCOM_APP_ID = Constants?.manifest?.extra?.INTERCOM_APP_ID
export const RECAPTCHA_SITE_KEY = Constants?.manifest?.extra?.RECAPTCHA_SITE_KEY

/**
 * Firebase for web
 */

export const FIREBASE_API_KEY = Constants?.manifest?.extra?.FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN =
  Constants?.manifest?.extra?.FIREBASE_AUTH_DOMAIN
export const FIREBASE_STORAGE_BUCKET =
  Constants?.manifest?.extra?.FIREBASE_STORAGE_BUCKET
export const FIREBASE_PROJECT_ID =
  Constants?.manifest?.extra?.FIREBASE_PROJECT_ID
export const FIREBASE_APP_ID = Constants?.manifest?.extra?.FIREBASE_APP_ID
export const FIREBASE_SENDER_ID = Constants?.manifest?.extra?.FIREBASE_SENDER_ID
export const FIREBASE_DATABASE_URL =
  Constants?.manifest?.extra?.FIREBASE_DATABASE_URL
export const FIREBASE_MEASUREMENT_ID =
  Constants?.manifest?.extra?.FIREBASE_MEASUREMENT_ID

/**
 * Client authentication
 */
export const AUTH_CLIENT_ID = Constants?.manifest?.extra?.CLIENT_ID
export const AUTH_CLIENT_SECRET = Constants?.manifest?.extra?.CLIENT_SECRET

export const DEFAULT_LANGUAGE = 'fr'
export const SUPPORTED_LOCALES = ['fr', 'en']

/**
 * UI
 */
export const PAGE_HORIZONTAL_MARGINS = 16

/**
 * APP STORES
 */
export const APPLE_STORE = 'https://apps.apple.com/fr/app/moodwork/id1446714282'
export const ANDROID_STORE =
  'https://play.google.com/store/apps/details?id=com.moodwalk.moodwork'

/*
 * Screen names
 */
export const SCREEN_HOME = 'Home'
export const SCREEN_HOME_INTERCOM = 'HomeIntercom'
export const SCREEN_PSYCHOLOGISTS = 'Psychologists'
export const SCREEN_PSYCHOLOGIST_CALENDAR = 'PsychologistCalendar'
export const SCREEN_PSYCHOLOGIST_APPOINTMENT_TYPE =
  'PsychologistAppointmentType'
export const SCREEN_PSYCHOLOGIST_APPOINTMENT_INFO =
  'PsychologistAppointmentInfo'
export const SCREEN_PSYCHOLOGIST_APPOINTMENT_CONFIRMATION =
  'PsychologistAppointmentConfirmation'
export const SCREEN_RESOURCE = 'Resource'
export const SCREEN_TEST_RESULT = 'TestResult'
export const SCREEN_WELLBEING_TRACKING = 'WellbeingTracking'
export const SCREEN_CONTENTS = 'Contents'
export const SCREEN_WELCOME = 'Welcome'
export const SCREEN_COMMING_SOON = 'CommingSoon'
export const SCREEN_SIGNIN = 'SignIn'
export const SCREEN_SSO = 'SSO'
export const SCREEN_SIGNUP_INTRO = 'SignUpIntro'
export const SCREEN_SIGNUP_EMAIL = 'SignupEmail'
export const MODAL_CONFIRMATION = 'ConfirmationModal'
export const SCREEN_SIGNUP_EMAIL_CONFIRMATION = 'EmailConfirmation'
export const SCREEN_SIGNUP_RGPD = 'SignupRgpd'
export const SCREEN_SIGNUP_FORM = 'SignupForm'
export const SCREEN_SIGNUP_SSO = 'SignupSSO'
export const SCREEN_PASSWORD = 'Password'
export const SCREEN_CONFIDENTIALITY_INFO = 'ConfidentialityInfo'
export const SCREEN_AUTH_BROWSER = 'AuthBrowser'
export const SCREEN_CONFERENCES = 'Conferences'
export const SCREEN_COMPANY = 'Company'
export const SCREEN_SEARCH = 'Search'
export const SCREEN_COLLECTION = 'Collection'
export const SCREEN_PORTALS = 'Portals'
export const SCREEN_LIBRARY = 'Library'
export const SCREEN_PROFILING = 'Profiling'
export const SCREEN_REGISTRATION = 'Registration'
export const SCREEN_ASSESSMENT = 'Assessments'
export const SCREEN_PROFILE = 'Profile'
export const SCREEN_PUBLIC_WEBINARS = 'Webinars'
export const SCREEN_PRIVATE_WEBINARS = 'PrivateWebinars'
export const SCREEN_PROGRAM = 'Program'
export const SCREEN_CONTACT = 'Contact'
export const SCREEN_FAQ = 'Faq'

/* Modals */
export const MODAL_WELCOME = 'WelcomeModal'
export const MODAL_ASSESSMENT = 'AssessmentModal'
export const MODAL_APPOINTMENT_TUTORIAL = 'AppointmentTutorialModal'
export const MODAL_SHARE_RESOURCE = 'ShareResourceModal'
export const MODAL_CANCEL_EVENT = 'CancelEventModal'

export const PASSWORD_REGEX = [
  /.{8,}/,
  /.{8,}/,
  /(?=.*?[0-9])(?=.*?[a-z]).{8,}/,
  /(?=.*?[0-9])(?=.*?[a-z]).{8,}/,
  /(?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z]).{8,}/,
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
]

export const PASSWORD_REGEX_MATCH_LIST = [
  [/./, /.{8,}/],
  [/./, /.{8,}/],
  [/./, /.{8,}/, /\d/, /[a-z]/],
  [/./, /.{8,}/, /\d/, /[a-z]/],
  [/./, /.{8,}/, /\d/, /[a-z]/, /[A-Z]/],
  [/./, /.{8,}/, /\d/, /[a-z]/, /[A-Z]/, /[#?!@$%^&*-]/],
]
