import * as React from 'react'
import { Foundation as Icon } from '@expo/vector-icons'
import ImagePlaceholder from '../ImagePlaceholder'
import { Paragraph } from '../Paragraph'
import { Spacer } from '../Spacer'
import { Container, IconContainer, Content } from './FeaturedContent.style'
import { FeaturedContentProps } from './FeaturedContent.types'
import { ContentTag } from '../ContentTag'

export default function FeaturedContent({ color }: FeaturedContentProps) {
  return (
    <Container color={color}>
      <ImagePlaceholder size={54} />
      <Spacer size={8} />
      <Content>
        <ContentTag color={color} content='Confiance en soit' />
        <Spacer size={8} />
        <Paragraph
          title='Abandonner son idéal de perfection'
          content='Arrêtez de vous fixer des objectifs inatteignables !'
        />
      </Content>
      <IconContainer>
        <Icon name='megaphone' color='rgba(0, 0, 0, 0.2)' size={20} />
      </IconContainer>
    </Container>
  )
}
