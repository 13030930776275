import styled, { css } from 'styled-components/native'
import { Pressable } from 'react-native'
import { Flex } from '@ui/Moodwork-UI'

export const List = styled.FlatList.attrs({
  contentContainerStyle: {
    width: '100%',
    minHeight: '100%',
    paddingTop: 64,
  },
})`
  width: 100%;
`

export const IntercomContainer = styled(Pressable)`
  margin-vertical: 20px;
  margin-horizontal: 4px;
`

export const FilterContainer = styled(Flex)`
  margin-bottom: 20px;
`

export const CheckboxContainer = styled(Flex)`
  flex-wrap: wrap;
  flex-direction: row;
`
