import styled from 'styled-components/native'

export const OTPInputContainer = styled.View`
  justify-content: center;
  align-items: center;
`

export const TextInputHidden = styled.TextInput`
  position: absolute;
  opacity: 0;
`

export const SplitOTPBoxesContainer = styled.Pressable`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;  
  column-gap: ${({ theme }) => (theme.device === 'small' ? '4px' : '17px')};
  justify-content: space-evenly;
`
export const SplitBoxes = styled.View<{
  defaultValue?: boolean
  hasValue: boolean
}>`
  border-color: ${({ hasValue, theme }) =>
    hasValue ? theme.colors.black : theme.colors.lightGrey};
  border-width: 1px;
  padding: ${({ theme }) => (theme.device === 'small' ? '4px' : '12px')};
  width: ${({ theme }) => (theme.device === 'small' ? '28px' : '49px')};
  height: ${({ theme }) => (theme.device === 'small' ? '28px' : '49px')};
  background-color: ${({ defaultValue, theme }) =>
    defaultValue ? theme.colors.primary : 'white'};
  margin-bottom: 8px;
`

export const SplitBoxText = styled.Text`
  font-size: ${({ theme }) => (theme.device === 'small' ? '16px' : '20px')};
  text-align: center;
  color: black;
`

export const SplitBoxesFocused = styled(SplitBoxes)`
  border-color: ${({ theme }) => theme.colors.primary};
`

export const ButtonContainer = styled.TouchableOpacity`
  background-color: #000000;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-top: 30px;
`

export const ButtonText = styled.Text`
  color: black;
  font-size: 20px;
`
