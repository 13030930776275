import styled from 'styled-components/native'

export const Container = styled.View`
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`

export const Image = styled.Image`
  width: 150px;
  height: 150px;
`
