import * as React from 'react'
import {
  Container,
  ImageContainer,
  Description,
  Image,
} from './Tutorials.style'
import { TutorialsProps } from './Tutorials.types'
import { Title, BigParagraph } from '@moodwork-ui'
import { useTheme } from '@utils'

export default function Tutorials({
  setCurrentStep,
  title,
  description,
  image,
  imageBackground = true,
}: TutorialsProps) {
  const device = useTheme().device

  return (
    <>
      <Container device={device}>
        <ImageContainer device={device} hasBackground={imageBackground}>
          <Image device={device} resizeMode={'contain'} source={image} />
        </ImageContainer>
        <Description
          device={device}
          contentContainerStyle={{
            justifyContent: device === 'small' ? 'flex-start' : 'center',
            flex: 1,
          }}>
          <Title marginSize={'0px'}>{title}</Title>
          <BigParagraph
            marginSize={
              device === 'small' ? '18px 0px 0px 0px;' : '25px 0px 0px 0px'
            }>
            {description}
          </BigParagraph>
        </Description>
      </Container>
    </>
  )
}
