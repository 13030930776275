import React from 'react'
import {
  ImageSkeleton,
  NameSkeleton,
  AvatarSkeleton,
  SkeletonContainer,
  Wrapper,
  PsySkeleton,
  CalendarSkeleton,
  PositionSkeleton,
  PsyInfoSkeleton,
  PsyDescriptionSkeleton,
  SmallRowSkeleton,
  MediumRowSkeleton,
  LongRowSkeleton,
  CalendarTitleSkeleton,
  SlotSkeleton,
  RowSkeleton,
  EmptySlotSkeleton,
} from './AppointmentCard.style'
import { useDevice, useTheme } from '@utils'
import { OnDevice } from '@ui/OnDevice'
import ContentLoader from 'react-content-loader/native'
import { Circle, Rect } from 'react-native-svg'

export function AppointmentCardCalendarLoader({ height }: { height: number }) {
  const theme = useTheme()

  return (
    <CalendarSkeleton height={height}>
      <ContentLoader
        backgroundColor={theme.colors.lightGrey}
        width='100%'
        height='7'>
        <Rect x='91' y='0' rx='3' ry='3' width='292' height='7' />
      </ContentLoader>
      <ContentLoader
        backgroundColor={theme.colors.lightSecondaryGrey}
        width='100%'
        height='100%'>
        <Rect x='91' y='84' rx='3' ry='3' width='81' height='39' />
        <Rect x='182' y='84' rx='3' ry='3' width='81' height='39' />
        <Rect x='273' y='84' rx='3' ry='3' width='81' height='39' />
        <Rect x='364' y='84' rx='3' ry='3' width='81' height='39' />
        <Rect x='455' y='84' rx='3' ry='3' width='81' height='39' />
        <Rect x='546' y='84' rx='3' ry='3' width='81' height='39' />

        <Rect x='91' y='140' rx='3' ry='3' width='81' height='39' />
        <Rect x='182' y='140' rx='3' ry='3' width='81' height='39' />
        <Rect x='273' y='140' rx='3' ry='3' width='81' height='39' />
        <Rect x='364' y='140' rx='3' ry='3' width='81' height='39' />
        <Rect x='455' y='140' rx='3' ry='3' width='81' height='39' />
        <Rect x='546' y='140' rx='3' ry='3' width='81' height='39' />

        <Rect x='91' y='196' rx='3' ry='3' width='81' height='39' />
        <Rect x='182' y='196' rx='3' ry='3' width='81' height='39' />
        <Rect x='273' y='196' rx='3' ry='3' width='81' height='39' />
        <Rect x='364' y='196' rx='3' ry='3' width='81' height='39' />
        <Rect x='455' y='196' rx='3' ry='3' width='81' height='39' />
        <Rect x='546' y='196' rx='3' ry='3' width='81' height='39' />

        <Rect x='91' y='252' rx='3' ry='3' width='81' height='39' />
        <Rect x='273' y='252' rx='3' ry='3' width='81' height='39' />
        <Rect x='364' y='252' rx='3' ry='3' width='81' height='39' />
        <Rect x='546' y='252' rx='3' ry='3' width='81' height='39' />

        <Rect x='364' y='308' rx='3' ry='3' width='81' height='39' />
      </ContentLoader>
    </CalendarSkeleton>
  )
}

/**
 * TODO: mobile
 */
export default function AppointmentCardLoader({ height }: { height: number }) {
  const device = useDevice()
  const theme = useTheme()
  // TODO: found a way to make a proper loading component
  return (
    <Wrapper>
      <SkeletonContainer device={device}>
        <PsySkeleton>
          <AvatarSkeleton>
            <ContentLoader
              backgroundColor={theme.colors.lightSecondaryGrey}
              width={80}
              height={80}>
              <Circle cx='40' cy='40' r='40' />
            </ContentLoader>
            <PsyInfoSkeleton>
              <ContentLoader
                backgroundColor={theme.colors.lightGrey}
                width={200}
                height={15}>
                <Rect x='8' y='5' rx='3' ry='3' width='176' height='7' />
              </ContentLoader>
              <ContentLoader
                backgroundColor={theme.colors.lightSecondaryGrey}
                width={200}
                height={60}>
                <Rect x='10' y='30' rx='3' ry='3' width='110' height='7' />
              </ContentLoader>
            </PsyInfoSkeleton>
          </AvatarSkeleton>
          <PsyDescriptionSkeleton>
            <ContentLoader
              backgroundColor={theme.colors.lightSecondaryGrey}
              width={350}
              height={200}>
              <Rect x='0' y='21' rx='3' ry='3' width='311' height='7' />
              <Rect x='0' y='44' rx='3' ry='3' width='99' height='7' />
              <Rect x='0' y='67' rx='3' ry='3' width='135' height='7' />
              <Rect x='0' y='90' rx='3' ry='3' width='135' height='7' />
              <Rect x='0' y='113' rx='3' ry='3' width='99' height='7' />
            </ContentLoader>
          </PsyDescriptionSkeleton>
        </PsySkeleton>

        <OnDevice size={['medium', 'large']}>
          <AppointmentCardCalendarLoader height={height} />
        </OnDevice>
      </SkeletonContainer>
    </Wrapper>
  )
}
