import React, { useCallback } from 'react'
import { ViewProps } from 'react-native'
import { BannerProps } from './index.types'
import { Container, Image, TextContainer, ButtonContainer } from './index.style'
import { OnDevice } from '@ui/OnDevice'
import { Button, BigParagraph, FontIcon } from '@moodwork-ui'
import { useTheme } from '@ui/utils'
import { trackEvent } from '@core/lib/analytics'
import { useNavigation } from '@react-navigation/native'

export default function Banner({
  headline,
  title,
  image,
  button,
  style,
  link,
  openNewTab,
  onPressLink,
  tag,
}: BannerProps & Pick<ViewProps, 'style'>) {
  const theme = useTheme()
  const onPress = useCallback(
    () => onPressLink(link, openNewTab),
    [onPressLink, link, openNewTab]
  )
  const onPressContainer = useCallback(() => {
    trackEvent('clicked_db_banner_item', {
      url_redirection: link,
      item_name: title,
      item_tag: tag,
    }).then()
    //onPressLink(link, openNewTab)
  }, [onPressLink, link, openNewTab])
  return (
    <Container style={style} onPress={onPressContainer}>
      {!!image?.url && (
        <Image resizeMode='contain' source={{ uri: image.url }} />
      )}
      <TextContainer>
        <BigParagraph color='secondary'>{headline}</BigParagraph>
        <BigParagraph weight='bold'>{title}</BigParagraph>
      </TextContainer>
      <ButtonContainer>
        <OnDevice size={['medium', 'large']}>
          <Button
            label={button}
            trackLabel='clicked_db_banner_item'
            trackProperties={{
              url_redirection: link,
              item_name: title,
            }}
            onPress={onPress}
          />
        </OnDevice>
        <OnDevice size={['small']}>
          <FontIcon
            size={14}
            name='arrow-right'
            color={theme.colors.secondary}
          />
        </OnDevice>
      </ButtonContainer>
    </Container>
  )
}
