import styled, { css } from 'styled-components/native'
import { IS_BROWSER } from '../../../../utils/device'

const web = css`
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
`

export const Container = styled.Pressable<{ hover: boolean }>`
  display: flex;
  align-items: center;
  max-width: 275px;
  margin: ${({ theme }) => `${theme.spacings.XL}px`};
  ${({ hover }) =>
    hover &&
    css`
        transform: scale(1.1, 1.1);
        ${IS_BROWSER && web};
        shadowColor: ${({ theme }) => theme.colors.black};
        shadowOffset: {
            width: 0,
            height: 0,
        };
        shadowRadius: 45px;
        shadowOpacity: 0.1;
        elevation: 1;
    `};
  ${IS_BROWSER && css`transition: all 0.2s ease-in-out;`}
  padding: ${({ theme }) => `${theme.spacings.S}px`};
`

export const TextAndIconWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => `${theme.spacings.S}px`};
`
