import { IS_BROWSER } from '@ui/utils'
import styled, { css } from 'styled-components/native'
const web =
  IS_BROWSER &&
  css`
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  `

export const Container = styled.View`
flex: 8;
width: 100%;
marginVertical: 16px;
`

export const ResponseContainer = styled.View`
flex: 1;
flex-basis: undefined;
flex-wrap: wrap;
flex-direction: row;
flex-grow: 0;
justify-content: center;
`

export const Response = styled.Pressable<{ isSelected: boolean }>`
border-radius: 999px;
padding: 16px;
border: solid ${({ theme }) => theme.colors.secondary} 2px;
align-items: center;
justify-content: center;
background-color: ${({ theme, isSelected }) =>
  isSelected ? theme.colors.secondary : theme.colors.transparent};
`

export const Wrapper = styled.View<{ hover: boolean; color: string }>`
  max-width: ${({ theme }) => (theme.device === 'small' ? '100%' : '45%')};
  min-width: ${({ theme }) => (theme.device === 'large' ? '30%' : '100%')};
  margin: 12px;
  padding: 2px;
  border-radius: 500px;
  min-height: 52px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.transparent};
  justify-content: center;
  ${web};
  ${({ hover, color }) =>
    hover &&
    css`
      border-color: ${({ theme }) =>
        color ? theme.colors[color] : theme.colors.primary}};
    `}
`
