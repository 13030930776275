import {
  HTMLView,
  RoundedImage,
  SubTitle,
  Text,
  Title,
} from '@ui/Moodwork-UI/atoms'
import { Button } from '@ui/Moodwork-UI/molecules'
import React from 'react'
import { WebinarProps } from './index.type'
import {
  HTMLContainer,
  Speaker,
  SpeakerList,
  SpeakersContainer,
  WebinarContainer,
} from './index.styles'
import { useNavigationLink } from '@core/lib/navigation'
import { useTranslation } from 'react-i18next'
import { Video } from 'expo-av'
import { FlexCenter } from '@ui/Moodwork-UI/templates'
import { useDevice } from '@ui/utils'

export default function Webinar({ data }: WebinarProps) {
  const linkTo = useNavigationLink()
  const { t } = useTranslation(['webinar', 'dashboard'])
  const device = useDevice()

  return (
    <WebinarContainer>
      <Title marginSize=' 0 0 32px 0'>{data?.title}</Title>
      <Text marginSize='0 0 16px 0'>{data?.nice_date}</Text>
      {data?.video ? (
        <FlexCenter>
          <Video
            resizeMode='contain'
            useNativeControls
            style={{ maxWidth: device != 'small' ? '70%' : '100%' }}
            source={{ uri: data.video.url }}
            videoStyle={{
              position: 'relative',
              width: '100%',
              backgroundColor: 'black',
            }}
          />
        </FlexCenter>
      ) : !data.has_subscribed && data.subscribe_link ? (
        <Button
          onPress={() => linkTo(data.subscribe_link, true)}
          label={t('dashboard.webinar.subscribe', { ns: 'dashboard' })}
        />
      ) : (
        <Text weight='bold' color='secondary'>
          {t('dashboard.webinar.has_subscribe', { ns: 'dashboard' })}
        </Text>
      )}
      <HTMLContainer>
        <HTMLView big source={data?.description} />
      </HTMLContainer>
      <SpeakersContainer>
        <SubTitle>{t('webinar.speaker')}</SubTitle>
        <SpeakerList>
          {data?.speakers.map((speaker) => {
            return (
              <Speaker key={speaker?.name}>
                <RoundedImage
                  imageSize='192'
                  uri={{ uri: speaker?.photo?.optimized_url }}
                />
                <Text weight='bold'>{speaker?.name}</Text>
                <Text italic>{speaker?.description}</Text>
              </Speaker>
            )
          })}
        </SpeakerList>
      </SpeakersContainer>
    </WebinarContainer>
  )
}
