import { fetchAuthTokens, storeAuthTokens } from '../lib/auth'
import { createReducer, createStore } from '../lib/store'
import { Thunk } from '../lib/types'

/**
 * The session store will contain
 * the required informations to authenticate the user:
 *  - The access token that expires,
 *  - the refresh token that is used to get a fresh token
 */
export interface SessionState {
  accessToken: string | null
  refreshToken: string | null
  tokenExpiration: string | null
}

/**
 * We fetch the default values from the cookies
 * and create our store with theme
 */
const initialState: SessionState = {
  accessToken: null,
  refreshToken: null,
  tokenExpiration: null,
}

const { actions, reducer } = createReducer({
  setState: (
    state: SessionState,
    { accessToken, refreshToken, tokenExpiration }
  ) => {
    state.accessToken = accessToken
    state.refreshToken = refreshToken
    state.tokenExpiration = tokenExpiration
  },
})

const [useState, SessionProvider] = createStore(reducer, initialState)
export { SessionProvider, useState }

/**
 * Thunks
 */
export function clear(): Thunk<SessionState> {
  return async (dispatch) => {
    await storeAuthTokens(null, null, null)
    dispatch(actions.setState({ accessToken: null, refreshToken: null }))
  }
}

export function setTokens(tokens: SessionState): Thunk<SessionState> {
  return async (dispatch) => {
    await storeAuthTokens(
      tokens.accessToken,
      tokens.refreshToken,
      tokens.tokenExpiration
    )
    dispatch(actions.setState(tokens))
  }
}

export function initTokens(): Thunk<SessionState> {
  return async (dispatch) => {
    dispatch(setTokens(await fetchAuthTokens()))
  }
}
