import { DeviceSizeType } from '@ui/utils'
import styled from 'styled-components/native'

export const Container = styled.View`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 32px;
`

export const Image = styled.Image<{ device: DeviceSizeType }>`
  max-width: 100%;
  width: ${({ device }) => {
    switch (device) {
      case 'small':
        return '75%'
      case 'medium':
        return '35%'
      case 'large':
        return '25%'
    }
  }};
  height: auto;
  aspectRatio: 1.15;
`
