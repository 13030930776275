import React, { useCallback } from 'react'
import NavigationModal from '@ui/Moodwork-UI/atoms/NavigationModal/NavigationModal'
import { storeModal } from '../../../../../core/lib/modal'
import { WelcomeModalContent } from '@ui/Moodwork-UI/molecules/Dashboard/WelcomeModalContent'
import { MODAL_WELCOME } from '@core/constants'
import { useNavigation } from '@react-navigation/native'
import { useUserInfo } from '@core/services/viewer'
import { useCompanyInfo } from '@core/services/users'

export default function WelcomeModal() {
  const navigation = useNavigation()
  const { data: userInfoData } = useUserInfo()
  const { data: companyInfoData } = useCompanyInfo()

  const handleClose = useCallback(() => {
    storeModal(userInfoData?.email, 'false', MODAL_WELCOME)
    navigation.goBack()
  }, [navigation, userInfoData?.email])

  return (
    <NavigationModal onClose={handleClose}>
      <WelcomeModalContent
        logoUrl={companyInfoData?.logo_img_url || ''}
        companyText={companyInfoData?.onboarding_intro_text || ''}
        userName={userInfoData?.name || ''}
        handleClose={handleClose}
      />
    </NavigationModal>
  )
}
