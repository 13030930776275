import { useNavigationLink } from '@core/lib/navigation'
import { ResourceCard } from '@ui/Moodwork-UI/molecules/pressables/ResourceCard'
import React from 'react'
import { View } from 'react-native'
import HorizontalList from '../HorizontalList'
import { Container } from './index.styles'
import { useTheme } from '@utils'
import { useFavorites } from '@core/services/resources'
import { useNavigation } from '@react-navigation/native'
import { SCREEN_COLLECTION } from '@core/constants'

export default function Section({ data }) {
  const theme = useTheme()
  const linkTo = useNavigationLink()
  const { mutate, data: favorites } = useFavorites()
  const navigation = useNavigation()

  const renderItem = React.useCallback(
    ({ item }) => {
      const link = () => {
        if (item.type === 'diary_groups') {
          navigation.push(SCREEN_COLLECTION, {
            id: item.uuid,
          })
        } else if (item.type === 'diaries') {
          navigation.push(SCREEN_RESOURCE, {
            id: item.uuid,
          })
        } else {
          linkTo(item.link)
        }
      }
      return (
        <View
          key={item.title}
          style={{
            width: 230,
            minHeight: theme.device !== 'small' ? 400 : 200,
            backgroundColor: 'white',
          }}>
          <ResourceCard
            uuid={item.uuid}
            type={item.type}
            padding='0'
            title={item.title}
            duration={item.duration}
            image={item.image?.optimized_url || item.image?.url}
            favorite={
              favorites != null && favorites[item.uuid] !== undefined
                ? favorites[item.uuid]
                : item.favorite
            }
            tagKey={item.sticker?.key}
            onCardPress={link}
            onFavorite={mutate}
            link={item.link}
            bottomElement={item.bottom}
            state={item.state}
            sizeForInline={[]}
            customImageHeight='65%'
            removeImageOverlay
            count={item.collection_count}
            trackLabel='clicked_db_collections_item'
            trackProperties={{
              url_redirection: item.link,
              item_name: item.title,
              item_tag: item.sticker?.name,
              section_name: data.title,
            }}
          />
        </View>
      )
    },
    [favorites]
  )

  return (
    <Container>
      <HorizontalList
        title={data?.title}
        titleInline={theme.device != 'small'}
        data={data?.resources}
        renderItem={renderItem}
      />
    </Container>
  )
}
