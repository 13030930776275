import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Animated } from 'react-native'
import { FontIcon } from '../../../atoms/images/FontIcon'
import {
  Container,
  AnimatedView,
  Gradient,
} from './GradientScoreIndicator.styles'

interface GradientScoreIndicatorProps {
  score: number
  style?: any
}

export const GradientScoreIndicator = ({
  score,
  ...props
}: GradientScoreIndicatorProps) => {
  const [width, setWidth] = useState(0)
  const [positionAnim] = useState(new Animated.Value(0))
  const endValue = width * (0.09 * score + 0.05)

  const animation = useMemo(
    () =>
      Animated.timing(positionAnim, {
        useNativeDriver: true,
        toValue: endValue,
        duration: 750,
      }),
    [endValue, positionAnim]
  )

  useEffect(() => {
    animation.start()
  }, [animation])

  return (
    <Container
      onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width)
      }}
      {...props}>
      <AnimatedView style={{ transform: [{ translateX: positionAnim }] }}>
        <FontIcon name='down-triangle' size={18} />
      </AnimatedView>
      <Gradient
        colors={['#51CB9F', '#FFD644', '#FF6086']}
        start={{ x: 1, y: 0 }}
        end={{ x: 0, y: 1 }}
        locations={[0, 0.49, 1]}
      />
    </Container>
  )
}
