import React from 'react'
import {
  Container,
  IntroContainer,
  RefererSeparator,
  ContactTitle,
  ContactContainer,
  FinalContainer,
} from './index.style'
import { useCompanyInfo } from '@core/services/users'
import { useTranslation } from 'react-i18next'
import { HTMLView, Text } from '@moodwork-ui'
import { PsychologistRefererCard } from '@ui/Moodwork-UI/organisms/PsychologistRefererCard'
import { FlatList, useWindowDimensions } from 'react-native'

export const Company = () => {
  const companyInfo = useCompanyInfo()
  const { t } = useTranslation('company')
  const { width } = useWindowDimensions()

  if (companyInfo?.data) {
    const itemWidth = companyInfo.data?.contacts?.reduce(
      (width: number, contact: { email: string }) => {
        return Math.max(
          Math.max(contact?.email?.length - 15, 0) * 12 + 300, // Add 12px for every character after the 15th character
          width
        )
      },
      300
    )

    // page margin = 240 | item right margin = 32
    const numColumn = ~~((width - 240) / (itemWidth + 32))
    return (
      <Container>
        <Text weight='bold' size='XXL'>
          {t('company.title')}
        </Text>
        <IntroContainer>
          <HTMLView source={companyInfo?.data?.intro_text} />
        </IntroContainer>
        <ContactTitle weight='bold' size='XL'>
          {t('company.contact-title')}
        </ContactTitle>
        <ContactContainer>
          <FlatList
            key={numColumn}
            numColumns={numColumn}
            ItemSeparatorComponent={() =>
              numColumn <= 1 ? <RefererSeparator /> : null
            }
            data={companyInfo.data?.contacts}
            renderItem={({ item, index }) => (
              <PsychologistRefererCard
                name={item.name}
                email={item.email}
                phone={item.phone}
                role={item.role || item.role_title}
                index={index}
                refererCount={companyInfo.data?.contacts?.length}
                itemWidth={itemWidth}
                numColumn={numColumn}
                description={item.description}
              />
            )}
          />
        </ContactContainer>
        {companyInfo.data.final_text && (
          <>
            <Text weight='bold' size='XL'>
              {t('company.links')}
            </Text>
            <FinalContainer>
              <HTMLView source={companyInfo.data.final_text} />
            </FinalContainer>
          </>
        )}
      </Container>
    )
  } else {
    // TODO: loading page
    return <></>
  }
}
