import { goBack } from '@core/lib/navigation'
import { FontIcon, Text } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import React from 'react'
import { Pressable } from 'react-native'
import { Container } from './index.styles'
import { NavigationProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

export default function BackButton({
  navigation,
}: {
  navigation: NavigationProp<ReactNavigation.RootParamList>
}) {
  const { t } = useTranslation(['resources'])

  return (
    <Container
      onPress={() => {
        goBack(navigation)
      }}>
      <FontIcon style={{ marginRight: 8 }} name='arrow-left' size={16} />
      <Text size='XXS'>{t('menu.back')}</Text>
    </Container>
  )
}
