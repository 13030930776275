import * as React from 'react'
import { useContactCategories } from '@core/services/contact'
import { PageContent } from '@ui/Moodwork-UI/templates/Page'
import SupportTemplate from '@ui/Moodwork-UI/templates/Support'
import {
  FormHeading,
  FormContent,
} from '@ui/Moodwork-UI/templates/Support/index.styles'
import { Text, SubTitle } from '@ui/Moodwork-UI'
import ContactForm from '@ui/Moodwork-UI/molecules/ContactForm'
import { useTranslation } from 'react-i18next'

export default function Contact() {
  const { data, isSuccess } = useContactCategories()
  const { t } = useTranslation('contact')

  return (
    <PageContent>
      <SupportTemplate>
        {isSuccess && data && (
          <>
            <FormHeading>
              <SubTitle>{t('tabs.contact.title')}</SubTitle>
              <Text>{t('description')}</Text>
            </FormHeading>
            <FormContent>
              <ContactForm categories={data} />
            </FormContent>
          </>
        )}
      </SupportTemplate>
    </PageContent>
  )
}
