import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from '@utils'
import { Border, Container } from './index.style'

export default function EntryPointLoading() {
  const theme = useTheme()
  return (
    <Container color={theme.colors.lightSecondaryGrey}>
      <Border>
        <ContentLoader
          backgroundColor={theme.colors.lightGrey}
          width={theme.device === 'large' ? '100%' : '119px'}
          height={theme.device === 'large' ? '74px' : '106px'}>
          {theme.device === 'large' ? (
            <>
              <Rect x='0' y='10' rx='100' ry='100' width='54' height='54' />
              <Rect x='80' y='25%' rx='3' ry='3' width='50%' height='50%' />
            </>
          ) : (
            <>
              <Rect x='32' y='0' rx='100' ry='100' width='54' height='54' />
              <Rect x='15%' y='70' rx='3' ry='3' width='70%' height='30px' />
            </>
          )}
        </ContentLoader>
      </Border>
    </Container>
  )
}
