import * as React from 'react'
import { Button } from '../Button'
import { Paragraph } from '../Paragraph'
import { Spacer } from '../Spacer'
import { Container, ImagePlaceholder, Image } from './Article.style'
import { ArticleProps } from './Article.types'

export default function Article({
  title,
  content,
  details,
  image,
}: ArticleProps) {
  return (
    <Container>
      {image ? <Image source={{ uri: image.url }} /> : <ImagePlaceholder />}
      <Paragraph title={title} content={content} details={details} />
    </Container>
  )
}
