import styled, { css } from 'styled-components/native'

export const Container = styled.View<{ fullscreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 30px;
  background: ${({ theme }) => theme.colors.white};
  max-width: 1360px;
  max-height: 95%;
  ${({ theme }) =>
    theme.device === 'large' ? css`width: 83.3333333333%;` : css`width: 90%;`}
  ${({ fullscreen }) =>
    fullscreen &&
    css`width: 100%; height: 100%; max-height: 100%; padding: 0;`};
`
