import { useDashboard } from '@core/services/dashboard'
import React, { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import Section from '@ui/Moodwork-UI/molecules/Dashboard/Section'
import { SubTitle } from '@moodwork-ui'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@utils'
import { Line } from '@ui/Moodwork-UI/atoms/Separator/Line'
import { trackEvent } from '@core/lib/analytics'

export default function SectionList() {
  const { data, isLoading } = useDashboard()
  const { t } = useTranslation('dashboard')
  const theme = useTheme()

  const sections = useMemo(
    () => data?.sections?.filter((section) => section?.resources?.length > 0),
    [data?.sections]
  )

  useEffect(() => {
    if (sections) {
      trackEvent('viewed_db_collections', {
        nb_collections: sections.reduce((accumulator, object) => {
          return accumulator + object.resources.length
        }, 0),
      })
    }
  }, [sections])

  if (!sections || sections?.length == 0) {
    return <></>
  }

  return (
    <>
      <SubTitle marginSize={`40px 0 16px 5%`}>
        {t('dashboard.sections.title')}
      </SubTitle>
      {sections?.map((section, index, array) => (
        <View key={'section-' + section.id}>
          <Section data={section} />
          {index < array.length - 1 && (
            <View
              style={{
                marginTop: -16,
                marginBottom: theme.spacings.L,
                marginHorizontal: '5%',
              }}>
              <Line color={'separatorGrey'} height={1} />
            </View>
          )}
        </View>
      ))}
    </>
  )
}
