import styled from 'styled-components/native'
import { Text } from '../Text'

export const Container = styled.View``

export const ItemContainer = styled.View`
  background-color: #e1e1e1;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 4px;
`

export const Content = styled(Text)`
  font-family: Inter_700Bold;
  font-size: 14px;
  text-transform: uppercase;
`

export const Items = styled.View`
  flex-direction: row;
`
