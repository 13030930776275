import styled, {css} from 'styled-components/native'

export const ResourceMarginBottomContentCSS = css`
  margin-bottom: ${({ theme }) => (theme.device === 'small' ? '24px' : '40px')};
`

export const ResourceContentCSS = css`

  ${({ theme }) => {
    switch (theme.device) {
      case 'small':
        return `margin-horizontal: ${theme.spacings.M}px;`
      case 'medium':
        return `margin-horizontal: ${theme.spacings.XL}px;`
      case 'large':
        return `margin-left: ${theme.spacings.XXXL}px;`
      default:
        return `margin-horizontal: ${theme.spacings.M}px;`
    }
  }};
`