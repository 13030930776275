import { useToggle } from '@core/lib/hooks'
import React from 'react'
import { PannelSelectionItem } from './HistoryFactors.styles'
import { PannelSelectionProps } from './interface'

export const PannelSelection = ({
  onPress,
  selected,
  children,
  element,
  index,
}: PannelSelectionProps) => {
  const [isHover, onHoverIn, onHoverOut] = useToggle(false)

  return (
    <PannelSelectionItem
      onPress={() => onPress(element, index)}
      selected={selected}
      hover={isHover}
      // @ts-ignore
      onMouseEnter={onHoverIn}
      // @ts-ignore
      onMouseLeave={onHoverOut}>
      {children}
    </PannelSelectionItem>
  )
}
