import { isExpiredStoredDate, storeDate } from '@core/lib/modal'
import { useToggle } from '@core/lib/hooks'
import { useSendNPS } from '@core/services/dashboard'
import { useUserInfo } from '@core/services/viewer'
import { FontIcon, Line, Text } from '@ui/Moodwork-UI/atoms'
import { StarCounter } from '@ui/Moodwork-UI/molecules'
import { Feedback } from '@ui/Moodwork-UI/molecules/Feedback'
import { useTheme } from '@ui/utils'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentWrapper,
  Container,
  NumbersContainer,
  TextContainer,
  CheckIconContainer,
  FeedbackContainer,
} from './index.styles'
import { trackEvent } from '@core/lib/analytics'
import { HeadContent } from '@ui/Moodwork-UI/templates/Dashboard/HeadContent'

const SURVEY_NPS_CANCEL_DATE = 'SURVEY_NPS_CANCEL_DATE'

export default function SurveyNPS() {
  const { t } = useTranslation(['resources', 'dashboard'])
  const [mark, onChangeMark] = React.useState<number | null>(null)
  const [feedbackSent, onChangeFeedbackSent] = React.useState(false)
  const [npsVisiblity, setVisible, setHidden] = useToggle(false)
  const sendNPS = useSendNPS()
  const { data, isLoading, isError } = useUserInfo()
  const theme = useTheme()

  useEffect(() => {
    if (sendNPS.isError) {
      onChangeMark(null)
      onChangeFeedbackSent(false)
    }
  }, [sendNPS.isError])

  useEffect(() => {
    if (data?.ask_promoter_score_counter) {
      isExpiredStoredDate(SURVEY_NPS_CANCEL_DATE, 60).then((notCanceled) => {
        notCanceled ? setVisible() : setHidden()
      })
    }
  }, [data?.ask_promoter_score_counter])

  const starPressed = (index: number) => {
    sendNPS.mutate({
      mark: index - 1,
      counter: data?.ask_promoter_score_counter || 1,
    })
    onChangeMark(index)
  }

  const feedbackPress = (comment: string, mark: number) => {
    sendNPS.mutate({
      mark: mark - 1,
      comment,
      counter: data?.ask_promoter_score_counter || 1,
    })
    onChangeFeedbackSent(true)
    cancelNPS()
  }

  const cancelNPS = () => {
    storeDate(SURVEY_NPS_CANCEL_DATE).then(setHidden)
  }

  if (isLoading || !data?.ask_promoter_score_counter) {
    return <></>
  }

  return (
    <HeadContent
      visible={npsVisiblity}
      onClose={() => {
        trackEvent('clicked_db_feedback_close')
        cancelNPS()
      }}>
      <ContentWrapper>
        {!feedbackSent && mark != null ? (
          <Feedback
            text={t(
              mark <= 6
                ? 'dashboard.nps.comment.detractor'
                : mark <= 8
                ? 'dashboard.nps.comment.passive'
                : 'dashboard.nps.comment.promoter',
              { ns: 'dashboard' }
            )}
            mark={mark}
            onPress={feedbackPress}
            fromDashboard={true}
          />
        ) : (
          <>
            {mark ? (
              <FeedbackContainer>
                <CheckIconContainer>
                  <FontIcon
                    name='check-circle'
                    color={theme.colors.secondary}
                  />
                </CheckIconContainer>
                <Text size={theme.device === 'small' ? 'S' : 'M'}>
                  {t('resources.mark.feedback-thanks')}
                </Text>
              </FeedbackContainer>
            ) : (
              <>
                <TextContainer>
                  <Text size='M' weight='medium'>
                    {t('dashboard.nps.question', { ns: 'dashboard' })}
                  </Text>
                </TextContainer>
                <NumbersContainer>
                  <StarCounter
                    trackLabel='clicked_db_feedback_mark'
                    trackProperties={{ mark: mark }}
                    onPress={starPressed}
                    mark={mark}
                    item='circle'
                    totalStar={11}
                  />
                </NumbersContainer>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </HeadContent>
  )
}
