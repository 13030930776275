/**
 * Providers
 */
import * as Store from './store'
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/fr'

export const ping = 'pong'

export const Provider = Store.Provider

export { AppCore } from './AppCore'

export * as UI from '../ui'
