import React, { useContext, useEffect, useState } from 'react'
import { Button, Link, Text, Title } from '@ui/Moodwork-UI'
import OTPInput from '@ui/Moodwork-UI/molecules/OTPInput'
import { AlertContext } from '@core/components/Alerthandler'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@core/lib/analytics'
import { CompanyCodeProps } from './index.type'
import { useTheme } from '@ui/utils'
import { View } from 'react-native'

export default function CompanyCode({
  onValidate,
  setCompanyCode,
  mutate,
  data,
  error,
}: CompanyCodeProps) {
  const [code, setCode] = useState('')
  const [isPinReady, setIsPinReady] = useState(false)
  const { setMessage, setType } = useContext(AlertContext)
  const { t } = useTranslation('sign')
  const theme = useTheme()

  useEffect(() => {
    if (isPinReady) {
      mutate('mw' + code)
    }
  }, [isPinReady, code])

  useEffect(() => {
    if (error) {
      setType('warning')
      setMessage(error.response?.data?.errors[0]?.detail?.company_code[0])
    }
  }, [error])

  useEffect(() => {
    if (data) {
      code !== '' && setCompanyCode('mw' + code)
      trackEvent('redirected_signup_to_privacy_statement')
      onValidate()
    }
  }, [data])

  return (
    <View style={{ marginHorizontal: 16, flex: 1, alignItems: 'center' }}>
      <Title marginSize='0'>
        {t('up.code.title')}
        <Text weight='bold' color='grey' size='L'>
          {' ' + 1}
        </Text>
        <Text weight='bold' color='grey' size='M'>
          /3
        </Text>
      </Title>
      <Text
        marginSize='32px 0'
        alignPosition={theme.device === 'small' ? 'left' : 'center'}>
        {t('up.code.subtitle')}
      </Text>
      <OTPInput
        code={code}
        setCode={setCode}
        setIsPinReady={setIsPinReady}
        maximumLength={8}
      />
      <Button
        label={t('up.common.next')}
        onPress={() => {
          mutate('mw' + code)
        }}
        style={{
          marginTop: theme.device === 'large' ? 32 : 0,
          marginBottom: 32,
        }}
      />
      <Link
        trackLabel='clicked_signup_signin'
        text={t('up.code.already-account')}
        url='/users/sign_in'
      />
    </View>
  )
}
