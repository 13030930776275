import styled from 'styled-components/native'
import { IS_BROWSER } from '../../ui/utils/device'

export const Content = styled.View`
  justify-content: center;
  /* flex:1; */ // TODO: check if need on native app
  padding-top: ${IS_BROWSER ? '32px' : '16px'}
  padding-bottom: 106px;
  height: 100%;
`
