import React, { useMemo } from 'react'
import { FontIcon, Text, Title } from '@ui/Moodwork-UI/atoms'
import { useTheme } from '@ui/utils'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useRoute } from '@react-navigation/native'

export default function AwaitConfirmation() {
  const { t } = useTranslation('sign')
  const theme = useTheme()
  const route = useRoute()
  const params = route.params

  const subtitle = useMemo(() => {
    return t(
      `up.email.subtitle-${
        params?.isPersonnal === 'true' || params?.isPersonnal
          ? 'private'
          : 'professionnal'
      }`
    )
  }, [params])

  return (
    <View style={{ marginHorizontal: 16, flex: 1, alignItems: 'center' }}>
      <Title marginSize='0'>
        {t('up.email.title')}
        <Text weight='bold' color='grey' size='L'>
          {' ' + 3}
        </Text>
        <Text weight='bold' color='grey' size='M'>
          /3
        </Text>
      </Title>
      <View style={{ marginTop: 32, flexDirection: 'row' }}>
        <FontIcon
          name='check-circle'
          size={19}
          color={theme.colors.lightGreen}
          style={{ marginRight: 8 }}
        />
        <Text
          marginSize='0'
          alignPosition={theme.device === 'small' ? 'left' : 'center'}>
          {subtitle}
        </Text>
      </View>
    </View>
  )
}
